import React from 'react';
import "./TabNavigation.scss";
import { convertToLowerCase } from '../../../helper/Validation';
import { isOffline } from '../../../common/helper';
import { serverNotRespond } from '../../../helper/ApiToast';
import { constantValue } from '../../../const/errorTypes';

function TabNavigation(props) {
    const { tabListArray = [], selectedBtn = "Upcoming", _handleGetSelectedMenu = () => { } } = props;
    const handleTab = (selectedItem = "") => {
        if(isOffline()) {
            serverNotRespond(constantValue.INTERNET_ERROR);
            return false;
        }
        _handleGetSelectedMenu(selectedItem);
    };

    return (
        <div className='btn_nav'>
            <div className='btn_nav_list'>
                {tabListArray.map((obj, index) => {
                    return (
                        <button key={convertToLowerCase(index + "btn_nav")}
                            className={`btn_nav_btn ${selectedBtn === obj ? "active" : " "}`} type='' onClick={() => handleTab(obj)}>
                            {obj}
                        </button>
                    )
                })}
            </div>
        </div>
    );
}

export default TabNavigation;
