/* eslint-disable no-var */
import IVSBroadcastClient from "amazon-ivs-web-broadcast";
import { addHelper, isOffline, leftHelper, updateToken } from "../common/helper";
import { constantValue } from "../const/errorTypes";
import { isScreenShareOnAIrUpdate, updateScreenShare } from "../firebase/firebaseRealtimeFunctions";
import { serverNotRespond } from "../helper/ApiToast";
import {
  diableAudio,
  diableScreen,
  diableVideo,
  getCamera,
  getMic,
} from "../helper/AwsDeviceAccess";
import { getCurrentOrgId } from "../helper/utility";
import { stopScreenShare } from "../janus/functions";
import store from "../store";
import {
  awsParticipantAction,
  awsParticipantDataAction,
  updateCameraStream,
  updateMicStream,
  updateScreenShareStream,
} from "../store/action/awsActions";
import { constructStream } from "./awsDataConstructor";
import {
  getStageData,
  handleMuteStatus,
  participantLeftResponse,
} from "./awsHelper";
import {
  endBroadcast,
  handleStreamAddedinBroadcast,
  updateParticipantStream,
} from "./broadcastFunction";
import { IS_IVS_STREAM_MODE } from "../helper/ApiUrl";

const { Stage, LocalStageStream, SubscribeType, StageEvents, ConnectionState } = IVSBroadcastClient;

let participants = 0;
let stage;
let strategy;
let screenshareStage;
export const createConstrains = () => {
  const constrains = {
    index: 0,
    x: (participants % 3) * 300,
    y: Math.floor(participants / 3) * 250,
    width: 300,
    height: 300,
  };
  participants += 1;
  return constrains;
};
const muteStatusDefault = {
  isVideoMute: false,
  isAudioMute: false,
};
/* eslint-disable max-len */
export const joinStage = async (stageData, muteStatus = muteStatusDefault) => {
  const { isVideoMute = false, isAudioMute = false } = muteStatus;
  console.log(isVideoMute, isAudioMute, "muteStatus");
  const checkStageData =
    stageData?.method === "create" ? getStageData(stageData) : stageData;
  const token = checkStageData?.participantToken;

  if (!token) {
    console.error(constantValue.PLEASE_CREATE_STAGE);
    return;
  }
  const localStreamData = store.getState()?.localStreams;
  // Retrieve the User Media currently set on the page
  const localCamera = localStreamData?.localCamera;
  const localMic = localStreamData?.localMic;
  // Create StageStreams for Audio and Video
  const cameraStageStream = new LocalStageStream(
    localCamera.getVideoTracks()[0]
  );
  const micStageStream = new LocalStageStream(localMic.getAudioTracks()[0]);

  strategy = {
    audioTrack: micStageStream,
    videoTrack: cameraStageStream,

    // optional
    updateTracks(newAudioTrack, newVideoTrack) {
      this.audioTrack = newAudioTrack;
      this.videoTrack = newVideoTrack;
    },

    // required
    stageStreamsToPublish() {
      return [this.audioTrack, this.videoTrack];
    },

    // required
    shouldPublishParticipant() {
      return true;
    },

    // required
    shouldSubscribeToParticipant() {
      return SubscribeType.AUDIO_VIDEO;
    },
  };

  stage = await new Stage(token, strategy);
  // Other available events:
  // https://aws.github.io/amazon-ivs-web-broadcast/docs/sdk-guides/stages#events

  stage.on(StageEvents.STAGE_CONNECTION_STATE_CHANGED, (state) => {
    if (state === "errored") {
      //  // store.dispatch(appStatusAction("errored"));
    }
  });

  stage.on(StageEvents.STAGE_PARTICIPANT_JOINED, (participant) => {
    store.dispatch(awsParticipantAction(participant));
  });

  stage.on(
    StageEvents.STAGE_PARTICIPANT_STREAMS_ADDED,
    (participant, streams) => {
      !participant.isLocal && store.getState()?.isHostRemoved === false && addHelper(participant);
      const streamData = constructStream(participant, streams);
      const stageParticipantsData = store.getState()?.stageParticipantsData;
      const broadcastBranding = store?.getState()?.broadcastBranding || {};
      const fbInBoardcastParticipants = broadcastBranding?.inBoardcastParticipants || [];

      if (stageParticipantsData[participant?.id]?.stream?.length > 0) {
        store.dispatch(
          awsParticipantDataAction({ ...streamData, updateLocalStream: true })
        );
        fbInBoardcastParticipants.includes(parseInt(streamData?.userId)) && updateParticipantStream(streamData);
      } else {
        store.dispatch(awsParticipantDataAction(streamData));
        console.log(streamData, "streamData---streamData")
        fbInBoardcastParticipants.includes(parseInt(streamData?.userId)) && handleStreamAddedinBroadcast(streamData);
      }
    }
  );

  stage.on(StageEvents.STAGE_PARTICIPANT_LEFT, (participant) => {
    if (!participant.isLocal) {
      leftHelper(participant);
    }
    participantLeftResponse(participant);
  });
  stage.on(StageEvents.STAGE_STREAM_MUTE_CHANGED, (participant, stream) => {
    handleMuteStatus(participant, stream);
  });
  try {
    await stage.join();
  } catch (err) {
    console.log(err, "err.messageerr.message");
    if (err.message === constantValue.TOKEN_EXPIRED || err.code === 1001) {
      if (isOffline()) {
        serverNotRespond(constantValue.INTERNET_ERROR);
      }
      updateToken(stageData);
    }
  }
};

export const leaveStage = async () => {
  if (stage) {
    stage.leave();
  }
  await endBroadcast();
};

export const strategyUpdate = async (myNewAudioStream, myNewVideoStream) => {
  const myNewAudio = await getMic(myNewAudioStream);
  const myNewVideo = await getCamera(myNewVideoStream);
  if (IS_IVS_STREAM_MODE) {
    const myNewVideoTrack = new LocalStageStream(myNewVideo.getVideoTracks()[0]);
    const myNewAudioTrack = new LocalStageStream(myNewAudio.getAudioTracks()[0]);
    strategy.updateTracks(myNewAudioTrack, myNewVideoTrack);
    stage.refreshStrategy();
  }
  diableVideo();
  diableAudio();
  const cameraPayload = {
    localCamera: myNewVideo,
    deviceId: myNewVideoStream,
  };
  const MicPayload = {
    localMic: myNewAudio,
    deviceId: myNewAudioStream,
  };
  store.dispatch(updateCameraStream(cameraPayload));
  store.dispatch(updateMicStream(MicPayload));
  const myNewVideoTrack = await new LocalStageStream(myNewVideo.getVideoTracks()[0]);
  const myNewAudioTrack = await new LocalStageStream(myNewAudio.getAudioTracks()[0]);
  strategy.updateTracks(myNewAudioTrack, myNewVideoTrack);
  stage.refreshStrategy();
  diableVideo();
  diableAudio();
};

export const initialScreenShare = async (stageData) => {
  const token = stageData?.token
  try {
    const media = await navigator.mediaDevices.getDisplayMedia({ audio: true, video: true });
    media.getVideoTracks()[0].addEventListener('ended', () => {
      const screenUnload = {
        screenShare: {},
      };
      isScreenShareOnAIrUpdate(false);
      store.dispatch(updateScreenShareStream(screenUnload));
      screenshareStage.leave();
      updateScreenShare(getCurrentOrgId(), false);
      screenshareStage = null
    });
    if (!store.getState()?.appStatus) {
      const screenShareTracks = media?.getTracks();
      screenShareTracks.forEach((track) => {
        track?.stop();
      });
      updateScreenShare(getCurrentOrgId(), false);
      isScreenShareOnAIrUpdate(false);
      return;
    }
    const screenPayload = {
      screenShare: media,
    };
    store.dispatch(updateScreenShareStream(screenPayload));
    const screenshare = { videoStream: new LocalStageStream(media.getVideoTracks()[0]) };
    const screenshareAudio = { AudioStream: media.getAudioTracks()[0] ? new LocalStageStream(media.getAudioTracks()[0]) : null };

    const screenshareStrategy = {
      stageStreamsToPublish: () => {
        if (screenshareAudio?.AudioStream) {
          return [screenshare.videoStream, screenshareAudio?.AudioStream];
        } else {
          return [screenshare.videoStream]
        }
      },
      shouldPublishParticipant: (participant) => {
        return true;
      },
      shouldSubscribeToParticipant: (participant) => {
        return SubscribeType.AUDIO_VIDEO;
      }
    }
    screenshareStage = new Stage(token, screenshareStrategy);
    screenshareStage.on(
      StageEvents.STAGE_PARTICIPANT_STREAMS_ADDED,
      (participant, streams) => {
        console.log(participant, streams, "STAGE_PARTICIPANT_STREAMS_ADDED")
      }
    );
    try {
      await screenshareStage.join();
    } catch (err) {
      updateScreenShare(getCurrentOrgId(), false)
    }
  } catch (err) {
    updateScreenShare(getCurrentOrgId(), false)
  }
}

export const leaveScreenShare = async () => {
  stopScreenShare();
  if (screenshareStage) {
    await screenshareStage.leave();
    diableScreen();
    isScreenShareOnAIrUpdate(false);
    screenshareStage = null
  }
}
