import { serverNotRespond, succToast } from "../../helper/ApiToast";
import { call, put, takeLatest } from "redux-saga/effects";
import { getpaymentOverviewService, paymentsListService, getRazorpaySessionIdService, getPaypalPaymentSessionId,
  updateUserPlanIDCampaignService } from "../../services/paymentsServices";
import { DO_GET_PAYMENTS_HISTORY_LIST, DO_GET_PAYMENT_OVERVIEW_DATA, GET_PAYPAL_SESSION_ID,
  GET_RAZORPAY_SESSION_ID, UPDATE_USER_PLANID_CAMPAIGN } from "../actionTypes/paymentsTypes";
import { apiUrl, payMent } from "../../helper/ApiUrl";
import { encodeGetParams } from "../../helper/Encypt";
import { getHeaders } from "../../common/httpRestServices";
import { getCustomerDetails, getStreamingLimit } from "../action/customeAction";

function* getPaymentsHistoryList(obj = {}) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const response = yield call(paymentsListService, obj);
    const { data = {} } = response || {};
    if (data.status === 200) {
      yield put({ type: "PAYMENTS_OVERVIEW_DATA", paymentsOverviewData: { ...response.data, data : { paymenthistory : response.data.data.paymentHistoryResponse } } });
    } else {
      serverNotRespond();
    }
  } catch (error) {
    serverNotRespond();
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
  yield put({ type: "DO_LOADING_PAGE", loading: false });
}

function* getPaymentOverviewSaga(obj = {}) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const response = yield call(getpaymentOverviewService, obj);
    const { data = {} } = response || {};
    if (data.status === 200) {
      yield put({ type: "PAYMENTS_OVERVIEW_DATA", paymentsOverviewData: { ...data }});
    } else {
      serverNotRespond();
    }
  } catch (err) {
    yield put({ type: "DO_LOADING_PAGE", loading: false });
    console.error(err);
  }
  yield put({ type: "DO_LOADING_PAGE", loading: false });
}

function* getRazorpaySessionID(obj = {}) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const RZresponse = yield call(getRazorpaySessionIdService, obj);
    const { data: { statusCode = 0, response = {}, message = '' } } = RZresponse || {};

    if (statusCode === 200) {
      const queryString = encodeGetParams({
        subscription_id : response?.paymentData?.id || '',
        order_id        : response?.paymentData?.id || '',
        auto_renewal    : response?.paymentData?.notes?.auto_renewal || '',
        customer_id     : response?.paymentData?.customer_id || '',
        currency_code   : obj?.data?.currency_code || '',
        plan_id         : response?.paymentData?.notes?.plan_type || '',
        authorization   : getHeaders()?.['Authorization'] || '',
      });
      window.location.href = `${apiUrl}${payMent.getRazorpayNavigation}${queryString}`;
    } else {
      serverNotRespond(response?.message || message);
    }
  } catch (err) {
    console.error(err);
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
  yield put({ type: "DO_LOADING_PAGE", loading: false });
  yield put({ type: "DO_PAYMENTS_LOADER", isPaymentLoading: false });
}

function* getPaypalSessionID(obj = {}) {

  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
      const PPresponse = yield call(getPaypalPaymentSessionId, obj);
      const { data: { statusCode = 0, response = {}, message = '' } } = PPresponse || {};

      if (statusCode === 200) {
        const link = response?.paymentData?.links.find((T) => T.rel === 'approve');
        window.location.href = link?.href;
      } else {
        serverNotRespond(response?.message || message);
      }
    } catch (err) {
      console.error(err);
      yield put({ type: "DO_LOADING_PAGE", loading: false });
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });
}

function* updateUserPlanIDCampaignSaga(obj = {}) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });

  try {
    const CMresponse = yield call(updateUserPlanIDCampaignService, obj);
    const { data: { statusCode = 0, response = {}, message = '' } } = CMresponse || {};

    if (statusCode === 200) {
      // Add this sccess message here and show payments screen, after 3 seconds this will be deleted in payments scree itself
      if (!window.location.pathname.includes('/payments')) {
        yield localStorage.setItem('campaignPaymentSuccessMessage', message);
        window.location.href = '/payments';
      } else succToast(message);

      yield getCustomerDetails();
      yield getStreamingLimit();
    } else {
      yield serverNotRespond(response?.message || message);
      if (!window.location.pathname.includes('/payments')) window.location.href = '/payments';
    }
  } catch (err) {
    console.error(err);
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
  yield put({ type: "DO_LOADING_PAGE", loading: false });
}

export default function* paymentsSaga() {
  yield takeLatest(DO_GET_PAYMENTS_HISTORY_LIST, getPaymentsHistoryList);
  yield takeLatest(DO_GET_PAYMENT_OVERVIEW_DATA, getPaymentOverviewSaga);
  yield takeLatest(GET_RAZORPAY_SESSION_ID, getRazorpaySessionID);
  yield takeLatest(GET_PAYPAL_SESSION_ID, getPaypalSessionID);
  yield takeLatest(UPDATE_USER_PLANID_CAMPAIGN, updateUserPlanIDCampaignSaga);
}
