import React from 'react';
import Modal from ".";

function Modal3(props) {
    const { children } = props;
    return (
        <Modal id="modal3" >
            {children}
        </Modal>
    );
}

export default Modal3;
