import i18next from "i18next";
import store from "../store";
import { updateParticipantCount, updateParticipantPostion } from "../firebase/firebaseRealtimeFunctions";
import { Get, Post } from "../common/httpRestServices";
import { REACT_APP_ALLOWANCE_MINUTES, apiUrl, envPath, oracleBaseUrl, webinarApiUrl } from "./ApiUrl";
import moment from "moment";
import { SDK } from "mf-chat-uikit/dist";
import { editStreamSaveAction, recentlyAddedSocialMedia } from "../store/action/editStreamAction";
import { failToast } from "./ApiToast";
import { constantValue } from "../const/errorTypes";
import { Children } from "react";
import { destructStage, destructStageId } from "../common/helper";
import { EJBD_GROUPS } from "../constant";
import { guestSessionOrgAction } from "../store/action/awsActions";
import { removeScreenShare } from "../aws/broadcastFunction";
import { getSimulcastMediaListAction } from "../store/action/facebookAction";
import { muteAudioJanus } from "../janus/functions";
import { ImgFacebook, ImgRTMP, ImgTwitch, ImgYoutube } from "../assets/images";
import { getSessionDetails } from "../services/guestService";

export const HexColorRegex = /^#([0-9a-f]{6}|[0-9a-f]{3})$/i;
export const isEmptyArray = (arr) => {
  return arr.length === 0;
};

export const translate = (key) => {
  return i18next.t(key);
};

export const millisecondsToTime = (duration) => {
  let seconds = parseInt((duration / 1000) % 60),
    minutes = parseInt((duration / (1000 * 60)) % 60),
    hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return (hours > 0 ? hours + ":" : "00:") + minutes + ":" + seconds;
};

export const currentStageArrn = (data) => {
  if (data.method === "join" || data.method === "guestJoin") {
    return data.stageArn;
  } else {
    return data.stage.arn;
  }
};

export const currentStageArrnId = (data) => {
  if (data.method === "join" || data.method === "guestJoin") {
    return data.stageArn;
  } else {
    return data.stage.arn;
  }
};
export const convertSectoMillesec = (data) => {
  return data * 1000;
};
export const getCurrentOrgId = () => {
  if (store.getState()?.isGuestLogin === "guestLogin") {
    const stageData = store?.getState()?.awsStageReducer;
    return stageData?.orgId;
  } else {
    const currentOrgData = JSON.parse(window.localStorage.getItem("currntOrgid"));
    return currentOrgData?.organisationId || store.getState()?.currentOrganisationReducer?.currentOrganisation?.organisationId;
  }
};

export const getCurrentOrgRoleId = () => {
  const currentOrgData = JSON.parse(window.localStorage.getItem("currntOrgid"));
  return currentOrgData?.userRoleId || currentOrgData?.invitedUserRoleId || store.getState()?.currentOrganisation?.invitedUserRoleId;
};

export const getCurrentOrgData = () => {
  const currentOrgData = JSON.parse(window.localStorage.getItem("currntOrgid"));
  return currentOrgData || store.getState()?.currentOrganisation;
};

export const getCurrentUserId = () => {
  if (store.getState()?.isGuestLogin === "guestLogin") {
    return store.getState()?.awsStageReducer?.userId;
  } else {
    return store.getState()?.CusPage?.customerDtls?.userId;
  }
};

export const capitalize = (str = "") => {
  const arr = str.split(" ");
  //loop through each element of the array and capitalize the first letter.
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const capitalizedString = arr.join(" ");
  return capitalizedString;
};

export const isHost = (stageData) => {
  if (stageData?.method === "create") {
    return true;
  } else if (stageData === "host") {
    return true;
  } else {
    return stageData.hostId === 0 && stageData.userId === getCurrentUserId() ? true : false;
  }
};

export const positioningHost = (arrayList, isHosted, hostId) => {
  if (isHosted) {
    const hostInd = arrayList.findIndex((el) => el && el?.userId == hostId);
    const hostData = arrayList.find((el) => el && el?.userId == hostId);
    arrayList.splice(hostInd, 1);
    arrayList.unshift(hostData);
    return arrayList;
  } else {
    return arrayList;
  }
};

export const isHostJoined = (arrayList, hostId) => {
  return (
    arrayList?.findIndex((el) => el && el?.userId == hostId) >= 0
  );
};

export const isScreenShareId = (userId) => {
  return typeof (userId) === "string" && userId?.split("-")[0] === "screenshare";
};

export const removedHostParticipants = (arrayList, hostId) => {
  return (
    arrayList.filter(ele => ele?.userId != hostId && !isScreenShareId(ele?.userId))
  );
};

export const removeMultipleSpaces = (str = "") => {
  return str.replace(/\s+/g, " ").trim();
};

export const removeMultipleAndStartSpaces = (str = "") => {
  return str.replace(/\s+/g, ' ').trimStart();
};

export const isCamMicGranted = (cameraPermissions, micPermissions) => {
  return !(
    micPermissions === "denied" ||
    micPermissions === "prompt" ||
    cameraPermissions === "denied" ||
    cameraPermissions === "prompt"
  );
};
export const convertMilliSecToHrandSec = (duration) => {
  const convertToNum = Number(duration);
  const minutes = Math.floor((convertToNum / (1000 * 60)) % 60),
    hours = Math.floor((convertToNum / (1000 * 60 * 60)) % 24);

  const hr = hours;
  const min = minutes;

  return `${hr}h ${min}m`;
};

export const convertMilliSecToHrandSecCustom = (duration = 0) => {
  const convertToNum = Number(duration);
  const seconds = Math.round((Math.floor((convertToNum / (1000)) % 60))),
    minutes = Math.floor((convertToNum / (1000 * 60)) % 60),
    hours = Math.floor((convertToNum / (1000 * 60 * 60)) % 24);
  const sec = seconds > 9 ? seconds : 0 + seconds;
  const hr = hours > 9 ? hours : 0 + hours;
  const min = minutes > 9 ? minutes : 0 + minutes;
  let converted = "";
  if (hr > 0 && sec > 0) {
    converted = min === 0 ? `${hr}hr  ${sec}sec` : `${hr}hr  ${min}min  ${sec}sec`;
  }
  else if (hr === 0 || min > 0) {
    converted = ` ${min}min  ${sec}sec`;
  }
  return converted;
};

export const convertMilliSecToHrandSecTimeFormat = (duration = 0) => {
  const convertToNum = Number(duration);
  const seconds = Math.round((Math.floor((convertToNum / (1000)) % 60))),
    minutes = Math.floor((convertToNum / (1000 * 60)) % 60),
    hours = Math.floor((convertToNum / (1000 * 60 * 60)) % 24);
  const sec = seconds > 9 ? seconds : 0 + seconds;
  const hr = hours > 9 ? hours : 0 + hours;
  const min = minutes > 9 ? minutes : 0 + minutes;
  return `${hr > 9 ? hr : "0" + hr}:${min > 9 ? min : "0" + min}:${sec > 9 ? sec : "0" + sec}`;
};

export const positioningMembers = (
  arrayList,
  isHosted,
  fbPosition1,
  fbPosition2,
  fbPosition3,
  fbPosition4,
  fbPosition5,
  fbPosition6,
  fbPosition7
) => {
  const hostData = arrayList[0];
  const fbPosition1Data = arrayList.find(
    (ele) => ele?.userId == fbPosition1.toString()
  );
  const fbPosition2Data =
    fbPosition2 !== fbPosition1
      ? arrayList.find((ele) => ele?.userId == fbPosition2.toString())
      : undefined;
  const fbPosition3Data =
    fbPosition3 !== fbPosition1 && fbPosition3 !== fbPosition2
      ? arrayList.find((ele) => ele?.userId == fbPosition3.toString())
      : undefined;
  let dataAltered = [];
  if (isHosted) {
    dataAltered = [hostData, fbPosition1Data, fbPosition2Data, fbPosition3Data];
  } else {
    dataAltered = [fbPosition1Data, fbPosition2Data, fbPosition3Data];
  }
  return dataAltered.filter((ele) => ele !== undefined);
};

export const reorderParticipantList = async (awsStageReducer) => {
  const broadcastBranding = store.getState()?.broadcastBranding;
  const participantCount = broadcastBranding?.participantCount;
  const fbPosition1 = broadcastBranding?.postion1 || "";
  const fbPosition2 = broadcastBranding?.postion2 || "";
  const fbPosition3 = broadcastBranding?.postion3 || "";
  const participantList = [fbPosition1, fbPosition2, fbPosition3];
  const currentPosition =
    participantList.findIndex((ele) => ele === getCurrentUserId()) + 1;
  updateParticipantCount(
    getCurrentOrgId(),
    currentStageArrn(awsStageReducer),
    participantCount - 1
  );
  switch (currentPosition) {
    case 1:
      updateParticipantPostion(
        getCurrentOrgId(),
        currentStageArrn(awsStageReducer),
        1,
        "ordering",
        participantList[1] ? participantList[1] : -1
      );
      updateParticipantPostion(
        getCurrentOrgId(),
        currentStageArrn(awsStageReducer),
        2,
        "ordering",
        participantList[2] ? participantList[2] : -1
      );
      updateParticipantPostion(
        getCurrentOrgId(),
        currentStageArrn(awsStageReducer),
        3,
        "ordering",
        -1
      );

      break;
    case 2:
      updateParticipantPostion(
        getCurrentOrgId(),
        currentStageArrn(awsStageReducer),
        2,
        "ordering",
        participantList[2] ? participantList[2] : -1
      );
      updateParticipantPostion(
        getCurrentOrgId(),
        currentStageArrn(awsStageReducer),
        3,
        "ordering",
        -1
      );
      break;
    case 3:
      updateParticipantPostion(
        getCurrentOrgId(),
        currentStageArrn(awsStageReducer),
        3,
        "ordering",
        -1
      );
      break;
    default:
    // code block
  }
};
export const setColorCode = (
  BannerStyle = 0,
  fbBannerBG = "",
  FbBannerTextColor = false
) => {
  if (BannerStyle > 0) {
    if (fbBannerBG && FbBannerTextColor) {
      return "#fff";
    } else {
      return "#000";
    }
  } else {
    return "#fff";
  }
};
export const streamDetails = {
  id: `OTF-${new Date().toISOString()}`,
  snippet: {
    title: `OTF-${new Date().toISOString()}`,
  },
  cdn: {
    format: "",
    ingestionType: "rtmp",
    resolution: "720p",
    frameRate: "30fps",
  },
};
export const ConvertMillsecToMin = (milliseconds) => {
  const number = Number(milliseconds);
  return Math.floor(number / 60000);
};

export const createMediaConfig = async (tokenResponse, userInfo, mediaType, rtmpDetails) => {
  const rtmpLength = rtmpDetails ? Object.keys(rtmpDetails).length : -1;
  let mediaConfigDetails;
  if (rtmpLength < 0) {
    mediaConfigDetails = {
      "media_id": userInfo?.sub,
      "media_name": userInfo?.name,
      "status": 1,
      "media_type": mediaType,
      "auth_token": tokenResponse?.access_token,
      "refresh_token": tokenResponse?.refresh_token,
      "expires_in": tokenResponse?.expires_in,
      "media_mail": userInfo?.email,
      "media_profile": userInfo?.picture,
    };
    if (mediaType === "FB") {
      mediaConfigDetails.config_type = "profile";
      mediaConfigDetails.custom_key = "auth";
      mediaConfigDetails.config_id = userInfo?.sub;
    }
  } else {
    mediaConfigDetails = {
      "stream_key": rtmpDetails?.streamKey.value,
      "media_id": rtmpDetails?.streamKey.value,
      "media_name": rtmpDetails?.title.value,
      "rtmp_url": rtmpDetails?.rtmpUrl.value,
      "media_type": "CR"
    };
  }

  const responseMediaConfig = await Post(`${apiUrl}simulcast/api/media-config`, mediaConfigDetails, true);
  recentlyAddedSocialMedia(mediaConfigDetails?.media_id);
  getSimulcastMediaListAction();
  return responseMediaConfig;
};

export const isAllowJoin = (scheduledTimeStamp) => {
  if (scheduledTimeStamp) {
    const now = moment.utc();
    const unixnew = moment(
      moment.utc(moment.unix(scheduledTimeStamp / 1000).utc())
    );
    const duration = moment.duration(unixnew.diff(now)).asMinutes();
    return REACT_APP_ALLOWANCE_MINUTES > duration;
  } else {
    return true;
  }
};

export const isAllowCreate = (scheduledTimeStamp) => {
  if (scheduledTimeStamp) {
    const now = moment.utc();
    const scheduledUTCTime = moment(
      moment.utc(moment.unix(scheduledTimeStamp / 1000).utc())
    );
    const duration = moment.duration(scheduledUTCTime.diff(now)).asMinutes();
    return REACT_APP_ALLOWANCE_MINUTES < duration;
  }
};

export const isAllowPreRecordCreate = (scheduledTimeStamp) => {
  if (scheduledTimeStamp) {
    const now = moment.utc();
    const scheduledUTCTime = moment(
      moment.utc(moment.unix(scheduledTimeStamp / 1000).utc())
    );
    const duration = moment.duration(scheduledUTCTime.diff(now)).asMinutes();
    return 5 < duration;
  }
};

export const dateStampToDate = (scheduledTimeStamp) => {
  return moment.unix(scheduledTimeStamp / 1000).utc();
};
export const INITIALS_COLOR_CODES = [
  "#9068BE",
  "#E62739",
  "#845007",
  "#3A4660",
  "#1D1E22",
  "#A55A44",
  "#005995",
  "#600473",
  "#CD5554",
  "#00303F",
  "#BE4F0C",
  "#35A998",
  "#FC4A1A",
  "#368CBF",
  "#7EBC59",
  "#201D3A",
  "#1C8CB9",
  "#737272",
  "#237107",
  "#52028E",
  "#AF0D74",
  "#5BA672",
  "#0DAFA4",
];

export const BG_COLOR_CODES =
{
  "#9068BE": "#E8D4FF",
  "#E62739": "#FFD4D8",
  "#845007": "#FFEED5",
  "#3A4660": "#D3E1FF",
  "#1D1E22": "#D2DBFF",
  "#A55A44": "#FFDDD4",
  "#005995": "#D2EDFF",
  "#600473": "#F8D4FF",
  "#CD5554": "#FFD3D3",
  "#00303F": "#D4F5FF",
  "#BE4F0C": "#FFE3D3",
  "#35A998": "#D4FFF9",
  "#FC4A1A": "#FFDCD3",
  "#368CBF": "#D2EEFF",
  "#7EBC59": "#E3FFD3",
  "#201D3A": "#D8D3FF",
  "#1C8CB9": "#D2F2FF",
  "#737272": "#E8E8E8",
  "#237107": "#DFFFD4",
  "#52028E": "#ECD4FF",
  "#AF0D74": "#FFD4EF",
  "#5BA672": "#D4FFE1",
  "#0DAFA4": "#D3FFFC",
};

export const getHashCode = (s) => {
  let _h = 0;
  for (let i = 0; i < s.length; i++)
    _h = (Math.imul(31, _h) + s.charCodeAt(i)) | 0;
  return _h;
};

export const getColorBgCodeInitials = (colorCode) => {
  return BG_COLOR_CODES[colorCode];
};

export const getColorCodeInitials = (name) => {
  if (name) {
    const hashCode = getHashCode(name);
    const code = hashCode % INITIALS_COLOR_CODES.length;
    return INITIALS_COLOR_CODES[Math.abs(code)];
  }
  return "#0376da";
};

export const getInitialsFromName = (name = "") => {
  let acronym,
    matches = [];
  if (!name) return null;
  if (name.match(/\b(\w)/g) && name.match(/\b(\w)/g).length === 1) {
    matches = name.split("");
    acronym = [matches[0], matches[1]].join("");
  } else {
    matches = name.match(/\b(\w)/g);
    acronym = matches ? [matches[0], matches[1]].join("") : "";
  }
  return acronym.toUpperCase();
};

export const addParticipantChat = (participant, name, groupJids) => {
  const getHost = participant.find(
    (item) =>
      item.userId ===
      JSON.parse(localStorage.getItem("userDetails")).data.userId
  ).isHost;
  if (getHost === 0) {
    const sessionName = name
      ? name
      : store.getState()?.awsStageReducer?.sessionName; //store
    const groupJid = groupJids
      ? groupJids
      : JSON.parse(localStorage.getItem("Group_Jid")) || "";
    if (participant.length >= 1) {
      participant.map(async (key) => {
        const firstName = key?.userEmail;
        const userId = firstName.replace(/[^A-Z0-9]/gi, "").toLowerCase();
        const userJidDetails = await SDK.getJid(userId);
        const uJID = [];
        uJID.push(userJidDetails.userJid);
        await SDK.addParticipants(groupJid, sessionName, uJID);
        await SDK.getGroupParticipants(groupJid);
      });
    }
  }
};
export const updateProfile = async (value) => {
  const searchParam = window?.location?.search.replace("?", "");
  const responseSession = await getSessionDetails(searchParam);
  const dispalyname = value === "" ? responseSession?.stageDetails?.displayName : value;
  return await SDK.setUserProfile(dispalyname, "", "", "", "");
};

export const createGroup = async (groupName) => {
  updateProfile();
  const searchParam = window?.location?.search.replace("?", "");
  const responseSession = await getSessionDetails(searchParam);
  const getUserProfile = await SDK.getUserProfile(responseSession?.stageDetails?.displayName);
  console.log("getUserProfile", getUserProfile)
  return await SDK.createGroup(groupName, [...EJBD_GROUPS[envPath]], "");
};

// // export const userProfile = async () => {
// //   const loginDetail = window.localStorage.getItem("userDetails")
// //   ? JSON.parse(window.localStorage.getItem("userDetails"))
// //   : {};
// //   const { data: { firstName = "" } = {} } = loginDetail;
// //   const setUserProfile = await SDK.setUserProfile(firstName, "", "", "", "");
// // }

export const deleteChatGroup = async () => {
  const host = store.getState().awsStageReducer;
  const groupJid = window.localStorage.getItem("Group_Jid")
    ? JSON.parse(window.localStorage.getItem("Group_Jid"))
    : "";
  if (isHost(host)) {
    const deleteChat = await SDK.userDeleteGroup(groupJid);
  }
};

export const filterSSDate = (parcipants) => {
  return parcipants.filter(ele => ele?.attributes?.type !== "screenShare");
};
export const filteredSSDate = (parcipants) => {
  return parcipants.filter(ele => ele?.attributes?.type === "screenShare");
};
export const currentUserFullName = () => {
  return store?.getState()?.CusPage?.customerDtls?.fullName;
};
export const mediaBroadcast = async (select, arn) => {
  // // const getsessionBroadcast = destructStageId(destructStage(arn));
  localStorage.removeItem("Select_Media");
  const selectMedia = {
    "media_type": select[0]?.media_type,
    "media_id": select[0]?.media_id,
    "config_id": select[0]?.config_id === null ? undefined : select[0]?.config_id,
    "config_type": select[0]?.config_type === null ? undefined : select[0]?.config_type,
    "broadcast_id": arn,
    "name": select[0]?.name ? select[0]?.name : select[0]?.media_name
  };
  setTimeout(() => {
    store.dispatch({ type: "DO_LOADING_PAGE", loading: true });
  }, 1500);
  const resSelectMedia = await Post(`${apiUrl}simulcast/api/media-broadcast`, selectMedia);
  if (resSelectMedia.status === 200) {
    setTimeout(() => {
      store.dispatch({ type: "DO_LOADING_PAGE", loading: false });
    }, 1500);
    store.dispatch(editStreamSaveAction(true));
    localStorage.setItem("Select_Media", JSON.stringify(select[0]?.media_type));
    localStorage.setItem("editStreamSave", true);
  } else {
    setTimeout(() => {
      store.dispatch({ type: "DO_LOADING_PAGE", loading: false });
    }, 1500);
  }
};

const multiMediaBroadcast = async (select, arn, sessionName, scheduleMedia) => {
  store.dispatch(editStreamSaveAction(false));
  setTimeout(() => {
    scheduleMedia !== "create" ? store.dispatch({ type: "DO_LOADING_PAGE", loading: true }) : store.dispatch({ type: "MEDIA_LOADER", isMediaLoading: true });
    let count = select.length;
    for (const element of select) {
      localStorage.removeItem("Select_Media");
      const selectMedia = {
        "media_config_id": element?.id,
        "broadcast_id": arn,
        "title": sessionName
      };

      const resSelectMedia = Post(`${apiUrl}simulcast/api/media-broadcast`, selectMedia);
      // eslint-disable-next-line no-loop-func
      resSelectMedia.then((selectResponse) => {
        if (selectResponse.status === 200) {
          count = count - 1;
          if (count <= 0) {
            store.dispatch(editStreamSaveAction(true));
            scheduleMedia !== "create" ? store.dispatch({ type: "DO_LOADING_PAGE", loading: false }) : store.dispatch({ type: "MEDIA_LOADER", isMediaLoading: false });
            localStorage.setItem("Select_Media", JSON.stringify(select[0]?.media_type));
          }
          localStorage.setItem("editStreamSave", true);
        } else {
          count = count - 1;
          if (count <= 0) {
            store.dispatch(editStreamSaveAction(true));
            scheduleMedia !== "create" ? store.dispatch({ type: "DO_LOADING_PAGE", loading: false }) : store.dispatch({ type: "MEDIA_LOADER", isMediaLoading: false });
          }
          if (selectResponse?.status === 400) {
            scheduleMedia !== "create" ? store.dispatch({ type: "DO_LOADING_PAGE", loading: false }) : store.dispatch({ type: "MEDIA_LOADER", isMediaLoading: false });
            const cryptoRandom = window.crypto.getRandomValues(new Uint32Array(1))[0];
            failToast(constantValue.SOMETHING, cryptoRandom);
          }
        }
      });
    }
  }, 1000);
};

export const selectStream = async (stageArn, select, selectedId, broadcastId, sessionName, scheduleMedia) => {
  localStorage.removeItem("Facebook_Live_Url");
  localStorage.removeItem("Select_Media");

  const getlistBroadcast = await Get(`${apiUrl}simulcast/api/media-broadcast/list?broadcast_id=${stageArn}`);
  const listBroadcast = getlistBroadcast?.data?.response;
  const unmatchedValues = [];
  if (listBroadcast.length > 0) {
    const dbList = listBroadcast?.filter(item1 => !select?.some(item2 => item1?.id === item2?.id));
    const changedSelectValue = select?.filter(item2 => !listBroadcast?.some(item1 => item1?.id === item2?.id));
    unmatchedValues.push(...dbList, ...changedSelectValue);
    const unmatchedId = unmatchedValues.map((res) => res.id);
    // for await(const element of unmatchedValues){
    if (typeof (unmatchedId[0]) === "string" && broadcastId) {
      const deleteSelectAcc = {
        "delete_id": unmatchedId,
      };
      Post(`${apiUrl}simulcast/api/media-broadcast/remove`, deleteSelectAcc, true);
    }
  }
  if (select?.length > 0) {
    multiMediaBroadcast(select, stageArn, sessionName, scheduleMedia);
  }
};

export const isTesting = () => {
  return false;
};

export const tooltipNames = (_getUnRegisteredEmails) => {
  let constructNames = "";
  const filteredMembers = _getUnRegisteredEmails.filter((item, i) => {
    return i > 2;
  });
  filteredMembers.forEach(ele => {
    constructNames = constructNames ? constructNames + "," + ele.emailId : ele.emailId;
  });
  return constructNames;
};

export const isCurrPaticipantJoined = () => {
  const broadcastBranding = store.getState()?.broadcastBranding;
  const { postion1 = -1, postion2 = -1, postion3 = -1, postion4 = -1, postion5 = -1, postion6 = -1, postion7 = -1, } = broadcastBranding;
  const ParticipantPosition = [postion1, postion2, postion3, postion4, postion5, postion6, postion7];
  return ParticipantPosition.some(ele => ele === getCurrentUserId());
};

export const orderInAdded = (orderedArray, addedArray) => {
  const orderedWithHost = [];
  addedArray.forEach(ele => orderedWithHost.push(orderedArray
    .find(elements => elements?.userId == ele)));
  return orderedWithHost.filter(ele => ele !== undefined);
};

export const getParticipantData = (participants, fbuserId) => {
  return participants.find(ele => ele?.userId == fbuserId);
};
export const handleLoader = (state = false) => {
  return store.dispatch({ type: "DO_LOADING_PAGE", loading: state });
};

export const handleWebinarLoader = (state = false) => {
  return store.dispatch({ type: "DO_LOADING_WEBINAR_PAGE", loading: state });
};

export const getStageParticipantData = (participants, fbuserId) => {
  return participants.find(ele => ele?.userId == fbuserId);
};
export const emptyFunction = () => void 0;

export const hasPermission = (destination = '') => {
  if (!destination) return false; // Return false if the destination is empty
  const organisations = store.getState()?.organisationMemberListReducer?.organisations || [];
  const currentOrg = organisations.filter((ele) => ele.organisationId === getCurrentOrgId())[0] || {};
  const userPermissions = currentOrg?.userPermissions;
  if (userPermissions) {
    const res = JSON.parse(userPermissions);
    return res?.[destination] || false;
  } else return false;
};

export const getUserConfig = (y = '') => {
  // Below else values are default values
  const result = hasPermission(y);
  if (y === 'onScreenParticipants') return result || 2;
  if (y === 'showPlaybackURL') return result || false;
  if (y === 'showEmbedTab') return result || false;
  if (y === 'streamRecordingType') return result || 'anyone';
  return result;
};

export const getStreamingLimitDetails = () => {
  const _sd = { ...store.getState()?.organisationMemberListReducer?.orgStreamingData };
  const usedPercentage = ((_sd?.planStreamingHours - _sd?.remainingStreamingHours) / _sd?.planStreamingHours) * 100;
  _sd.usedPercentage = parseFloat(usedPercentage);
  _sd.balanceTime = (_sd?.planStreamingHours - _sd?.remainingStreamingHours);
  return _sd;
};

export const emilimateDuplicate = (arr = []) => {
  const uniqueIds = [];
  if (Array.isArray(arr)) {
    const unique = arr?.filter(element => {
      const isDuplicate = uniqueIds.includes(element.id);
      if (!isDuplicate) {
        uniqueIds.push(element.id || element.stageParticipantId);
        return true;
      }
      return false;
    });
    return unique;
  }
};

export const removeOrgDuplicate = (orgList = []) => {
  const ownOrg = orgList?.find(ele => !ele?.invitedUserId);
  return orgList.filter(ele => {
    if (ele?.organisationId === ownOrg?.organisationId && ele?.invitedUserId) {
      return false;
    } else {
      return true;
    }
  });
};

export const Each = ({ render, of }) => Children.toArray(of.map((item, index) => render(item, index)));

export const isCurrentRoute = (searchKey = "") => {
  const awsStageReducer = store.getState()?.awsStageReducer;
  const stageArn = awsStageReducer?.stage?.arn || awsStageReducer?.stageArn;
  return searchKey !== destructStageId(destructStage(stageArn));
};

export const setUserIdStream = (rfUserName) => {
  if (rfUserName.includes("_") || (rfUserName.includes("-") && rfUserName.split("-")[0] === "screenshare")) {
    return rfUserName;
  } else {
    return parseInt(rfUserName);
  }
};

export const jIsScreenSharing = (janusParticipants) => {
  return janusParticipants.some((ele) => typeof (ele?.userId) === "string" && ele?.userId?.split("-")[0] === "screenshare");
};

export const screenSharingParticipant = (janusParticipants) => {
  return janusParticipants.find((ele) => typeof (ele?.userId) === "string" && ele?.userId?.split("-")[0] === "screenshare");
};

export const isRemovedScreenshare = (getStream, participantId) => {
  const removedStream = getStream?.find(ele => ele?.participantId === participantId);
  if (typeof (removedStream?.userId) === "string" && removedStream?.userId?.split("-")[0] === "screenshare") {
    removeScreenShare(removedStream);
  }
};

export const isVideoMuted = (userId) => {
  const fbIsVideoMuted = store.getState()?.broadcastBranding?.isVideoMuted || [];
  return fbIsVideoMuted?.includes(userId);
};

export const isAudioMuted = (userId) => {
  const fbIsAudioMuted = store.getState()?.broadcastBranding?.isAudioMuted || [];
  return fbIsAudioMuted?.includes(userId);
};

export const isAudioMutedPiped = (isLocalUserAudioMute, userId) => {
  if (userId === getCurrentUserId()) {
    return isLocalUserAudioMute;
  }
};

export const janusStreamProvider = (userId) => {
  const janusParticipants = store.getState()?.janusParticipants || [];
  return janusParticipants.find(ele => ele?.userId === userId)?.stream;
};

const joinUseridString = (arr = []) => {
  let value = "";
  arr.forEach(ele => {
    if (ele !== "screenshare") { value = value + "-" + ele; };
  });
  return value.slice(1);
};
export const getScreenShareUserId = (screenShareUserId = "") => {
  if (typeof (screenShareUserId) === "string" && screenShareUserId.split("-")[0] === "screenshare") {
    return parseInt(screenShareUserId.split("-")[1]) ? parseInt(screenShareUserId.split("-")[1]) : joinUseridString(screenShareUserId.split("-"));
  }
};

export const getIntegrations = (media) => {
  let socialIintegration = '';
  if (media && media.length !== 0) {
    const keys = Object.keys(media);
    // Loop through keys and access values
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      let value = media[key].media_type;
      if (value === 'TW')
        value = 'TWITCH';
      else if (value === 'FB')
        value = 'FACEBOOK';
      else if (value === 'YT')
        value = 'YOUTUBE';
      else if (value === 'CR')
        value = 'RTMP';
      socialIintegration += value + ", ";
    }
    socialIintegration = socialIintegration.slice(0, -2);
  }
  return (socialIintegration.length === 0 ? 'NIL' : socialIintegration);
};

export const getStreamingActiveTab = (isPast = false) => {
  const streamingActiveTab = store?.getState()?.commonData?.streamingActiveTab || "All";
  return isPast ? "Past" : streamingActiveTab;
};

export const getInternetTime = async () => {
  return Get(`${apiUrl}${webinarApiUrl.internetTime}`, false)
    .then((res) => {
      if (res?.status === 200) {
        return res?.data?.response;
      };
    })
    .catch((err) => {
      console.error("Error fetching internetTime data:", err);
    });
};

export const constructStageArn = (stageData) => {
  return stageData?.stageArn ? stageData : { ...stageData, stageArn: stageData?.membersdata[0]?.stageArn };
};

export const exractBrandingMediaFileName = (fileUrl = '') => {
  return oracleBaseUrl + fileUrl;
};

export const blobToBase64 = function (blob = "") {
  if (typeof blob === "object") {
    return URL.createObjectURL(blob);
  }
  else {
    return blob;
  }
};

export const matchExposure = (currentExposure, capability) => {
  const exposureTime = capability.exposureTime;
  if (currentExposure >= exposureTime.max - 12) {
    return exposureTime.max;
  } else if (currentExposure <= exposureTime.min + 12) {
    return exposureTime.min;
  }
};

export const isVideoMutedLive = (userId) => {
  const fbIsVideoMuted = store.getState()?.broadcastBranding?.isVideoMuted || [];
  return fbIsVideoMuted?.includes(userId);
};

export const isAudioMutedLive = (userId) => {
  const fbIsAudioMuted = store.getState()?.broadcastBranding?.isAudioMuted || [];
  return fbIsAudioMuted?.includes(userId);
};

export const constructGuestData = (data = {}) => {
  const { participantTokensResponse = {}, stageDetails = {} } = data;
  const attributesDto = participantTokensResponse?.attributesDto;
  const localGuestUserId = localStorage.getItem("guestUserId");
  return {
    ...stageDetails, ...participantTokensResponse, ...attributesDto, userId: localGuestUserId
  };
};

export const getParticipantListData = (participantList, userId) => {
  return participantList.filter((ele) => ele?.userId == userId);
};

export const isGuestLogin = () => {
  return store.getState()?.awsStageReducer?.method === "guestJoin";
};

export const isGuestUserId = (userId) => {
  return typeof (userId) === "string" && userId?.split("_")[0] !== "";
};

export const isUserInOrg = (orgList = [], sessionResponse = {}) => {
  const sessionOrgId = parseInt(sessionResponse?.participantTokensResponse?.attributesDto?.orgId);
  let sessionOrgData = {};
  if (sessionOrgId) {
    sessionOrgData = orgList.find(ele => ele?.organisationId === sessionOrgId);
    store.dispatch(guestSessionOrgAction(sessionOrgData));
    return sessionOrgData;
  }
};

export const mediaDataUtils = (isFbMuted, partData) => {
  const isAudioMute = partData.find(ele => ele?.userId == getCurrentUserId())?.stream;
  if (isFbMuted && isAudioMute?.getAudioTracks()[0]?.enabled) {
    muteAudioJanus();
  }
};

export const nonScreenSharePart = (janusParticipants) => {
  return janusParticipants.filter(ele => !isScreenShareId(ele?.userId));
};

export const actualPartCount = (_fbIsHostJoined, fbPartcount) => {
  return _fbIsHostJoined ? fbPartcount + 1 : fbPartcount;
};

export const getChannelArn = (data = "") => {
  const dataArr = data?.split('/');
  return dataArr[dataArr?.length - 1];
};

export const stringCampares = (str1 = "", str2 = "") => {
  return str1 && str1 === str2 && str2;
};

export const socialMediaLogos = (_chooseMedia = "") => {
  switch (_chooseMedia) {
    case "YT": return ImgYoutube;
    case "FB": return ImgFacebook;
    case "CR": return ImgRTMP;
    case "TW": return ImgTwitch;
    default: return false;
  };
};

export const getStageIdFromStageArn = (stageArn = "") => {
  return stageArn && stageArn.replace("stage/", "");
};

export const formatFileSize = (size = 0, isShowSizeText = true) => {
  size = Number(size); // Ensure size is a number
  if (isNaN(size) || size < 0) {
    throw new Error('Invalid input: size must be a non-negative number');
  }

  // Convert size to bytes for calculations
  const bytes = size;

  const kb = bytes / 1024;
  const mb = kb / 1024;
  const gb = mb / 1024;

  if (gb >= 1) {
    return gb.toFixed(1) + (isShowSizeText ? ' GB' : '');
  } else if (mb >= 1) {
    return mb.toFixed(1) + (isShowSizeText ? ' MB' : '');
  } else if (kb >= 1) {
    return kb.toFixed(1) + (isShowSizeText ? ' KB' : '');
  } else {
    return 0;
  };
};

export const validateMP4Url = (url = "") => {
  return (url.toLowerCase().endsWith('.mp4'));
};

export const checkedObjFirstIndex = (arr = []) => {
  // Assuming you want to move the object with id 2 to the first position
  if ( arr && arr.length > 0) {
    const objectToMove = arr.find(obj => obj?.isChecked === true);
    if(objectToMove && !Object.keys(objectToMove).length) return [];
    const index = arr.indexOf(objectToMove);
    // Remove the object from its current position
    if (index > -1) {
      arr.splice(index, 1);
    }
    // Insert the object at the first position
    arr.unshift(objectToMove);
    return arr.filter(item => item !== undefined) || [];
  }
  else {
    return arr.filter(item => item !== undefined) || [];
  }
};
