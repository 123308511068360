export const initalState = {
  brandingMediaData: {
    overlay : [],
    background : []
  },
};
export const brandingMediaReducer = (state = initalState, action = {}) => {
  switch (action.type) { //NOSONAR
    case 'BRANDING_MEDIA_FILES': {
        return {
            ...state,
            brandingMediaData: action.brandingMediaData,
        };
    }
    default:
        return {
            ...state
        };
    }
};
