import { Get, Post } from "../common/httpRestServices";
import { apiUrl, simulcastAPI } from "../helper/ApiUrl";

export const getSimulcastMediaListService = (broadcastId = '') => {
  return Get(`${apiUrl}${simulcastAPI.getMediaList}&broadcast_id=${broadcastId}`, true);
};

export const postSimulcastMediaService = (obj = {}) => {
  return Post(`${apiUrl}${simulcastAPI.postMedia}`, obj, true);
};

export const removeSimulcastMediaService = (id = '') => {
  const deleteSelectAcc = {
    "delete_id": [id],
  };
  return Post(`${apiUrl}${simulcastAPI.removeMedia}`, deleteSelectAcc, true);
};
