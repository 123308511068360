import React from "react";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { convertToLowerCase } from "../../../helper/Validation";
import { IconDropDown } from "../../../assets/otf";

const PaginationControl = (props = {}) => {
  const {
    pager = {},
    bottomDetails = {},
    buttonPress = () => { }, //click
  } = props;
  const { t } = useTranslation();
  const { totalPages = 0 } = bottomDetails;

  return (
    <div className="pagination">
      <button type="button"
        id="jestPreviousButton"
        onClick={pager.currentPage === 1 ? () => { } : () => buttonPress(pager.currentPage - 1)}
        className={`${pager.currentPage === 1 ? "pageDisabled" : ""} `}
      >
        <div className="btn previous" >
          <IconDropDown className="show_920 d-none" style={{ transform: "rotate(90deg)" }} />
          <span
            className="hide_920"
          >
            {t("COMMON.PREVIOUS")}
          </span>
        </div>
      </button>
      {(pager.pages || []).map((page, index) => (
        <button type="button"
          onClick={pager.currentPage === page ? () => { } : () => buttonPress(page)}
          key={convertToLowerCase(index + "pager-pages-jsdh")}
        >

          <div className={`page_num ${pager.currentPage === page ? "active" : "inactive"}`}>
            <span>{page}</span>
          </div>
        </button>
      ))}
      <span>{pager.currentPage} of {totalPages} </span>
      <button id="jestNextButton" type="button" onClick={pager.currentPage === pager.totalPages ? () => { } : () => buttonPress(pager.currentPage + 1)}
        className={`next ${pager.currentPage === pager.totalPages ? " pageDisabled " : ""
          }`}
      >
        <div className="btn">
          <IconDropDown className="show_920 d-none" style={{ transform: "rotate(-90deg)" }} />
          <span className="hide_920">{t("COMMON.NEXT")}</span>
        </div>
      </button>
    </div>
  );
};
PaginationControl.propTypes = {
  pager: propTypes.object,
  buttonPress: propTypes.func, //click
};
export default React.memo(PaginationControl);
