export const reduceDuplicateStream = (state = [], actionData = {}) => {
    const OutData = state.some(ele => ele?.userId == actionData?.userId) ?
        state : [...state, actionData];
    return OutData;
};

export const reduceLeavedParticipants = (state = [], actionData = {}) => {
    if (actionData?.leaving) {
        return state.filter(ele => !ele?.participantId || ele?.participantId !== actionData?.leaving);
    }
    if (actionData?.unpublished) {
        return state.filter(ele => !ele?.participantId || ele?.participantId !== actionData?.unpublished);
    }
};
