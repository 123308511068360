import * as awsTypes from "../actionTypes/awsTypes";

export const janusParticipantAction = (data = {}) => {
  return {
    type: awsTypes.JANUS_PARTICIPANT_STREAM,
    data,
  };
};

export const janusParticipantLeaving = (data = {}) => {
  return {
    type: awsTypes.JANUS_PARTICIPANT_REMOVED,
    data,
  };
};
