export const DATA_FETCH = 'DATA_FETCH';
export const CUSTOMER_ADD = 'CUSTOMER_ADD';
export const CUSTOMER_STORE = 'CUSTOMER_STORE';
export const DATA_FETCH_FAIL = 'DATA_FETCH_FAIL';
export const CUSTOMER_DELETE = 'CUSTOMER_DELETE';
export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE';
export const GET_CUST_DETAILS = 'GET_CUST_DETAILS';
export const CUSTOMER_GET_LIST = 'CUSTOMER_GET_LIST';
export const DATA_FETCH_SUCCESS = 'DATA_FETCH_SUCCESS';
export const UPDATE_CUS_LICENSE = 'UPDATE_CUS_LICENSE';//update licesnse key
export const GET_CUST_SEARCH_DETAILS = 'GET_CUST_SEARCH_DETAILS';
export const GET_CUS_PAGE_TEAMS_LIST = 'GET_CUS_PAGE_TEAMS_LIST';
export const GET_CUSTOMER_DELETE_REQUEST_COUNT = 'GET_CUSTOMER_DELETE_REQUEST_COUNT';
export const GET_CANCEL_SUBSCRIPTION_REQUEST_COUNT = 'GET_CANCEL_SUBSCRIPTION_REQUEST_COUNT';
export const GET_CUSTOMER_DELETE_REQUEST_LIST = 'GET_CUSTOMER_DELETE_REQUEST_LIST';
export const GET_CUSTOMER_DELETED_LIST = 'GET_CUSTOMER_DELETED_LIST';
export const GET_CUSTOMER_DELETE_FROM_REQUEST = 'GET_CUSTOMER_DELETE_FROM_REQUEST';
export const GET_CANCEL_SUBSCRIPTION_REQUEST_LIST = 'GET_CANCEL_SUBSCRIPTION_REQUEST_LIST';
export const GET_CANCELLED_SUBSCRIPTION_LIST = 'GET_CANCELLED_SUBSCRIPTION_LIST';
export const APPROVE_CANCELE_SUBSCRIPTION_REQUEST = 'APPROVE_CANCELE_SUBSCRIPTION_REQUEST';
export const GET_DELETE_REQUEST_STATUS = 'GET_DELETE_REQUEST_STATUS';
export const OPEN_CANCEL_SUBSCRIPTION_POPUP = 'OPEN_CANCEL_SUBSCRIPTION_POPUP';
export const GET_CUSTOMER_ORGANISATION_DETAILS = 'GET_CUSTOMER_ORGANISATION_DETAILS';
export const CURRENT_ORGANISATION_DATA = 'CURRENT_ORGANISATION_DATA';
export const Tempory_LOGIN_TOKEN = 'Tempory_LOGIN_TOKEN';
export const RECORD_TIME_START = 'RECORD_TIME_START';
export const RECORDED_TIME = 'RECORDED_TIME';
export const GET_CUSTOMER_STREAMING_LIMIT = 'GET_CUSTOMER_STREAMING_LIMIT';
export const LOGIN_AS_CUSTOMER = 'LOGIN_AS_CUSTOMER';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
