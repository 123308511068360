import * as awsTypes from "../actionTypes/awsTypes";
import { isFreeuser, msToTime, trackHubspotEvent } from "../../common/helper";
import { TRACK_END_LIVE } from "../../helper/ApiUrl";

export const awsCreateStageAction = (data = {}) => {
  return {
    type: awsTypes.CREATE_STAGE,
    data,
  };
};

export const awsJoinStage = (data = {}) => {
  return {
    type: awsTypes.JOIN_STAGE,
    data,
  };
};

export const awsGuestJoinStage = (data = {}) => {
  return {
    type: awsTypes.GUEST_JOIN_STAGE,
    data,
  };
};

export const isGuestLoginAction = (data = {}) => {
  return {
    type: "IS_GUEST_LOGIN_ACTION",
    data,
  };
};

export const awsParticipantAction = (data = {}) => {
  return {
    type: awsTypes.ADD_STAGE_PARTICIPANTS,
    data,
  };
};
export const awsParticipantUpdateAction = (data = {}) => {
  return {
    type: awsTypes.UPDATE_STAGE_PARTICIPANTS,
    data,
  };
};

export const awsParticipantDataAction = (data = {}) => {
  return {
    type: awsTypes.ADD_STAGE_STREAM_DATA,
    data,
  };
};

export const localStreamUpdate = (data = {}) => {
  return {
    type: awsTypes.ADD_LOCAL_STREAMS,
    data,
  };
};

export const localDeviceLists = (data = {}) => {
  return {
    type: awsTypes.ADD_DEVICES_LISTS,
    data,
  };
};

export const updateLogo = (data = {}) => {
  return {
    type: awsTypes.UPDATE_LOGO,
    data,
  };
};

export const updateBranding = (data = {}) => {
  return {
    type: awsTypes.UPDATE_BRANDING,
    data,
  };
};

export const localLeftStageAction = (data = {}) => {
  return {
    type: awsTypes.LOCAL_LEFT,
    data,
  };
};

export const userLeftAction = (data = "") => {
  return {
    type: awsTypes.USER_LEFT_STAGE,
    data,
  };
};

export const participantMuteStatus = (data = "") => {
  return {
    type: awsTypes.PARTICIPANT_MUTE_UPDATE,
    data,
  };
};

export const awsGetMeetingData = (data = {}) => {
  return {
    type: awsTypes.GET_MEET_DATA,
    data,
  };
};

export const awsGetTodayMeetData = (data = {}) => {
  return {
    type: awsTypes.GET_TODAY_MEET,
    data,
  };
};

export const awsGetPastMeetData = (data = {}) => {
  return {
    type: awsTypes.GET_PAST_MEET,
    data,
  };
};

export const awsGetScheduleMeetData = (data = {}) => {
  return {
    type: awsTypes.GET_SCHEDULED_MEET,
    data,
  };
};

export const awsGetPast = (data = {}) => {
  return {
    type: awsTypes.GET_PAST_DATA,
    data,
  };
};
export const awsGetUpcoming = (data = {}) => {
  return {
    type: awsTypes.GET_UPCOMING_DATA,
    data,
  };
};

export const awsGetBroadcastData = (data = {}) => {
  return {
    type: awsTypes.GET_BROADCAST_DATA,
    data,
  };
};

export const updateCallStatusAction = (data = {}) => {
  if (data && data.callStatus === 'ended') {
    trackHubspotEvent(TRACK_END_LIVE, {
      user_type: isFreeuser() ? 'free' : 'paid',
      call_duration: msToTime(data.duration)
    });
  }
  return {
    type: awsTypes.UPDATE_CALL_STATUS,
    data,
  };
};

export const updateCameraStream = (data = {}) => {
  return {
    type: awsTypes.UPDATE_CAMERA_STREAM,
    data,
  };
};

export const updateMicStream = (data = {}) => {
  return {
    type: awsTypes.UPDATE_MIC_STREAM,
    data,
  };
};

export const updateScreenShareStream = (data = {}) => {
  return {
    type: awsTypes.UPDATE_SCREEN_STREAM,
    data,
  };
};

export const updateMuteStatus = (data = {}) => {
  return {
    type: awsTypes.UPDATE_MUTE_STATUS,
    data,
  };
};

export const updateSpeaker = (data = {}) => {
  return {
    type: awsTypes.UPDATE_SPEAKER,
    data,
  };
};

export const updateMeetData = (data = {}) => {
  return {
    type: awsTypes.UPDATE_MEET_DATA,
    data,
  };
};

export const addMeetData = (data = {}) => {
  return {
    type: awsTypes.ADD_MEET_DATA,
    StageData: data,
  };
};

export const broadcastPostionsUpdate = (data = {}) => {
  return {
    type: awsTypes.UPDATE_POSITIONING,
    broadcastData: data,
  };
};

export const appStatusAction = (data = "") => {
  return {
    type: awsTypes.APP_STATUS,
    status: data,
  };
};

export const updateLocalCameraStream = (data = {}) => {
  return {
    type: awsTypes.UPDATE_LOCAL_VIDEO_STREAM,
    stream: data,
  };
};
export const updateLocalMicStream = (data = {}) => {
  return {
    type: awsTypes.UPDATE_LOCAL_AUDIO_STREAM,
    stream: data,
  };
};

export const updateBlockAccess = (data = {}) => {
  return {
    type: awsTypes.UPDATE_BLOCK_ACCESS,
    access: data,
  };
};

export const appOnlineStatusAction = (data = {}) => {
  return {
    type: awsTypes.APP_ONLINE_STATUS,
    status: data,
  };
};

export const camPermmissionStatusAction = (data = {}) => {
  return {
    type: awsTypes.CAM_PERMISSION,
    status: data,
  };
};

export const micPermmissionStatusAction = (data = {}) => {
  return {
    type: awsTypes.MIC_PERMISSION,
    status: data,
  };
};

export const tempVideoStream = (data = {}) => {
  return {
    type: awsTypes.TEMP_VIDEO_STREAM,
    status: data,
  };
};

export const tempAudioStream = (data = {}) => {
  return {
    type: awsTypes.TEMP_AUDIO_STREAM,
    status: data,
  };
};

export const tempAudioOutputAction = (data = {}) => {
  return {
    type: awsTypes.TEMP_AUDIO_STREAM_OUTPUT,
    status: data,
  };
};

export const tempInitials = (data = {}) => {
  return {
    type: awsTypes.TEMP_INITIALS,
    status: data,
  };
};

export const isHostAction = (data = null) => {
  return {
    type: awsTypes.IS_HOST_ACTION,
    status: data,
  };
};

export const updateLiveStatus = (isLive = false) => {
  return {
    type: awsTypes.IS_LIVE,
    isLive,
  };
};

export const startLoader = (isLive = false) => {
  return {
    type: awsTypes.START_LOADER,
    isLive,
  };
};

export const stopLoader = (isLive = false) => {
  return {
    type: awsTypes.STOP_LOADER,
    isLive,
  };
};

export const webinarUsersAction = (data = {}) => {
  return {
    type: awsTypes.WEBINAR_USERS,
    data,
  };
};

export const webinarCancelAction = (data = {}) => {
  return {
    type: awsTypes.WEBINAR_CANCEL,
    data,
  };
};

export const webinarBrandingUpdateAction = (data = {}) => {
  return {
    type: awsTypes.WEBINAR_BRANDING_UPDATE,
    data,
  };
};

export const webinarLogoUploadAction = (data = {}) => {
  return {
    type: awsTypes.WEBINAR_LOGO_UPLOAD,
    data,
  };
};

export const webinarBrandingAction = (data = {}) => {
  return {
    type: awsTypes.WEBINAR_BRANDING_DATA,
    data,
  };
};

export const webinarLogoSelectAction = (data = {}) => {
  return {
    type: awsTypes.WEBINAR_LOGO_SELECT,
    data,
  };
};

export const webinarLogoRemoveAction = (data = {}) => {
  return {
    type: awsTypes.WEBINAR_LOGO_REMOVE,
    data,
  };
};

export const webinarSettingsAction = (data = {}) => {
  return {
    type: "WEBINAR_SETTING_REDUCER",
    data,
  };
};

export const guestSessionOrgAction = (data = {}) => {
  return {
    type: "GUEST_AND_ORG_DETAILS",
    data,
  };
};

export const localJanusVideoStreamAction = (data = {}) => {
  return {
    type: "LOCAL_JANUS_VIDEO",
    data,
  };
};

export const poorConnectionSlowLink = (data = {}) => {
  return {
    type: "LOCAL_JANUS_POOR_CONNEC",
    data,
  };
};

export const localUnplugged = (data = {}) => {
  return {
    type: "LOCAL_UNPLUGGED",
    data,
  };
};

export const localUnpluggedReset = (data = {}) => {
  return {
    type: "LOCAL_UNPLUGGED_RESET",
    data,
  };
};
