import { Post } from "../common/httpRestServices";
import { apiUrl, serverStudioApi } from "../helper/ApiUrl";

export const startStreamService = (data = {}) => {
    const obj = {
        "stage_id": data?.stageId,
    };
    return Post(`${apiUrl}${serverStudioApi.startStream}`, obj, true);
};

export const startRecordService = (data = {}) => {
    const obj = {
        stage_id: data?.stageId,
        org_id : `${data?.organisationId}`,
        type : data?.recordType,
        record_name : data?.sessionName,
    };
    return Post(`${apiUrl}${serverStudioApi.startRecord}`, obj, true);
};

export const stopStreamService = (data = {}) => {
    const obj = {
        "stage_id": data.stageId,
    };
    return Post(`${apiUrl}${serverStudioApi.stopStream}`, obj, true);
};

export const simulcastStreamService = (data = {}) => {
    const obj = {
        "stage_id": data.access,
    };
    return Post(`${apiUrl}${serverStudioApi.simulcastStream}`, obj, true);
};
