import moment from "moment";
import {
  constructInvites,
  isOffline,
  removeUnderscoreFromPrefix,
  validateBothUnderscoreAndSpace,
  validateTitle,
} from "../common/helper";
import { Post, Get, Put, Delete } from "../common/httpRestServices";
import {
  apiUrl,
  loginApi,
  // registerApiUrl,
  awsStageAPI,
  customerApi,
} from "../helper/ApiUrl";
import { checkWhiteSpaces } from "../helper/Validation";
import store from "../store";
import { getParticipantListAction } from "../store/action/participantAction";
import { serverNotRespond } from "../helper/ApiToast";
import { constantValue } from "../const/errorTypes";
import { getCurrentOrgId, getCurrentUserId } from "../helper/utility";
import { getTeamMembers } from "./inviteMemberServices";
import { awsGetUpcoming } from "../store/action/awsActions";
import { encodeGetParams } from "../helper/Encypt";

export const fetchLogin = (obj = {}) => {
  const { data: { email = "", password = "", ssoType = "" } = {} } = obj;
  const logValue = {
    email: email,
    ssoType: ssoType,
    password: password,
  };
  return Post(`${apiUrl}${loginApi.logIn}`, logValue, true);
};

export const stageResponse = (stageData = {}) => {
  const {
    displayName = {},
    webinarDescription = "",
    membersdata = [],
    currentOrg = {},
    title = {},
    registration = false,
    webinarEvent = false,
    scheduledTime = null,
    hostScheduledTime = "",
    scheduledTimeZone = "",
    coverImage = "",
  } = stageData;
  const validTitle = validateTitle(title.value);
  const encodedDisplayName = encodeURIComponent(displayName.value);
  const validate = () => {
    const regData = membersdata.filter((ele) => ele.userId);
    if (membersdata.length > 0 && regData.length > 0) {
      return regData.map((ele) => JSON.stringify(ele.userId));
    } else {
      return "";
    }
  };
  const validateInvitedMails = () => {
    const unRegData = membersdata.filter(
      (ele) => !ele.userId || ele.emailVerified === 0
    );
    if (membersdata.length > 0 && unRegData.length > 0) {
      return unRegData.map((ele) => ele?.emailId);
    } else {
      return "";
    }
  };
  if (webinarEvent) {
    const formData = new FormData();
    if (typeof coverImage === 'object') {
      formData.append("coverImage", coverImage.file, coverImage?.fileName);
    }
    formData.append("invitedMailIds", validateInvitedMails());
    formData.append("orgId", currentOrg.organisationId);
    formData.append("participantUserIds", validate());
    formData.append("stageName", validTitle);
    formData.append("userRoleId", currentOrg.invitedUserRoleId || currentOrg.userRoleId);
    formData.append("scheduledTime", scheduledTime);
    formData.append("hostScheduledTime", hostScheduledTime);
    formData.append("timeZone", scheduledTimeZone);
    formData.append("displayName", encodedDisplayName);
    formData.append("description", webinarDescription.value);
    formData.append("registration", registration);
    formData.append("webinarEvent", webinarEvent);

    return Post(`${apiUrl}${awsStageAPI.createRoom}`, formData, true);
  }

  else if (scheduledTime && hostScheduledTime) {
    const formData = new FormData();
    formData.append("invitedMailIds", validateInvitedMails());
    formData.append("orgId", currentOrg.organisationId);
    formData.append("participantUserIds", validate());
    formData.append("stageName", validTitle);
    formData.append("displayName", encodedDisplayName);
    formData.append("userRoleId", currentOrg.invitedUserRoleId || currentOrg.userRoleId);
    formData.append("scheduledTime", scheduledTime);
    formData.append("hostScheduledTime", hostScheduledTime);
    formData.append("timeZone", scheduledTimeZone);

    return Post(
      `${apiUrl}${awsStageAPI.createRoom
      }?invitedMailIds=${validateInvitedMails()}&orgId=${currentOrg.organisationId
      }&displayName=${encodedDisplayName}&participantUserIds=${validate()}&stageName=${validTitle}&userRoleId=${currentOrg.invitedUserRoleId || currentOrg.userRoleId
      }&scheduledTime=${scheduledTime}&hostScheduledTime=${hostScheduledTime}&timeZone=${scheduledTimeZone}`,
      {},
      true
    );
  } else {
    const formData = new FormData();
    formData.append("invitedMailIds", validateInvitedMails());
    formData.append("orgId", currentOrg.organisationId);
    formData.append("displayName", encodedDisplayName);
    formData.append("participantUserIds", validate());
    formData.append("stageName", validTitle);
    formData.append("userRoleId", currentOrg.invitedUserRoleId || currentOrg.userRoleId);
    return Post(
      `${apiUrl}${awsStageAPI.createRoom
      }?invitedMailIds=${validateInvitedMails()}&orgId=${currentOrg.organisationId
      }&displayName=${encodedDisplayName}&participantUserIds=${validate()}&stageName=${validTitle}&userRoleId=${currentOrg.invitedUserRoleId || currentOrg.userRoleId
      }`,
      {},
      true
    );
  }
};

export const createSession = (sessionRequest = {}) => {
  return Post(`${apiUrl}${awsStageAPI.createSession}`, {
    ...sessionRequest,
    callStatus: sessionRequest?.callStatus || 'pending',
    userId: sessionRequest?.userId || getCurrentUserId().toString(),
  }, true);
};

const handlePostionValue = (ActiveTab) => {
  switch (ActiveTab) {
    case "All": return 0;
    case "Upcoming": return 1;
    case "Past": return 2;
    case "Cancelled": return 3;
    default: return 0;
  }
};

export const getMeetDataService = (meetData = {}) => {
  if (isOffline()) {
    serverNotRespond(constantValue.INTERNET_ERROR);
    return;
  }

  const { userId = "", orgId = "", searchData = {} } = meetData;
  const localUTC = moment().format('YYYY-MM-DD HH:mm:ss');
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const streamingActiveTab = store.getState()?.commonData?.streamingActiveTab || 0;

  const payload = {
    sort: 'desc',
    orgId: orgId,
    page: searchData.page || 1,
    size: searchData.size || 10,
    userId: userId,
    position: handlePostionValue(streamingActiveTab),
    localUtcTime: localUTC,
    timeZone: timeZone,
    searchName: (checkWhiteSpaces(searchData.searchTerm) || searchData.searchTerm) ? encodeURIComponent(removeUnderscoreFromPrefix(
      validateBothUnderscoreAndSpace(searchData.searchTerm))) : '',
  };

  const queryString = payload ? encodeGetParams(payload) : "";
  return Get(`${apiUrl}${awsStageAPI.getSession}${queryString}`, true);
};

export const getPastDataService = (meetData = {}) => {
  return getMeetDataService(meetData);
};

export const getUpcomingDataService = (upcomingData = {}) => {
  return getMeetDataService(upcomingData);
};

export const broadCastResponse = (data) => {
  const { stageArn = "", sessionName = "", organisationId = "", isLive = false } = data;
  // // isRecord = false, removed this KEY for default record enabling
  return Post(`${apiUrl}${awsStageAPI.createChannel}?orgId=${organisationId}&stageArn=${stageArn}
&stageName=${validateTitle(sessionName)}&isRecord=true&isLive=${isLive}`, {}, true
  );
};

export const updateSession = (data) => {
  const { stageArn = "", callStatus = "", duration = "" } = data;
  return Put(
    `${apiUrl}${awsStageAPI.updateSession}?stageArn=${stageArn}&status=${callStatus}&duration=${duration}`,
    {},
    true
  );
};

export const getListInOrg = async (getUnRegisteredEmails = []) => {
  const {
    data: { data: teamMembersDetails = [] },
  } = await getTeamMembers(getCurrentOrgId());
  const inviteMemberListReducer = teamMembersDetails?.teamMembersDetails;
  const getMemberId = getUnRegisteredEmails?.map(ele => {
    if (ele?.userId) {
      return ele;
    } else {
      const elementUserId = inviteMemberListReducer.find(element => element?.emailId === ele?.emailId)?.userId;
      return {
        ...ele, userId: elementUserId
      };
    }
  });
  return getMemberId;
};

export const updateParticipantToken = async (data) => {
  const {
    updatedArray = [],
    jwt = "",
    addFromBroadcast = false,
    stageArn = "",
    type = "",
    scheduledData = {},
    description = "desc",
    registration = false,
    webinarEvent = false,
    stageName = "",
    displayName = "",
    coverImage = "",
    searchData = {},
  } = data;
  const { regArray = [], unregArray = [] } = await constructInvites(
    updatedArray,
    stageArn
  );
  if (data === "screenShare") {
    Post(`${apiUrl}${awsStageAPI.updateRoom}?addFromBroadcast=false&invitedMailIds=ScreenShare&stageArn=arn:aws:ivs:ap-south-1:680703584604:stage/CBJi05Ip6RFq`, [],
      true, jwt);
  }
  if (type === "UPDATE" && scheduledData) {
    const formData = new FormData();
    formData.append("addFromBroadcast", addFromBroadcast);
    formData.append("invitedMailIds", unregArray);
    formData.append("stageArn", stageArn);
    formData.append("stageName", stageName);
    formData.append("displayName", displayName);
    formData.append("scheduledTime", scheduledData?.scheduledTime);
    formData.append("hostScheduledTime", scheduledData?.hostScheduledTime);
    formData.append("description", description);
    formData.append("registration", registration);
    formData.append("webinarEvent", webinarEvent);
    formData.append("timeZone", scheduledData?.scheduledTimeZone);
    if (regArray.length > 0) {
      regArray.map((obj, index) => {
        formData.append(`participantRequestDto[${index}].stageArn`, obj.stageArn);
        formData.append(`participantRequestDto[${index}].participantUserId`, obj.participantUserId);
        formData.append(`participantRequestDto[${index}].orgId`, obj.orgId);
      });
    }

    if (typeof coverImage === 'object') {
      formData.append("coverImage", coverImage.file, coverImage?.fileName);
    }

    const tokenResponseUpdate = await Post(
      `${apiUrl}${awsStageAPI.updateRoom}`, formData,
      true,
      jwt
    );
    if (tokenResponseUpdate?.data?.status === 200) {
      const streamingActiveTab = store.getState()?.commonData?.streamingActiveTab || 0;
      store.dispatch(
        awsGetUpcoming({
          userId: getCurrentUserId() || '',
          orgId: getCurrentOrgId() || '',
          searchData: { ...searchData },
          streamingActiveTab: streamingActiveTab,
        }));
      store.dispatch(getParticipantListAction(stageArn));
      return tokenResponseUpdate?.data?.data;
    }
  } else {
    const formData = new FormData();
    formData.append("addFromBroadcast", addFromBroadcast);
    formData.append("invitedMailIds", unregArray);
    formData.append("stageArn", stageArn);
    formData.append("timeZone", scheduledData?.timeZone);
    if (regArray.length > 0) {
      regArray.map((obj, index) => {
        formData.append(`participantRequestDto[${index}].stageArn`, obj.stageArn);
        formData.append(`participantRequestDto[${index}].participantUserId`, obj.participantUserId);
        formData.append(`participantRequestDto[${index}].orgId`, obj.orgId);
      });
    }
    if (typeof coverImage === 'object') {
      formData.append("coverImage", coverImage.file, coverImage?.fileName);
    }
    const tokenResponse = await Post(
      `${apiUrl}${awsStageAPI.updateRoom}`, formData,
      true,
      jwt
    );
    if (tokenResponse?.data?.status === 200) {
      store.dispatch(getParticipantListAction(stageArn));
      return tokenResponse?.data?.data;
    }
  }
};

export const signUpdateTokenService = async (data) => {
  const {
    updatedArray = [],
    jwt = "",
    addFromBroadcast = false,
    invitedMailIds = [],
    stageArn = "",
  } = data;

  const formData = new FormData();
  formData.append("addFromBroadcast", addFromBroadcast);
  formData.append("invitedMailIds", invitedMailIds);
  formData.append("stageArn", stageArn);
  if (updatedArray.length > 0) {
    updatedArray.map((obj, index) => {
      formData.append(`participantRequestDto[${index}].stageArn`, obj.stageArn);
      formData.append(`participantRequestDto[${index}].participantUserId`, obj.participantUserId);
      formData.append(`participantRequestDto[${index}].orgId`, obj.orgId);
    });
  }
  const tokenResponse = await Post(`${apiUrl}${awsStageAPI.updateRoom}`, formData, true, jwt);

  if (tokenResponse?.data?.status === 200) {
    return tokenResponse?.data?.data;
  }
};

export const endApiBroadcast = async (channelArn) => {
  const endResponse = await Post(
    `${apiUrl}${customerApi.stopStream}?channelArn=${channelArn}`,
    {},
    true
  );
  return endResponse?.data;
};

export const updateEncryptUrl = async (channelArn, encryptUrl, stageArn) => {
  const reqBodyEncUrl = {
    stageArn: stageArn,
    channelArn: channelArn,
    encryptedUrl: encryptUrl
  };
  const updateResponse = await Post(
    `${apiUrl}${customerApi.postEncryptUrl}`,
    reqBodyEncUrl,
    true
  );
  return updateResponse?.data;
};

export const deleteParticipantRequest = (removeList) => {
  return Delete(`${apiUrl}api/customer/removeParticipants?`, removeList, true);
};

export const getMeetDataServiceToday = (meetData = {}) => {
  const { userId = "", orgId = "", searchData = {}, position = "" } = meetData;
  const localUTC = moment.utc(moment()).format('YYYY-MM-DD HH:mm:ss');
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const payload = {
    sort: 'desc',
    orgId: orgId,
    page: searchData.page || 1,
    size: searchData.size || 10,
    userId: userId,
    position: handlePostionValue(position),
    localUtcTime: localUTC,
    timeZone: timeZone,
    searchName: (checkWhiteSpaces(searchData.searchTerm) || searchData.searchTerm) ? encodeURIComponent(removeUnderscoreFromPrefix(
      validateBothUnderscoreAndSpace(searchData.searchTerm))) : '',
  };
  const queryString = payload ? encodeGetParams(payload) : "";
  return Get(`${apiUrl}${awsStageAPI.getSession}${queryString}`, true);
};

export const getWebinarUserListApiCall = (data) => {
  const params = encodeGetParams(data);
  return Get(
    `${apiUrl}${awsStageAPI.WebinarUsers}?${params}`,
    true
  );
};

export const updateWebinarBranding = (stageData = {}) => {
  const {
    webinarToken = "",
    backgroundColor = "transparent",
    reminderUpdate = false,
    emailReminder1 = true,
    emailReminder2 = true,
    emailReminder3 = true,
    coverImageDelete = false,
    logoDelete = false,
    fileName = "",
    coverImage = "",
    logos = []
  } = stageData;

  if (webinarEvent) {
    const formData = new FormData();
    if (typeof coverImage === 'object') {
      formData.append("coverImage", coverImage.file, coverImage?.fileName);
    }
    if (logos.length > 0) {
      logos?.map((obj, index) => {
        formData.append(`logos[${index}]`, obj.logos.file);
      });
    }
    formData.append("webinarToken", webinarToken);
    formData.append("backgroundColor", backgroundColor);
    formData.append("reminderUpdate", reminderUpdate);
    formData.append("emailReminder1", emailReminder1);
    formData.append("emailReminder2", emailReminder2);
    formData.append("emailReminder3", emailReminder3);
    formData.append("coverImageDelete", coverImageDelete);
    formData.append("logoDelete", logoDelete);
    formData.append("fileName", fileName);
    formData.append("fileName", fileName);

    return Post(`${apiUrl}${awsStageAPI.createStage}`, formData, true);
  }
}

export const getWebinarCancelApiCall = (data) => {
  const params = encodeGetParams(data);
  return Post(
    `${apiUrl}${awsStageAPI.cancelWebinar}?${params}`,
    true
  );
};

/**
 * updatewebinarbrandings
 * second Params,Header Need or Not
 */
export const updateWebinarbrandingsApiCall = (data = {}) => {

  const {
    webinarToken = "", backgroundColor = "transparent", reminderUpdate = true, emailReminder1 = true,
    emailReminder2 = true, emailReminder3 = true, coverImageDelete = false, fileName = "", coverImage = ""
  } = data;

  const formData = new FormData();

  formData.append("webinarToken", webinarToken);
  formData.append("backgroundColor", backgroundColor);
  formData.append("reminderUpdate", reminderUpdate);
  formData.append("emailReminder1", emailReminder1);
  formData.append("emailReminder2", emailReminder2);
  formData.append("emailReminder3", emailReminder3);
  formData.append("coverImageDelete", coverImageDelete);
  formData.append("logoDelete", false);
  formData.append("fileName", fileName);

  if (typeof coverImage === 'object') {
    formData.append("coverImage", coverImage.file, coverImage?.fileName);
  }
  return Post(`${apiUrl}${customerApi.updatewebinarbrandings}`, formData, true);
};

/**
* updateWebinarLogo
*/
export const webinarLogoSelectApiCall = (data = {}) => {
  const params = encodeGetParams(data);
  return Put(`${apiUrl}${customerApi.updateWebinarLogo}?${params}`, {}, true);
};

export const webinarLogoRemoveApiCall = (data = {}) => {
  const { fileName = "", webinarToken = "", } = data;
  const formData = new FormData();
  formData.append("reminderUpdate", false);
  formData.append("coverImageDelete", false);
  formData.append("logoDelete", true);
  formData.append("webinarToken", webinarToken);
  formData.append("fileName", fileName);
  return Post(`${apiUrl}${customerApi.updatewebinarbrandings}`, formData, true);
};

export const webinarLogoUploadApiCall = (data = {}) => {
  const { logos = "", webinarToken = "", } = data;
  const formData = new FormData();
  formData.append("reminderUpdate", false);
  formData.append("coverImageDelete", false);
  formData.append("logoDelete", false);
  formData.append("webinarToken", webinarToken);
  if (logos?.length > 0) {
    logos?.map((obj, index) => {
      if (typeof obj !== "string") formData.append(`logos[${index}]`, obj, obj.name || `defaultLogo.png`);
    });
  }
  return Post(`${apiUrl}${customerApi.updatewebinarbrandings}`, formData, true);
};
