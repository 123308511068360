import { internetStatusDecrypt } from "./Encypt";
import toastService from "../common/toastServices";
import { constantValue } from "../const/errorTypes";
import ToastLayout from "../common/ToastContainerJSX/ToastLayout";

export const infoToast = (successMsg = "", informToastId = "") => {
  toastService.customToast(<ToastLayout heading={"Info"} type={"info"} desc={successMsg} />,
    informToastId
  );
};
export const APPVersionToast = (successMsg = "", informToastId = "") => {
  toastService.customToast(<ToastLayout heading={"Success"} type={"success"} desc={successMsg} />,
    informToastId
  );
};
export const failToast = (failMsg = "", failToastId = "", duration = true) => {
  if (failMsg === "") {
    return;
  }

  const cryptoRandom = window.crypto.getRandomValues(new Uint32Array(1))[0] || 1;
  const internetStatus = internetStatusDecrypt() || true;
  toastService.customToast(<ToastLayout heading={"Error"} type={"error"} desc={internetStatus ? failMsg : constantValue.INTERNET_ERROR} />,
    `failToastId${cryptoRandom}`, duration);
};

export const copyToast = (cpyMsg = "", toastId = "") => {
  toastService.customToast(<ToastLayout className="sm" heading={"Info"} type={"info"} desc={cpyMsg} />,
    toastId
  );
};

export const succToast = (Msg = "", successtoastId = "") => {
  toastService.customToast(<ToastLayout heading={"Success"} type={"success"} desc={Msg} />, successtoastId);
};

export const warnToast = (Msg = "", warnToastId = "") => {
  toastService.customToast(<ToastLayout heading={"Alert"} type={"warning"} desc={Msg} />,
    warnToastId
  );
};

export const serverNotRespond = (Msg = "", toastIdNotRespond = "") => {
  toastService.customToast(<ToastLayout heading={"Error"} type={"error"} desc={Msg || constantValue.SERVER_ERROR} />,
    toastIdNotRespond);
};

export const toastInternet = (Msg = "", toastIdInternet = "") => {
  toastService.customToast(<ToastLayout heading={"Info"} type={"error"} desc={Msg} />,
    toastIdInternet
  );
};
