import { Get, Post } from "../common/httpRestServices";
import { Plans } from "../components/MainContent/Payments/commonPayments";
import { apiUrl, payMent } from "../helper/ApiUrl";
import { encodeGetParams } from "../helper/Encypt";

export const paymentsListService = (obj = {}) => {
  const queryString = obj ? encodeGetParams(obj.data) : null;
  return Get(`${apiUrl}${payMent.paymentHistory}${queryString}`, true);
};

export const getRazorpaySessionIdService = (obj = {}) => {
  return Post(`${apiUrl}${payMent.getRazorpaySessionId}`, {...obj.data}, true);
};

export const getPaypalPaymentSessionId = async (passPaymentData = {}) => {
  return Post(`${apiUrl}${payMent.createPaypalSessionId}`, passPaymentData.data, true);
};

export const updateUserPlanIDCampaignService = (obj = {}) => {
  return Post(`${apiUrl}${payMent.updateUserPlanIDCampaign}`, {...obj.data}, true);
};

export const getpaymentOverviewService = (obj = {}) => {
  const payload = { ...obj.data };
  payload.createdatStart = payload?.startDate || '';
  payload.createdatEnd = payload?.endDate || '';
  const planDetails = Plans.find((pl) => pl.name === payload?.planType);
  payload.planTypeId = planDetails?.id || '';
  payload.status = payload?.listType === 'receivedPayment' ? true : payload.status;
  payload.sortColumn = payload?.listType === 'receivablePayment' ? 'subscriptionDueAt' : '';
  payload.sortType = payload?.listType === 'receivablePayment' ? 'desc' : '';

  const queryString = payload ? encodeGetParams(payload) : null;
  return Get(`${apiUrl}${payMent.paymentInvoiceOverview}${queryString}`, true);
};
