import React from 'react';
import Modal from '.';
import Modal1 from './Modal1';
import Modal2 from './Modal2';
import Modal3 from './Modal3';
import Modal4 from './Modal4';
import Modal5 from './Modal5';
import Modal6 from './Modal6';

function ModalWraper(props = {}) {
    const { index = 1, children = "" } = props;

    const handleModal = () => {
        switch (index) {
            case 0: return (<Modal>{children}</Modal>);
            case 1: return (<Modal1>{children}</Modal1>);
            case 2: return (<Modal2>{children}</Modal2>);
            case 3: return (<Modal3>{children}</Modal3>);
            case 4: return (<Modal4>{children}</Modal4>);
            case 5: return (<Modal5>{children}</Modal5>);
            case 6: return (<Modal6>{children}</Modal6>);
            default: return (<Modal>{children}</Modal>);
        }
    };

    return (
        handleModal()
    );
}

export default ModalWraper;
