import { BROADCAST_RESOLUTION } from "../helper/ApiUrl";

// maxWidthBasic = 848 maxHeightBasic = 477
export const layoutPositionBasic = [
    [],
    [{ index: 5 }],
    [
        { index: 2, height: 225, width: 400, x: 15, y: 127 },
        { index: 2, height: 225, width: 400, x: 435, y: 127 },
    ],
    [
        { index: 2, height: 225, width: 400, x: 15, y: 10 },
        { index: 2, height: 225, width: 400, x: 435, y: 10 },
        { index: 2, height: 225, width: 400, x: 225, y: 250 },
    ],
    [
        { index: 2, height: 225, width: 400, x: 15, y: 10 },
        { index: 2, height: 225, width: 400, x: 435, y: 10 },
        { index: 2, height: 225, width: 400, x: 15, y: 250 },
        { index: 2, height: 225, width: 400, x: 435, y: 250 },
    ],
    [
        { index: 2, height: 144, width: 256, x: 20, y: 61 },
        { index: 2, height: 144, width: 256, x: 296, y: 61 },
        { index: 2, height: 144, width: 256, x: 572, y: 61 },

        { index: 2, height: 144, width: 256, x: 109, y: 266 },
        { index: 2, height: 144, width: 256, x: 474, y: 266 },
    ],
    [
        { index: 2, height: 144, width: 256, x: 20, y: 61 },
        { index: 2, height: 144, width: 256, x: 296, y: 61 },
        { index: 2, height: 144, width: 256, x: 572, y: 61 },

        { index: 2, height: 144, width: 256, x: 20, y: 266 },
        { index: 2, height: 144, width: 256, x: 296, y: 266 },
        { index: 2, height: 144, width: 256, x: 572, y: 266 },

    ],
    [
        { index: 2, height: 144, width: 256, x: 20, y: 12 },
        { index: 2, height: 144, width: 256, x: 296, y: 12 },
        { index: 2, height: 144, width: 256, x: 572, y: 12 },

        { index: 2, height: 144, width: 256, x: 20, y: 168 },
        { index: 2, height: 144, width: 256, x: 296, y: 168 },
        { index: 2, height: 144, width: 256, x: 572, y: 168 },

        { index: 2, height: 144, width: 256, x: 296, y: 323 },
    ],
    [
        { index: 2, height: 144, width: 256, x: 20, y: 12 },
        { index: 2, height: 144, width: 256, x: 296, y: 12 },
        { index: 2, height: 144, width: 256, x: 572, y: 12 },

        { index: 2, height: 144, width: 256, x: 20, y: 168 },
        { index: 2, height: 144, width: 256, x: 296, y: 168 },
        { index: 2, height: 144, width: 256, x: 572, y: 168 },

        { index: 2, height: 144, width: 256, x: 109, y: 323 },
        { index: 2, height: 144, width: 256, x: 474, y: 323 },
    ]
];

export const layoutPositionImageBasic = [
    [],
    [{ index: 6 }],
    [
        { index: 3, height: 225, width: 400, x: 15, y: 127 },
        { index: 3, height: 225, width: 400, x: 435, y: 127 },
    ],
    [
        { index: 3, height: 225, width: 400, x: 15, y: 10 },
        { index: 3, height: 225, width: 400, x: 435, y: 10 },
        { index: 3, height: 225, width: 400, x: 225, y: 250 },
    ],
    [
        { index: 3, height: 225, width: 400, x: 15, y: 10 },
        { index: 3, height: 225, width: 400, x: 435, y: 10 },
        { index: 3, height: 225, width: 400, x: 15, y: 250 },
        { index: 3, height: 225, width: 400, x: 435, y: 250 },
    ],
    [
        { index: 3, height: 144, width: 256, x: 20, y: 61 },
        { index: 3, height: 144, width: 256, x: 296, y: 61 },
        { index: 3, height: 144, width: 256, x: 572, y: 61 },

        { index: 3, height: 144, width: 256, x: 109, y: 266 },
        { index: 3, height: 144, width: 256, x: 474, y: 266 },
    ],
    [
        { index: 3, height: 144, width: 256, x: 20, y: 61 },
        { index: 3, height: 144, width: 256, x: 296, y: 61 },
        { index: 3, height: 144, width: 256, x: 572, y: 61 },

        { index: 3, height: 144, width: 256, x: 20, y: 266 },
        { index: 3, height: 144, width: 256, x: 296, y: 266 },
        { index: 3, height: 144, width: 256, x: 572, y: 266 },

    ],
    [
        { index: 3, height: 144, width: 256, x: 20, y: 12 },
        { index: 3, height: 144, width: 256, x: 296, y: 12 },
        { index: 3, height: 144, width: 256, x: 572, y: 12 },

        { index: 3, height: 144, width: 256, x: 20, y: 168 },
        { index: 3, height: 144, width: 256, x: 296, y: 168 },
        { index: 3, height: 144, width: 256, x: 572, y: 168 },

        { index: 3, height: 144, width: 256, x: 296, y: 323 },
    ],
    [
        { index: 3, height: 144, width: 256, x: 20, y: 12 },
        { index: 3, height: 144, width: 256, x: 296, y: 12 },
        { index: 3, height: 144, width: 256, x: 572, y: 12 },

        { index: 3, height: 144, width: 256, x: 20, y: 168 },
        { index: 3, height: 144, width: 256, x: 296, y: 168 },
        { index: 3, height: 144, width: 256, x: 572, y: 168 },

        { index: 3, height: 144, width: 256, x: 109, y: 323 },
        { index: 3, height: 144, width: 256, x: 474, y: 323 },
    ]
];

export const layoutPositionAdvance = [
    [],
    [{ index: 5 }],
    [
        { index: 2, height: 450, width: 800, x: 106, y: 315 },
        { index: 2, height: 450, width: 800, x: 1012, y: 315 },
    ],
    [
        { index: 2, height: 450, width: 800, x: 106, y: 45 },
        { index: 2, height: 450, width: 800, x: 1012, y: 45 },
        { index: 2, height: 450, width: 800, x: 560, y: 585 },
    ],
    [
        { index: 2, height: 450, width: 800, x: 106, y: 45 },
        { index: 2, height: 450, width: 800, x: 1012, y: 45 },
        { index: 2, height: 450, width: 800, x: 106, y: 585 },
        { index: 2, height: 450, width: 800, x: 1012, y: 585 },
    ],
    [
        { index: 2, height: 288, width: 512, x: 96, y: 168 },
        { index: 2, height: 288, width: 512, x: 708, y: 168 },
        { index: 2, height: 288, width: 512, x: 1312, y: 168 },

        { index: 2, height: 288, width: 512, x: 298, y: 624 },
        { index: 2, height: 288, width: 512, x: 1109, y: 624 },
    ],
    [
        { index: 2, height: 288, width: 512, x: 96, y: 168 },
        { index: 2, height: 288, width: 512, x: 708, y: 168 },
        { index: 2, height: 288, width: 512, x: 1312, y: 168 },

        { index: 2, height: 288, width: 512, x: 96, y: 624 },
        { index: 2, height: 288, width: 512, x: 708, y: 624 },
        { index: 2, height: 288, width: 512, x: 1312, y: 624 },

    ],
    [
        { index: 2, height: 288, width: 512, x: 96, y: 54 },
        { index: 2, height: 288, width: 512, x: 708, y: 54 },
        { index: 2, height: 288, width: 512, x: 1312, y: 54 },

        { index: 2, height: 288, width: 512, x: 96, y: 396 },
        { index: 2, height: 288, width: 512, x: 708, y: 396 },
        { index: 2, height: 288, width: 512, x: 1312, y: 396 },

        { index: 2, height: 288, width: 512, x: 704, y: 738 },
    ],
    [
        { index: 2, height: 288, width: 512, x: 96, y: 54 },
        { index: 2, height: 288, width: 512, x: 708, y: 54 },
        { index: 2, height: 288, width: 512, x: 1312, y: 54 },

        { index: 2, height: 288, width: 512, x: 96, y: 396 },
        { index: 2, height: 288, width: 512, x: 708, y: 396 },
        { index: 2, height: 288, width: 512, x: 1312, y: 396 },

        { index: 2, height: 288, width: 512, x: 298, y: 738 },
        { index: 2, height: 288, width: 512, x: 1109, y: 738 },
    ]
];

export const layoutPositionImageAdvance = [
    [],
    [{ index: 6 }],
    [
        { index: 3, height: 450, width: 800, x: 106, y: 315 },
        { index: 3, height: 450, width: 800, x: 1012, y: 315 },
    ],
    [
        { index: 3, height: 450, width: 800, x: 106, y: 45 },
        { index: 3, height: 450, width: 800, x: 1012, y: 45 },
        { index: 3, height: 450, width: 800, x: 560, y: 585 },
    ],
    [
        { index: 3, height: 450, width: 800, x: 106, y: 45 },
        { index: 3, height: 450, width: 800, x: 1012, y: 45 },
        { index: 3, height: 450, width: 800, x: 106, y: 585 },
        { index: 3, height: 450, width: 800, x: 1012, y: 585 },
    ],
    [
        { index: 3, height: 288, width: 512, x: 96, y: 168 },
        { index: 3, height: 288, width: 512, x: 708, y: 168 },
        { index: 3, height: 288, width: 512, x: 1312, y: 168 },

        { index: 3, height: 288, width: 512, x: 298, y: 624 },
        { index: 3, height: 288, width: 512, x: 1109, y: 624 },
    ],
    [
        { index: 3, height: 288, width: 512, x: 96, y: 168 },
        { index: 3, height: 288, width: 512, x: 708, y: 168 },
        { index: 3, height: 288, width: 512, x: 1312, y: 168 },

        { index: 3, height: 288, width: 512, x: 96, y: 624 },
        { index: 3, height: 288, width: 512, x: 708, y: 624 },
        { index: 3, height: 288, width: 512, x: 1312, y: 624 },

    ],
    [
        { index: 3, height: 288, width: 512, x: 96, y: 54 },
        { index: 3, height: 288, width: 512, x: 708, y: 54 },
        { index: 3, height: 288, width: 512, x: 1312, y: 54 },

        { index: 3, height: 288, width: 512, x: 96, y: 396 },
        { index: 3, height: 288, width: 512, x: 708, y: 396 },
        { index: 3, height: 288, width: 512, x: 1312, y: 396 },

        { index: 3, height: 288, width: 512, x: 704, y: 738 },
    ],
    [
        { index: 3, height: 288, width: 512, x: 96, y: 54 },
        { index: 3, height: 288, width: 512, x: 708, y: 54 },
        { index: 3, height: 288, width: 512, x: 1312, y: 54 },

        { index: 3, height: 288, width: 512, x: 96, y: 396 },
        { index: 3, height: 288, width: 512, x: 708, y: 396 },
        { index: 3, height: 288, width: 512, x: 1312, y: 396 },

        { index: 3, height: 288, width: 512, x: 298, y: 738 },
        { index: 3, height: 288, width: 512, x: 1109, y: 738 },
    ]
];

const bannerConstrains = {
    index: 1,
    x: -40,
    y: 0,
    width: 920,
    height: 517.5,
};
const bannerConstrainsHD = {
    index: 1,
    x: 0,
    y: 0,
};
export const UpdateBgContrain = (BROADCAST_RESOLUTION === "BASIC_LANDSCAPE" ? bannerConstrains : bannerConstrainsHD);

export const test8Participants = [
    { index: 2, height: 144, width: 256, x: 20, y: 12 },
    { index: 2, height: 144, width: 256, x: 296, y: 12 },
    { index: 2, height: 144, width: 256, x: 572, y: 12 },

    { index: 2, height: 144, width: 256, x: 158, y: 168 },
    { index: 2, height: 144, width: 256, x: 434, y: 168 },

    { index: 2, height: 144, width: 256, x: 20, y: 323 },
    { index: 2, height: 144, width: 256, x: 296, y: 323 },
    { index: 2, height: 144, width: 256, x: 572, y: 323 },

]

export const test9Participants = [
    { index: 2, height: 144, width: 256, x: 20, y: 12 },
    { index: 2, height: 144, width: 256, x: 296, y: 12 },
    { index: 2, height: 144, width: 256, x: 572, y: 12 },

    { index: 2, height: 144, width: 256, x: 20, y: 168 },
    { index: 2, height: 144, width: 256, x: 296, y: 168 },
    { index: 2, height: 144, width: 256, x: 572, y: 168 },

    { index: 2, height: 144, width: 256, x: 20, y: 323 },
    { index: 2, height: 144, width: 256, x: 296, y: 323 },
    { index: 2, height: 144, width: 256, x: 572, y: 323 },

    { index: 2, height: 288, width: 512, x: 298, y: 624 },
    { index: 2, height: 288, width: 512, x: 1109, y: 624 },

]

export const test5Participants = [
    { index: 2, height: 288, width: 512, x: 96, y: 168 },
    { index: 2, height: 288, width: 512, x: 708, y: 168 },
    { index: 2, height: 288, width: 512, x: 1312, y: 168 },

    { index: 2, height: 288, width: 512, x: 298, y: 624 },
    { index: 2, height: 288, width: 512, x: 1109, y: 624 },
]

export const testCoordinates = [
    [], [], [], [], [],
    [
        { x: 0, y: 430 },
        { x: 620, y: 430 },
        { x: 1245, y: 430 },

        { x: 200, y: 890 },
        { x: 1025, y: 890 },

    ],
    [
        { x: 0, y: 430 },
        { x: 620, y: 430 },
        { x: 1245, y: 430 },

        { x: 0, y: 890 },
        { x: 620, y: 890 },
        { x: 1245, y: 890 }
    ],
    [
        { x: 0, y: 315 },
        { x: 620, y: 315 },
        { x: 1245, y: 315 },

        { x: 0, y: 665 },
        { x: 620, y: 665 },
        { x: 1245, y: 665 },

        { x: 620, y: 1010 }

    ],
    [
        { x: 0, y: 315 },
        { x: 620, y: 315 },
        { x: 1245, y: 315 },

        { x: 0, y: 665 },
        { x: 620, y: 665 },
        { x: 1245, y: 665 },

        { x: 200, y: 1010 },
        { x: 1025, y: 1010 }

    ]
]

export const testCoordinatesAdvanced = [
    [], [], [], [], [],
    [
        { x: 35, y: 430 },
        { x: 650, y: 430 },
        { x: 1255, y: 430 },

        { x: 235, y: 885 },
        { x: 1047, y: 885 },

    ],
    [
        { x: 35, y: 430 },
        { x: 650, y: 430 },
        { x: 1258, y: 430 },

        { x: 35, y: 885 },
        { x: 650, y: 885 },
        { x: 1258, y: 885 }
    ],
    [
        { x: 35, y: 312 },
        { x: 650, y: 312 },
        { x: 1258, y: 312 },

        { x: 35, y: 653 },
        { x: 650, y: 653 },
        { x: 1258, y: 653 },

        { x: 650, y: 995 }

    ],
    [
        { x: 35, y: 312 },
        { x: 650, y: 312 },
        { x: 1258, y: 312 },

        { x: 35, y: 653 },
        { x: 650, y: 653 },
        { x: 1255, y: 653 },

        { x: 235, y: 995 },
        { x: 1047, y: 995 }

    ]
]

export const removeVideoConfig = {
    index: 0, height: 0, width: 0, x: 30, y: 150,
}
export const logoRightPosition = (BROADCAST_RESOLUTION === "BASIC_LANDSCAPE" ? 830 : 1920);

export const screenSharePosition = [
    { index: 2, height: 144, width: 256, x: 20, y: 704 },
]
