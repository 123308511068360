import React, { useEffect, useState } from "react";
import "./Login.scss";
import _get from "lodash/get";
import Loader from "../../common/Loader";
import {
  clearLocalStorage,
  decodeQueryString,
  getQueryString,
  isEmailValidateToken,
  isOffline,
  setCurrentOrg,
  superAdminLandingScreen,
} from "../../common/helper";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { doLogin } from "../../store/action/loginAction";
import { leadingAndTrailingspaceAvoid } from "../../helper";
import AccountActiveAlert from "./AccountActiveAlert/index";
import { failToast, serverNotRespond, succToast } from "../../helper/ApiToast";
import {
  errorValidateLoginPage,
  emptyInputErrorMsgThrow,
  findEmptyOrNotLoginInput,
  loginEmailValidate,
} from "./loginPageCommon";
import {
  validateForm,
  EmailValidate,
  passwordEncrypt,
} from "../../helper/Validation";
import LoginInputField from "./LoginInputField";
import { constantValue } from "../../const/errorTypes";
import { getStripPaymentSession } from "../MainContent/Payments";
import { handleLoader } from "../../helper/utility";

//Password Show or Hide Function
const passwordEyes = (type = "password") => {
  return type === "password" ? "text" : "password";
};

const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { search = "" } = location;
  const globalStore = useSelector((state) => state || {}); //store
  const history = useHistory();
  const obj = decodeQueryString(search);
  const { userEmailId = "", organisationId = 0 } = obj;
  const loginDetail = window.localStorage.getItem("userDetails")
    ? JSON.parse(window.localStorage.getItem("userDetails")) : {};

  const { data: { email: emailId = "", userRoleId = "" } = {} } = loginDetail;
  const [stateOfLoginPage, setStateOfLoginPage] = useState({
    accontActive: false,
    emailActiveLoader: false,
  });
  const url = new URL(window.location.href);
  const queryParams = Object.fromEntries(url.searchParams.entries());

  const [ErrorName, setErrorName] = useState(false);
  const [delayShow, setdelayShow] = useState(false);
  const [isValidForm, setValidForm] = useState(false);
  const [ErrorPassword, setErrorPassword] = useState(false);
  const [spaceOnlyError, setSpaceOnlyError] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const { loginPage: { otpPage = false, isLoading = false } = {}, loader: { isPaymentLoading = false } } = globalStore;
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });

  const [errinputPass, seterrinputPass] = useState({
    email: false,
    password: false,
    onlySpace: true,
  });
  const [onErrinputPass, setonErrinputPass] = useState({
    email: false,
    password: false,
  });

  /**
   * _onLogin submit is make a Login trigger
   * doLogin create Login sagas action
   */
  const _onLogin = (e = {}) => {
    if (isOffline()) {
      serverNotRespond(constantValue.INTERNET_ERROR);
    }
    e.preventDefault();
    if (isValidForm) {
      const logData = {
        password: passwordEncrypt(inputValue.password),
        email: leadingAndTrailingspaceAvoid(
          userEmailId === "" ? inputValue.email : userEmailId
        ),
      };
      dispatch({ type: "DO_LOADING_LOGIN_PAGE", loading: true });
      dispatch(doLogin(logData));
    } else {
      setonErrinputPass({
        ...onErrinputPass,
        email: emptyInputErrorMsgThrow(
          userEmailId === "" ? inputValue.email : userEmailId
        ),
        password: emptyInputErrorMsgThrow(inputValue.password),
      });
    }
  };

  const onErrorManage = (error = {}) => {
    const { email, password, onlySpace } = error; //on email and password type on field validate
    email && setErrorName(false);
    password && setErrorPassword(false);
    onlySpace && setSpaceOnlyError(false);
  };

  //OnBlur use user enter email or password throw validate
  const errorValidateShow = () => {
    const errors = errinputPass;
    errors.email = EmailValidate(
      userEmailId === "" ? inputValue.email : userEmailId
    );
    setSpaceOnlyError(false);
    if (!inputValue.email) {
      errors.onlySpace = true;
    }
    errors.password = (inputValue.password) ? true : false;
    seterrinputPass(errors);
    setValidForm(validateForm(errors));
    onErrorManage(errors);
    const { email, password, onlySpace } = errors; //on email and password type on field validate
    setErrorName(errorValidateLoginPage(inputValue.email, email));
    setErrorPassword(errorValidateLoginPage(inputValue.password, password));
    setSpaceOnlyError(errorValidateLoginPage(inputValue.email, onlySpace));
  };

  /**
   * Handle user Email and password value
   * @param  {Object} {event} -
   */
  const onChangeHandler = (event = {}) => {
    event.preventDefault();
    const { name = "", value = "" } = _get(event, "target", {});
    const errors = errinputPass;
    switch (name) {
      case "email":
        errors.email = EmailValidate(value);
        break;
      case "password":
        errors.password = value;
        break;
      default:
        break;
    }
    onErrorManage(errors);
    setInputValue({ ...inputValue, [name]: value });
    setValidForm(validateForm(errors));
    setonErrinputPass({
      ...onErrinputPass,
      email: findEmptyOrNotLoginInput(
        userEmailId === "" ? inputValue.email : userEmailId
      ),
      password: findEmptyOrNotLoginInput(inputValue.password),
    });
  };

  const handleEnterPress = (e = {}) => {
    if (e.code === "Enter") {
      errorValidateShow();
    }
  };

  const passwordEye = () => {
    const passType = passwordEyes(passwordType);
    setPasswordType(passType);
  };

  //active email close
  const handleClose = () => {
    setStateOfLoginPage({
      accontActive: false,
      emailActiveLoader: false,
    });
  };

  /**
   * user api call
   */
  const trailUserValidateCall = async () => {
    if (isOffline() || queryParams?.planid) {
      return;
    }
    setStateOfLoginPage({
      accontActive: false,
      emailActiveLoader: true,
    });
    clearLocalStorage();
    if (apiCall) return;
    const response = await loginEmailValidate(search);
    setApiCall(true);
    const { data: { message = "", status = 0 } = {} } = response || {};
    if (status === 200) {
      setStateOfLoginPage(() => ({
        accontActive: true,
        emailActiveLoader: false,
      }));
      succToast(message);
    } else {
      if (isOffline()) {
        return;
      }
      setStateOfLoginPage({
        accontActive: false,
        emailActiveLoader: false,
      });
      failToast(message);
    }
    history.push(`/login`);
  };

  useEffect(() => {
    if (otpPage) {
      history.push(`/register${getQueryString(window.location.href)}`);
    }
  }, [otpPage]);

  useEffect(() => {
    handleLoader(true);
    setTimeout(() => {
      handleLoader(false);
      setdelayShow(true);
    }, 500);
    return (() => {
      setdelayShow(false);
    });
  }, []);

  /**
   * User login successfully redirect To DashBoard Page or dynamic redirectPage
  */
  const isUserLoggedIn = !!window.localStorage.getItem("userData");
  useEffect(() => {
    if (search && !search.includes('planid') && !search.includes('mode') && obj?.emailValidateToken) {
      trailUserValidateCall();
      return;
    }
    if (!isUserLoggedIn && emailId !== userEmailId && userRoleId !== 4 && !isEmailValidateToken(search)) {
      history.push(`/login${getQueryString(window.location.href)}`);
    } else if (isUserLoggedIn) {
      if (organisationId) {
        setCurrentOrg(organisationId);
      }
      if ([4, 5].includes(userRoleId)) {
        history.push(superAdminLandingScreen); // Navigation for super admin.
      } else if (queryParams.planid) {
        getStripPaymentSession(+queryParams.planid, queryParams.mode === 'm' ? 'monthly' : 'yearly', 'webRegister');
      } else {
        history.push(`/broadcast`);
      }
    }
  }, [isUserLoggedIn, search]);

  return (
    <React.Fragment>
      {(isLoading || stateOfLoginPage.emailActiveLoader || isPaymentLoading) && (
        <Loader imageLoader={false} type={"fixed overlay"} />
      )}

      <div style={{ opacity: delayShow ? 1 : 0 }} className="LoginWrapper Custom">
        <div className="Container">
          {stateOfLoginPage.accontActive && (
            <AccountActiveAlert handleClose={handleClose} />
          )}
          {/* <LoginBrandInfo/> */}

          <div className="LoginInner grid">
            <div className="formWrapper grid-12">
              <LoginInputField
                _onLogin={_onLogin}
                ErrorName={ErrorName}
                inputValue={inputValue}
                passwordEye={passwordEye}
                passwordType={passwordType}
                ErrorPassword={ErrorPassword}
                spaceOnlyError={spaceOnlyError}
                onErrinputPass={onErrinputPass}
                onChangeHandler={onChangeHandler}
                handleEnterPress={handleEnterPress}
                errorValidateShow={errorValidateShow}
                token={userEmailId !== ""}
                emailId={userEmailId === "" ? "" : userEmailId}
                search={search}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default React.memo(Login);
