const initialStage = {};

export const uploaderReducer = (state = initialStage, action = {}) => {
  switch (action.type) {
    case "UPLOAD_PROGRESS_REDUCER": {
      return {
        ...state,
        ...action.data
      };
    }
    case "UPLOAD_PROGRESS_RESET": {
      return initialStage;
    }
    case "RECENTLY_UPLOADED_MEDIA_ID": {
      return {
        ...state,
        recentlyUploadedMediaId: action.data.recentlyUploadedMediaId
      };
    }
    default:
      return state;
  }
};
