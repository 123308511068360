import React, { useEffect, useState } from 'react';
import { ReactComponent as IconSuccess } from "./img/success.svg";
import { ReactComponent as IconError } from "./img/error.svg";
import { ReactComponent as IconInfo } from "./img/info.svg";
import { ReactComponent as IconAlert } from "./img/alert.svg";

function ToastLayout(props = {}) {
    //// type =>  info, success, warning, error
    const { icon = "", className = "", type = "info", heading = "", desc = "desc" } = props;
    const [getIcon, setIcon] = useState();
    useEffect(() => {
        switch (type) {
            case "success":
                setIcon(<IconSuccess />);
                break;
            case "warning":
                setIcon(<IconAlert />);
                break;
            case "info":
                setIcon(<IconInfo />);
                break;
            case "error":
                setIcon(<IconError />);
                break;
            default: setIcon(<IconInfo />);
                break;
        }
    }, [type]);
    return (
        <div className={`toast_layout ${className} ${type}`}>
            <div className='toast_layout_inner'>
                <div className='toast_layout_icon'>
                    <i className='icon'>{icon ? icon : getIcon}</i>
                </div>
                <div className='toast_layout_info'>
                    {heading ? <div style={{ display: "none" }} className='toast_layout_heading'>{heading}</div> : null}
                    {desc ? <div className='toast_layout_desc'>{desc}</div> : null}
                </div>
            </div>
        </div>
    );
}

export default ToastLayout;
