import React, { memo } from 'react';
import TabNavigation from '../TabNavigation';
import TableSearchInput from './TableSearchInput';

function TableLayoutHeader(props = {}) {
    const { children = "", style = {}, enablefilter = false, selectedBtn = "", tabListArray = [],
        enableTab = false, isVisible = true, enableSearch = true, _handleSearch = () => { }, _handleClearSearch = () => { },
        _handleGetSelectedMenu = () => { }, searchName = "searchTerm", searchTerm = "", _placeholder = "", dateFilter = "" } = props;
    return (<>
        {isVisible ? <div style={{ ...style }} className='table_layout_header'>
            <div className='table_layout_left' >
                {(tabListArray.length > 0 && enableTab) ?
                    <TabNavigation
                        selectedBtn={selectedBtn}
                        tabListArray={tabListArray}
                        _handleGetSelectedMenu={_handleGetSelectedMenu}
                    />
                    : null
                }
                {enablefilter ? dateFilter : ""}
            </div>
            <div className='table_layout_right'>
                {enableSearch ? <TableSearchInput
                    _isFocusOnInput={true}
                    type={"text"}
                    value={searchTerm}
                    name={searchName}
                    placeholder={_placeholder}
                    handleSetSearchInput={_handleSearch}
                    handleInputboxClear={_handleClearSearch}
                    parentClass={` tableSearch-absolute ${enableSearch ? " " : " mobileHide"
                        }`}
                /> : null}
            </div>
            {children}
        </div > : null
        }
    </>
    );
}
export default memo(TableLayoutHeader);
