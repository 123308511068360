export const fetchUploadMediaListAction = (data = "") => {
  return {
    type: "FETCH_UPLOAD_MEDIA_LIST",
    data,
  };
};

export const fetchUploadMediaListTableAction = (data = "") => {
  return {
    type: "FETCH_UPLOAD_MEDIA_LIST_TABLE",
    data,
  };
};

export const updateUploadMediaListAction = (data = "") => {
  return {
    type: "UPDATE_UPLOAD_MEDIA_LIST",
    data,
  };
};
