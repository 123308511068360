import React, { useState, useEffect } from "react";
import Moment from 'moment';
import DynamicDropList from "./DynamicDropList";
import MonthPicker from '../DatePicker/MonthPicker';
import StartToEndPicker from "../DatePicker/StartToendPicker";
import OutsideClickHandler from "react-outside-click-handler";
import { IconDropdownCc, IconClose, IconCalendarNew } from '../../assets/images';
import DayToYearDropDownListdatas from "./DayToYearDropDownListdatas";
import { DateFormate } from "../../helper";
import { translate } from "../../helper/utility";
import { CUSTOM_DATE_FILTER_VALUE, DATE_FILTER_ALL_START_DATE } from "../../constant";
import { checkOffline } from "../helper";

const DayToYearDropDown = (props = {}) => {

    const {
        name = "",
        classValue = "",
        dropDownData = [],
        parentClass = "",
        dashBordMonth = false,
        dynamicOptiondata = false,
        enableMonthPicker = false,
        onChangeHandle = () => { },
        onChangeDropDown = () => { },
        preSelectedValue = '', // Pre-selected option's lable name
        earliestDate = DATE_FILTER_ALL_START_DATE // Earliest date available in your dataset
    } = props;

    const cusDate = "Custom date";
    const allDays = "All days";
    const [focusedInputData, setfocusedInputData] = useState(null);
    const [DropDown, setDropDown] = useState(false);
    const [CustomDate, setCustomDate] = useState(false);
    const [calendarActive, setCalenderActive] = useState(false);
    const [selectedValue, setSelectedValue] = useState(translate('TABLE.SEVEN_DAY'));

    const changeDateGroup = ({ startDate = "", endDate = "" }) => {
        if (checkOffline()) { return false; }
        onChangeHandle({
            startDate: startDate ? DateFormate(startDate) : null,
            endDate: endDate ? DateFormate(endDate) : null,
            value: cusDate
        });
    };

    /** Custom Date Clear **/
    const dateClewr = () => {
        onChangeHandle({
            startDate: null,
            endDate: null,
            value: translate('TABLE.SEVEN_DAY')
        });
    };

    const getSelectedOptionValue = (value) => {
        switch (value) {
            case 0: return allDays;
            case 1: return "Today";
            case 7: return translate('TABLE.SEVEN_DAY');
            case 14: return translate('TABLE.FOURTEEN_DAY');
            case 28: return translate('TABLE.TWENTYEIGHT_DAY');
            case CUSTOM_DATE_FILTER_VALUE: return cusDate;
            default: return translate('TABLE.SEVEN_DAY');
        }
    };

    /**
     * @param  {object} {val} - Get selected dropDown value
    **/
    const _handleSelect = (value = 1) => {
        if (checkOffline()) { return false; }
        dateClewr(); // Reset dates when user comes first time to custome date filter
        setDropDown(false);
        setSelectedValue(getSelectedOptionValue(value));
        if (value === CUSTOM_DATE_FILTER_VALUE) {
            setCustomDate(true);
            return false;
        }
        setCustomDate(false);

        // Handle "All days" selection
        if (value === 0) {
            onChangeHandle({
                startDate: earliestDate,
                endDate: DateFormate(Moment()),
                value:  getSelectedOptionValue(value),
            });
            return;
        }

        onChangeHandle({
            startDate: DateFormate(Moment().subtract(value - 1, 'd')),
            endDate: DateFormate(Moment()),
            value: getSelectedOptionValue(value),
        });
    };

    const _handleDropDown = (event = {}) => {
        event.preventDefault();
        setDropDown(!DropDown);
    };

    const _handleOnOutsideClick = () => {
        setDropDown(false);
    };
    const dateClearPlac = () => {
        return (
            <React.Fragment>
                {props.endDate || props.startDate ?
                    <i id="#jestIconReset"
                        className="IconReset" onClick={dateClewr}>
                        <IconClose />
                    </i> : null
                }
            </React.Fragment>
        );
    };

    const dropDownDynamicHandle = (event = {}) => {
        const { target = {} } = event;
        const nameOption = target.name || target.getAttribute("name");
        const valueOption = target.value || target.getAttribute("value");
        const displayValue = target.getAttribute("data-value");
        setDropDown(false);
        setSelectedDefault(displayValue);
        onChangeDropDown({
            target: {
                name: nameOption,
                value: valueOption
            }
        });
    };

    const changeDropIcon = () => {
        if (DropDown) {
            return "open";
        } return "";
    };
    useEffect(() => {
        setfocusedInputData(props.startDate ? 'endDate' : 'startDate');
        if (props.endDate && props.startDate) {
            setfocusedInputData(null);
            setTimeout(() => {
                const findDropEle = document.querySelector('.CalendarDay__selected_end');
                findDropEle && findDropEle.click();
            }, 500);
        }
        if (preSelectedValue && preSelectedValue !== selectedValue) {
            if (preSelectedValue === cusDate) {
                setCustomDate(true);
            }
            setSelectedValue(preSelectedValue);
        }
    }, [props.startDate, props.endDate, preSelectedValue]);

    useEffect(() => {
        if (CustomDate && !props.startDate && !props.endDate) {
            setfocusedInputData('startDate');
        } else setfocusedInputData(null);

        return (() => {
            setfocusedInputData(null);
        });
    }, [CustomDate]);
    const DropActive = (value) => setCalenderActive(!!value);

    return (
        <div data-calendar="daytoyearDrop" className={`${parentClass || " "} daytoyearDrop`}>
            <OutsideClickHandler
                onOutsideClick={() => { _handleOnOutsideClick(); }}
            >
                <label
                    htmlFor={classValue}
                    onClick={selectedValue !== cusDate ? (event) => _handleDropDown(event) : null}
                    className={`custom-dropdown custom-dropdown-2 active ${selectedValue === cusDate ? " dateActive " : ""} `}
                >
                    <i> <IconCalendarNew /> </i>
                    {CustomDate ?
                        <React.Fragment>
                            <span className={`datepicker   ${calendarActive ? " active" : ""}`}>
                                {enableMonthPicker ?
                                    <MonthPicker
                                        DropActive={(e) => DropActive(e)}
                                    />
                                    :
                                    <StartToEndPicker
                                        endDate={props.endDate ? Moment(props.endDate) : ''}
                                        onChange={changeDateGroup}
                                        focusedInput={focusedInputData}
                                        startDate={props.startDate ? Moment(props.startDate) : ''}
                                        dashBordMonth={dashBordMonth}
                                        onCalendarHandle={true}
                                    />
                                }
                            </span>
                            {dateClearPlac()}
                        </React.Fragment>
                        :
                        <span> {selectedValue} </span>
                    }
                    <input
                        className=""
                        id={classValue}
                        type="checkbox"
                        onChange={() => { }}
                    />
                    <i className={`dropIcon ${changeDropIcon()}`} onClick={selectedValue === cusDate ? (event) => _handleDropDown(event) : null}>
                        <IconDropdownCc />
                    </i>
                </label>

                {/* dropDown values */}
                {dynamicOptiondata ?
                    <DynamicDropList
                        name={name}
                        DropDown={DropDown}
                        dropDownData={dropDownData}
                        _handleSelect={dropDownDynamicHandle}
                    />
                    :
                    <DayToYearDropDownListdatas
                        SelectedValue={selectedValue}
                        DropDown={DropDown}
                        _handleSelect={_handleSelect}
                        enableMonthPicker={enableMonthPicker}
                    />
                }
            </OutsideClickHandler>
        </div>
    );

};
export default React.memo(DayToYearDropDown);
