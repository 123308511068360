import { Get } from "../common/httpRestServices";
import { apiUrl, broadCastcountList, customerList } from "../helper/ApiUrl";
import { encodeGetParams } from "../helper/Encypt";

export const customerListRequest = async (obj = {}) => {
  const { data } = obj || {};
  if (data.startDate && data.endDate) { // This will prevent single date search
  const queryString = data ? encodeGetParams(data) : null;
  return Get(`${apiUrl}${customerList.getCustomerList}${queryString}`, true);
  }
};

export const broadcastCountListRequest = (obj = {}) => {
  const { data: { searchTerm = "", page = 1, size = 10, orgId = 0 } = {} } = obj;
  if (!orgId) return;

  return Get(`${apiUrl}${broadCastcountList.getbroadCastcountList}page=${page}&searchKeyword=${encodeURIComponent(searchTerm)}&size=${size}&orgId=${orgId}`, true);
};
