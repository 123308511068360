import React, { useState, useEffect, useRef } from 'react';
import { IconRupees, Iconminus, Iconplus } from '../../../assets/otf';
import { Business, Individual, PLAN_IDS, PaymentIndividualFeaturesList, Plans, getClassName, getDisableClassName, getPlanAmount,
  handleDurationText, isINR } from './commonPayments';
import { Each } from '../../../helper/utility';
import CommonActiveToggle from '../../../common/CommonActiveToggle';
import { convertNumToCurrency } from '../../../helper/PaymentMonckData';
import { IconCurrencyRupee } from '../../../assets/images';
import { useSelector } from 'react-redux';

const PricingFeatureAccordion = (props = {}) => {
  const { selectedTab = '', planDuration = '', handleInput = () => { }, handleButtonClick = () => { } } = props;
  const [expandedIndex, setExpandedIndex] = useState(0); // Initialize with 0 to open the first item
  const [height, setHeight] = useState([]);
  const contentRefs = useRef([]);
  const userDetails = useSelector(s => s.CusPage.customerDtls);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [activeTab, setActiveTab] = useState(PLAN_IDS.BASIC);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setHeight(contentRefs.current.map(ref => (ref ? ref.scrollHeight : 0)));

    if (selectedTab === Business && [PLAN_IDS.FREE, PLAN_IDS.BASIC, PLAN_IDS.PREMIUM].includes(activeTab)) {
      setActiveTab(PLAN_IDS.GROWTH);
    } else if (selectedTab === Individual && [PLAN_IDS.GROWTH, PLAN_IDS.BUSINESS].includes(activeTab)) setActiveTab(PLAN_IDS.BASIC);

  }, [selectedTab, activeTab]);

  const toggleAccordion = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null); // collapse the current item if it's already expanded
    } else {
      setExpandedIndex(index); // expand the clicked item
    }
  };
  const handlePlanText = (_id, _name) => {
    if ((userDetails.planId === _id && planDuration === userDetails.planDuration) || (_id === PLAN_IDS.FREE && userDetails.planId === PLAN_IDS.FREE)) {
      return "Your Current Plan";
    }
    else if (_id === PLAN_IDS.FREE) {
      return "Start For Free";
    }
    if (userDetails.planId === PLAN_IDS.FREE && [PLAN_IDS.BASIC, PLAN_IDS.PREMIUM].includes(_id)) return "Try free for 30 days";
    return `Choose ${_id === PLAN_IDS.PREMIUM ? "Professional" : _name}`;
  };

  return (
    <div className="pricing_feature_section">
      <div className="pricing_feature_header">
        <h2> Find the Perfect Plan for You </h2>
        {screenWidth <= 1000 &&
          <>
            <div className={`subscriptions_plan style_2 ${planDuration === 'monthly' ? "month_box" : "year_box"}`}>
              <div className="subscriptions_content">
                <div className="plan_info allbox">
                  <div className='all_plan_title'>
                    All Plans
                    <div className='offer'>Save Upto 20% Yearly</div>
                  </div>
                  <div className="subscriptions_toggle">
                    <label className={` ${planDuration === 'monthly' ? " active pointer-events-none" : " "} tab_label`} htmlFor="subscriptions">Monthly</label>
                    <CommonActiveToggle
                      toggleType=" style2 "
                      toggleAnimate={true}
                      jestId={`Status-1`}
                      name="getBannerStatus"
                      onChange={(e) => { handleInput(e); }}
                      id={`subscriptions`}
                      status={planDuration === 'yearly'}
                      parentId={"_subscriptions"}
                    />
                    <label className={` ${planDuration === 'yearly' ? " active pointer-events-none" : " "} tab_label`} htmlFor="subscriptions">Yearly</label>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
      <div className="pricing_feature_body">
        <div className="flex box_plan_amount">
          {screenWidth >= 1000 &&
            <>
              <div className={`subscriptions_plan style_2 ${planDuration === 'monthly' ? "month_box" : "year_box"}`}>
                <div className="subscriptions_content">
                  <div className="plan_info allbox">
                    <div className='all_plan_title'>All Plans</div>
                    <div className="subscriptions_toggle">
                      <label className={` ${planDuration === 'monthly' ? " active pointer-events-none" : " "} tab_label`} htmlFor="subscriptions">Monthly</label>
                      <CommonActiveToggle
                        toggleType=" style2 "
                        toggleAnimate={true}
                        jestId={`Status-1`}
                        name="getBannerStatus"
                        onChange={(e) => { handleInput(e); }}
                        id={`subscriptions`}
                        status={planDuration === 'yearly'}
                        parentId={"_subscriptions"}
                      />
                      <label className={` ${planDuration === 'yearly' ? " active pointer-events-none" : " "} tab_label`} htmlFor="subscriptions">Yearly</label>
                    </div>
                    <div className='offer'>Save Upto 20% Yearly</div>
                  </div>
                </div>
              </div>
            </>
          }

          {planDuration && selectedTab &&
            <Each
              of={Plans}
              render={(item) => {
                const { name = "", type = "", id = "", monthlyBill = "", annualBill = "", offer = "" } = item;
                if (type !== selectedTab) { return false; }

                return (
                  <div className={` ${id} ${type} subscriptions_plan style_2 `}>
                    <button className={`tabbutton ${activeTab === id ? 'buttonActive' : ''}`} onClick={() => handleTabClick(id)}> <div className="subscriptions_content">
                      <div className="plan_info">
                        <strong>{name}</strong>
                      </div>
                      <div className="plan_price">
                        <strong>{isINR ? <IconRupees /> : "$"}
                          {convertNumToCurrency(getPlanAmount(planDuration === 'monthly' ? monthlyBill : annualBill, !isINR))}
                        </strong>
                        <span className="plan_expiry">{handleDurationText(id)}</span>
                        <span className="plan_offer">
                          {PLAN_IDS.FREE !== id && (<>Save {isINR ?
                            <IconCurrencyRupee style={{ width: "14px", height: "14px", position: "relative", top: "3px" }} /> : "$"}
                            {offer ? `${convertNumToCurrency(getPlanAmount(offer, !isINR))} billed annually` : ''}
                          </>)}
                        </span>
                      </div>
                      <div className="selected_plan_action">
                        <button className={`${getClassName(id, userDetails, planDuration)} plan_action ${getDisableClassName(id, userDetails, planDuration)}`}
                          onClick={() => handleButtonClick(id)}
                          type="button">
                          {handlePlanText(id, name)}
                        </button>
                      </div>
                    </div>
                    </button>
                  </div>
                );
              }}
            />
          }
        </div>
        <div className="accordion">
          {PaymentIndividualFeaturesList.map((item, index) => {
            const isIndividual = selectedTab === 'Individual';
            return (
              <div key={item.title} className="accordion-item">
                <div
                  className={`accordion-title`}
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="icon">
                    {expandedIndex === index ? <Iconminus /> : <Iconplus />}
                  </span>
                  {item.title}
                </div>
                {expandedIndex === index && (
                  <div
                    className="accordion-content"
                    style={{
                      maxHeight: expandedIndex === index ? `${height[index]}px` : '0',
                      overflow: 'hidden',
                      transition: 'max-height 0.8s ease',
                    }}
                  >
                    {item.contents.map((content) => {
                      if (selectedTab === 'Individual' && !content.values) return null;
                      return (
                        <div className="feature_row_individual" key={content.title}>
                          <div className="box title">{content?.title}</div>
                          {screenWidth <= 1000 ? (
                            <>
                              {isIndividual ? (
                                <>
                                  {screenWidth <= 1000 && activeTab === PLAN_IDS.FREE && (
                                    <div className="box free">{content.values.free}</div>
                                  )}
                                  {screenWidth <= 1000 && activeTab === PLAN_IDS.BASIC && (
                                    <div className="box basic">{content.values.basic}</div>
                                  )}
                                  {screenWidth <= 1000 && activeTab === PLAN_IDS.PREMIUM && (
                                    <div className="box premium">{content.values.premium}</div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {screenWidth <= 1000 && activeTab === PLAN_IDS.GROWTH && (
                                    <div className="box growth">{content.values.growth}</div>
                                  )}
                                  {screenWidth <= 1000 && activeTab === PLAN_IDS.BUSINESS && (
                                    <div className="box business">{content.values.business}</div>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {isIndividual ? (
                                <>
                                  <div className="box free">{content.values.free}</div>
                                  <div className="box basic">{content.values.basic}</div>
                                  <div className="box premium">{content.values.premium}</div>
                                </>
                              ) : (
                                <>
                                  <div className="box growth">{content.values.growth}</div>
                                  <div className="box business">{content.values.business}</div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PricingFeatureAccordion;
