import store from "..";

export const progressAction = (data = {}) => {
  return { type: "UPLOAD_PROGRESS_REDUCER_BK", data };
};

export const uploadProgressAction = (data = {}) => {
  const uploaderData = store.getState().uploaderData;
  data.progress = data?.uploadPercentage || 0;

  store.dispatch({
    type: "UPLOAD_PROGRESS_REDUCER", data: {
      ...uploaderData,
      [data?.mediaId]: data
    }
  });
};

export const progressResetAction = (data = {}) => {
  return { type: "UPLOAD_PROGRESS_RESET", data };
};
