// commentsSaga.js
import { call, put, takeLatest, take } from 'redux-saga/effects';
import { createEventChannel } from '../../firebase/firebaseRealtimeFunctions'; // Adjust the import path accordingly
import { FETCH_COMMENTS_REQUEST, POST_COMMENT_REQUEST } from '../actionTypes/commentsTypes';
import { fetchCommentsSuccess, fetchCommentsFailure, updateCommentPostStatus } from '../action/commentsAction';
import { commentsPostRequest, refreshGuestCommentsToken } from '../../services/commentsService';
import { failToast } from '../../helper/ApiToast';

function* fetchComments(action) {
  const { orgId, stageArn, isShowStarred } = action.payload;

  const channel = yield call(createEventChannel, orgId, stageArn, isShowStarred);
  try {
    while (true) {
      const { data, error } = yield take(channel);

      if (error) {
        throw error;
      }
      console.log("fetchComments data ===>>>>", data);
      yield put(fetchCommentsSuccess(data));
    }
  } catch (error) {
    yield put(fetchCommentsFailure(error.message));
  } finally {
    channel.close();
  }
}

function* postComment(action) {
  const MAX_RETRIES = 3; // Set the maximum number of retries
  let attempt = 0;
  const { userInput } = action?.payload?.data || {};
  console.log('action', action);

  function* makeRequest() {
    return yield call(commentsPostRequest, {
      data: {
        ...action.payload.data,
        inputData: {
          ...action.payload.data.inputData,
          authToken: window.localStorage.getItem('guestAccessToken'),
        },
      },
    });
  }

  try {
    console.log("postComment inside", action);
    yield put(updateCommentPostStatus('inprogress'));
    let response = yield call(makeRequest);
    console.log("postComment response==>", response);
    if(response?.data?.status === 400) {
      const { message } = response?.data;
      failToast(message);
      yield put(updateCommentPostStatus('error'));
    } else if (response?.status !== 401) {
      console.log("postComment response", response);
      yield put(updateCommentPostStatus('completed'));
      return; // Exit the saga if the comment is posted successfully
    } else {
      throw new Error('Failed to post comment after token refresh');
    }
  } catch (error) {
    console.log("postComment error", error);
    const { status, message } = error?.response?.data;
    yield put(updateCommentPostStatus('error'));

    if(status === 401 || status === 400) {
    while (attempt < MAX_RETRIES && userInput?.userType === 'guest' && window.localStorage.getItem('guestRefreshToken')) {
      console.log('inside the loop ====>', attempt);
      attempt++;
      try {
        yield call(refreshGuestCommentsToken, window.localStorage.getItem('guestRefreshToken'));
        yield put(updateCommentPostStatus('inprogress'));

        const response = yield call(makeRequest);

        if (response?.status !== 401) {
          console.log("postComment response", response);
          yield put(updateCommentPostStatus('completed'));
          return; // Exit the saga if the comment is posted successfully
        } else {
          throw new Error('Failed to post comment after token refresh');
        }
      } catch (refreshError) {
        console.error('Failed to refresh token:', refreshError);
        if (attempt >= MAX_RETRIES) {
          yield put(updateCommentPostStatus('error'));
          break; // Break the loop if max retries reached
        }
      }
    }
  } else {
    if(message) {
    failToast(message);
    }
  }
  }
}

export default function* commentsSaga() {
  yield takeLatest(FETCH_COMMENTS_REQUEST, fetchComments);
  yield takeLatest(POST_COMMENT_REQUEST, postComment);
}
