/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import "./subscriptions.scss";
import { failToast, succToast } from '../../../helper/ApiToast';
import { convertNumToCurrency } from '../../../helper/PaymentMonckData';
import store from "../../../store";
import { getCustomerDetails, getStreamingLimit } from "../../../store/action/customeAction";
import { useSelector } from "react-redux";
import CommonActiveToggle from "../../../common/CommonActiveToggle";
import { IconPaymentHeadingBg, IconPaymentCheckList, IconTooltip, IconRupees } from "../../../assets/otf";
import { Each } from "../../../helper/utility";
import { Individual, Business, Plans, PLAN_IDS, getPlanAmount, handleDurationText, getClassName, getDisableClassName, getPlanNamebyId } from "./commonPayments";
import InfoTooltip from "../../../common/LabelTooltip/infoTooltip";
import { IconCurrencyRupee } from "../../../assets/images";
import { userDetailsLocal } from "../../../helper/RoleConfig";
import { organisationMemberListAction } from "../../../store/action/organisationAction";
import { PAYMENT_GATEWAY } from "../../../constant";
import { getPayPalSessionId, getRazorPaySessionId } from "../../../store/action/paymentsAction";
import PaymentIntermediateScreen from "./PaymentIntermediateScreen";
import PricingFeatureAccordion from "./PricingFeatureAccordion";
import { trackHubspotEvent } from "../../../common/helper";
import { TRACK_UPGRADE_CLICK } from "../../../helper/ApiUrl";

export const getStripPaymentSession = async (planId = 1, getDuration = 'monthly', from = '') => {
    const isINR = localStorage.getItem("country_code") === "IN";

    const localUserDetails = userDetailsLocal();
    const updatedUserDetails = localStorage.getItem('updatedUserDetails') ? JSON.parse(localStorage.getItem('updatedUserDetails')) : {};
    if (!localStorage.getItem('updatedUserDetails') && from === 'webRegister' && window.location.pathname !== '/login' && window.location.pathname !== '/register') {
        window.location.href = `/login?planid=${planId}&mode=${getDuration === 'monthly' ? 'm' : 'y'}`;
        return;
    }
    const { data: { orgPlanDetails: { organisationId = '', userPlanTypeId: pid = 1, planDuration: pd = 'monthly' } } } = localUserDetails || {};
    // Below code need to check why local storage key is changed ramdomly. now below code only fixed
    const planTypeId    = updatedUserDetails.planId || updatedUserDetails.userPlanTypeId || localUserDetails.planId || pid;

    const planDuration  = updatedUserDetails.planDuration || pd;
    if (planId && from  === 'webRegister') {
        if (planId === PLAN_IDS.FREE || (planId < planTypeId) || (planId === planTypeId && planDuration === getDuration) ||
            (planId === planTypeId && getDuration === 'monthly' && planDuration === 'yearly')) {
            window.location.href = '/payments'; // Navigate user to homepage if already logged in
            return;
        }
    }

    try {
        const selectedPlan = Plans.find((p) => p.id === planId);
        store.dispatch({ type: "DO_LOADING_PAGE", loading: true });
        store.dispatch({ type: "DO_PAYMENTS_LOADER", isPaymentLoading: true });

        const paymentConfig = {
            currency_code: isINR ? "inr" : "non-inr",
            amount: getDuration === 'monthly' ? selectedPlan?.monthlyBill : selectedPlan?.annualBill * 12,
            plan_type: planId,
            plan_duration: getDuration,
            organisation_id: updatedUserDetails.organisationId || organisationId,
        };

        if (PAYMENT_GATEWAY === 'RZP' && isINR) {
            const razorpayConfig = {
                currency_code: paymentConfig.currency_code,
                amount: paymentConfig.amount,
                notes: paymentConfig,
            };
            getRazorPaySessionId(razorpayConfig);
            return;
        } else {
            getPayPalSessionId(paymentConfig);
            return;
        }

    } catch (err) {
        console.error('!!', err);
        failToast("An error occurred. Please try again later.");
        if (window.location.pathname === "/login") {
            window.location.href = '/payments';
        }
    }
};

const Payments = () => {
    const [getTab, setTab] = useState('');
    const userDetails = useSelector(s => s.CusPage.customerDtls);
    const [getDuration, setDuration] = useState('');
    const [enableDetailsPopup, setEnableDetailsPopup] = useState(false);
    const isINR = localStorage.getItem("country_code") === "IN";

    const handleTab = (state = "") => {
        setTab(state);
    };

    const handleInput = (ele = {}) => {
        const { target: { value = false } = {} } = ele;
        setDuration(value ? 'yearly' : 'monthly');
    };

    const handleClick = (planId = 0) => {
        if ((planId === userDetails.planId && getDuration === userDetails.planDuration) || planId < userDetails.planId) return false;
        if (planId === userDetails.planId && userDetails.planDuration === 'yearly' && getDuration === 'monthly') return false;
        getStripPaymentSession(planId, getDuration);
    };

    useEffect(() => {
        store.dispatch(organisationMemberListAction());
        getCustomerDetails();
        getStreamingLimit();
    }, [userDetails.organisationId]);

    const handlePlanText = (_id, name) => {
        if ((userDetails.planId === _id && getDuration === userDetails.planDuration) || (_id === PLAN_IDS.FREE && userDetails.planId === PLAN_IDS.FREE)) {
            return "Your Current Plan";
        }
        else if (_id === PLAN_IDS.FREE) {
            return "Start For Free";
        }
        return `Choose ${name}`;
    };

    const handleButtonClick = (id) => {

        if (!getClassName(id, userDetails, getDuration) && !getDisableClassName(id, userDetails, getDuration)) {
            const freeUser = userDetails.planId === PLAN_IDS.FREE && [PLAN_IDS.BASIC, PLAN_IDS.PREMIUM].includes(id);
            trackHubspotEvent(TRACK_UPGRADE_CLICK, {
                "plan_name": (freeUser) ? getPlanNamebyId(id) + " (Trial)" : getPlanNamebyId(id),
                "duration": getDuration
            });
            setEnableDetailsPopup(id);
        } else setEnableDetailsPopup(false);
    };

    useEffect(() => {
        if (userDetails.planId) setTab([1, 2, 3].includes(userDetails?.planId) ? Individual : Business);
        setDuration(userDetails?.planDuration?.includes('month') ? 'monthly' : 'yearly');
    }, [userDetails?.planDuration, userDetails?.planId]);

    const escFunction = (event) => {
        if (event.key === "Escape") setEnableDetailsPopup(false);
    };

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return (() => {
            document.removeEventListener("keydown", escFunction, false);
        });
    });

    const successMessage = localStorage.getItem('campaignPaymentSuccessMessage');
    useEffect(() => {
        if (!successMessage) return;
        succToast(successMessage);
        setTimeout(() => {
            localStorage.removeItem('campaignPaymentSuccessMessage');
        }, 3000);
    }, [successMessage]);

    return (
        <>
        <div className="subscriptions">
            <div className="subscriptions_header">
                <h1 className="heading">
                    Stream LIVE with
                    <span className="text">
                        <span>Smart Plans</span>
                        <IconPaymentHeadingBg />
                    </span>
                </h1>
                <h6 className="heading_sm">Grow your live viewership like never before </h6>
                <p className="desc">Pick the plan that most suits your live streaming goals & business needs.</p>
            </div>
            <div className="subscriptions_body">
                <div className="subscriptions_tab">
                    <button type="button" onClick={() => handleTab(Individual)}
                        className={` ${getTab === Individual ? " active " : " "} subscriptions_action`}>Individual</button>
                    <button type="button" onClick={() => handleTab(Business)}
                        className={` ${getTab === Business ? " active " : " "} subscriptions_action`}>Business</button>
                </div>
                <div className="subscriptions_toggle">
                    <label className={` ${getDuration === 'monthly' ? " active pointer-events-none" : " "} tab_label`} htmlFor="subscriptions">Monthly</label>
                    <CommonActiveToggle
                        toggleType=" style2 "
                        toggleAnimate={true}
                        jestId={`Status-1`}
                        name="getBannerStatus"
                        onChange={(e) => { handleInput(e); }}
                        id={`subscriptions`}
                        status={getDuration === 'yearly'}
                        parentId={"_subscriptions"}
                    />
                    <label className={` ${getDuration === 'yearly' ? " active pointer-events-none" : " "} tab_label`} htmlFor="subscriptions">Yearly</label>
                </div>
                <div className="subscriptions_plans">
                    {getDuration && getTab ?
                        <Each
                            of={Plans}
                            render={(item) => {
                                const { name = "", type = "", desc = "", monthlyBill = "", id = "",
                                    heading = "", annualBill = "", offer = "", toolTip = "",
                                    plandetails = [], enableInfo = false } = item;
                                if (type !== getTab) { return false; };
                                return (
                                    <div className={` ${getClassName(id, userDetails, getDuration)} subscriptions_plan style_2`}>
                                        <div className="subscriptions_content">
                                            <div className="plan_info">
                                                <strong>{name}</strong>
                                                <p style={{ maxWidth: id === 1 || id === 5 ? "210px" : " 250px ", margin: "auto" }}>{desc}</p>
                                            </div>
                                            <div className="plan_price">
                                                <strong>{isINR ? <IconRupees /> : "$"}
                                                {convertNumToCurrency(getPlanAmount(getDuration === 'monthly' ? monthlyBill : annualBill, !isINR))}
                                                </strong>
                                                <span className="plan_expiry">{handleDurationText(id)}</span>
                                                <span className="plan_offer">
                                                    {PLAN_IDS.FREE !== id && (<>Save {isINR ?
                                                        <IconCurrencyRupee style={{ width: "14px", height: "14px", position: "relative", top: "3px" }} /> : "$"}
                                                        {offer ? `${convertNumToCurrency(getPlanAmount(offer, !isINR))} billed annually` : ''}
                                                    </>)}
                                                </span>
                                            </div>
                                            <div className="selected_plan_action">
                                                <button className={`${getClassName(id, userDetails, getDuration)} plan_action ${getDisableClassName(id, userDetails, getDuration)}`}
                                                    onClick={() => handleButtonClick(id)}
                                                    type="button">
                                                    {handlePlanText(id, name)}
                                                </button>
                                            </div>

                                            <div className="selected_plan_feature">
                                                <h5 className="flex info_tooltip_hover infoicon1">{heading}
                                                    {enableInfo && toolTip &&
                                                        <InfoTooltip className="absolute" iconType="" tooltipDesc={toolTip} maxWidth={"260px"} />
                                                    }
                                                </h5>
                                                <div className="selected_plan_details">
                                                    <Each
                                                        of={plandetails}
                                                        render={(_item) => {
                                                            return (
                                                                <div className="selected_plan_content">
                                                                    <span className="icon"><IconPaymentCheckList /></span>
                                                                    <p>{_item?.text} <span className="infoicon info_tooltip_hover"><IconTooltip />
                                                                        <InfoTooltip className="absolute" iconType="" tooltipDesc={_item?.toolTip} maxWidth={"260px"} /></span></p>
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    : null}
                </div>
            <PricingFeatureAccordion
                selectedTab={getTab}
                planDuration={getDuration}
                handleInput={(e) => handleInput(e)}
                handleButtonClick={handleButtonClick}
            />

            </div>
            {enableDetailsPopup ?
                <PaymentIntermediateScreen
                    selectedPlan={enableDetailsPopup}
                    isINR={isINR}
                    getDuration={getDuration}
                    currentPlan={userDetails.planId}
                    handleClick={(id) => {
                        setEnableDetailsPopup(false);
                        handleClick(id);
                    }}
                    handleToggle={() => setEnableDetailsPopup(false)}
                />
                : null}
        </div>
        </>
    );
};

export default React.memo(Payments);
