import { all, call, put, takeLatest } from "redux-saga/effects";
import { getSessionRequest } from "../../aws/awsHelper";
import {
  broadCastResponse,
  createSession,
  getMeetDataService,
  getMeetDataServiceToday,
  getPastDataService,
  getUpcomingDataService,
  getWebinarCancelApiCall,
  getWebinarUserListApiCall,
  stageResponse,
  updateSession,
  webinarLogoSelectApiCall,
  updateWebinarbrandingsApiCall,
  webinarLogoRemoveApiCall,
  webinarLogoUploadApiCall,
} from "../../services/aswServices";
import * as awsTypes from "../actionTypes/awsTypes";
import { succToast, failToast } from "../../helper/ApiToast";
import {
  addTodayMeetData,
  validateGetDataUnderscore,
  validateTitle,
  validateUnderscore,
} from "../../common/helper";
import { initializeFirebaseRealDb } from "../../firebase/firebaseHelper";
import { replaceSpacesWithNonbreakSpace } from "../../helper/Validation";
import { participantListRequest } from "../../services/participantServices";
import { addParticipantChat, createGroup, getStageIdFromStageArn, handleLoader, selectStream } from "../../helper/utility";
import History from "../../common/History";
import { getDeleteStageService } from "../../services/deleteServices";
import store from "..";
import { startRecordService, startStreamService, stopStreamService } from "../../services/serverStudioService";
import { updateLiveStatus } from "../action/awsActions";
import { IS_IVS_STREAM_MODE } from "../../helper/ApiUrl";
import { recordTimeDelayAction } from "../action/tempAction";
import { getSessionDetails } from "../../services/guestService";

export function* createStage(payload) {
  const { data } = payload;
  const { title = "", displayName = "", webinarEvent = false
  } = data;
  const searchData = data.searchData || {};

  yield all([put({ type: "DO_LOADING_PAGE", loading: true })]);

  try {
    const groupResponse = yield createGroup(title?.value);
    if (groupResponse?.statusCode === 200) {
      localStorage.setItem("Group_Jid", JSON.stringify(groupResponse?.data?.groupJid || ''));
    }
    const stageState = yield call(stageResponse, data);
    const stageArnData = stageState?.data?.data?.stage?.arn;
    selectStream(stageArnData, data?.select, data?.selectedId, data?.broadcastId, title.value, data.type);
    if (stageState.data.status === 200) {
      succToast(stageState.data.message);
      const sessionRequest = yield call(getSessionRequest, {
        ...stageState?.data?.data,
        sessionName: validateTitle(title.value),
        displayName: displayName?.value,
        hostUserId: data?.host.userId,
        sessionJid: groupResponse?.data?.groupJid || '',
      });

      const sessionResponse = yield call(createSession, sessionRequest);
      if (sessionResponse?.data?.status === 200) {
        const stageArn = { data: sessionRequest?.stageArn };
        const participantList = yield call(participantListRequest, stageArn);
        if (participantList?.status === 200) {
          addParticipantChat(
            participantList?.data?.data?.participantsList,
            title.value,
            groupResponse?.data?.groupJid || '',
          );
        }
        if (!data?.scheduledTime) {
          yield all([
            put({
              type: "CREATE_STAGE_REDUCER",
              StageData: {
                ...stageState?.data?.data,
                method: "create",
                sessionName: validateTitle(
                  validateUnderscore(replaceSpacesWithNonbreakSpace(title.value))
                ),
                displayName: displayName?.value,
                hostUserId: data?.host.userId,
                sessionJid: groupResponse?.data?.groupJid || '',
              },
              stageStatus: true,
            }), //lodering on / off
          ]);
        }
        if (data?.scheduledTime) {
          const meetDataInput = {
            userId: data?.host?.userId,
            orgId: data?.currentOrg?.organisationId,
            position: searchData?.position || "Upcoming",
          };
          const meetTableData = yield call(getMeetDataService, meetDataInput);
          if (meetTableData.data.status === 200) {
            handleLoader(webinarEvent);
            yield all([
              put({ type: "DO_LOADING_WEBINAR_PAGE", loading: webinarEvent }),
              put(
                {
                  type: "GET_MEET_DATA_REDUCER",
                  sessionData: validateGetDataUnderscore(
                    meetTableData?.data?.data?.sessionInfo
                  ),
                  sessionStatus: true,
                }
              ), //lodering on / off
              put({ type: "DO_LOADING_WEBINAR_PAGE", loading: webinarEvent }),
            ]);
            yield all([
              put({ type: "DO_LOADING_WEBINAR_PAGE", loading: webinarEvent }),
              put({
                type: "GET_MEET_TOTALRECORD_DATA_REDUCER",
                sessionTotalRecordData: meetTableData?.data,
              }),
              put({ type: "DO_LOADING_WEBINAR_PAGE", loading: webinarEvent }),
            ]);
            if (webinarEvent && stageArnData) {
              yield all([
                put({ type: "DO_LOADING_WEBINAR_PAGE", loading: webinarEvent }),
              ]);
              setTimeout(() => {
                handleLoader(webinarEvent);
                History.push(`/webinar-studio?url=${stageArnData}`);
                setTimeout(() => {
                  store.dispatch({ type: "DO_LOADING_WEBINAR_PAGE", loading: false });
                }, 4000);
              }, 2000);
            }
          }
        }
        initializeFirebaseRealDb(sessionRequest, data);
        addTodayMeetData();
      }
      if (sessionResponse?.data?.status === 400) {
        failToast(sessionResponse.data.message);
      }
    } else {
      failToast(stageState.data.message);
    }
  } catch (error) {
    yield all([put({ type: "DO_LOADING_PAGE", loading: false })]); //lodering on / off
  }
  yield all([put({ type: "DO_LOADING_PAGE", loading: false })]);
}

export function* getMeetData(payload) {
  const { data } = payload;
  try {
    const meetTableData = yield call(getMeetDataService, data);
    if (meetTableData.data.status === 200) {
      yield all([
        put({
          type: "GET_MEET_DATA_REDUCER",
          sessionData: validateGetDataUnderscore(
            meetTableData?.data?.data?.sessionInfo
          ),
          sessionStatus: true,
        }), //lodering on / off
      ]);
      yield all([
        put({
          type: "GET_MEET_TOTALRECORD_DATA_REDUCER",
          sessionTotalRecordData: meetTableData?.data,
        }),
      ]);
      yield all([put({ type: "ENABLE_BROADCAST", loading: false })]); //lodering on / off
    } else {
      yield all([put({ type: "ENABLE_BROADCAST", loading: false })]); //lodering on / off
    }
  } catch (error) {
    yield all([put({ type: "ENABLE_BROADCAST", loading: false })]); //lodering on / off
  }
}
export function* getPastData(payload) {
  const { data } = payload;
  try {
    const meetTableData = yield call(getPastDataService, data);
    if (meetTableData.data.status === 200) {
      yield all([
        put({
          type: "GET_MEETPAST_TOTALRECORD_DATA_REDUCER",
          sessionPastData: meetTableData?.data,
        }),
      ]);
      yield all([put({ type: "ENABLE_BROADCAST", loading: false })]);
    }
  } catch (error) {
    yield all([put({ type: "ENABLE_BROADCAST", loading: false })]);
  }
}
export function* getUpcomingData(payload) {
  const { data } = payload;
  try {
    const meetTableData = yield call(getUpcomingDataService, data);
    if (meetTableData.data.status === 200) {
      yield all([
        put({
          type: "GET_MEETUPCOMING_TOTALRECORD_DATA_REDUCER",
          sessionUpcomingData: meetTableData?.data,
        }),
      ]);
      yield all([put({ type: "ENABLE_BROADCAST", loading: false })]);
    }
  } catch (error) {
    yield all([put({ type: "ENABLE_BROADCAST", loading: false })]);
  }
}

export function* getBroadcastData(payload) {
  const { data } = payload;
  yield put({ type: "DO_LOADING_PAGE", loading: true });

  try {
    const broadcastCredentials = yield call(broadCastResponse, data);
    if (broadcastCredentials.data.status === 200) {
      yield all([
        put({
          type: "GET_BROADCAST_DATA_REDUCER",
          broadcastCredentials: broadcastCredentials?.data?.data,
          broadcastCredentialsStatus: true,
        }),
      ]);
      const stageId = getStageIdFromStageArn(data.stageArn);
      if (!IS_IVS_STREAM_MODE) yield call(getserverStartStream, { ...data, stageId });
    } else {
      failToast(broadcastCredentials.data.message);
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  } catch (error) {
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

export function delayAPICall(ms = 1000) {
  // This will help to delay API call by given time (Millisecond)
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function* getserverStartStream(payload) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const res = yield call(startStreamService, payload);
    if (res.data.statusCode === 200 || res.data.statusCode === 422) {

      succToast("You are Live");
      store.dispatch(updateLiveStatus(true));
      const searchParam = window?.location?.search.replace("?", "");

      const stageDetails = yield call(getSessionDetails, searchParam);
      yield put({ type: "RECORDED_STREAM_TIME", recordTime: stageDetails?.stageDetails?.recordStartTime });

      if (stageDetails?.stageDetails?.recordStartTime) {
        store.dispatch(recordTimeDelayAction("Broadcasted"));
        return false;
      } else {
        const maxAttempts = 20; // To avoid infinite loop, set a maximum number of attempts
        let attempts = 0;
        let recordStartSuccess = false;

        while (attempts < maxAttempts && !recordStartSuccess) {
          yield call(delayAPICall, 3000); // Delay 3 seconds between attempts
          const recordResponse = yield call(startRecordService, { ...payload, recordType: 'start' });

          if (recordResponse?.data?.statusCode === 200) {
            recordStartSuccess = true;
            const stageDetails2 = yield call(getSessionDetails, searchParam);
            yield put({ type: "RECORDED_STREAM_TIME", recordTime: stageDetails2?.stageDetails?.recordStartTime });
            store.dispatch(recordTimeDelayAction("Broadcasted"));
          }
          attempts += 1;
        }

        if (!recordStartSuccess) {
          console.log("Failed to start record after multiple attempts");
        }
      }
    }
  } catch (error) {
    console.error("Error in getserverStartStream:", error);
  } finally {
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

export function* getserverStopStream(payload) {

  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    // Stop the recording before stop the stream
    yield call(startRecordService, {
      stageId: payload?.data?.stageId,
      organisationId: payload?.data?.organisationId,
      recordType: 'stop',
    });

    const res = yield call(stopStreamService, payload.data);
    if (res.data.statusCode === 200) {
      succToast(res.data?.message);
    } else {
      failToast(res.data.message);
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  } catch (error) {
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

export function* updateCallStatus(payload) {
  const { data } = payload;
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const broadcastCredentials = yield call(updateSession, data);
    if (broadcastCredentials.status === 200 && data.callStatus !== "on-live") {
      yield all([
        put({
          type: "UPDATE_CALL_STATUS_REDUCER",
          callStatus: data,
        }),
      ]);
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  } catch (error) {
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

export function* updateTodayMeet(payload) {
  const { data } = payload;
  try {
    const meetTableData = yield call(getMeetDataServiceToday, data);
    if (meetTableData.data.status === 200) {
      yield all([
        put({
          type: "GET_MEET_TODAY_DATA_REDUCER",
          sessionData: validateGetDataUnderscore(
            meetTableData?.data?.data?.sessionInfo
          ),
          sessionStatus: true,
        }), //lodering on / off
      ])
    }
  } catch (error) {
    yield all([put({ type: "ENABLE_BROADCAST", loading: false })]); //lodering on / off
  }
}

export function* updatePastMeet(payload) {
  const { data } = payload;
  try {
    const meetTableData = yield call(getMeetDataServiceToday, data);
    if (meetTableData.data.status === 200) {
      yield all([
        put({
          type: "GET_MEET_PAST_DATA_REDUCER",
          sessionData: validateGetDataUnderscore(
            meetTableData?.data?.data?.sessionInfo
          ),
          sessionStatus: true,
        }), //lodering on / off
      ])
    }
  } catch (error) {
    yield all([put({ type: "ENABLE_BROADCAST", loading: false })]); //lodering on / off
  }
}

export function* updateScheduledMeet(payload) {
  const { data } = payload;
  try {
    const meetTableData = yield call(getMeetDataServiceToday, data);
    if (meetTableData.data.status === 200) {
      yield all([
        put({
          type: "GET_MEET_SCHEDULED_DATA_REDUCER",
          sessionData: validateGetDataUnderscore(
            meetTableData?.data?.data?.sessionInfo
          ),
          sessionStatus: true,
        }), //lodering on / off
      ])
    }
  } catch (error) {
    yield all([put({ type: "ENABLE_BROADCAST", loading: false })]); //lodering on / off
  }
}

export function* getWebinarUserData(payload) {
  const { data } = payload;
  try {
    yield put({ type: "DO_LOADING_PAGE", loading: true });
    const webinarUserListRes = yield call(getWebinarUserListApiCall, data);
    if (webinarUserListRes.data.status === 200) {
      yield put({
        type: "WEBINAR_USERS_REDUCER",
        webinarUsers: webinarUserListRes?.data?.data,
        pagination: {
          totalPages: webinarUserListRes?.data.totalPages,
          totalRecords: webinarUserListRes?.data.totalRecords
        }
      });
    } else {
      failToast(webinarUserListRes.data.message);
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });

  } catch (error) {
    yield put({ type: "DO_LOADING_PAGE", loading: false });

  }
}

export function* getWebinarCancelled(payload) {
  const { data } = payload;
  try {
    yield put({ type: "DO_LOADING_PAGE", loading: true });
    const webinarCancelledRes = yield call(getWebinarCancelApiCall, data);
    if (webinarCancelledRes.data.status === 200) {
      succToast(webinarCancelledRes.data.data);
    } else {
      failToast(webinarCancelledRes.data.message);
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  } catch (error) {
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

export function* updateWebinarBrandingsSaga(payload) {
  const { data: { stageArn = "", brandingData = "" } = {} } = payload;
  try {
    yield put({ type: "DO_LOADING_PAGE", loading: true });
    const res = yield call(updateWebinarbrandingsApiCall, brandingData);
    if (res.data.status === 200) {
      succToast(res.data.message);
      try {
        yield put({ type: "DO_LOADING_PAGE", loading: true });
        const response = yield call(getDeleteStageService, stageArn);
        if (response.data.status === 200) {
          yield put({
            type: "WEBINAR_BRANDING_REDUCER",
            webinarToken: response?.data?.data?.stageDetails?.webinarDetails?.webinarToken,
            stageDetails: response?.data?.data?.stageDetails,
            webinarBrandings: response?.data?.data?.stageDetails?.webinarBrandings,
            webinarDetails: response?.data?.data?.stageDetails?.webinarDetails,
          });
        } else {
          failToast(response.data.message);
        }
        yield put({ type: "DO_LOADING_PAGE", loading: false });
      } catch (error) {
        yield put({ type: "DO_LOADING_PAGE", loading: false });
      }
    } else {
      failToast(res.data.message);
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });

  } catch (error) {
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

export function* getWebinarBrandingDataSaga(payload) {
  const { data } = payload;
  try {
    yield put({ type: "DO_LOADING_PAGE", loading: true });
    const response = yield call(getDeleteStageService, data);
    if (response?.data?.status === 200) {
      yield put({
        type: "WEBINAR_BRANDING_REDUCER",
        webinarToken: response?.data?.data?.stageDetails?.webinarDetails?.webinarToken,
        stageDetails: response?.data?.data?.stageDetails,
        webinarBrandings: response?.data?.data?.stageDetails?.webinarBrandings,
        webinarDetails: response?.data?.data?.stageDetails?.webinarDetails,
      });
    } else {
      failToast(response?.data?.message);
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });

  } catch (error) {
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

export function* webinarLogoUploadSaga(payload) {
  const { data: { stageArn = "", logoData = "" } = {} } = payload;
  try {
    yield put({ type: "DO_LOADING_PAGE", loading: true });
    const res = yield call(webinarLogoUploadApiCall, logoData);
    if (res.data.status === 200) {
      try {
        yield put({ type: "DO_LOADING_PAGE", loading: true });
        yield call(getWebinarBrandingDataSaga, { data: stageArn });
      } catch (error) {
        yield put({ type: "DO_LOADING_PAGE", loading: false });
      }
    } else {
      failToast(res.data.message);
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });

  } catch (error) {
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

export function* webinarLogoSelectSaga(payload) {
  const { data: { stageArn = "", logoData = "", toast = true } = {} } = payload;
  try {
    yield put({ type: "DO_LOADING_PAGE", loading: true });
    const res = yield call(webinarLogoSelectApiCall, logoData);
    if (res.data.status === 200) {
      toast ? succToast(res.data?.data) : console.log(res.data?.data, "Logo");
      try {
        yield put({ type: "DO_LOADING_PAGE", loading: true });
        yield call(getWebinarBrandingDataSaga, { data: stageArn });
      } catch (error) {
        yield put({ type: "DO_LOADING_PAGE", loading: false });
      }
    } else {
      failToast(res.data.message);
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });

  } catch (error) {
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

export function* webinarLogoRemoveSaga(payload) {
  const { data: { stageArn = "", logoData = "" } = {} } = payload;
  try {
    yield put({ type: "DO_LOADING_PAGE", loading: true });
    const res = yield call(webinarLogoRemoveApiCall, logoData);
    if (res.data.status === 200) {
      succToast("Logo removed successfully");
      try {
        yield put({ type: "DO_LOADING_PAGE", loading: true });
        yield call(getWebinarBrandingDataSaga, { data: stageArn });
      } catch (error) {
        yield put({ type: "DO_LOADING_PAGE", loading: false });
      }
    } else {
      failToast(res.data.message);
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });

  } catch (error) {
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

export default function* rootAwsSaga() {
  yield takeLatest(awsTypes.CREATE_STAGE, createStage);
  yield takeLatest(awsTypes.GET_MEET_DATA, getMeetData);
  yield takeLatest(awsTypes.GET_PAST_DATA, getPastData);
  yield takeLatest(awsTypes.GET_UPCOMING_DATA, getUpcomingData);
  yield takeLatest(awsTypes.GET_BROADCAST_DATA, getBroadcastData);
  yield takeLatest(awsTypes.UPDATE_CALL_STATUS, updateCallStatus);
  yield takeLatest(awsTypes.GET_TODAY_MEET, updateTodayMeet);
  yield takeLatest(awsTypes.GET_PAST_MEET, updatePastMeet);
  yield takeLatest(awsTypes.GET_SCHEDULED_MEET, updateScheduledMeet);
  yield takeLatest(awsTypes.WEBINAR_USERS, getWebinarUserData);
  yield takeLatest(awsTypes.WEBINAR_CANCEL, getWebinarCancelled);
  yield takeLatest(awsTypes.WEBINAR_BRANDING_UPDATE, updateWebinarBrandingsSaga);
  yield takeLatest(awsTypes.WEBINAR_BRANDING_DATA, getWebinarBrandingDataSaga);
  yield takeLatest(awsTypes.WEBINAR_LOGO_UPLOAD, webinarLogoUploadSaga);
  yield takeLatest(awsTypes.WEBINAR_LOGO_SELECT, webinarLogoSelectSaga);
  yield takeLatest(awsTypes.WEBINAR_LOGO_REMOVE, webinarLogoRemoveSaga);
  yield takeLatest("STOP_STREAM_SERVICE", getserverStopStream);
}
