import { updateParticipantCount, updateParticipantPostion } from "../firebase/firebaseRealtimeFunctions";
import store from "../store";
import { currentStageArrn, getCurrentOrgId, getCurrentUserId } from "./utility";

export const reorderParticipantListLoop = async (awsStageReducer) => {
    const broadcastBranding = store.getState()?.broadcastBranding;
    const participantCount = broadcastBranding?.participantCount;
    const fbPosition1 = broadcastBranding?.postion1 || "";
    const fbPosition2 = broadcastBranding?.postion2 || "";
    const fbPosition3 = broadcastBranding?.postion3 || "";
    const fbPosition4 = broadcastBranding?.postion4 || "";
    const fbPosition5 = broadcastBranding?.postion5 || "";
    const fbPosition6 = broadcastBranding?.postion6 || "";
    const fbPosition7 = broadcastBranding?.postion7 || "";

    const participantList = [fbPosition1, fbPosition2, fbPosition3, fbPosition4, fbPosition5, fbPosition6, fbPosition7];
    const currentPosition =
        participantList.findIndex((ele) => ele === getCurrentUserId()) + 1;
    updateParticipantCount(
        getCurrentOrgId(),
        currentStageArrn(awsStageReducer),
        participantCount - 1
    );
    for (let i = currentPosition; i < 8; i++) {
        updateParticipantPostion(
            getCurrentOrgId(),
            currentStageArrn(awsStageReducer),
            i,
            "ordering",
            i >= 6 ? -1 : participantList[i]
        );
    }
};

export const positioningMembers = (
    arrayList,
    isHosted,
    fbPosition1,
    fbPosition2,
    fbPosition3,
    fbPosition4,
    fbPosition5,
    fbPosition6,
    fbPosition7,
    hided
) => {
    if (!hided) {
        return arrayList;
    }
    const arr = [];
    const arragment = ["host", fbPosition1,
        fbPosition2,
        fbPosition3,
        fbPosition4,
        fbPosition5,
        fbPosition6,
        fbPosition7]
    arragment.forEach(ele => {
        if (ele === "host") {
            arr.push(arrayList[0]);
        } else {
            arr.push(arrayList.find((elements) => elements?.userId == ele.toString()));
        }
    });
    if (!isHosted) {
        arr.shift();
    }
    return arr.filter((ele) => ele !== undefined);
};

export const reorderParticipantList = async (awsStageReducer) => {
    const broadcastBranding = store.getState()?.broadcastBranding;
    const participantCount = broadcastBranding?.participantCount;
    const fbPosition1 = broadcastBranding?.postion1 || "";
    const fbPosition2 = broadcastBranding?.postion2 || "";
    const fbPosition3 = broadcastBranding?.postion3 || "";
    const fbPosition4 = broadcastBranding?.postion4 || "";
    const fbPosition5 = broadcastBranding?.postion5 || "";
    const fbPosition6 = broadcastBranding?.postion6 || "";
    const fbPosition7 = broadcastBranding?.postion7 || "";

    const participantList = [fbPosition1, fbPosition2, fbPosition3, fbPosition4, fbPosition5, fbPosition6, fbPosition7];
    const currentPosition =
        participantList.findIndex((ele) => ele === getCurrentUserId()) + 1;
    updateParticipantCount(
        getCurrentOrgId(),
        currentStageArrn(awsStageReducer),
        participantCount - 1
    );
    switch (currentPosition) {
        case 1:
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                1,
                "ordering",
                participantList[1] ? participantList[1] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                2,
                "ordering",
                participantList[2] ? participantList[2] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                3,
                "ordering",
                participantList[3] ? participantList[3] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                4,
                "ordering",
                participantList[4] ? participantList[4] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                5,
                "ordering",
                participantList[5] ? participantList[5] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                6,
                "ordering",
                participantList[6] ? participantList[6] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                7,
                "ordering",
                -1
            );

            break;
        case 2:
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                2,
                "ordering",
                participantList[2] ? participantList[2] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                3,
                "ordering",
                participantList[3] ? participantList[3] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                4,
                "ordering",
                participantList[4] ? participantList[4] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                5,
                "ordering",
                participantList[5] ? participantList[5] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                6,
                "ordering",
                participantList[6] ? participantList[6] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                7,
                "ordering",
                -1
            );
            break;
        case 3:
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                3,
                "ordering",
                participantList[3] ? participantList[3] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                4,
                "ordering",
                participantList[4] ? participantList[4] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                5,
                "ordering",
                participantList[5] ? participantList[5] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                6,
                "ordering",
                participantList[6] ? participantList[6] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                7,
                "ordering",
                -1
            );
            break;
        case 4:
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                4,
                "ordering",
                participantList[4] ? participantList[4] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                5,
                "ordering",
                participantList[5] ? participantList[5] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                6,
                "ordering",
                participantList[6] ? participantList[6] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                7,
                "ordering",
                -1
            );
            break;
        case 5:
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                5,
                "ordering",
                participantList[5] ? participantList[5] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                6,
                "ordering",
                participantList[6] ? participantList[6] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                7,
                "ordering",
                -1
            );
            break;
        case 6:
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                6,
                "ordering",
                participantList[6] ? participantList[6] : -1
            );
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                7,
                "ordering",
                -1
            );
            break;
        case 7:
            updateParticipantPostion(
                getCurrentOrgId(),
                currentStageArrn(awsStageReducer),
                7,
                "ordering",
                -1
            );
            break;
        default:
        // code block
    }
};
