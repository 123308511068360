import { reduceDuplicateStream, reduceLeavedParticipants } from "./janusReducerHelper";

const initialParticipantJanus = [];

export const janusParticipantReducer = (
  state = initialParticipantJanus,
  action = {}
) => {
  switch (action.type) {
    case "JANUS_PARTICIPANT_STREAM": {
      return reduceDuplicateStream(state, action.data);
    }
    case "JANUS_PARTICIPANT_REMOVED": {
      return reduceLeavedParticipants(state, action.data);
    }
    case "LOCAL_LEFT": {
      return [];
    }
    default:
      return state;
  }
};
