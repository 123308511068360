import React from 'react';
import './TicketSuccesPopup.scss';
import { IconGreenTickFilled } from '../../../assets/otf';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const TicketSuccesPopup = (props = {}) => {

    return (
        <div className='successPopupWrapper'>
            <div className='successPopup'>
                <i> <IconGreenTickFilled /> </i>
                <strong className='successMsg'>Ticket Submission Successful</strong>
                <small className='successInfo'>Our team will attend to your query shortly. Thank you for reaching out to us.</small>
                <Link to='/'>
                    <button
                        type="button"
                        className="cp-btn-custom"
                    > Back to Home
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default TicketSuccesPopup;
