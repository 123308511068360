import { handleParticipantRemoved, handleStreamAddedinBroadcast } from "../aws/broadcastFunction.js";
import { isHiddenUser } from "../components/MainContent/Broadcast/commonBroadcastfunctions.js";
import { isScreenShareOnAIrUpdate, updateScreenShare } from "../firebase/firebaseRealtimeFunctions.js";
import { isMobile, reinitiateAudio } from "../helper/AwsDeviceAccess.js";
import { getCurrentOrgId, getCurrentUserId, isGuestLogin, isRemovedScreenshare, setUserIdStream } from "../helper/utility.js";
import { poorConnectionSlowLink, startLoader, stopLoader } from "../store/action/awsActions.js";
import { janusParticipantAction, janusParticipantLeaving } from "../store/action/janusAction.js";
import store from "../store/index.js";
import { AVERAGE_BITRATE, GOOD_BITRATE, POOR_BITRATE } from "./callConstants.js";
import { adjustResoltion } from "./callHelper.js";
import Janus from "./janus.js";

let janusSession = null;
let screenShare = null;
let serverUrl = null;
let callbacks = {};
let useAudio = true;
let useVideo = true;
let uniqueID = null;
let call = null;
let isUpdateAudioStream = false;
const VIDEO_ROOM_PLUGIN = "janus.plugin.videoroom";
let localIceState = null;
let mystream = null;
let mystreamScreenShare = null;
let publisherId = null;
const capture = "screen";
let reconnectionScheduler = null;
let bitrate;

let highResolution = {
    "width": 1280,
    "height": 720,
    "frameRate": 24,
    "facingMode": isMobile() ? "user" : "environment"
}
let resolution = {
    "width": 854,
    "height": 480,
    "frameRate": 24,
    "facingMode": "user"
};
let screenShareResolution = {
    "screenshareWidth": 640,
    "screenshareHeight": 480,
    "screenshareFrameRate": 5
};

let videoQuality = ""
let slowLinkPeers = [];
const acodec = null;
const vcodec = null;
let feeds = [];
let feedsObj = {};
let myroom;

export const consoleLog = (title, valObj) => {
    console.log(title, valObj);
};
const errorCallback = (msg) => {
    console.log(msg)
}

const publishOwnFeed = (errorCallbackOwn) => {
    const localStream = store.getState()?.localStreams;
    const { DeviceIds: { video = "" } = {} } = localStream;
    call.createOffer({
        media: {
            audioRecv: false,
            videoRecv: false,
            audioSend: true,
            videoSend: useVideo,
            video: video ? { ...resolution, deviceId: { exact: video } } : null,
            audio: {
                noiseSuppression: {
                    exact: true
                },
                echoCancellation: {
                    exact: true
                },
                googEchoCancellation: {
                    exact: true
                },
                googAutoGainControl: {
                    exact: true
                },
                googNoiseSuppression: {
                    exact: true
                }
            }
        },
        success: function (jsep) {
            videoQuality = GOOD_BITRATE;
            const publish = {
                request: "configure",
                audio: true,
                video: true
            };
            // You can force a specific codec to use when publishing by using the
            // audiocodec and videocodec properties, for instance:
            // 		publish["audiocodec"] = "opus"
            // to force Opus as the audio codec to use, or:
            // 		publish["videocodec"] = "vp9"
            // to force VP9 as the videocodec to use. In both case, though, forcing
            // a codec will only work if: (1) the codec is actually in the SDP (and
            // so the browser supports it), and (2) the codec is in the list of
            // allowed codecs in a room. With respect to the point (2) above,
            // refer to the text in janus.plugin.videoroom.jcfg for more details.
            // We allow people to specify a codec via query string, for demo purposes
            if (acodec)
                publish["audiocodec"] = acodec;
            if (vcodec)
                publish["videocodec"] = vcodec;
            call.send({
                message: publish,
                jsep: jsep
            });
            if (!useAudio) {
                call.muteAudio();
            }
        },
        error: function (error) {
            console.error(error);
            errorCallbackOwn(error);
        }
    });
};

const newRemoteFeed = (id, display, audio, video, errorCallbackRemote) => {
    // A new feed has been published, create a new plugin handle and attach to it as a subscriber
    let remoteFeed = null;
    janusSession.attach({
        plugin: VIDEO_ROOM_PLUGIN,
        success: function (pluginHandle) {
            remoteFeed = pluginHandle;
            remoteFeed.simulcastStarted = false;
            // We wait for the plugin to send us an offer
            const subscribe = {
                request: "join",
                room: myroom,
                ptype: "subscriber",
                feed: id
            };
            // In case you don't want to receive audio, video or data, even if the
            // publisher is sending them, set the 'offer_audio', 'offer_video' or
            // 'offer_data' properties to false (they're true by default), e.g.:
            // 		subscribe["offer_video"] = false;
            // For example, if the publisher is VP8 and this is Safari, let's avoid video
            if (Janus.webRTCAdapter.browserDetails.browser === "safari" &&
                (video === "vp9" || (video === "vp8" && !Janus.safariVp8))) {
                if (video)
                    video = video.toUpperCase();
                // toastr.warning("Publisher is using " + video + ", but Safari doesn't support it: disabling video");
                subscribe["offer_video"] = false;
            }
            remoteFeed.videoCodec = video;
            remoteFeed.send({
                message: subscribe
            });
        },
        error: function (error) {
            Janus.error(error);
            errorCallbackRemote(error);
        },
        onmessage: function (msg, jsep) {
            console.log(msg, jsep, ">>>onmessage")
            const event = msg["videoroom"];
            if (event) {
                if (event === "attached") {
                    // Subscriber created and attached
                    for (let i = 1; i < 10; i++) {
                        if (!feeds[i]) {
                            feeds[i] = remoteFeed;
                            remoteFeed.rfindex = i;
                            break;
                        }
                    }
                    remoteFeed.rfid = msg["id"];
                    remoteFeed.rfdisplay = msg["display"];
                    feedsObj[remoteFeed.rfid] = remoteFeed;
                }
            }
            if (jsep) {
                // Answer and attach
                remoteFeed.createAnswer({
                    jsep: jsep,
                    // Add data:true here if you want to subscribe to datachannels as well
                    // (obviously only works if the publisher offered them in the first place)
                    media: {
                        audioSend: false,
                        videoSend: false
                    }, // We want recvonly audio/video
                    success: function (jsepNew) {
                        const body = {
                            request: "start",
                            room: myroom
                        };
                        remoteFeed.send({
                            message: body,
                            jsep: jsepNew
                        });
                    },
                    error: function (error) {
                        errorCallbackRemote(error);
                    }
                });
            }
        },
        iceState: function (state) {
            if (state === "connected") {
                callbacks.connectionListener({
                    status: state,
                    uniqueId: remoteFeed.rfdisplay
                });
            }
        },
        onremotestream: async function (stream) {
            if (stream?.active) {
                const participantAdded = { isLocal: false, participantId: remoteFeed?.rfid, userId: setUserIdStream(remoteFeed?.rfdisplay), stream }
                store.dispatch(janusParticipantAction(participantAdded));
                setTimeout(async () => {
                    store.dispatch(startLoader(true));
                    await handleStreamAddedinBroadcast(participantAdded);
                    store.dispatch(stopLoader(true));
                }, [1000]);
            }
            if (remoteFeed.rfdisplay !== uniqueID && reconnectionScheduler === null) {
                const videoTracks = stream.getVideoTracks();
                if ((!videoTracks || videoTracks.length === 0) && useVideo) {
                    callbacks.remoteStreamUnavailableListener({
                        uniqueId: remoteFeed.rfdisplay,
                        trackType: "video"
                    });
                }
                if (callbacks.remoteStreamListener !== undefined) {
                    callbacks.remoteStreamListener({
                        stream: stream,
                        uniqueId: remoteFeed.rfdisplay
                    });
                } else {
                    Janus.error("remoteStreamListener not configured");
                    errorCallbackRemote("remoteStreamListener not configured");
                }
            }
        },
        slowLink: function () {
            console.log(">>>slowLink")
            // eslint-disable-next-line no-use-before-define
            //slowLink();
        }
    });
};

const handleJoinedEvent = (msg) => {
    console.log(">>>handleJoinedEvent", msg)
    publisherId = msg["id"];
    publishOwnFeed(errorCallback);
    if (msg["publishers"]) {
        const joinedPublisherList = msg["publishers"];
        joinedPublisherList.forEach((jf) => {
            const joinedId = jf["id"];
            const joinedDisplay = jf["display"];
            const joinedAudio = jf["audio_codec"];
            const joinedVideo = jf["video_codec"];
            if (joinedDisplay !== uniqueID) {
                newRemoteFeed(joinedId, joinedDisplay, joinedAudio, joinedVideo, errorCallback);
            }
        });
    }
};

const handleEventEvent = (msg) => {
    console.log(">>>handleEventEvent", msg)
    if (msg["publishers"]) {
        const eventPublisherList = msg["publishers"];
        eventPublisherList.forEach((jf) => {
            const eventId = jf["id"];
            const eventDisplay = jf["display"];
            const eventAudio = jf["audio_codec"];
            const eventVideo = jf["video_codec"];
            if (eventDisplay !== uniqueID) {
                newRemoteFeed(eventId, eventDisplay, eventAudio, eventVideo, errorCallback);
            }
        });
    } else if (msg["leaving"]) {
        isRemovedScreenshare(store.getState()?.janusParticipants, msg?.leaving);
        const removedPartcipant = store.getState()?.janusParticipants.filter(ele => ele?.participantId === msg?.leaving)
        removedPartcipant.length > 0 && handleParticipantRemoved(removedPartcipant[0]);
        store.dispatch(janusParticipantLeaving(msg));
        const leaving = msg["leaving"];
        let remoteFeed = null;
        feeds.forEach((feed) => {
            if (feed && feed.rfid === leaving) {
                remoteFeed = feed;
            }
        });
        if (remoteFeed !== null) {
            //Need to remove the remote feed
            remoteFeed.detach();
            feeds[remoteFeed.rfindex] = null;
            feedsObj[remoteFeed.rfid] = null;
            const leavingUserJid = remoteFeed.rfdisplay;
            let havingAnotherPeerConnection = false;
            if (callbacks.endedListener !== undefined) {
                feeds.forEach((feed) => {
                    if (feed && feed.rfdisplay === leavingUserJid) {
                        havingAnotherPeerConnection = true;
                    }
                });
                if (!havingAnotherPeerConnection) {
                    callbacks.endedListener({
                        room: myroom,
                        uniqueId: remoteFeed.rfdisplay
                    });
                }
            } else {
                errorCallback("endedListener not configured");
            }
        }
    } else if (msg["unpublished"]) {
        isRemovedScreenshare(store.getState()?.janusParticipants, msg?.unpublished);
        const removedPartcipant = store.getState()?.janusParticipants.filter(ele => ele?.participantId === msg?.unpublished)
        removedPartcipant.length > 0 && handleParticipantRemoved(removedPartcipant[0]);
        handleParticipantRemoved(removedPartcipant);
        store.dispatch(janusParticipantLeaving(msg));
        const unpublished = msg["unpublished"];
        if (unpublished === "ok") {
            // That's us
            call.hangup();
            return;
        }
    } else if (msg["error"]) {
        errorCallback(msg["error"])
    }
};
let slowLinkTimer = null;
let lastSlowLinkReceivedTimeStamp = null;

const startUpdatingBitrate = () => {
    if (call) {
        // eslint-disable-next-line no-use-before-define
        initiateBitrateUpdatingTimer();
        if (videoQuality === POOR_BITRATE) {
            adjustResoltion(AVERAGE_BITRATE);
            videoQuality = AVERAGE_BITRATE;
            store.dispatch(poorConnectionSlowLink("AVERAGE_BITRATE"));
        } else if (videoQuality === AVERAGE_BITRATE) {
            adjustResoltion(GOOD_BITRATE);
            videoQuality = GOOD_BITRATE;
            store.dispatch(poorConnectionSlowLink("GOOD_BITRATE"));
        }
    }
};

const initiateBitrateUpdatingTimer = () => {
    if (slowLinkTimer !== null) {
        clearTimeout(slowLinkTimer);
    }
    if (call) {
        slowLinkTimer = setTimeout(() => {
            slowLinkPeers[uniqueID] = 0;
            startUpdatingBitrate();
        }, 10000);
    }
}

const startDowngradingBitrate = () => {
    if (call) {
        console.log(videoQuality, ">>>startDowngradingBitrate")
        initiateBitrateUpdatingTimer();
        if (videoQuality === GOOD_BITRATE) {
            adjustResoltion(AVERAGE_BITRATE);
            videoQuality = AVERAGE_BITRATE;
            store.dispatch(poorConnectionSlowLink("AVERAGE_BITRATE"));
        } else if (videoQuality === AVERAGE_BITRATE) {
            adjustResoltion(POOR_BITRATE);
            videoQuality = POOR_BITRATE;
            store.dispatch(poorConnectionSlowLink("POOR_BITRATE"));
        } else if (videoQuality === POOR_BITRATE) {
            store.dispatch(poorConnectionSlowLink("POOR_BITRATE"));
        }
    }
};

const slowLink = () => {
    consoleLog(`#flyCall #JANUS Slow link received. roomId => `);
    if (lastSlowLinkReceivedTimeStamp !== null) {
        const diffInSeconds = parseInt((Date.now() - lastSlowLinkReceivedTimeStamp) / 1000);
        if (diffInSeconds < 5) {
            slowLinkPeers[uniqueID] = (slowLinkPeers[uniqueID] || 0) + 1;
        } else {
            slowLinkPeers[uniqueID] = 0;
        }
    } else {
        slowLinkPeers[uniqueID] = (slowLinkPeers[uniqueID] || 0) + 1;
    }
    lastSlowLinkReceivedTimeStamp = Date.now();
    if (slowLinkTimer !== null) {
        clearTimeout(slowLinkTimer);
    }
    // If two or more events are received within 5 seconds, downgrade bitrate immediately
    if (slowLinkPeers[uniqueID] >= 2) {
        slowLinkPeers[uniqueID] = 0;
        startDowngradingBitrate();
    } else {
        // Otherwise, start a timer to potentially update bitrate after 10 seconds
        initiateBitrateUpdatingTimer();
    }
};

const createSession = (server, janusListeners, successCallback, errorCallbackCs) => {
    callbacks = janusListeners;
    serverUrl = server;
    if (janusSession) {
        janusSession.destroy({
            cleanupHandles: true,
            unload: true
        });
    }
    janusSession = new Janus({
        server: server.janus,
        // iceServers: server.iceServers,
        success: function (session) {
            console.log(session, "janusSession---janusSession");
            // janusSession = session;
            successCallback("Session created");
        },
        error: function (error) {
            consoleLog("error occured", error);
            janusSession = null;
            errorCallbackCs(error);
        },
        destroyed: function () {
            //Janus session removed
        }
    });
    console.log(janusSession, "janusSession---janusSession");
};

const attachplugincallBack = (successCallback, errorCallbackAp) => {
    console.log(janusSession, "janusSession---janusSession11");
    janusSession.attach({
        plugin: VIDEO_ROOM_PLUGIN,
        success: function (pluginHandle) {
            call = pluginHandle;
            successCallback("Session created and Plugin attached");
        },
        error: function (error) {
            consoleLog("error occured", error);
            errorCallbackAp("Error in attaching plugin");
        },
        iceState: function (state) {
            localIceState = state;
            if (localIceState === "disconnected") {
                consoleLog(">>>localIceState---disconnected")
                // let { behaviour = "call" } = getCallInfo();
                // if ((behaviour === "call" && isAUserConnectedCall()) || (behaviour === "meet")) {
                //     initiateReconnectionWaitingTimer();
                //     intiateReconnectionScheduler();
                //     initiateReconnectionSignalServer();
                //     callbacks.connectionListener({
                //         status: localIceState,
                //         uniqueId: uniqueID
                //     });
                // }
                // else {
                //     callbacks.endedListener({
                //         uniqueId: uniqueID,
                //         room: myroom
                //     });
                //     if (upscalingBitrateTimer !== null) {
                //         clearTimeout(upscalingBitrateTimer);
                //         upscalingBitrateTimer = null;
                //     }
                //     if (upscalingBitrateAndResolutionTimer !== null) {
                //         clearTimeout(upscalingBitrateAndResolutionTimer);
                //         upscalingBitrateAndResolutionTimer = null;
                //     }
                // }
            }
            if (localIceState === "connected") {
                // if (reconnectionScheduler === null) {
                callbacks.connectionListener({
                    status: localIceState,
                    uniqueId: uniqueID
                });
                // }
            }
        },
        onmessage: function (msg, jsep) {
            const event = msg["videoroom"];
            if (!event) {
                return;
            }
            switch (event) {
                case "joined":
                    handleJoinedEvent(msg);
                    break;
                case "destroyed":
                    console.log(msg, ">>>destroyed");
                    // The room has been destroyed
                    break;
                case "event":
                    console.log(msg, ">>>event");
                    handleEventEvent(msg);
                    break;
                case "talking":
                    console.log(">>>talking");
                    // handleTalkingEvent(msg);
                    break;
                case "stopped-talking":
                    console.log(">>>stopped-talking");
                    // handleStoppedTalkingEvent(msg);
                    break;
                default:
                    console.log(">>>default-onmessage");
                    break;
            }
            if (jsep) {
                call.handleRemoteJsep({
                    jsep: jsep
                });
                const audioCodec = msg["audio_codec"];
                if (mystream && mystream.getAudioTracks() && mystream.getAudioTracks().length > 0 && !audioCodec) {
                    errorCallback("Our audio stream has been rejected, viewers won't hear us");
                }
                const videoCodec = msg["video_codec"];
                if (mystream && mystream.getVideoTracks() && mystream.getVideoTracks().length > 0 && !videoCodec) {
                    errorCallback("Our video stream has been rejected, viewers won't see us");
                }
            }
        },
        onlocalstream: function (stream) {
            if (!isHiddenUser()) {
                store.dispatch(janusParticipantAction({ isLocal: true, userId: !isGuestLogin() ? parseInt(getCurrentUserId()) : getCurrentUserId(), stream }))
                mystream = stream;
            }
        },
        onremotestream: function (stream) {
            // The publisher stream is sendonly, we don't expect anything here
        },
        oncleanup: function () {
            mystream = null;
        },
        slowLink: function () {
            console.log(">>>slowLink");
            //slowLink();
        }
    });
};

export const initiateConnectionJanus = (server, janusListeners, successCallback, errorCallbackInit) => {
    Janus.init({
        callback: () => {
            if (!Janus.isWebrtcSupported()) {
                errorCallbackInit("Webrtc not supported");
            }
            createSession(server, janusListeners, successCallback, errorCallbackInit);
        }
    });
};

export const attachPlugin = (uniqueId, isAudioMute, isVideoMute, successCallback, errorCallbackAtPl) => {
    useAudio = !isAudioMute;
    useVideo = !isVideoMute;
    uniqueID = uniqueId;
    if (janusSession === null || !janusSession?.isConnected()) {
        createSession(
            serverUrl,
            callbacks,
            (sucessResponse) => {
                attachplugincallBack(successCallback, errorCallbackAtPl);
            },
            (errorResponse) => {
            }
        );
    } else {
        attachplugincallBack(successCallback, errorCallbackAtPl);
    }

};

export const createRoom = (roomId, createRoomCallback) => {
    bitrate = 512000;
    const createRoomRequest = {
        "request": "create",
        "publishers": 10,
        "bitrate": bitrate,
        "bitrate_cap": true,
        "fir_freq": 10,
        "video_svc": true,
        "videocodec": "vp8,vp9,h264,av1,h265",
        "audiocodec": "opus,g722,pcmu,pcma,isac32,isac16",
        "audio_active_packets": 200
    };
    if (roomId) {
        createRoomRequest.room = roomId
    }
    call.send({
        message: createRoomRequest,
        success: (response) => {
            if (response.error_code && response.error_code === 427) {
                myroom = roomId;
            } else {
                myroom = response.room;
            }
            console.log(">>>createRoomCallback", response)
            createRoomCallback(myroom);
        },
        error: (error) => {
            console.log("create room error", error);
            createRoomCallback(false);
        }
    });
};

export const joinRoom = (room) => {
    feeds = [];
    feedsObj = {};
    myroom = room;
    const joinRoomRequest = {
        request: "join",
        room: room,
        ptype: "publisher",
        display: `${getCurrentUserId()}` //user identifier
    };
    call.send({
        message: joinRoomRequest,
        // success: () => {
        //     joinRoomCallback(true);
        // },
        // error: (error) => {
        //     Janus.error(error);
        //     joinRoomCallback(false);
        // }
    });
};

const pulblishScreen = () => {
    screenShare.createOffer({
        media: {
            video: capture,
            audioSend: true,
            videoRecv: false,
            screenshareWidth: 1280,
            screenshareHeight: 720,
            screenshareFrameRate: 24
        }, // Screen sharing Publishers are sendonly
        success: function (jsep) {
            consoleLog("Got publisher SDP!", jsep);
            const publish = {
                request: "configure",
                audio: false,
                video: true
            };
            screenShare.send({
                message: publish,
                jsep: jsep
            });
        },
        error: function (error) {
            consoleLog("WebRTC error:", error);
        }
    });
};

export const stopScreenShare = () => {
    if (screenShare) {
        isScreenShareOnAIrUpdate(false);
        updateScreenShare(getCurrentOrgId(), false);
        screenShare.hangup();
    }
    screenShare = null;
};

export const attachPluginForScreenShare = (successCallback, errorCallbackSS) => {
    janusSession.attach({
        plugin: VIDEO_ROOM_PLUGIN,
        success: function (pluginHandle) {
            screenShare = pluginHandle;
            successCallback("Session created and Plugin attached");
        },
        error: function (error) {
            Janus.error(error);
            errorCallbackSS("Error in attaching plugin");
        },
        iceState: function (state) {
            localIceState = state;
        },
        onmessage: function (msg, jsep) {
            const event = msg["videoroom"];
            if (event) {
                if (event === "joined") {
                    // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
                    pulblishScreen();
                } else if (event === "event") {
                    if (msg["error"]) {
                        errorCallbackSS(msg["error"]);
                    }
                }
            }
            if (jsep) {
                screenShare.handleRemoteJsep({
                    jsep: jsep
                });
            }
        },
        onlocalstream: function (stream) {
            mystreamScreenShare = stream;
            mystreamScreenShare.getVideoTracks()[0].onended = function () {
                consoleLog("screenshare data screen share stopped", true);
                stopScreenShare();
            };
            if (callbacks.localStreamListener !== undefined) {
                callbacks.localScreenShareStreamListener({
                    stream: stream,
                    uniqueId: uniqueID,
                });
            } else {
                errorCallback("localStreamListener not configured");
            }
        },
        oncleanup: function () {
            mystreamScreenShare = null;
        }
    });
};

export const joinRoomForScreenShare = (room, joinRoomScreenShareCallback) => {
    feeds = [];
    feedsObj = {};
    myroom = room;
    const joinRoomRequest = {
        request: "join",
        room: room,
        ptype: "publisher",
        display: `screenshare-${getCurrentUserId()}`
    };
    screenShare.send({
        message: joinRoomRequest,
        success: () => {
            joinRoomScreenShareCallback(true);
            console.log("screenShare.send-reg-suss")
        },
        error: (error) => {
            Janus.error(error);
            joinRoomScreenShareCallback(false);
        }
    });
    console.log("screenShare.send-reg")
    screenShare.send({ message: { request: "configure", bitrate: 0 * 1000 } });

};

export const destroySession = () => {
    // clearTimeouts();
    // slowLinkPeers = [];
    bitrate = 512000;
    resolution = {
        "width": 1280,
        "height": 720,
        "frameRate": 24
    };
    if (call) {
        //Leaving the room
        const leave = {
            "request": "leave"
        };
        call.send({
            message: leave
        });
        call.hangup(true);
        if (janusSession !== null) {
            janusSession.destroy({
                cleanupHandles: true,
                unload: true
            });
            janusSession = null;
        }
        stopScreenShare();
    }
};

export const muteVideoJanus = () => {
    if (call) {
        call.muteVideo();
    }
}

export const unmuteVideoJanus = () => {
    if (call) {
        call.unmuteVideo();
    }
}

export const muteAudioJanus = () => {
    console.log(">>muteAudioJanus<<")
    if (call) {
        call.muteAudio();
    }
}

export const unmuteAudioJanus = () => {
    if (call) {
        if (!isUpdateAudioStream) {
            call.unmuteAudio();
        } else {
            call.createOffer({
                media: {
                    audioRecv: false,
                    videoRecv: false,
                    audioSend: true,
                    addAudio: true,
                    audio: {
                        noiseSuppression: {
                            exact: true
                        },
                        echoCancellation: {
                            exact: true
                        },
                        googEchoCancellation: {
                            exact: true
                        },
                        googAutoGainControl: {
                            exact: true
                        },
                        googNoiseSuppression: {
                            exact: true
                        },
                        deviceId: { exact: "default" }
                    }
                },
                success: function (jsep) {
                    call.send({
                        message: {
                            request: "configure"
                        },
                        "jsep": jsep
                    });
                },
                error: function (error) {
                    console.error("unmute error", error);
                }
            });
            isUpdateAudioStream = false;
        }

    }
}

export const isCurrentScreenShare = () => {
    console.log(screenShare, "isCurrentScreenShare")
    if (screenShare) {
        return true;
    } else {
        return false;
    }
}

export const makeScreenShareInitials = () => {
    console.log(screenShare, "makeScreenShareInitials1")
    screenShare = null;
    console.log(screenShare, "makeScreenShareInitials2")

}

export const unPluggedAudio = () => {
    const localStream = store.getState()?.localStreams;
    const { localMic, DeviceIds } = localStream;
    if (!localMic?.active) {
        if (call) {
            call.createOffer({
                media: {
                    removeAudio: true
                },
                success: function (jsep) {
                    console.log("unPluggedAudio>>SucessCallback")
                    call.send({
                        message: {
                            request: "configure"
                        },
                        "jsep": jsep
                    });
                },
                error: function (error) {
                    console.error("mute error", error);
                }
            });
        }
        isUpdateAudioStream = true
        reinitiateAudio(DeviceIds?.audio);
        }
};

export const StreamSwitch = (DeviceIds = "", _isMobile = false) => {
    call.createOffer({
        iceRestart: true,
        media: {
            audioRecv: false,
            videoRecv: false,
            audioSend: false,
            replaceVideo: true,
            video: { ...resolution, deviceId: { exact: DeviceIds } },
        },
        success: function (jsep) {
            const publish = {
                request: "configure",
                audio: true,
                video: true
            };
            if (acodec)
                publish["audiocodec"] = acodec;
            if (vcodec)
                publish["videocodec"] = vcodec;
            call.send({
                message: publish,
                jsep: jsep
            });
        },
        error: function (error) {
            console.error(error);
            // errorCallbackOwn(error);
        }
    });
};
