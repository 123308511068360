import store from "../store";
import _get from "lodash/get";
import History from "../common/History";
import { Put } from "../common/httpRestServices";
import { convertToLowerCase } from "./Validation";
import { apiUrl, envPath, payMent } from "./ApiUrl";
import { dataFetch } from "../store/action/customeAction";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { IconInfoSm, IconRupee, IconToolTipArrow } from "../assets/images";

/**
 * planTypeId Id based planName define
 * using bingo message component
 */
export const planDetailsPlanPrice = (data = 0) => {
    switch (data) {
        case 1:
            return {
                user: 999,
            };
        case 2:
            return {
                user: 1999,
            };
        case 3:
            return {
                user: 2999,
            };
        default:
            return {
            };
    }
};

/**
 * @param  {object} data={}
 * pass which plan has been choosed
 * this is non indain plan
 */
export const usplanNameFindUrl = (data = {}) => {
    const usplanName = [
        { amt: 999, value: "price_1JybkhSEUeaLaGhhJEUJeZI7" },
        { amt: 1999, value: "price_1JyblPSEUeaLaGhhu4mtCLFl" },
        { amt: 2999, value: "price_1JybmfSEUeaLaGhhUSjUwZPb" },
    ];
    const findRecord = usplanName.find((ele) => ele.amt === data.planAmount);
    return findRecord.value;
};

/**
 * @param  {object} data={}
 * pass which plan has been choosed
 * this is indain plan,user is indian collect charge for INR
 */
export const indiaPlanNameFindUrl = (data = {}) => {
    const indiaPlanName = [
        { amt: 999, value: "price_1JybkJSEUeaLaGhhkfuBeYIB" },
        { amt: 1999, value: "price_1JyblySEUeaLaGhhHnoAW3DB" },
        { amt: 2999, value: "price_1JybmOSEUeaLaGhhuFfv9LOe" },
    ];
    const findRecord = indiaPlanName.find((ele) => ele.amt === data.planAmount);
    return findRecord.value;
};

/**
 * @param  {object} data={}
 * pass which plan has been choosed
 * this is non indain plan
 */
export const usLiveplanNameFindUrl = (data = {}) => {
    const usLive = [
        { amt: 999, value: "price_1JybaZSEUeaLaGhh9duZJBCt" },
        { amt: 1999, value: "price_1Jybc3SEUeaLaGhhzpvM6Gq9" },
        { amt: 2999, value: "price_1Jybd8SEUeaLaGhhWc3lZV2l" },
    ];
    const findRecord = usLive.find((ele) => ele.amt === data.planAmount);
    return findRecord.value;
};

/**
 *  @param  {object} data={}
 * pass which plan has been choosed
 * this is indain plan,user is indian collect charge for INR
 */
export const indiaLivePlanNameFindUrl = (data = {}) => {
    const indiaLive = [
        { amt: 999, value: "price_1JybbJSEUeaLaGhhgbnjirzO" },
        { amt: 1999, value: "price_1JybcbSEUeaLaGhhLAw3EQQs" },
        { amt: 2999, value: "price_1JybdeSEUeaLaGhhrQW31x2q" },
    ];
    const findRecord = indiaLive.find((ele) => ele.amt === data.planAmount);
    return findRecord.value;
};

//for tax add purpose
export const getIndianLocationOrNotFind = (loginDetails = {}) => {
    const localLocation = localStorage.getItem("country_code");
    //find location
    let locationBase;
    if (localLocation !== "undefined" && localLocation !== null) {
        locationBase = localLocation;
    } else {
        locationBase = loginDetails.countryCodeShort;
    }
    if (locationBase !== "IN") {//non-india
        return "non-inr";
    } else {//india
        return "inr";
    }
};

/**
 * @param  {object} loginDetails={}//* login user Deatisl for find user is INDIA or NOT
 * @param  {object} state={}//* find user which product choosed
 * pass which plan has been choosed
 * this is indain plan,user is indian collect charge for INR
 */
export const planIDFind = (loginDetails = {}, planDetail = {}) => {
    const {
        currentTap = "", chossedObj: { testUSMonth = "", testUSYear = "",
            testINRYear = "", testINRMonth = "",
            usLiveMonth = "", usLiveYear = "", indLiveMonth = "", indLiveYear = ""
        } = {}
    } = planDetail;
    const localLocation = localStorage.getItem("country_code");
    //find location
    let locationBaseCondition;
    if (localLocation !== "undefined" && localLocation !== null) {
        locationBaseCondition = localLocation;
    } else {
        locationBaseCondition = loginDetails.countryCodeShort;
    }

    if (locationBaseCondition !== "IN") {//us id
        if (currentTap === "month") {
            return envPath === "prod" ? usLiveMonth : testUSMonth;
        } else {
            return envPath === "prod" ? usLiveYear : testUSYear;
        }
    } else {//india
        //currently india is not working
        if (currentTap === "month") {
            return envPath === "prod" ? indLiveMonth : testINRMonth;
        } else {
            return envPath === "prod" ? indLiveYear : testINRYear;
        }
    }
};

/**
 * planTypeId Id based price and user update
 * using priceRange Bar
 */
export const planTypeID = (data = 0) => {
    switch (data) {
        case 4:
            return 33;
        case 5:
            return 66;
        case 6:
            return 99;
        default:
            return 0;
    }
};

export const initialStartValue = () => {
    return 999;//or condition starting value
};

//userRoleId Billing History
export const PaymentStatusOption = [
    { id: 0, name: "status", value: "", planName: "All" },
    { id: 1, name: "status", value: "true", planName: "Success" },
    { id: 2, name: "status", value: "false", planName: "Failed" },
];

//userRoleId Billing History
export const DefaultersStatusOption = [
    { id: 0, name: "status", value: "", planName: "All" },
    { id: 1, name: "status", value: "true", planName: "Activate" },
    { id: 2, name: "status", value: "false", planName: "Deactivate" },
];

//payment and defaulter page palan name mock
export const paymentPageplanType = (key = 0, planArray = []) => {
    const planNameFind = planArray.find(ele => ele.id === key);
    return _get(planNameFind, "planName", "");
};

//payment and defaulter page palan name mock
export const paymentPageplanAmount = (key = 0, planArray = []) => {
    const planNameFind = planArray.find(ele => ele.id === key);
    return _get(planNameFind, "planAmount", "");
};

/**
 * @param  {object} data={}
 * price Based PlanId finded
 * Trail-->0
 * 1000-->1
 * 2000-->2
 * 3000-->3
 * 5000-->4
 */
export const pricePlanIdFind = (data = {}, Type = "Id") => {
    const storeData = store.getState();
    const { CusPage: { planType = [] } = {} } = storeData;
    const findPlanId = planType.find((ele) => convertToLowerCase(ele.planAmount) === convertToLowerCase(data.planAmount));
    if (Type === "Name") {
        return findPlanId.planName;
    }
    return Type === "Amount" ? findPlanId.planAmount : findPlanId.id;
};

export const paymentProcess = async (
    stripe = {}, elements = {}, conInfo = {}, globalStore = {}, planDetail = {}) => {

    const { currentTap = "",
        chossedObj: { packUserCount = "", planid = "", planName = "",
            isMonthAmount = "", isYearAmount = "" } = {} } = planDetail;
    const { CusPage = {}, stripePage = {} } = globalStore;//store
    const { stripeInPutForm = null } = stripePage;//store
    const { customerDtls = {} } = CusPage;//store
    store.dispatch({ type: "DO_LOADING_PAYMENT_INITIAL_LOADER", stripeLoad: true });//lodering on / off
    const localLocationFind = localStorage.getItem("country_code");
    let locationBaseCondition;

    if (localLocationFind && localLocationFind !== "undefined" && localLocationFind !== null) {
        locationBaseCondition = localLocationFind;
    } else {
        locationBaseCondition = _get(customerDtls, "countryCodeShort", "");
    }

    let name = "", phone = "", email = "", line1 = "";
    if (stripeInPutForm.fullName !== "") {
        name = stripeInPutForm.fullName;
        email = stripeInPutForm.emailId;
        phone = stripeInPutForm.phoneNumber;
        line1 = stripeInPutForm.businessAddress;
    } else {
        name = conInfo.fullName;
        phone = conInfo.phoneNumber;
        line1 = conInfo.businessAddress;
        email = conInfo.email ? conInfo.email : customerDtls.emailId;
    }

    const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
            address: {
                line1: line1,
                country: locationBaseCondition,
            },
            name: name,
            email: email,
            phone: phone,
        }
    });
    const planData = {
        customerId: customerDtls.emailId,
        organisation: customerDtls.organisationId,
        paymentMethodId: payload?.paymentMethod?.id,
        planId: planIDFind(customerDtls, planDetail),
        planTypeId: planid,
        planTypeName: planName,
        planDuration: currentTap,
        totalUsers: packUserCount,
        planTypeAmount: currentTap === "month" ? isMonthAmount : isYearAmount,
    };
    if ("error" in payload) {
        return {};
    } else {
        return paymentInitCallService(planData);//payMent process
    }
};

export const payMentFailCall = () => {//if Payment is fail off loader off and Rediect to fail page
    History.push({ pathname: "/payment-fail", state: { data: {} } });
    store.dispatch({ type: "DO_LOADING_PAYMENT_INITIAL_LOADER", stripeLoad: false });//lodering on / off
};

export const callsupscriptionPageData = (userDeatils = {}) => {
    const { data: { userRoleId = 0 } = {} } = userDeatils;
    const getPlanType = store.getState();
    const { CusPage: { planType = [] } } = getPlanType || {};
    if ((userRoleId <= 4) && _get(planType, "length", 0) === 0) {
        store.dispatch(dataFetch());//call subscription data
        store.dispatch({ type: "CUS_PAGE_PLAN_TYPE_API_LOADER", planApiHitLoader: true });
    }
};

export const convertNumToCurrency = (value = "") => {
    const amt = "" + value;
    let lastThree = amt.substring(amt.length - 3);
    const otherNumbers = amt.substring(0, amt.length - 3);
    if (otherNumbers !== '')
        lastThree = ',' + lastThree;
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;//NOSONAR not used in secure contexts
};

export const userLocationFind = () => {
    const storeData = store.getState();
    const { CusPage: { customerDtls = {} } = {}, } = storeData || {};//store
    const getLocationFromSession = localStorage.getItem("country_code");//sesstion store
    let userLocation;

    if (getLocationFromSession && getLocationFromSession !== "undefined" && getLocationFromSession !== null) {
        userLocation = getLocationFromSession;
    } else {
        userLocation = _get(customerDtls, "countryCodeShort", "");
    }
    return userLocation;
};

export const findMonthOrYear = (activeTab = "") => {
    return activeTab === "month";
};

const handlePriceStrike = (price = "") => {
    return price;
};

export const priceAmtDisplay = (activeTab = "", obj = {}) => {
    const userLocation = userLocationFind();
    const locationBasedAmtDisplay = convertToLowerCase(userLocation) === convertToLowerCase("in") ? true : false;
    const findDuration = findMonthOrYear(activeTab);
    const { isMonthAmount = 0, packUserCount = 0, isINDMonthAmount } = obj;
    const amt = locationBasedAmtDisplay ? <>
        <div className="rupee"><IconRupee style={{ fill: "transparent" }} /></div>
        <div className="currency">
            <div className="relative">
                {convertNumToCurrency(isINDMonthAmount)}
                <div className="plan_old_price_container">
                    <div className="plan_old_price " style={{ marginRight: "12px" }}>
                        <IconRupee strokeWidth={2} style={{ fill: "transparent" }} />
                        {convertNumToCurrency(handlePriceStrike(isINDMonthAmount))}
                    </div>
                </div>
            </div>
            <div className="gst_tag relative"><span>{"+"} GST </span>
                <i className="relative"><IconInfoSm />
                    <div className="tool_tip">18% Applicable <IconToolTipArrow />
                    </div>
                </i>
            </div>
        </div>
    </> :

        <div className="currency">
            <div className="relative">
                {"$" + convertNumToCurrency(isMonthAmount)}
                <div className="plan_old_price_container">
                    <div className="plan_old_price p-0">
                        {"$" + convertNumToCurrency(handlePriceStrike(isMonthAmount))}
                    </div>
                </div>
            </div>
        </div>
        ;
    return (
        <>
            {(packUserCount === 100 || packUserCount === 50 || activeTab === "year") ?
                <>
                    <strong className="plan_price sm">
                        Custom Pricing
                    </strong>
                </> :
                <>
                    {findDuration &&
                        <>
                            <strong className="plan_price">{amt}</strong>
                            <span className="plan_duration">/Per month</span>
                        </>
                    }
                </>}

        </>
    );
};

export const getPaymentSessionId = async (passPaymentData = {}) => {
    try {
        const response = await Put(`${apiUrl}${payMent.getsessionId}`, passPaymentData, true);
        const { data: { status = 0, data: { sessionID = "" } = {} } = {} } = response;
        if (status === 200) {
            return sessionID;
        }
        return "";
    } catch (error) {
        return "";
    }
};

export const recentActivityGraphTypes = [
    { id: 0, name: "type", value: "option1", planName: "Streams" },
    { id: 0, name: "type", value: "option2", planName: "Lives" },
    { id: 0, name: "type", value: "option3", planName: "Participants" },
];
