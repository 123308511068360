import React, { memo } from 'react';
import "./TableLayout.scss";

function TableLayout(props = {}) {
    const { children = "", style } = props;
    return (
        <div style={{ ...style }} className='table_layout'>

            {children}
        </div>
    );
}

export default memo(TableLayout);
