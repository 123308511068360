const initialStage = {
  data: []
};

export const mediaUploadListReducer = (state = initialStage, action = {}) => {
  switch (action.type) {
    case "UPDATE_UPLOAD_MEDIA_LIST": {
      return {
        ...state,
        ...action.data
      };
    }
    case "LOCAL_LEFT": {
      return initialStage;
    }
    default:
      return state;
  }
};
