import { Post } from "../common/httpRestServices";
import { apiUrl, loginApi, registerApiUrl } from "../helper/ApiUrl";
import { encodeGetParams } from "../helper/Encypt";

export const fetchLogin = (obj = {}) => {
    const { data: { email = "", password = "", ssoType = "" } = {} } = obj;
    const logValue = {
        "email": email,
        "ssoType": ssoType,
        "password": password,
    };
    return Post(`${apiUrl}${loginApi.logIn}`, logValue, true);
};

export const registerUserService = (obj = {}) => {
    const { data = {} } = obj;
    return Post(`${apiUrl}${registerApiUrl.trialUser}`, data, true);
};

export const otpEmailService = (obj = {}) => {
    const { data = {} } = obj;
    const params = encodeGetParams(data);
    return Post(`${apiUrl}${registerApiUrl.otpEmailGenerate}?${params}`, {}, true);
};

export const otpVerifyService = (obj = {}) => {
    const { data = {} } = obj;
    const params = encodeGetParams(data);
    return Post(`${apiUrl}${registerApiUrl.otpVerify}?${params}`, {}, true);
};

export const validateUserService = (obj = {}) => {
    const { data = {} } = obj;
    const params = encodeGetParams(data);
    return Post(`${apiUrl}${registerApiUrl.validateUser}?${params}`, {}, true);
};

export const logoutService = (obj = {}) => {
    return Post(`${apiUrl}${loginApi.logout}?orgId=${obj.data.organisationId}&userId=${obj.ownUser.userId}`, {}, true);
};
