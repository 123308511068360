import { call, put, takeLatest } from "redux-saga/effects";
import { getCustomerOrganisationList } from "../../services/organisationServices";
import { GET_CUSTOMER_ORGANISATION_DETAILS } from "../actionTypes/customerTypes";
import { failToast, serverNotRespond } from "../../helper/ApiToast";
import { DecryptLogInDetails } from "../../helper/Encypt";

function* getCustomerOrganisation() {
    try {
        yield put({ type: "DO_LOADING_PAGE", loading: true });
        const organisationMemberList = yield call(getCustomerOrganisationList);
        const { data = {} } = organisationMemberList || {};
        if (data.status === 200) {
            yield put({ type: "DO_GET_ORGANISATION_LIST", organisationMemberList: data });
            // this condition for only application users, not for super admin
            const userDetails = DecryptLogInDetails();
            if (!data?.data?.organisations?.length && [1, 2, 3].includes(userDetails?.userRoleId)) {
                window.location.href = '/';
                window.localStorage.clear();
            }
        } else {
            failToast(data.message);
            yield put({ type: "DO_GET_ORGANISATION_LIST", organisationMemberList: {} });
        }
        yield put({ type: "DO_LOADING_PAGE", loading: false });
    }
    catch (error) {
        serverNotRespond();
        yield put({ type: "DO_LOADING_PAGE", loading: false });
    }
}

export default function* organisationSaga() {
    yield takeLatest(GET_CUSTOMER_ORGANISATION_DETAILS, getCustomerOrganisation);
}
