import React from 'react';

export const LayoutBody = (props = {}) => {
    const { className = "", children = "", style = {} } = props;
    return (
        <div style={{ ...style }} className={` ${className} common_layout_body `}>
            {children}
        </div>
    );
};

export default LayoutBody;
