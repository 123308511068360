import { all, fork } from "redux-saga/effects";
import TeamsSaga from './teamsSaga';
import LoginSaga from "./loginSaga";
import ResetPassSaga from "./resetPassSaga";
import BroadcastSaga from "./BroadcastSaga";
import ForgetPassSaga from "./forgetPassSaga";
import ChangePasswordSaga from "./ChangePasswordSaga";
import customerSaga from "./customerSaga";
import dashboardSaga from "./dashboardSaga";
import inviteMemberSaga from "./inviteMemberSaga";
import rootAwsSaga from "./awsIvsSaga";
import organisationSaga from "./organisationSaga";
import videoTableListSaga from "./videoTableSaga";
import participantListSaga from "./participantSaga";
import CustomerListSaga from "./customerlistSaga";
import DeleteListSaga from "./deleteSaga";
import paymentsSaga from "./paymentsSaga";
import simulcastSaga from "./simulcastSaga";
import commentsSaga from "./commentsSaga";
import preRecordSaga from "./preRecordSaga";

export default function* rootSaga() {
  yield all([
    fork(TeamsSaga),//TeamsPage(list.addMember)
    fork(LoginSaga), //login
    fork(ResetPassSaga), //Reset
    fork(ForgetPassSaga), //Forget
    fork(ChangePasswordSaga), //change password
    fork(customerSaga),
    fork(inviteMemberSaga),
    fork(BroadcastSaga),
    fork(rootAwsSaga),
    fork(organisationSaga),
    fork(videoTableListSaga),
    fork(participantListSaga),
    fork(CustomerListSaga),
    fork(dashboardSaga),
    fork(DeleteListSaga),
    fork(paymentsSaga),
    fork(simulcastSaga),
    fork(commentsSaga),
    fork(preRecordSaga),
  ]);
}
