export const PREVIEW = "Pre-Preview";
export const INIT_PREVIEW = "Button-clicked";
export const BROADCAST_CALL = "In-BroadcastCall";
export const BROADCAST_CALL_LIVE = "In-BroadcastCall-Live";
export const BLOCKED = "BLOCKED";
export const BANNER_DEFAULT =
    "Here is an illustration of a banner. To Display a banner, turn on the toggle button below";
export const VIDEO_MUTE_TOAST = "Your Video has been mutted by host";
export const AUDIO_MUTE_TOAST = "Your Audio has been mutted by host";
export const COHOST_REMOVED = "You have been removed by host";
export const NOT_IN_CALL = "You'r not a participant in this Broadcast";

// Feature restriction related
export const MAX_UPLOAD_MEDIA_COUNT = 20; // Background, overlay, music
export const MINIMUM_STREAMING_LIMIT = 300000; // 300000 = 5 mints is minimum streaming limit if not, then dont allow streaming
export const SHOW_TIMER_BEFORE = 1800000; // 1800000 = 30 mints
export const PERSMISSION_ARRAY = ["denied", "prompt", {}];
export const EJBD_GROUPS = {
    dev: [
        "9863749374@xmpp-uikit-qa.contus.us",
        "6370532974@xmpp-uikit-qa.contus.us",
    ],
    qa: [
        "9863749374@xmpp-uikit-qa.contus.us",
        "6370532974@xmpp-uikit-qa.contus.us",
    ],
    uat: [
        "9863749374@xmpp-uikit-qa.contus.us",
        "6370532974@xmpp-uikit-qa.contus.us",
    ],
    stage: [
        "9863749374@onthefly-xmpp.mirrorfly.com",
        "6370532974@onthefly-xmpp.mirrorfly.com",
    ],
    prod: [
        "9863749374@onthefly-xmpp.mirrorfly.com",
        "6370532974@onthefly-xmpp.mirrorfly.com",
    ],
};

// Date filter values
export const CUSTOM_DATE_FILTER_VALUE = 108;
export const MAXIMUM_MULTISTREAM_LIMIT = 10;
export const PAYMENT_GATEWAY            = 'RZP';
export const DATE_FILTER_ALL_START_DATE = "2020-01-01";
export const NO_RESULT                  = "No Results Found";
export const INVISIBLE_USER_NAME        = 'invisible-user';

/**
 * Use this popup types to set unique name for popups
 * Do not change any existing keys
 */
export const POPUP_TYPES = {
    ADD_EDIT_DESTINATIONS: 'PP_ADD_EDIT_DESTINATIONS',
    DIRECT_GO_LIVE_POPUP: 'PP_DIRECT_GO_LIVE_POPUP',
    INTEGRATE_ACCOUNTS: 'PP_INTEGRATE_ACCOUNTS',
    CREATE_PRE_RECORDED: 'PP_CREATE_PRE_RECORDED',
};

export const tempHide = false; // Dont change this key or value
