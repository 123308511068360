import axios from "axios";
import { Post, Put, Delete, Get } from "../common/httpRestServices";
import { apiUrl, commentsAPI, ssoYTGmailClientId, ssoYTGmailClientSecret } from "../helper/ApiUrl";
import { encodeGetParams } from "../helper/Encypt";
import store from "../store";
import { updateCommentPostStatus } from "../store/action/commentsAction";

export const commentsPostRequest = async (obj = {}) => {
    console.log("commentsPostRequest obj", obj);
  const { data: { inputData, userInput } } = obj || {};
  const queryString = userInput ? encodeGetParams(userInput) : null;
  console.log('Request =>>', `${apiUrl}${commentsAPI.postComments}?${queryString}`);
  if(userInput?.userType === 'guest') {
    return await axios.post(`${apiUrl}${commentsAPI.postComments}?${queryString}`, inputData);
  }

  return await Post(`${apiUrl}${commentsAPI.postComments}?${queryString}`, inputData, true);
};

export const updateComments = (obj = {}, action = "") => {
  console.log("updateComments obj", obj);
  const { data: { inputData, userInput } = {} } = obj;
  console.log("updateComments userInput", userInput);
  const queryString = userInput ? encodeGetParams(userInput) : null;
  console.log("updateComments queryString", queryString);
  return Put(`${apiUrl}${commentsAPI.updateComments}${queryString}`, inputData, true);
};

export const fetchLatestComments = (obj = {}) => {
    console.log("fetchLatestComments obj", obj);
    store.dispatch(updateCommentPostStatus(''));
    const { data: { inputData, userInput } } = obj || {};
    const queryString = userInput ? encodeGetParams(userInput) : null;
    return Post(`${apiUrl}${commentsAPI.fetchLatestComments}?${queryString}`, inputData, true);
}

export const refreshGuestCommentsToken = async (refreshToken) => {
  try {
    const clientId = ssoYTGmailClientId;
    const clientSecret = ssoYTGmailClientSecret;
    const response = await axios.post('https://oauth2.googleapis.com/token', {
      client_id: clientId,
      client_secret: clientSecret,
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    });

    const { access_token } = response.data;
    localStorage.setItem('guestAccessToken', access_token);
    console.log('Refreshed Access Token:', access_token);
  } catch (error) {
    console.error('Error refreshing access token:', error);
  }
};

export const deleteComments = async (obj = {}) => {
  try {
    console.log("deleteComments obj", obj);
    const { data: { inputData, userInput } = {} } = obj;
    console.log("deleteComments userInput", userInput);
    const queryString = userInput ? encodeGetParams(userInput) : null;
    console.log("deleteComments queryString", queryString);
    return Delete(`${apiUrl}${commentsAPI.deleteComments}?${queryString}`, inputData, true);
  }  catch (error) {
    console.error('Error refreshing access token:', error);
  }
};

export const blockUsers = async (obj = {}) => {
  try {
    console.log("blockUsers obj", obj);
    const { data: { inputData, userInput } = {} } = obj;
    console.log("blockUsers userInput", userInput);
    const queryString = userInput ? encodeGetParams(userInput) : null;
    console.log("deleteComments queryString", queryString);
    return Post(`${apiUrl}${commentsAPI.blockUsers}?${queryString}`, inputData, true);
  }  catch (error) {
    console.error('Error refreshing access token:', error);
  }
};

export const listenComments = async (obj = {}) => {
  try {
    const queryString = obj ? encodeGetParams(obj) : null;
    return Get(`${apiUrl}${commentsAPI.listenComments}?${queryString}`, true);
  } catch (error) {
    console.error('Error listenComments:', error);
  }
};
