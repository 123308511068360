import { camelCase, startCase } from "lodash";
import { IconTooltip, Iconno, Iconyes } from "../../../assets/otf";
import { taxCollectionDeCode } from "../../../helper/Validation";

export const Individual = "Individual";
export const Business = "Business";
export const PLAN_IDS = {
    FREE: 1,
    BASIC: 2,
    PREMIUM: 3,
    GROWTH: 4,
    BUSINESS: 5,
};
export const getPlanNamebyId = (id = 0) => {
    const updatedUserDetails = localStorage.getItem('updatedUserDetails') ? JSON.parse(localStorage.getItem('updatedUserDetails')) : {};
    const planId = id || updatedUserDetails.planId;
    const _D = Object.keys(PLAN_IDS).find((key) => PLAN_IDS[key] === planId);
    return startCase(camelCase(_D));
};

const unlimited = "Unlimited";
const yes = <Iconyes />;
const no = <Iconno />;

export const USDValue = 82;
export const productionTestPaymentID = 'price_1OjjOMSEUeaLaGhhpCATa8ZX';
export const isINR = localStorage.getItem("country_code") === "IN";

export const Plans = [
    {
        id: PLAN_IDS.FREE,
        stripId: '',
        type: Individual,
        name: "Free",
        desc: "Go live with core free features and best-quality streaming.",
        monthlyBill: 0,
        annualBill: 0,
        duration: "/ Forever",
        offer: 0,
        toolTip: ` 
        Default banners, banner tag, themes, colors, background images, videos, HD music, layouts and overlays. Access to real-time in participant chat.`,
        heading: <span> Core Studio Branding Features  + <span style={{ verticalAlign: 'middle', cursor: 'pointer' }}> <IconTooltip /> </span> </span>,
        enableInfo: true,
        plandetails: [
            {
                text: "OnTheFly Logo in the Stream",
                toolTip: `Stream with the OnTheFly logo added to the top corner of your live stream and recordings.`
            },
            {
                text: "Stream in 720P",
                toolTip: `Stream with a minimum of 720p quality.`
            },
            {
                text: '"5" Hours of Streaming',
                toolTip: `Multistream for 5 hours uninterruptedly on multiple platforms.`
            },
            {
                text: '"5" Hours of Storage',
                toolTip: `Store up to 5 hours of stream in the cloud.`
            },
            {
                text: '"2" On Screen Participants ',
                toolTip: ` You can stream with 2 on screen participants.`
            },
            {
                text: 'Multistream in 2 Destinations ',
                toolTip: `Stream to 2 destinations at once.`
            },
            {
                text: "On Screen Participants Chat",
                toolTip: `Chat with your participants on screen during live sessions for interactions.`
            },
            {
                text: "Unlimited Duration Per Session ",
                toolTip: `Max duration of a single studio recording or live stream.`,
            },
            {
                text: 'Scheduled Live Streaming',
                toolTip: `Schedule your live streaming & plan your events to invite your teammates well in advance.`
            },
            {
                text: 'Video Storage Duration',
                toolTip: `Recorded older videos will be removed after 60 days.`
            },
            {
                text: 'Unlimited Local Download ',
                toolTip: `Download your recording into your local device uninterruptedly.`
            },
            {
                text: "Webinar (Only for 7 Days) ",
                toolTip: `Host your webinar and stream exclusive content.`
            },
        ]
    },
    {
        id: PLAN_IDS.BASIC,
        type: Individual,
        name: "Basic",
        desc: "Skyrocket your views with exceptional features in the Basic plan!",
        monthlyBill: 18,
        annualBill: 15,
        duration: "/ Per month",
        offer: 36,
        toolTip: "",
        heading: "Includes All Free Plan Features, With:",
        enableInfo: false,
        plandetails: [
            {
                text: 'Add your Brand Logo, Overlays & Backgrounds to Stream',
                toolTip: ` Customize your logo, tags, overlay, background to the video stream and recordings.`
            },
            {
                text: "Stream in 720P",
                toolTip: `Stream with a minimum of 720p quality.`
            },
            {
                text: '“10” Hours of Streaming',
                toolTip: ` Multistream for 10 hours uninterruptedly on multiple platforms.`
            },
            {
                text: '"20" Hours of Storage',
                toolTip: ` Record and store up to 20 hours of streams in the cloud and repurpose.`
            },
            {
                text: `"4" On Screen Participants`,
                toolTip: ` You can stream with 4 on screen participants.`
            },
            {
                text: "Multistream - 3 Destinations",
                toolTip: ` Stream to 3 destinations at once. `
            },
            {
                text: '"10" Hours of Storage',
                toolTip: `Multistream for 10 hours uninterruptedly on multiple platforms.`
            },
            {
                text: "Custom RTMP Destinations",
                toolTip: `Stream to all custom RTMP supported platforms with the link & access token.`
            },
            {
                text: "Live Recording of Stream ",
                toolTip: ` Record  20 hours of live stream on your device.`
            },
            {
                text: "Live Stream Analytics ",
                toolTip: `Get real-time insights into your live stream performance.`
            },
            {
                text: "Webinar- 50 users",
                toolTip: `Host a webinar for up to 50 users per stream. This count is per stream and does not affect the number 
                of social viewers you can have, which is unlimited on all plans. `
            },
        ]
    },
    {
        id: PLAN_IDS.PREMIUM,
        name: "Premium",
        type: Individual,
        desc: "Go professional with studio-grade features in the Premium plan!",
        monthlyBill: 48,
        annualBill: 38,
        duration: "/ Per month",
        offer: 120,
        toolTip: "",
        heading: "Includes All Basic Plan Features, With:",
        enableInfo: false,
        plandetails: [
            {
                text: `Stream in Full HD 1080p`,
                toolTip: `Stream with a maximum of 1080p quality.`
            }, {
                text: `Multistream in 5 Destinations`,
                toolTip: `Stream to 5 destinations at once.`
            },
            {
                text: `"25" Hours of Streaming `,
                toolTip: `Multistream for 25 hours uninterruptedly on multiple platforms.`
            },
            {
                text: `"40" Hours of Storage `,
                toolTip: `Record and store up to 40 hours of streams in the cloud and repurpose.`
            },
            {
                text: `"8" On Screen Participants `,
                toolTip: `You can stream with 8 on screen participants.`
            },
            {
                text: `"25" Hours of Streaming `,
                toolTip: `Multistream for 25 hours uninterruptedly on multiple platforms.`
            },
            {
                text: "Webinar- 100 users",
                toolTip: `Host a webinar for up to 100 users per stream. This count is per stream and does not affect the number of 
                social viewers you can have, which is unlimited on all plans. `
            }]
    },
    {
        id: PLAN_IDS.GROWTH,
        name: "Growth",
        type: Business,
        desc: "Achieve a 10x ROI for your business with the Growth Plan!.",
        monthlyBill: 95,
        annualBill: 80,
        duration: "/ Per month",
        offer: 180,
        toolTip: "",
        heading: "Includes All Premium Plan Features, With:",
        enableInfo: false,
        plandetails: [
            {
                text: `"30" Hours of streaming`,
                toolTip: `Multistream for 30 hours uninterruptedly on multiple platforms.`
            }, {
                text: `"60" Hours of Storage`,
                toolTip: `Record and store up to 60 hours of streams in the cloud and repurpose.`
            }, {
                text: `"8" On Screen Participants`,
                toolTip: ` You can stream with 8 on screen participants.`
            }, {
                text: "Multistream - 10 Destinations",
                toolTip: `Stream to 10 destinations at once.`
            }, {
                text: "Custom RTMP Destinations",
                toolTip: `Stream to all custom RTMP supported platforms with the link & access token.`
            }]
    },
    {
        id: PLAN_IDS.BUSINESS,
        name: "Business",
        type: Business,
        desc: "Attain a 20x increase in business revenue with the Business Plan!.",
        monthlyBill: 280,
        annualBill: 238,
        duration: "/ Per month",
        offer: 504,
        toolTip: "",
        heading: "Includes All Growth Plan Features, With:",
        enableInfo: false,
        plandetails: [
            {
                text: `"50" Hours of streaming`,
                toolTip: `Multistream for 50 hours uninterruptedly on multiple platforms.`
            }
            , {
                text: `"300" Hours of Storage`,
                toolTip: `Record and store up to 300 hours of streams in the cloud and repurpose.`
            }
            , {
                text: `"8" On Screen Participants`,
                toolTip: `You can stream with 8 on screen participants.`
            }
            , {
                text: "Multistream - 10 Destinations",
                toolTip: `Stream to 10 destinations at once.`
            }]
    },
];

export const PRICE_IDS = {
    [PLAN_IDS.BASIC]: {
        TEST: {
            USD: {
                monthly: 'price_1OfHHLSD4svxQQVsUnJDGQRi',
                yearly: 'price_1OezdtSD4svxQQVsTpPdEHmL',
            },
            INR: {
                monthly: 'price_1OfHW3SD4svxQQVsmB69dpQI',
                yearly: 'price_1OfHX2SD4svxQQVsrGZ4uozZ',
            }
        },
        LIVE: {
            USD: {
                monthly: 'price_1OjjL4SEUeaLaGhhbn86U628', //price_1OhXppSD4svxQQVsoroDeJVU
                yearly: 'price_1OjjM2SEUeaLaGhhdgGM70sC', //price_1OhXRXSD4svxQQVs0QRZtIMZ
            },
            INR: {
                monthly: 'price_1OjjLeSEUeaLaGhhyr7pWYwV', //price_1OhXpOSD4svxQQVsDmSpXpBN
                yearly: 'price_1OjjMPSEUeaLaGhhPiZbmLxF', //price_1OhXp1SD4svxQQVsW5Ajyzzv
            }
        }
    },
    [PLAN_IDS.PREMIUM]: {
        TEST: {
            USD: {
                monthly: 'price_1OfHYlSD4svxQQVsPcFz2dfE',
                yearly: 'price_1OezenSD4svxQQVsQR3mFkHY',
            },
            INR: {
                monthly: 'price_1OfHZiSD4svxQQVseEQPhL6X',
                yearly: 'price_1OfHaXSD4svxQQVsJ2J8xHkR',
            }
        },
        LIVE: {
            USD: {
                monthly: 'price_1OjjH2SEUeaLaGhhZQ5IUh5k', //price_1OhXWBSD4svxQQVsMpvnNkjx
                yearly: 'price_1OjjJTSEUeaLaGhhntrr43Eq', //price_1OhXsHSD4svxQQVsdolEl75O
            },
            INR: {
                monthly: 'price_1OjjImSEUeaLaGhhSi1N8gNm', //price_1OhXseSD4svxQQVsK5lpIvcP
                yearly: 'price_1OjjJoSEUeaLaGhhgTMVkXpm', //price_1OhXt4SD4svxQQVsBgU2n3wX
            }
        }
    },
    [PLAN_IDS.GROWTH]: {
        TEST: {
            USD: {
                monthly: 'price_1OfHcaSD4svxQQVsEBtfQcSk',
                yearly: 'price_1OezfWSD4svxQQVsrh4qrAQr',
            },
            INR: {
                monthly: 'price_1OfHdKSD4svxQQVs7xlljda0',
                yearly: 'price_1OfHe2SD4svxQQVsKvbVU8ro',
            }
        },
        LIVE: {
            USD: {
                monthly: 'price_1OjjCCSEUeaLaGhhHo1KYP7f', //price_1OhXcQSD4svxQQVsYTi2eT4O
                yearly: 'price_1OjjETSEUeaLaGhhJSmqVcbu', //price_1OhXiUSD4svxQQVsdqROsXRR
            },
            INR: {
                monthly: 'price_1OjjDwSEUeaLaGhh5ntcsHdv', //price_1OhXdiSD4svxQQVsbVpPYoks
                yearly: 'price_1OjjF9SEUeaLaGhhbWrgKRVg', //price_1OhXkXSD4svxQQVsZK4nFybx
            }
        }
    },
    [PLAN_IDS.BUSINESS]: {
        TEST: {
            USD: {
                monthly: 'price_1OfHpISD4svxQQVsEjZCQwVd',
                yearly: 'price_1OezgKSD4svxQQVswBf1gdQG',
            },
            INR: {
                monthly: 'price_1OfHq3SD4svxQQVsOnloJNPg',
                yearly: 'price_1OfHqtSD4svxQQVsn8JedaiN',
            }
        },
        LIVE: {
            USD: {
                monthly: 'price_1OjibhSEUeaLaGhhQwrwfyT0', //price_1OhXuqSD4svxQQVsH499uUkx
                yearly: 'price_1OjiL1SEUeaLaGhhKW7Q7liA', //price_1OhXw0SD4svxQQVsFz2TokSw
            },
            INR: {
                monthly: 'price_1OjibNSEUeaLaGhhyA8F3Vvp', //price_1OhXvbSD4svxQQVswsaXiQDK
                yearly: 'price_1OjiGbSEUeaLaGhhNZ4PXSHB', //price_1OhXwOSD4svxQQVsFUsmBjpo
            }
        }
    },
};

export const getCurrencyFormat = (value = 0, type = 'usd') => {
    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    return parseFloat(value).toLocaleString(type === 'inr' ? 'en-IN' : 'en-US', options);
};

export const planAmountTaxCalculation = (element = {}) => {
    const obj = {};
    const tax = taxCollectionDeCode(element?.taxCollections);
    const currentySymbal = tax?.currency === 'inr' ? '₹' : '$';
    obj.planTypeAmount = currentySymbal + getCurrencyFormat(element.planTypeAmount, tax.currency);
    obj.actualPriceNotax = currentySymbal + getCurrencyFormat(element.actualPrice, tax.currency);
    obj.taxAmount = tax.currency === 'inr' ? (tax?.taxAmount || 0) : 0;
    obj.actualPrice = currentySymbal + getCurrencyFormat(parseFloat(element.actualPrice || 0) + parseFloat(obj.taxAmount || 0), tax?.currency);
    return obj;
};

export const getPlanAmount = (amount, isUSD) => {
    return isUSD ? amount : amount * USDValue;
};

export const PaymentIndividualFeaturesList = [
    {
        title: 'Streaming',
        contents: [
            { title: 'Streaming Hours', values: { free: 5, basic: 10, premium: 25, growth: 30, business: 50 } },
            { title: 'Destinations', values: { free: 2, basic: 3, premium: 5, growth: 10, business: 10 } },
            { title: 'Custom RTMP', values: { free: no, basic: yes, premium: yes, growth: yes, business: yes } },
            { title: 'Streaming Quality', values: { free: '720P', basic: '720P', premium: '1080P', growth: '1080P', business: '1080P' } },
            { title: 'Recording Storage Hours', values: { free: no, basic: '20 hours', premium: '40 hours', growth: '60 hours', business: '300 hours' } },
            { title: 'Number Of Meetings and Webinar', values: { free: unlimited, basic: unlimited, premium: unlimited, growth: unlimited, business: unlimited } },
            { title: 'Session Duration Limit', values: { free: unlimited, basic: unlimited, premium: unlimited, growth: unlimited, business: unlimited } },
            { title: 'Concurrent Sessions', values: { free: unlimited, basic: unlimited, premium: unlimited, growth: unlimited, business: unlimited } },
            { title: 'Social Media Live Streaming', values: { free: yes, basic: yes, premium: yes, growth: yes, business: yes } },
            { title: 'Live Stream Recording', values: { basic: yes, free: no, premium: yes, growth: yes, business: yes } },
            { title: 'Number Of Seats', values: { free: 2, basic: 4, premium: 8, growth: 8, business: 8 } },
            { title: '1-1 Meeting', values: { free: yes, basic: yes, growth: yes, premium: yes, business: yes } },
        ],
    },
    {
        title: 'Branding',
        contents: [
            { title: 'Logo Update', values: { free: no, basic: yes, growth: yes, premium: yes, business: yes } },
            { title: 'Brand Color Customization', values: { premium: yes, free: yes, basic: yes, growth: yes, business: yes, } },
            { title: 'Name Tag Customization', values: { business: yes, free: yes, basic: yes, premium: yes, growth: yes, } },
            { title: 'Background Image Customization', values: { free: no, basic: yes, premium: yes, business: yes, growth: yes, } },
            { title: 'Background Music Customization', values: { premium: yes, free: no, basic: yes, growth: yes, business: yes } },
            { title: 'Overlay Customization', values: { free: no, premium: yes, basic: yes, growth: yes, business: yes } },
            { title: 'Banner Overlay', values: { business: yes, free: yes, basic: yes, premium: yes, growth: yes, } },
        ],
    },
    {
        title: 'Teams',
        contents: [
            { title: 'Add Team Members', values: { free: yes, growth: yes, basic: yes, premium: yes, business: yes } },
            { title: 'Role Based Team Members', values: { free: yes, business: yes, basic: yes, premium: yes, growth: yes, } },
            { title: 'Invite Members', values: { basic: yes, premium: yes, free: yes, growth: yes, business: yes } },
            { title: 'Remove Members', values: { free: yes, premium: yes, growth: yes, basic: yes, business: yes } },
            { title: 'Account Assistants', values: { growth: yes, free: yes, basic: yes, premium: yes, business: yes } },
        ],
    },
    {
        title: 'Studio features',
        contents: [
            { title: 'Waiting Room', values: { free: yes, basic: yes, growth: yes, premium: yes, business: yes } },
            { title: 'External Camera', values: { free: yes, basic: yes, growth: yes, premium: yes, business: yes } },
            { title: 'External Mic', values: { free: yes, growth: yes, premium: yes, basic: yes, business: yes } },
            { title: 'Room Layout', values: { basic: yes, premium: yes, growth: yes, free: yes, business: yes } },
            { title: 'Screen Sharing', values: { free: yes, basic: yes, growth: yes, business: yes, premium: yes, } },
            { title: 'Embed Event On Your Site', values: { basic: yes, premium: yes, growth: yes, business: yes, free: no, } },
            { title: 'Invite Guest', values: { free: yes, basic: yes, premium: yes, business: yes, growth: yes, } },
            { title: 'Permanent Room', values: { basic: yes, premium: yes, growth: yes, free: yes, business: yes } },
            { title: 'Onscreen Participants Chat', values: { free: yes, growth: yes, basic: yes, premium: yes, business: yes } },
        ],
    },
    {
        title: 'Analytics',
        contents: [
            { title: 'Live Stream Analytics', values: { free: yes, basic: yes, growth: yes, premium: yes, business: yes } },
            { title: 'Scheduled Live Stream Analytics', values: { free: yes, business: yes, basic: yes, premium: yes, growth: yes, } },
            { title: 'Social Media Viewers Analytics', values: { free: yes, basic: yes, premium: yes, business: yes, growth: yes, } },
            { title: 'Webinar Analytics', values: { premium: yes, free: yes, basic: yes, growth: yes, business: yes } },
        ],
    },
    {
        title: 'Notifications',
        contents: [
            { title: 'Event Invitation', values: { free: yes, basic: yes, growth: yes, premium: yes, business: yes } },
            { title: 'Webinar Invitation', values: { free: yes, basic: yes, premium: yes, business: yes, growth: yes, } },
            { title: 'Webinar Attendees Invitation', values: { free: yes, premium: yes, basic: yes, growth: yes, business: yes } },
            { title: 'Add To Teams Invitation', values: { free: yes, business: yes, basic: yes, premium: yes, growth: yes, } },
        ],
    },
    {
        title: 'Recordings',
        contents: [
            { title: 'Play The Videos', values: { free: yes, business: yes, basic: yes, premium: yes, growth: yes, } },
            { title: 'Share The Embed Link For Videos', values: { free: yes, basic: yes, premium: yes, business: yes, growth: yes, } },
            { title: 'Download Videos', values: { free: yes, business: yes, basic: yes, premium: yes, growth: yes, } },
        ],
    },
    {
        title: 'Webinar',
        contents: [
            { title: 'Schedule Webinar', values: { basic: yes, free: yes, premium: yes, growth: yes, business: yes } },
            { title: 'Concurrent Webinar Sessions', values: { free: yes, premium: yes, basic: yes, growth: yes, business: yes } },
            { title: 'Webinar Duration Limit', values: { free: unlimited, basic: unlimited, premium: unlimited, growth: unlimited, business: unlimited } },
            { title: 'Webinar Attendees List', values: { basic: yes, premium: yes, growth: yes, free: yes, business: yes } },
            { title: 'Webinar Branding', values: { growth: yes, free: yes, basic: yes, premium: yes, business: yes } },
            { title: 'Registration For Webinar', values: { free: yes, basic: yes, premium: yes, business: yes, growth: yes, } },
            { title: 'Personalized Notifications For Attendees', values: { free: yes, premium: yes, basic: yes, growth: yes, business: yes } },
            { title: 'Record Webinar Sessions', values: { free: yes, basic: yes, business: yes, growth: yes, premium: yes, } },
        ],
    },
];
export const handleDurationText = (_id) => {
    if (_id === PLAN_IDS.FREE) {
        return "/ Forever";
    }
    return "/ Per month";
};

export const getClassName = (currentPlan = 0, userDetails = {}, getDuration = "") => {
    if (userDetails.planId === PLAN_IDS.FREE && currentPlan === PLAN_IDS.FREE) return ' active';
    if (currentPlan === userDetails.planId && getDuration === userDetails.planDuration) {
        return ' active ';
    }
    return '';
};

export const getDisableClassName = (planId = 0, userDetails = {}, getDuration = "") => {
    return ((planId < userDetails.planId) || (planId === userDetails.planId && userDetails.planDuration === 'yearly' && getDuration === 'monthly')) ? 'active' : '';
};
