import { Delete, Get, Post, Put } from "../common/httpRestServices";
import { apiUrl, broadcastApi, customerApi, participantList } from "../helper/ApiUrl";
import { encodeGetParams } from "../helper/Encypt";
import { getCurrentOrgId, getCurrentUserId } from "../helper/utility";

export const participantListRequest = async (obj = "") => {
  const getList = await Get(`${apiUrl}${participantList.getParticipantList}size=${500}&stageArn=${obj.data}`, true );
  return getList;
};

export const getOrgStreamingLimitService = (data = {}) => {
  const { data : { orgId = 0 } } = data;
  if (!orgId) return false;
  return Get(`${apiUrl}${broadcastApi.getStreamingLimit}organisationId=${orgId}`, true);
};

export const uploadMediaFilesService = (obj = {}) => {
  const inputData = new FormData();
  inputData.append("file", obj.data.file, obj?.data?.fileName);

  const payload = {
    organisationId : getCurrentOrgId(),
    userId : getCurrentUserId(),
    mediaType : obj?.data?.mediaType,
    width : parseInt(obj?.data?.width || 100),
    height : parseInt(obj?.data?.height || 60),
    brandId : 1,
    stageArn : obj.data.stageArn || 'test',
  };
  const queryString = encodeGetParams(payload);
  return Post(`${apiUrl}${customerApi.brandMediaUpload}?${queryString}`, inputData,true);
};

export const getBrandingMediaFilesService = (obj = {}) => {
  const payload = {
    organisationId : getCurrentOrgId(),
  };
  const queryString = encodeGetParams(payload);
  return Get(`${apiUrl}${customerApi.getBrandMediaFiles}?${queryString}`, true);
};

export const deleteBrandingMediaFileService = (obj = {}) => {
  const payload = {
    id : obj.id,
    mediaType : 'background',
    fileName : 'test',
  };
  const queryString = encodeGetParams(payload);
  return Delete(`${apiUrl}${customerApi.deleteBrandMediaFile}?${queryString}`,{}, true);
};

export const updateBrandingMediaFileService = (obj = {}) => {
  const payload = {
    id : obj?.data?.id || 0,
    isDisable : (obj?.data?.status) ? 1 : 0,
  };
  const queryString = encodeGetParams(payload);
  return Put(`${apiUrl}${customerApi.updateBrandMediaFile}?${queryString}`,{}, true);
};
