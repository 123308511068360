import { Get, Post } from "../common/httpRestServices";
import { apiUrl, guestApi } from "../helper/ApiUrl";

export const getSessionDetails = async (stageKey) => {
    const constructStage = `stage/${stageKey}`;
    const formData = new FormData();
    formData.append("stageArn", constructStage);
    formData.append("guestName", 'dummyInfo');
    const getResult = await Post(`${apiUrl}${guestApi.guestSessionData}`, formData, false);
    return getResult?.data?.data;
};

export const getSessionDetailsOnCall = async (stageArn, displayName = "") => {
    const formData = new FormData();
    formData.append("stageArn", stageArn);
    formData.append("guestName", displayName);
    if (!displayName) return false;
    const getResult = await Post(`${apiUrl}${guestApi.guestSessionData}`, formData, false);
    return getResult?.data?.data;
};

export const getUserOrgList = async (userId = 0) => {
    if (userId) {
        const getResult = await Get(`${apiUrl}${guestApi.getGuestOrgDetails}userId=${userId}`);
        return getResult?.data?.data?.organisations
        ;
    }
};
