import React from 'react';
import Modal from ".";

function Modal6(props) {
    const { children } = props;
    return (
        <Modal id="modal6" >
            {children}
        </Modal>
    );
}

export default Modal6;
