import store from '..';
import { userDetailsLocal } from '../../helper/RoleConfig';
import { getCurrentOrgId } from '../../helper/utility';
import * as customerTypes from '../actionTypes/customerTypes';
import { organisationMemberListAction } from './organisationAction';
import { isOffline, trackHubspotEvent, } from '../../common/helper';
import { TRACK_EXPLORE, TRACK_UPGRADE } from '../../helper/ApiUrl';
import { toastInternet } from '../../helper/ApiToast';
import { constantValue } from '../../const/errorTypes';

export const dataFetch = (data = {}) => {
    return {
        type: customerTypes.DATA_FETCH,
        data,
    };
};

export const getCusList = (data = {}) => {
    return {
        type: customerTypes.CUSTOMER_GET_LIST,
        data,
    };
};

export const cusAdd = (data = {}) => {
    return {
        type: customerTypes.CUSTOMER_ADD,
        data,
    };
};

/**
 * @param  {Array} customerIds=[]
 * passed which user have been deteled that user ID
 */

export const deleteCus = (customerIds = []) => {
    return {
        type: customerTypes.CUSTOMER_DELETE,
        customerIds,
    };
};

export const updateCus = (data = {}) => {
    return {
        type: customerTypes.CUSTOMER_UPDATE,
        data,
    };
};

export const dataFetchFail = (data = {}) => {
    return {
        type: customerTypes.DATA_FETCH_FAIL,
        data,
    };
};

export const getCustDtls = (data = {}) => {
    return {
        type: customerTypes.GET_CUST_DETAILS,
        data,
    };
};

export const getCustDtlsNew = (data = {}) => {
    store.dispatch({ type: customerTypes.GET_CUST_DETAILS, data });
    store.dispatch(organisationMemberListAction());
};
export const getCancelSubscripionRequestList = (data = {}) => {
    return {
        type: customerTypes.GET_CANCEL_SUBSCRIPTION_REQUEST_LIST,
        data,
    };
};

export const getCustomerDetails = () => {
    const userDetails = userDetailsLocal() || {};
    const { data: { userId = 0 } = {} } = userDetails || {};
    store.dispatch({ type: customerTypes.GET_CUST_DETAILS, data: { customerId: userId } });
};

export const getStreamingLimit = async (orgId = 0) => {
    const id = orgId || getCurrentOrgId();
    store.dispatch({ type: customerTypes.GET_CUSTOMER_STREAMING_LIMIT, data: { orgId: id } });
};

export const getUserDetails = (userId = 0) => {
    store.dispatch({ type: customerTypes.GET_USER_DETAILS, data: { customerId: userId } });
};

export const enableUpgradePlanPopup = (status = false, isExplore = false) => {
    if (isOffline()) {
        toastInternet(constantValue.INTERNET_ERROR);
        return;
    }
    if (status || isExplore) {
        const currentOrgId = window.localStorage.getItem("currntOrgid")
            ? JSON.parse(window.localStorage.getItem("currntOrgid"))
            : {};
        const hubKey = (isExplore) ? TRACK_EXPLORE : TRACK_UPGRADE;
        trackHubspotEvent(hubKey, {
            org_name: currentOrgId.organisationName,
            current_plan_name: currentOrgId.planId
        });
    }
    store.dispatch({ type: 'PLAN_UPGRADE_POPUP', status });
};

export const enableUpgradePlanAlertPopup = (status = false) => {
    store.dispatch({ type: 'PLAN_UPGRADE_ALERT_POPUP', status });
};

export const loginAsCustomer = (orgId = 0) => {
    store.dispatch({ type: 'LOGIN_AS_CUSTOMER', data: { orgId } });
};
