export const constantValue = {
    "SPACERROR": "This field cannot be empty.",
    "EMOJI_ERROR": "This character is not allowed",
    "EMAIL_ID_ERROR": "Please enter valid email address",
    "CHAR_ERROR_15": "Maximum 15 characters allowed.",
    "CHAR_ERROR_50": "Maximum 50 characters allowed.",
    "CHAR_ERROR_100": "Maximum 100 characters allowed",
    "CHAR_ERROR_500": "Maximum 500 characters allowed.",
    "CHAR_ERROR_250": "Maximum 250 characters allowed.",
    "CHAR_ERROR_2000": "Maximum 2000 characters allowed.",
    "PRODUCT_EMPTY_ERROR": "Please enter the product name.",
    "INTERNET_ERROR": "Please check your internet connection",
    "PRODUCT_ALREADY_EXIST_ERROR": "Product name already exist.",
    "COPY_PASTE_ERROR": "For security reasons, we don't allow Copy/Paste",
    "SERVER_ERROR": "The server is not responding. Please try again later",
    "VALID_URL_ERROR": "Please enter valid URL",
    "VALID_PRODUCT_NAME_ERROR": "Please enter valid name",
    "VALID_APIBASE_URL_ERROR": 'Please append URL with "/api/v1"',
    "EMAIL_ALREADY_ADDED": "Email already added",
    "TOKEN_EXPIRED": "Token expired and is no longer valid",
    "PLEASE_CREATE_STAGE": "Please Create a Stage",
    "CHOOSE_DATE": "Please Choose Date",
    "AUTHORIZATION_TOKEN_INVALID": "Authorization Token is invalid",
    "AUTHORIZATION_TOKEN_EMPTY": "Authorization Token is empty",
    "AUTHORIZATION_TOKEN_EXPIRED": "Authorization Token is expired",
    "ACCOUNT_DELETED_CONTACT_SUPPORT_TEAM": "Account is deleted; Please contact support team!",
    "PLEASE_SELECT_EMAIL": "Please select the email Id",
    "UNABLE_TO_INVITE_UNVERIFIED_USER": "Unable to invite Unverified User",
    "ONLY_1_MEMBER_ALLOWED": "Only 1 Member allowed",
    "PLEASE_SELECT_THE_COHOST": "Please select the cohost",
    "ONLY_2_MEMBER_ALLOWED": "Only 7 Member allowed",
    "Please_add_atleast_one_cohost": "Please add atleast one cohost",
    "UNAUTHORIZED_SESSION_ACCOUNT": "Unauthorized Session/Account is inactive. Please contact support team",
    "SOMETHING": "Something went wrong. Please try again later",
    "ACCOUNT_INACTIVE" : "Account is inactive, Please contact support Team",
    "ALREADY_JOINED":"You have already joined in the call",
    "EMAIL_ALREADY_INVITED": "Email already Invited",
};
