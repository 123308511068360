export const highConstraints = {
    width: { ideal: 1280 },
    height: { ideal: 720 },
    frameRate: { max: 30 }
};

export const avgConstraints = {
    width: { ideal: 854 },
    height: { ideal: 480 },
    frameRate: { max: 24 }
};

export const lowConstraints = {
    width: { ideal: 480 },
    height: { ideal: 360 },
    frameRate: { max: 24 }
};

export const veryLowConstraints = {
    width: { ideal: 352 },
    height: { ideal: 240 },
    frameRate: { max: 12 }
};

export const GOOD_BITRATE = "GOOD_BITRATE";
export const AVERAGE_BITRATE = "AVERAGE_BITRATE";
export const POOR_BITRATE = "POOR_BITRATE";
