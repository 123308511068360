export const initStateCommon = {
    PreRecordList: [],
    PreRecordUploadList: [],
    status: {
        create: false,
        update: false,
        deleteIds: '',
        deleteStatus: false,
        createdStageArn: "",
        deleteStream: false,
        stopStream: false,
        outsideDisable: false,
        loader: false,
        preRecordReset: false,
        preRecordUploadReset: false,
        preRecordUploadIngStatus: 0,
        preRecordChangeData: {},
    },
    currentPreRecordDetails: {},
    preRecordUploadIngData: {},
    currentPreRecordSocialDetails: []
};

const filterConnectedMedia = (mediaList = []) => {
    const copyData = [...mediaList];
    return copyData.filter((item) => item.connectedMedia && Object.keys(item.connectedMedia).length);
};

export const preRecordReducer = (state = initStateCommon, action = {}) => {
    switch (action.type) {
        case 'UPDATE_PRE_RECORD_LIST': {
            return {
                ...state,
                PreRecordList: action?.data,
            };
        }
        case 'UPDATE_PRE_RECORD_UPLOAD_LIST': {
            return {
                ...state,
                PreRecordUploadList: action?.data,
            };
        }
        case 'STORE_PRE_RECORD_DETAILS': {
            return {
                ...state,
                currentPreRecordDetails: action.data
            };
        }

        case 'STORE_PRE_RECORD_SOCIAL_DETAILS': {
            return {
                ...state,
                currentPreRecordSocialDetails: filterConnectedMedia(action.data)
            };
        }

        case 'STORE_PRE_RECORD_UPLOADING_DATA': {
            return {
                ...state,
                preRecordUploadIngData: action.data
            };
        }

        case 'STORE_PRE_RECORD_UPDATES': {
            return {
                ...state,
                status: { ...state.status, ...action.data }
            };
        }

        default:
            return {
                ...state
            };
    }
};
