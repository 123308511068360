import {serverNotRespond, succToast } from "../../helper/ApiToast";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { videoRecordListRequest, videoTableListRequest, videoRecordedDeleteRequest } from "../../services/videoTableServices";
import { DO_GET_VIDEO_RECORD_LIST, DO_GET_VIDEO_TABLE_LIST, DELETE_RECORDED_VIDEO } from "../actionTypes/videoTableType";

function* doVideoTableList(obj = {}, searchdata = {}) {
  try {
    yield put({ type: "DO_LOADING_PAGE", loading: true });
    const videoTableList = yield call(videoTableListRequest, obj, searchdata);
    const { data = {} } = videoTableList || {};
    if (data.statusCode === 200) {
      yield put({ type: "GET_VIDEO_TABLE_LIST", videoTableListData: data?.response });
    } else {
      serverNotRespond();
    }
  } catch (error) {
    serverNotRespond();
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
  yield put({ type: "DO_LOADING_PAGE", loading: false });
};

function* doVideoRecordList(obj = {}, searchdata = {}) {
  try {
    const videoRecordList = yield call(videoRecordListRequest, obj, searchdata);
    const { data = {} } = videoRecordList || {};
    if (data.status === 200) {
      yield all([
        put({ type: "GET_VIDEO_RECORD_LIST", videoRecordListData: data }), //Result Store
      ]);
      yield all([
        put({ type: "DO_LOADING_PAGE", loading: false }),
      ]);//lodering on / off
    } else if (data.status >= 201 && data.status <= 400) {
      yield all([
        put({ type: "GET_VIDEO_RECORD_LIST", videoRecordListData: [] }), //Result Store
      ]);
      yield all([
        put({ type: "DO_LOADING_PAGE", loading: false }),
      ]);//lodering on / off
    } else {
      serverNotRespond();
      yield all([
        put({ type: "GET_VIDEO_RECORD_LIST", videoRecordListData: [] }), //Result Store
      ]);
      yield all([
        put({ type: "DO_LOADING_PAGE", loading: false }),
      ]);//lodering on / off
    }
  } catch (error) {
    serverNotRespond();
    yield all([
      put({ type: "DO_LOADING_PAGE", loading: false }),
    ]);//lodering on / off
  }
}
function* deleteRecordedVideoSaga(obj = {}) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const delCusData = yield call(videoRecordedDeleteRequest, obj);
    const { data: { status = 0, message = "" } = {} } = delCusData || {};
    if (status === 200) {
      succToast(message);
    }
    const resData = yield call(videoRecordListRequest, obj);
    if (resData?.data?.status === 200) {
      yield put({ type: "GET_VIDEO_RECORD_LIST", videoRecordListData: resData?.data || {} })

    }
    const resDatas = yield call(videoTableListRequest, obj);
    if (resDatas?.data?.status === 200) {
      yield put({ type: "GET_VIDEO_TABLE_LIST", videoTableListData: resDatas?.data || {} })

    }
  }
  catch (error) {
    serverNotRespond();
  }
  yield put({ type: "DO_LOADING_PAGE", loading: false });
}
export default function* videoTableListSaga() {
  yield takeLatest(DO_GET_VIDEO_TABLE_LIST, doVideoTableList);
  yield takeLatest(DO_GET_VIDEO_RECORD_LIST, doVideoRecordList);
  yield takeLatest(DELETE_RECORDED_VIDEO, deleteRecordedVideoSaga);
}
