import * as customerTypes from "../actionTypes/customerType";
import { put, call, takeLatest } from 'redux-saga/effects';
import { failToast, serverNotRespond } from "../../helper/ApiToast";
import { getAnalyticsOverviewService, getRecentActivityService } from "../../services/dashboardServices";

function* getQuickOverViewSaga(obj = {}) {
    yield put({ type: "DASHBOARD_LOADER", loading: true });
    try {
        const response = yield call(getAnalyticsOverviewService, obj);
        const { status = 0, message = "", data = {} } = response?.data || {};

        if (status === 200) {
            yield put({ type: "DASHBOARD_QUICK_OVERVIEW_DATA", quickOverview: data });
        } else {
            failToast(message);
        }
    } catch (error) {
        serverNotRespond();
        yield put({ type: "DASHBOARD_LOADER", loading: false });
    }
    yield put({ type: "DASHBOARD_LOADER", loading: false });
}

function* getRecentActivitySaga() {
    try {
        yield put({ type: "DASHBOARD_LOADER", loading: true });
        const response = yield call(getRecentActivityService);
        const { status = 0, message = "", data = {} } = response?.data || {};

        if (status === 200) {
            yield put({ type: "DASHBOARD_RECENT_ACTIVITY_LIST", recentCustomers: data });
        } else {
            failToast(message);
        }
    } catch (error) {
        serverNotRespond();
    }
    yield put({ type: "DASHBOARD_LOADER", loading: false });
}

export default function* CustomerListSaga() {
    yield takeLatest(customerTypes.GET_DASHBOARD_QUICK_OVER_VIEW_DATA, getQuickOverViewSaga);
    yield takeLatest(customerTypes.GET_DASHBOARD_RECENT_ACTIVITY_LIST, getRecentActivitySaga);
}
