import React from 'react';
import ModalWraper from '../../../common/Modal/ModalWraper';
import OutsideClickHandler from 'react-outside-click-handler';
import { CSSTransition } from 'react-transition-group';
import { IconPaymentCheckList, Iconclose } from '../../../assets/otf';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import "./PaymentIntermediateScreen.scss";
import { Plans, USDValue, getCurrencyFormat } from './commonPayments';
import { utcToISOConvert } from '../../../helper';
import moment from 'moment';

const PaymentIntermediateScreen = (props = {}) => {
    const { handleToggle = () => { }, selectedPlan = '', isINR = false, getDuration = '', handleClick = () => { } } = props;
    const planDetails = Plans.find((P) => P.id === selectedPlan);
    const isFree = false; // // (currentPlan === PLAN_IDS.FREE && [PLAN_IDS.BASIC, PLAN_IDS.PREMIUM].includes(selectedPlan));

    const getPlanAmount = () => {
        const values = {};

        const monthlyBill = isINR ? planDetails.monthlyBill * USDValue : planDetails.monthlyBill;
        const annualBill  = isINR ? planDetails.annualBill * USDValue  : planDetails.annualBill;

        if (getDuration === 'monthly') {
            values.tax = isINR ? Number(0.18 * monthlyBill).toFixed(2) : 0;
            values.totalAmount = Number(parseFloat(monthlyBill) + parseFloat(values.tax)).toFixed(2);
            values.actualPrice = Number(monthlyBill).toFixed(2);
        } else {
            const tY = annualBill * 12;
            values.tax = isINR ? Number(0.18 * tY).toFixed(2) : 0;
            values.totalAmount = Number(parseFloat(tY) + parseFloat(values.tax)).toFixed(2);
            values.actualPrice = Number(tY).toFixed(2);
        }
        return values;
    };
    const taxDetails = getPlanAmount();

    return (
        <ModalWraper index={4}>
            <div className="PopupWrapper fixed xs">
                <div className={`PopupInner ActionPopup  `}>
                    <div className="outside_wraper">
                        <OutsideClickHandler onOutsideClick={() => handleToggle(false)}>
                            <CSSTransition
                                in={'popupAnimate'}
                                timeout={300}
                                classNames="alert"
                                unmountOnExit
                            >
                                <div className={`ActionPopupInner upgrapde_popup payment_popup`}>
                                    <button type='button' onClick={() => handleToggle(true)}><i className='closeicon'><Iconclose /></i></button>
                                    <div className="action_head">
                                        <strong>Upgrade Your Plan</strong>
                                        <p className='desc'>Take a look at the plan summary and payment information's <span className='next_line'>
                                            below for additional details.</span></p>
                                    </div>

                                    <div className='flex plan_box'>
                                        <div className='flex plan_box_row'>
                                            <div className='plan_type'>{planDetails?.name || ''} Plan</div>
                                            <div className='plan_amount'>{isINR ? '₹' : '$'}{getCurrencyFormat(taxDetails?.actualPrice, isINR ? 'inr' : 'usd')}/</div>
                                            <div className='plan_duration'>{getDuration === 'monthly' ? 'Month' : 'Year'}</div>
                                        </div>
                                        <p className='expand'>Expand your streaming journey with the {planDetails?.name} plan.</p>
                                        <div className='next_renewal'>Next renewal on
                                            <span className='date'> {utcToISOConvert(moment().add(getDuration === 'monthly' ? 30 : 365, 'd'))}</span>
                                        </div>
                                        <div className='renewal_feature_list'>
                                            <ul>
                                                <li className='flex'>
                                                    <span className='icon'> <IconPaymentCheckList /> </span>
                                                    <span className='renewal_point'>All features available in {planDetails?.name} Plan</span>
                                                </li>
                                                <li className='flex'>
                                                    <span className='icon'> <IconPaymentCheckList /> </span>
                                                    <span className='renewal_point'>24 x 7 Customer support</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='flex plan_amount_table'>
                                        <div className='plan_amount_table_header'> Payment Details </div>
                                        <div className='plan_amount_table_body'>
                                            <div className='flex row'>
                                                <div className='row_left'>Plan Amount</div>
                                                <div className={`row_right ${isFree ? 'line-through' : ''}`}>
                                                    {isINR ? '₹' : '$'}{getCurrencyFormat(taxDetails?.actualPrice, isINR ? 'inr' : 'usd')}</div>
                                            </div>

                                            {isINR && (
                                                <div className='flex row gst'>
                                                    <div className='row_left'>GST (18%)</div>
                                                    <div className={`row_right ${isFree ? 'line-through' : ''}`}>
                                                        {isINR ? '₹' : '$'}{getCurrencyFormat(taxDetails?.tax, isINR ? 'inr' : 'usd')}</div>
                                                </div>
                                            )}

                                            <div className='plan_amount_table_footer'>
                                                <div className='flex row'>
                                                    <div className='row_left'> Total </div>
                                                    <div className={`row_right ${isFree ? 'line-through' : ''}`}>
                                                        {isINR ? '₹' : '$'}{getCurrencyFormat(taxDetails?.totalAmount, isINR ? 'inr' : 'usd')}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='group-btn'>
                                        <Link style={{ marginLeft: "auto", marginRight: "auto", width: "100%" }}>
                                            <button
                                                className="Btn outline blue purchaseplan"
                                                onClick={() => handleClick(selectedPlan)}
                                            > {'Purchase Plan'}
                                            </button>
                                        </Link>
                                    </div>
                                    <p className='terms_and_conditions'>I agree that i will be charged {isINR ? '₹' : '$'}
                                        {getCurrencyFormat(taxDetails?.totalAmount, isINR ? 'inr' : 'usd')} today and that my subscription will auto renew at
                                        {isINR ? ' ₹' : ' $'}{getCurrencyFormat(taxDetails?.totalAmount, isINR ? 'inr' : 'usd')}
                                         &nbsp;a {getDuration === 'monthly' ? 'month' : 'year'} until I cancel.
                                    </p>
                                </div>
                            </CSSTransition>
                        </OutsideClickHandler>
                    </div>
                </div>
            </div>
        </ModalWraper>
    );
};

export default React.memo(PaymentIntermediateScreen);
