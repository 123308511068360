import { failToast, serverNotRespond, succToast } from "../../helper/ApiToast";
import { call, put, takeLatest } from "redux-saga/effects";
import { deleteBrandingMediaFileService, getBrandingMediaFilesService, getOrgStreamingLimitService, updateBrandingMediaFileService,
  uploadMediaFilesService } from "../../services/participantServices";
import * as customerTypes from '../actionTypes/customerTypes';
import { getBrandMediaFiles } from "../action/participantAction";
import { updateProfileImageStatus } from "../../firebase/firebaseRealtimeFunctions";
import { getCurrentOrgId } from "../../helper/utility";

function* getStreamingLimit(obj = {}) {
  try {
    yield put({ type: "DO_LOADING_PAGE", loading: true });
    const videoTableList = yield call(getOrgStreamingLimitService, obj);
    const { data = {} } = videoTableList || {};
    if (data.status === 200) {
      yield put({ type: "GET_ORG_STREAMING_LIMIT", orgStreamingData: data?.data });
    }
  } catch (error) {
    serverNotRespond();
  }
  yield put({ type: "DO_LOADING_PAGE", loading: false });
}

function* uploadMediaFilesSaga(obj = {}) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const response = yield call(uploadMediaFilesService, obj);
    const { data = {} } = response || {};
    if (data.status === 200) {
      updateProfileImageStatus(getCurrentOrgId(), data.data.referenceId)
      yield getBrandMediaFiles();
    } else failToast(data.message);
  } catch (error) {
    serverNotRespond();
  }
  yield put({ type: "DO_LOADING_PAGE", loading: false });
}

function* getBrandingMediaFilesSaga(obj = {}) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const mediaList = yield call(getBrandingMediaFilesService, obj);
    const { data = {} } = mediaList || {};
    if (data.status === 200) {
      yield put({ type: "BRANDING_MEDIA_FILES", brandingMediaData: data?.data?.categorizedData });
    } else failToast(data.message);
  } catch (error) {
    console.error('!!', error);
    serverNotRespond();
  }
  yield put({ type: "DO_LOADING_PAGE", loading: false });
}

function* deleteBrandingMediaFileSaga(obj = {}) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const mediaList = yield call(deleteBrandingMediaFileService, obj);
    const { data = {} } = mediaList || {};
    if (data.status === 200) {
      succToast(data.message);
      yield getBrandMediaFiles();
    } else failToast(data.message);
  } catch (error) {
    console.error('!!', error);
    serverNotRespond();
  }
  yield put({ type: "DO_LOADING_PAGE", loading: false });
}

function* updateBrandingMediaFileSaga(obj = {}) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const mediaList = yield call(updateBrandingMediaFileService, obj);
    const { data = {} } = mediaList || {};
    if (data.status === 200) {
      succToast(data.message);
      yield getBrandMediaFiles();
    } else failToast(data.message);
  } catch (error) {
    console.error('!!', error);
    serverNotRespond();
  }
  yield put({ type: "DO_LOADING_PAGE", loading: false });
}

export default function* BroadcastSaga() {
  yield takeLatest(customerTypes.GET_CUSTOMER_STREAMING_LIMIT, getStreamingLimit);
  yield takeLatest("UPLOAD_MEDIA_FILES", uploadMediaFilesSaga);
  yield takeLatest("GET_BRAND_MEDIA_FILES", getBrandingMediaFilesSaga);
  yield takeLatest("DELETE_BRANDING_MEDIA_FILE", deleteBrandingMediaFileSaga);
  yield takeLatest("UPDATE_BRANDING_MEDIA_FILE", updateBrandingMediaFileSaga);
}
