import { failToast, serverNotRespond, succToast } from "../../helper/ApiToast";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  doPreRecordCreate, doPreRecordDelete, doPreRecordStreamDelete,
  doPreRecordStreamStop, doPreRecordUpdate, fetchPreRecordDetails, fetchPreRecordService,
  serverStreamLiveSimulcastService,
  serverStreamSimulcastService,
} from "../../services/preReecordService";
import { doPreRecordStatusAction, fetchPreRecordDetailsAction } from "../action/preRecordAction";
import { updateUploadMediaListAction } from "../action/mediaUploadListAction";
import store from "..";
import { postSimulcastMediaAction } from "../action/facebookAction";
import { getSimulcastMediaListService } from "../../services/simulcastService";
import { getCurrentOrgId, getCurrentUserId } from "../../helper/utility";

function* getPreRecordListSaga(obj = {}) {
  yield put({ type: "STORE_PRE_RECORD_UPDATES", data: { loader: true } });
  try {
    const response = yield call(fetchPreRecordService, obj.data);
    const { data = {} } = response || {};
    if (data.statusCode === 200) {
      yield put({ type: "UPDATE_PRE_RECORD_LIST", data: data?.response || [] });
    }
    yield put({ type: "STORE_PRE_RECORD_UPDATES", data: { loader: false } });
  } catch (error) {
    serverNotRespond();
    yield put({ type: "STORE_PRE_RECORD_UPDATES", data: { loader: false } });

  }
}

function* doPreRecordStreamDeleteSaga(obj = {}) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const response = yield call(doPreRecordStreamDelete, obj.data);
    const { data = {} } = response || {};
    if (data.statusCode === 200) {
      succToast(data.message);
      store.dispatch(doPreRecordStatusAction({ deleteStream: true }));
    }
    else {
      failToast(data.message);
      store.dispatch(doPreRecordStatusAction({ deleteStream: false }));
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  } catch (error) {
    serverNotRespond();
    store.dispatch(doPreRecordStatusAction({ deleteStream: false }));
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

function* doPreRecordStreamStopSaga(obj = {}) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const response = yield call(doPreRecordStreamStop, obj.data);
    const { data = {} } = response || {};
    if (data.statusCode === 200) {
      const stageData = {
        stage_arn: "stage/"+obj.data.stage_id,
      };
      succToast(data.message);
      yield put({ type: "FETCH_PRE_RECORD_DETAILS", data: stageData });
      store.dispatch(doPreRecordStatusAction({ stopStream: true }));
    }
    else {
      failToast(data.message);
      store.dispatch(doPreRecordStatusAction({ stopStream: false }));
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  } catch (error) {
    serverNotRespond();
    store.dispatch(doPreRecordStatusAction({ stopStream: false }));
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

function* doPreRecordDeleteSaga(obj = {}) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const response = yield call(doPreRecordDelete, obj.data);
    const { data = {} } = response || {};
    if (data.statusCode === 200) {
      succToast(data.message);
      getPreRecordListSaga({
        data : {
          video_type: 3,
          org_id: getCurrentOrgId(),
          user_id: getCurrentUserId() ? getCurrentUserId() : JSON.parse(localStorage.getItem("userDetails") || '')?.data?.userId || ''
        }
      });
      store.dispatch(doPreRecordStatusAction({ deleteIds: obj.data.video_id, deleteStatus: true }));
    }
    else {
      failToast(data.message);
      store.dispatch(doPreRecordStatusAction({ deleteIds: "", deleteStatus: false }));
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  } catch (error) {
    serverNotRespond();
    yield put({ type: "DO_LOADING_PAGE", loading: false });
    store.dispatch(doPreRecordStatusAction({ deleteStatus: false }));
  }
}

function* getPreRecordDetailsSaga(obj = {}) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const response = yield call(fetchPreRecordDetails, obj.data);
    const { data = {} } = response || {};
    if (data.statusCode === 200) {
      yield put({ type: "STORE_PRE_RECORD_DETAILS", data: data?.response || [] });
      const res = yield call(getSimulcastMediaListService, obj.data.stage_arn);
      if (res.data.statusCode === 200) {
        yield put({ type: "STORE_PRE_RECORD_SOCIAL_DETAILS", data: res.data.response || [] });
      }
    }
    else {
      failToast(data.message);
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  } catch (error) {
    serverNotRespond();
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

function* doPreRecordUpdateSaga(obj = {}) {
  const { data: { streamData = {}, selectedMediaList = [], onLiveStatus = false, updateRemovables = [] } = {} } = obj;

  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const response = yield call(doPreRecordUpdate, streamData);
    const { data = {} } = response || {};
    if (data.statusCode === 200) {
      const stageData = {
        stage_arn: streamData.stage_arn,
      };
      const addList = [], removableList = [];

      selectedMediaList.forEach((item) => {
        const payload = {
          media_config_id: item?.media_config_id || item?.id,
          broadcast_id: streamData.stage_arn,
          title: streamData.title,
          description: streamData.description,
          scheduled_at: streamData.scheduled_at.toString(),
        };
        addList.push(payload);
      });

      updateRemovables.forEach((item) => {
        const payload = {
          ...item,
          media_config_id: item?.media_config_id || item?.id,
          broadcast_id: streamData.stage_arn,
          title: streamData.title,
          description: streamData.description,
          scheduled_at: streamData.scheduled_at.toString(),
        };
        removableList.push(payload);
      });

      const simulcastMediaParam = {
        addList,
        removableList,
        directGoLive: onLiveStatus ? 4 : false,
        broadcastId: stageData.stage_arn,
      };

      yield postSimulcastMediaAction(simulcastMediaParam);
      yield put({ type: "FETCH_PRE_RECORD_DETAILS", data: stageData });
      yield put({ type: "STORE_PRE_RECORD_UPDATES", data: { update: true } });
    }
    else {
      failToast(data.message);
      yield put({ type: "STORE_PRE_RECORD_UPDATES", data: { update: false } });
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  } catch (error) {
    serverNotRespond();
    yield put({ type: "STORE_PRE_RECORD_UPDATES", data: { update: false } });
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

function* getPreRecordCreateSaga(obj = {}) {
  const { data: { streamData = {}, selectedMediaList = [] } = {} } = obj;
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    const response = yield call(doPreRecordCreate, streamData);
    const { data = {} } = response || {};
    if (data.statusCode === 200) {
      const stageData = {
        stage_arn: data.response.stage_arn,
      };
      const addList = [];
      selectedMediaList.forEach((item) => {
        const payload = {
          ...item,
          media_config_id: item?.id,
          broadcast_id: data.response.stage_arn,
          title: streamData.title,
          description: streamData.description,
          scheduled_at: streamData.scheduled_at.toString(),
        };
        addList.push(payload);
      });

      const simulcastMediaParam = {
        addList,
        directGoLive: false,
        broadcastId: stageData.stage_arn,
      };

      yield postSimulcastMediaAction(simulcastMediaParam);
      store.dispatch(fetchPreRecordDetailsAction(stageData));
      yield put({ type: "STORE_PRE_RECORD_UPDATES", data: { create: true, createdStageArn: stageData.stage_arn } });
    }
    else {
      failToast(data.message.toString());
      yield put({ type: "STORE_PRE_RECORD_UPDATES", data: { create: false } });
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  } catch (error) {
    yield put({ type: "STORE_PRE_RECORD_UPDATES", data: { create: false } });
    serverNotRespond();
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

function* getMediaUploadListSaga(obj = {}) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    delete obj.data.status;
    const response = yield call(fetchPreRecordService, obj.data);
    const { data = {} } = response || {};
    if (data.statusCode === 200) {
      yield put({ type: "UPDATE_PRE_RECORD_UPLOAD_LIST", data: data?.response || [] });
    }
    else {
      failToast(data.message.toString());
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  } catch (error) {
    yield put({ type: "DO_LOADING_PAGE", loading: false });
    serverNotRespond();
  }
}

function* getMediaUploadListTableSaga(obj = {}) {
  yield put({ type: "STORE_PRE_RECORD_UPDATES", data: { loader: true } });
  try {
    const response = yield call(fetchPreRecordService, obj.data);
    const { data = {} } = response || {};
    if (data.statusCode === 200) {
      store.dispatch(updateUploadMediaListAction(data?.response));
    }
    yield put({ type: "STORE_PRE_RECORD_UPDATES", data: { loader: false } });
  } catch (error) {
    yield put({ type: "STORE_PRE_RECORD_UPDATES", data: { loader: false } });
    serverNotRespond();
  }
}

export function* getserverSimulcast(obj = {}) {
  try {
    const res = yield call(serverStreamSimulcastService, obj.data);
    if (res.data.statusCode !== 200) {
      failToast(res.data.message);
    }
  } catch (error) {
    failToast(error.message);
  }
}

export function* getserverLiveSimulcast(obj = {}) {
  try {
    const res = yield call(serverStreamLiveSimulcastService, obj.data);
    if (res.data.statusCode !== 200) {
      failToast(res.data.message);
    }
  } catch (error) {
    failToast(error.message);
  }
}

export default function* preRecordSaga() {
  yield takeLatest("FETCH_PRE_RECORD_LIST", getPreRecordListSaga);
  yield takeLatest("DO_PRE_RECORD_DELETE", doPreRecordDeleteSaga);
  yield takeLatest("FETCH_PRE_RECORD_DETAILS", getPreRecordDetailsSaga);
  yield takeLatest("DO_PRE_RECORD_STREAM_DELETE", doPreRecordStreamDeleteSaga);
  yield takeLatest("DO_PRE_RECORD_UPDATE", doPreRecordUpdateSaga);
  yield takeLatest("DO_PRE_RECORD_CREATE", getPreRecordCreateSaga);
  yield takeLatest("FETCH_UPLOAD_MEDIA_LIST", getMediaUploadListTableSaga);
  yield takeLatest("FETCH_UPLOAD_MEDIA_LIST_TABLE", getMediaUploadListSaga);
  yield takeLatest("DO_PRE_RECORD_STOP", doPreRecordStreamStopSaga);
  yield takeLatest("DO_PRE_RECORD_SIMULCAST", getserverSimulcast);
  yield takeLatest("DO_PRE_RECORD_LIVE_SIMULCAST", getserverLiveSimulcast);
}
