import {
  addName2,
  profilePic,
  participantName,
  sessionBannerName,
  showLogo,
  showBg,
  showOverlayBg,
  audioStream,
  videoEle,
  screeShareBg,
  checkBg,
} from "../helper/AwsDeviceAccess";
import store from "../store";
import { getStream, isMuted } from "../aws/awsHelper";
import { destructStage, destructStageId } from "../common/helper";
import { broadcastPostionsUpdate, stopLoader, updateLiveStatus } from "../store/action/awsActions";
import { endApiBroadcast } from "../services/aswServices";
import { recordTimeDelayAction } from "../store/action/tempAction";
import { getScreenShareUserId, isVideoMutedLive, orderInAdded, positioningHost, screenSharingParticipant } from "../helper/utility";
import { positioningMembers } from "../helper/oderingHelper";
import {
  layoutPositionBasic, layoutPositionImageBasic, layoutPositionAdvance,
  layoutPositionImageAdvance, UpdateBgContrain, test5Participants, removeVideoConfig, logoRightPosition
} from "./BCPositioning";
import { BROADCAST_RESOLUTION } from "../helper/ApiUrl";

const layoutPosition = BROADCAST_RESOLUTION === "BASIC_LANDSCAPE" ? layoutPositionBasic : layoutPositionAdvance;
const layoutPositionImage = BROADCAST_RESOLUTION === "BASIC_LANDSCAPE" ? layoutPositionBasic : layoutPositionAdvance;
let broadcastClient;
const orderingParticipants = (parcipants) => {
  const broadcastBranding = store.getState()?.broadcastBranding;
  const fbHostId = broadcastBranding?.host || "";
  const fbPosition1 = broadcastBranding?.postion1 || "";
  const fbPosition2 = broadcastBranding?.postion2 || "";
  const fbPosition3 = broadcastBranding?.postion3 || "";
  const fbPosition4 = broadcastBranding?.postion4 || "";
  const fbPosition5 = broadcastBranding?.postion5 || "";
  const fbPosition6 = broadcastBranding?.postion6 || "";
  const fbPosition7 = broadcastBranding?.postion7 || "";
  const fbInBoardcastParticipants = broadcastBranding?.inBoardcastParticipants || [];

  let orderedList = parcipants;
  orderedList = positioningHost(parcipants, true, fbHostId.toString());
  orderedList = positioningMembers(
    orderedList,
    true,
    fbPosition1,
    fbPosition2,
    fbPosition3,
    fbPosition4,
    fbPosition5,
    fbPosition6,
    fbPosition7
  );
  return orderInAdded(orderedList, fbInBoardcastParticipants);
};

export const updateLiveScreenShare = async () => {
  const participants = store.getState()?.janusParticipants;
  const screenShareData = screenSharingParticipant(participants);
  if (broadcastClient) {
    const screenShareStream = screenShareData?.stream;
    if (await broadcastClient.getAudioInputDevice(`audio-${screenShareData?.userId}`)) {
      await broadcastClient.removeAudioInputDevice(`audio-${screenShareData?.userId}`)
    }
    if (screenShareStream) {
      try {
        await broadcastClient.addAudioInputDevice(
          screenShareStream,
          `audio-${screenShareData?.userId}`)
      } catch (e) {
        console.log(e, "erroe on audio")
      }
    }
    const constrains = {
      index: 7,
    };
    await broadcastClient.addImageSource(
      screeShareBg(),
      `screenShareBg-${screenShareData?.userId}`,
      {
        index: 6, x: 0, y: 0
      }
    );
    await broadcastClient.addVideoInputDevice(
      screenShareStream,
      `video-${screenShareData?.userId}`,
      constrains
    );
  }
  store.dispatch(stopLoader(true));
}

export const updateOnAir = (screeShareData) => {
  if (broadcastClient) {
    broadcastClient.addAudioInputDevice(screeShareData?.stream, `audio-${screeShareData?.userId}`);
    broadcastClient.addVideoInputDevice(screeShareData?.stream, `video-${screeShareData?.userId}`, { index: 6, x: 0, y: 0 });
  }
}

export const removeScreenShare = (screeShareData) => {
  console.log(screeShareData, "removeScreenShare---removeScreenShare")
  // const globalStream = store.getState()?.janusParticipants.filter(ele => ele?.userId !== screeShareData?.userId);
  if (broadcastClient) {
    try {
      broadcastClient.removeAudioInputDevice(`audio-${screeShareData?.userId}`)
    } catch (e) {
      console.log(e, "errorLog");
    }
    try {
      broadcastClient.removeVideoInputDevice(`video-${screeShareData?.userId}`)
    } catch (e) {
      console.log(e, "errorLog");
    }
    // globalStream.forEach((ele, ind) => {
    //   broadcastClient.addAudioInputDevice(ele?.stream, `audio-${ele?.userId}`);
    //   broadcastClient.addVideoInputDevice(ele?.stream, `video-${ele?.userId}`, layoutPosition[globalStream.length][ind]);
    // });

  }

}

export const removeParticipant = (removedPartcipant) => {
  const participantsInStage = store?.getState()?.janusParticipants.filter((ele) => ele?.userId !== removedPartcipant?.userId)
  if (broadcastClient) {
    console.log(removedPartcipant, "participantsInStage---removeParticipant")
    if (broadcastClient.getVideoInputDevice(`video-${removedPartcipant?.userId}`)
      || broadcastClient.getAudioInputDevice(`audio-${removedPartcipant?.userId}`)) {
      try {
        broadcastClient.removeAudioInputDevice(`audio-${removedPartcipant?.userId}`)
      } catch (e) {
        console.log(e, "errorLog");
      }
      try {
        broadcastClient.removeVideoInputDevice(`video-${removedPartcipant?.userId}`)
      } catch (e) {
        console.log(e, "errorLog");
      }
      participantsInStage.forEach((ele, ind) => {
        if (broadcastClient.getVideoInputDevice(`video-${ele?.userId}`)) {
          broadcastClient.updateVideoDeviceComposition(`video-${ele?.userId}`, layoutPosition[participantsInStage.length][ind]);
        }
      });
    }
  }
};

export const playAudio = (broadcastBranding) => {
  const fbAudioPlayingFile = broadcastBranding?.audioPlayingFile || "";
  const fbAudioIsPlaying = broadcastBranding?.audioIsPlaying || false;
  const fbAudioIsMute = broadcastBranding?.audioIsMute || false;
  const fbAudioVolumeChanger = broadcastBranding?.audioVolumeChanger || 30;
  const fbAudioIsLoop = broadcastBranding?.audioIsLoop || false;
  if (fbAudioIsPlaying && !fbAudioIsMute) {
    const audioCheck = audioStream(fbAudioPlayingFile, fbAudioVolumeChanger, fbAudioIsLoop);
    if (broadcastClient.getAudioInputDevice("AudioPlay")) {
      broadcastClient.removeAudioInputDevice("AudioPlay");
    }
    broadcastClient.addAudioInputDevice(
      audioCheck,
      "AudioPlay"
    );
  }
}

export const initialBroadCast = async (
  getlayout,
  broadcastKey,
  currentParticipant,
  fbBannerStatus,
  fbBannerText,
  fbBannerStyle,
  fbBannerBgColor,
  isEight = false
) => {
  const broadcastBranding = store?.getState()?.broadcastBranding || [];
  const fbLayout = broadcastBranding?.layout || "";
  const layout = getlayout === "layout-share" ? fbLayout : getlayout;
  // const layout = "layout2";

  const {
    channel: { ingestEndpoint = "" },
    streamKey: { streamKey = "" },
  } = broadcastKey;
  const sdk = (await import('amazon-ivs-web-broadcast')).default;
  broadcastClient = sdk.create({
    // Enter the desired stream configuration
    streamConfig: BROADCAST_RESOLUTION === "BASIC_LANDSCAPE" ? sdk.BASIC_LANDSCAPE : sdk.STANDARD_LANDSCAPE,
    // Enter the ingest endpoint from the AWS console or CreateChannel API
    ingestEndpoint: ingestEndpoint,
    // "rtmps://3f1ac6d7e5bc.global-contribute.live-video.net:443/app/",
  });

  await broadcastClient
    .startBroadcast(streamKey)
    .then((result) => {
      store.dispatch(updateLiveStatus(true));
      console.log("I am successfully broadcasting!");
      setTimeout(() => {
        store.dispatch(recordTimeDelayAction("Broadcasted"));
      }, 3000);
    })
    .catch((error) => {
      console.error("Something drastically failed while broadcasting!", error);
    });
  const participants = currentParticipant.filter(ele => ele?.attributes?.type !== "screenShare");
  const participantsStreamData = store.getState()?.stageParticipantsData;
  const broadcastLogo = store.getState()?.broadcastLogo;
  const awsStageReducer = store.getState()?.awsStageReducer;
  const logo = showLogo(broadcastLogo);
  const awsStageArn = awsStageReducer?.stageArn || awsStageReducer?.stage?.arn;
  const initLogoDimension = broadcastBranding?.logoDimension;
  const isLogoRight = broadcastBranding?.isLogoRight;
  const bannerBackground = broadcastBranding?.bannerBackground;
  const bannerTopOverlay = broadcastBranding?.bannerTopOverlay;
  const bannerBg = showBg(bannerBackground);
  const overlayBg = showOverlayBg(bannerTopOverlay);

  const bannerConstrains = {
    index: 1,
    x: -40,
    y: 0,
    width: 920,
    height: 517.5,
  };
  console.log("overlay", overlayBg);

  const bannerOverlayConstrains = {
    index: 8,
    x: 0,
    y: 0,
  };
  console.log(initLogoDimension, initLogoDimension, "isLogoRight");
  const logoConstrains = {
    index: 10,
    x: isLogoRight ? logoRightPosition - initLogoDimension[0] : 52,
    y: 25,
    width: initLogoDimension ? initLogoDimension[0] : 370,
    height: initLogoDimension ? initLogoDimension[1] : 118,
  };

  if (logo) {
    await broadcastClient.addImageSource(
      logo,
      `logo-${destructStageId(destructStage(awsStageArn))}`,
      logoConstrains
    );
  }

  if (overlayBg) {
    await broadcastClient.addImageSource(
      overlayBg,
      `BannerOverlayImg-${destructStageId(destructStage(awsStageArn))}`,
      bannerOverlayConstrains
    );
  }

  if (bannerBg) {
    await broadcastClient.addImageSource(
      bannerBg,
      `BannerBg-${destructStageId(destructStage(awsStageArn))}`, UpdateBgContrain,
    );
  }
  getlayout === "layout-share" && updateLiveScreenShare();

  switch (layout) {
    case "layout1":
      participants.map(async (ele, ind) => {
        if (ind === 0) {
          const stream = ele?.stream;
          const constrains = {
            index: 5,
          };
          await broadcastClient.addVideoInputDevice(
            stream,
            `video-${ele?.userId}`,
            constrains
          );
          if (isVideoMutedLive(ele?.userId)) {
            const profilePiclayoutOne = profilePic();
            if (profilePiclayoutOne) {
              await broadcastClient.addImageSource(
                profilePiclayoutOne,
                `image-${ele?.userId}`,
                layoutPositionImage[1][0]
              ); // maxWidthBasic = 848 maxHeightBasic = 477
            }
          }
          if (!fbBannerStatus) {
            try {
              await broadcastClient.addImageSource(
                participantName(ele?.userId, ind),
                `DisplayName-${ele?.userId}`,
                {
                  index: 5,
                }
              );
            } catch (err) {
              console.error("displayName>>", err)
            }
          }
          await broadcastClient.addAudioInputDevice(
            stream,
            `audio-${ele?.userId}`
          );
          playAudio(broadcastBranding);
        }
      });
      break;
    case "layout2":
      // eslint-disable-next-line no-case-declarations
      participants.map(async (ele, ind) => {
        const stream = ele?.stream;
        if (isVideoMutedLive(ele?.userId)) {
          const profilePiclayoutOne = profilePic();
          if (profilePiclayoutOne) {
            await broadcastClient.addImageSource(
              profilePiclayoutOne,
              `image-${ele?.userId}`,
              { ...layoutPositionImage[participants.length][ind] }
            );
          }
        }
        await broadcastClient.addVideoInputDevice(
          stream,
          `video-${ele?.userId}`,
          { ...layoutPosition[participants.length][ind] }
        );
        !fbBannerStatus &&
          await broadcastClient.addImageSource(
            addName2(ele?.userId, ind, participants.length),
            `DisplayName-${ele?.userId}`,
            {
              index: 3,
            }
          );
        await broadcastClient.addAudioInputDevice(
          stream,
          `audio-${ele?.userId}`
        );
      });
      break;
    case "layout3":
      return "";
    default:
    // code block
  }
  fbBannerStatus &&
    await broadcastClient.addImageSource(
      sessionBannerName(fbBannerText, fbBannerStyle, fbBannerBgColor),
      `Banner-${destructStageId(destructStage(awsStageArn))}`,
      {
        index: 9,
      }
    );

  store.dispatch(
    broadcastPostionsUpdate({
      layout: layout,
      participantsList: currentParticipant,
    })
  );
};
export const handleParticipantRemoved = (removedParticipant, type = "callRemove") => {
  console.log(removedParticipant, "handleParticipantRemoved--handleParticipantRemoved")
  if (broadcastClient && removedParticipant) {
    const participantsInStage = store
      .getState()
      ?.janusParticipants;

    const config = {
      index: 4,
    };
    const broadcastBranding = store?.getState()?.broadcastBranding || [];
    const positionParticipant = orderingParticipants(participantsInStage)
      .filter((ele) => ele?.userId !== removedParticipant?.userId)
      .map(
        (ele, ind) => {
          return { ...ele, position: ind };
        }
      );
    const fbBannerText = broadcastBranding?.bannerText || "";
    const fbBannerStyle = broadcastBranding?.bannerStyle || "";
    const fbBannerBgColor = broadcastBranding?.bannerBgColor || "";
    const fbBannerStatus = broadcastBranding?.bannerStatus || "";
    const fbLayout = broadcastBranding?.layout || "layout1";
    const awsStageReducer = store.getState()?.awsStageReducer || "";
    const awsStageArn =
      awsStageReducer?.stageArn || awsStageReducer?.stage?.arn;
    setTimeout(() => {
      // remove ScreenShare
      if (getScreenShareUserId(removedParticipant?.userId)) {
        try {
          broadcastClient?.removeImage(`screenShareBg-${removedParticipant?.userId}`);
        } catch (err) {
          console.log("awsIvs>>>", err);
        }
        broadcastClient.removeVideoInputDevice(
          `video-${removedParticipant?.userId}`
        );
        if (broadcastClient.getAudioInputDevice(`audio-${removedParticipant?.userId}`)) {
          broadcastClient.removeAudioInputDevice(
            `audio-${removedParticipant?.userId}`
          );
        }
        store.dispatch(stopLoader(true));
        return;
      }
      // remove participant from live
      if (!fbBannerStatus) {
        try {
          broadcastClient?.removeImage(`DisplayName-${removedParticipant?.userId}`);
        } catch (err) {
          console.log("DisplayName>>>", err);
        }
      }
      if (
        broadcastClient.getVideoInputDevice(`video-${removedParticipant?.userId}`)
      ) {
        if (type === "onLiveRemove") {
          broadcastClient.updateVideoDeviceComposition(
            `video-${removedParticipant?.userId}`, removeVideoConfig
          );
        } else {
          broadcastClient.removeVideoInputDevice(
            `video-${removedParticipant?.userId}`
          );
        }
      }
      if (
        broadcastClient.getAudioInputDevice(`audio-${removedParticipant?.userId}`)
      ) {
        if (type === "onLiveRemove") {
        } else {
          broadcastClient.removeAudioInputDevice(
            `audio-${removedParticipant?.userId}`
          );
        }
      }
      try {
        broadcastClient?.removeImage(`image-${removedParticipant?.userId}`);
      } catch (err) {
        console.log("awsIvs>>>", err);
      }

      console.log(positionParticipant, "positionParticipant---positionParticipant--positionParticipant")

      // alter current Particiopants
      if (positionParticipant.length && positionParticipant.length < 2) {
        if (removedParticipant?.userId === positionParticipant[0]?.userId) {
          return;
        }
        if (!fbBannerStatus) {
          try {
            broadcastClient?.removeImage(
              `DisplayName-${positionParticipant[0]?.userId}`
            );
          } catch (err) {
            console.log("DisplayName>>>", err);
          }

        }
        if (
          broadcastClient.getVideoInputDevice(
            `video-${positionParticipant[0]?.userId}`
          )
        ) {
          if (isVideoMutedLive(positionParticipant[0]?.userId)) {
            try {
              broadcastClient?.removeImage(`image-${positionParticipant[0]?.userId}`);
            } catch (err) {
              console.log("DisplayName>>>", err);
            }
            const profilePiclayoutOne = profilePic();
            if (profilePiclayoutOne) {
              broadcastClient.addImageSource(
                profilePiclayoutOne,
                `image-${positionParticipant[0]?.userId}`,
                layoutPositionImage[1][0]
              );
            }
          }
          broadcastClient.updateVideoDeviceComposition(
            `video-${positionParticipant[0]?.userId}`,
            config
          );
          !fbBannerStatus &&
            broadcastClient.addImageSource(
              participantName(positionParticipant[0]?.userId),
              `DisplayName-${positionParticipant[0]?.userId}`,
              { index: 5 }
            );
        }
        fbBannerStatus &&
          broadcastClient.addImageSource(
            sessionBannerName(fbBannerText, fbBannerStyle, fbBannerBgColor),
            `Banner-${destructStageId(destructStage(awsStageArn))}`,
            {
              index: 9,
            }
          );
        store.dispatch(
          broadcastPostionsUpdate({
            layout: "layout1",
            participantsList: positionParticipant,
          })
        );
      } else if (positionParticipant.length > 1) {
        positionParticipant.map((ele, ind) => {
          if (!fbBannerStatus) {
            try {
              broadcastClient?.removeImage(`DisplayName-${ele?.userId}`);
            } catch (err) {
              console.log("DisplayName>>>", err);
            }
          }
          if (isVideoMutedLive(ele?.userId)) {
            try {
              broadcastClient?.removeImage(`image-${ele?.userId}`);
            } catch (err) {
              console.log("removeImage=>Image")
            }
            const profilePiclayoutOne = profilePic();
            if (profilePiclayoutOne) {
              console.log(ele?.userId, "r=>Image")
              broadcastClient.addImageSource(
                profilePiclayoutOne,
                `image-${ele?.userId}`,
                { ...layoutPositionImage[positionParticipant.length][ind] }
              );
            }
          }
          if (broadcastClient.getVideoInputDevice(`video-${ele?.userId}`)) {
            broadcastClient.updateVideoDeviceComposition(
              `video-${ele?.userId}`,
              layoutPosition[positionParticipant.length][ind]
            );
            !fbBannerStatus &&
              broadcastClient.addImageSource(
                addName2(ele?.userId, ind, positionParticipant.length),
                `DisplayName-${ele?.userId}`,
                { index: 3 }
              );
          }
          // fbBannerStatus &&
          //   broadcastClient.addImageSource(
          //     sessionBannerName(fbBannerText, fbBannerStyle, fbBannerBgColor),
          //     `Banner-${destructStageId(destructStage(awsStageArn))}`,
          //     {
          //       index: 9,
          //     }
          //   );
          store.dispatch(
            broadcastPostionsUpdate({
              layout: "layout2",
              participantsList: positionParticipant,
            })
          );
        });
      }
      setTimeout(() => {
        store.dispatch(stopLoader(true));
      }, [1000]);
    }, [1000]);
  } else {
    store.dispatch(stopLoader(true));
  }
};

export const muteBroadcastedVideo = (isVideoMutedList) => {
  const janusParticipants = store.getState()?.janusParticipants;
  const broadcastBranding = store?.getState()?.broadcastBranding || [];
  const layout = "layout2";
  switch (layout) {
    case "layout1":
      if (broadcastClient.getVideoInputDevice(`video-${janusParticipants[0]?.userId}`)
        && isVideoMutedList.includes(janusParticipants[0]?.userId)) {
        const profilePiclayoutOne = profilePic();
        if (profilePiclayoutOne) {
          try {
            broadcastClient.addImageSource(
              profilePiclayoutOne,
              `image-${janusParticipants[0]?.userId}`,
              layoutPositionImage[1][0]
            );
          } catch (e) {
            console.error(e, "unable to add Image")
          }
        }
      }
      if (broadcastClient.getVideoInputDevice(`video-${janusParticipants[0]?.userId}`)
        && !isVideoMutedList.includes(janusParticipants[0]?.userId)) {
        try {
          broadcastClient.removeImage(`image-${janusParticipants[0]?.userId}`);
        } catch (e) {
          console.error(e, "Image Unable to Remove");
        }
      }
      break;
    case "layout2":
      janusParticipants.forEach((ele, ind) => {
        if (broadcastClient.getVideoInputDevice(`video-${ele?.userId}`)
          && isVideoMutedList.includes(ele?.userId)) {
          const profilePiclayoutOne = profilePic();
          if (profilePiclayoutOne) {
            try {
              broadcastClient.addImageSource(
                profilePiclayoutOne,
                `image-${ele?.userId}`,
                layoutPositionImage[janusParticipants.length][ind]
              );
            } catch (e) {
              console.log(e, ">>> got an Error")
            }
          }
        }
        if (broadcastClient.getVideoInputDevice(`video-${ele?.userId}`)
          && !isVideoMutedList.includes(ele?.userId)) {
          try {
            broadcastClient.removeImage(`image-${ele?.userId}`);
          } catch (e) {
            console.log(e, "Image Unable to Remove");
          }
        }
      })
      break;
    case "layout3":
      break;
    default:
    // code block
  }
};

export const handleParticipantMute = (isVideoMutedList) => {
  if (broadcastClient) {
    muteBroadcastedVideo(isVideoMutedList);
  }
};

export const handleStreamAddedinBroadcast = async (participant) => {
  if (getScreenShareUserId(participant?.userId)) {
    return;
  }
  if (broadcastClient) {
    const broadcastBranding = store.getState()?.broadcastBranding;
    const fbLayout = broadcastBranding?.layout || [];
    const janusParticipants = store.getState()?.janusParticipants;
    const layout = fbLayout;
    const positionParticipant = orderingParticipants(janusParticipants);
    const fbBannerStatus = broadcastBranding?.bannerStatus || "";
    positionParticipant.map(async (ele, ind) => {
      if (broadcastClient.getVideoInputDevice(`video-${ele?.userId}`)) {
        try {
          await broadcastClient?.removeImage(`image-${ele?.userId}`);
        } catch (e) {
          console.error(e, "image ==> image not found")
        }
        if (!fbBannerStatus) {
          try {
            await broadcastClient?.removeImage(`DisplayName-${ele?.userId}`);
          } catch (e) {
            console.error(e, "DisplayName ==> image not found")
          }
        }
        await broadcastClient.updateVideoDeviceComposition(
          `video-${ele.userId}`,
          layoutPosition[positionParticipant.length][ind]
        );
        if (!fbBannerStatus) {
          if (layout === "layout1") {
            try {
              await broadcastClient.addImageSource(
                participantName(ele?.userId, ind),
                `DisplayName-${ele?.userId}`,
                {
                  index: 6,
                }
              );
            } catch (err) {
              console.error("displayName>>", err)
            }
          } else {
            await broadcastClient.addImageSource(
              addName2(ele?.userId, ind, positionParticipant.length, fbLayout),
              `DisplayName-${ele?.userId}`,
              {
                index: fbLayout === "layout1" ? 5 : 3,
              }
            );
          }
        }
        if (isVideoMutedLive(ele?.userId)) {
          const profilePiclayoutOne = profilePic();
          if (profilePiclayoutOne) {
            await broadcastClient.addImageSource(
              profilePiclayoutOne,
              `image-${ele?.userId}`,
              layoutPositionImage[positionParticipant.length][ind]
            );
          }
        }

      } else {
        if (!fbBannerStatus) {
          if (layout === "layout1") {
            try {
              await broadcastClient.addImageSource(
                participantName(ele?.userId, ind),
                `DisplayName-${ele?.userId}`,
                {
                  index: 6,
                }
              );
            } catch (err) {
              console.error("displayName>>", err)
            }
          } else {
            await broadcastClient.addImageSource(
              addName2(ele?.userId, ind, positionParticipant.length, fbLayout),
              `DisplayName-${ele?.userId}`,
              {
                index: fbLayout === "layout1" ? 5 : 3,
              }
            );
          }
        }
        await broadcastClient.addVideoInputDevice(
          ele?.stream,
          `video-${ele.userId}`,
          layoutPosition[positionParticipant.length][ind]
        );
        await broadcastClient.addAudioInputDevice(
          ele?.stream,
          `audio-${ele.userId}`
        );
        if (isVideoMutedLive(ele?.userId)) {
          const profilePiclayoutOne = profilePic();
          if (profilePiclayoutOne) {
            await broadcastClient.addImageSource(
              profilePiclayoutOne,
              `image-${ele?.userId}`,
              layoutPositionImage[positionParticipant.length][ind]
            );
          }
        }

      }
    });
    store.dispatch(
      broadcastPostionsUpdate({
        layout: layout,
        participantsList: positionParticipant,
      })
    );
    setTimeout(() => {
      store.dispatch(stopLoader(true));
    }, [1000]);
  } else {
    store.dispatch(stopLoader(true));
  }

};

export const updateBroadcastImage = (broadcastLogo, stageArn) => {
  const broadcastBranding = store?.getState()?.broadcastBranding || [];
  const initLogoDimension = broadcastBranding?.logoDimension;
  const isLogoRight = store.getState()?.broadcastBranding?.isLogoRight || false;

  const updateLogoConstrains = {
    index: 10,
    x: isLogoRight ? logoRightPosition - initLogoDimension[0] : 20,
    y: 25,
    width: initLogoDimension ? initLogoDimension[0] : 0,
    height: initLogoDimension ? initLogoDimension[1] : 1,
  };

  if (broadcastClient) {
    const logo = showLogo(broadcastLogo);
    if (logo) {
      try {
        broadcastClient?.removeImage(
          `logo-${destructStageId(destructStage(stageArn))}`
        );
      } catch (exception) {
        console.log(exception, "exception: updateBroadcastImage => removeImage");
      }
      try {
        broadcastClient.addImageSource(
          logo,
          `logo-${destructStageId(destructStage(stageArn))}`,
          updateLogoConstrains
        );
      } catch (exception) {
        console.log(exception, "exception: updateBroadcastImage => addImageSource");
      }
    }
  }
};

export const updateBroadcastLayout = (
  LaytoutType,
  unused,
  fbBannerText,
  fbBannerStatus,
  fbBannerBgColor,
  awsStageReducer
) => {
  const updateConfig = {
    index: 4,
  };
  const currentParticipant = orderingParticipants(
    store.getState()?.janusParticipants
  );
  if (broadcastClient) {
    switch (LaytoutType) {
      case "layout1":
        if (!fbBannerStatus) {
          try {
            broadcastClient?.removeImage(
              `DisplayName-${currentParticipant[0]?.userId}`
            );
          } catch (exception) {
            console.error(exception, "exception: updateBroadcastImage => removeImage");
          }
        }
        if (
          broadcastClient?.getVideoInputDevice(
            `video-${currentParticipant[0]?.userId}`
          )
        ) {
          broadcastClient?.updateVideoDeviceComposition(
            `video-${currentParticipant[0]?.userId}`,
            updateConfig
          );
          !fbBannerStatus &&
            broadcastClient?.addImageSource(
              participantName(currentParticipant[0]?.userId),
              `DisplayName-${currentParticipant[0]?.userId}`,
              { index: 5 }
            );
          if (isVideoMutedLive(currentParticipant[0]?.userId)) {
            try {
              broadcastClient?.removeImage(`image-${currentParticipant[0]?.userId}`);
            } catch (e) {
              console.error(e, "exception: updateBroadcastImage => removeImage")
            }
            const profilePiclayoutOne = profilePic();
            if (profilePiclayoutOne) {
              broadcastClient?.addImageSource(
                profilePiclayoutOne,
                `image-${currentParticipant[0]?.userId}`,
                layoutPositionImage[1][0]
              );
              console.log("profilepic>>>Added");
            }
          }
        }
        store.dispatch(
          broadcastPostionsUpdate({
            layout: "layout1",
            participantsList: currentParticipant,
          })
        );
        break;
      case "layout2":
        currentParticipant.map((ele, ind) => {
          if (broadcastClient?.getVideoInputDevice(`video-${ele?.userId}`)) {
            if (!fbBannerStatus) {
              try {
                broadcastClient?.removeImage(`DisplayName-${ele?.userId}`);
              } catch (e) {
                console.log(e, "Error =>>>> RemoveImage")
              }
            }
            if (isVideoMutedLive(ele?.userId)) {
              try {
                broadcastClient?.removeImage(`image-${ele?.userId}`);
              } catch (e) {
                console.log(e, "Error =>>>> RemoveImage")
              }
            }
            broadcastClient?.updateVideoDeviceComposition(
              `video-${ele?.userId}`,
              layoutPosition[currentParticipant.length][ind]
            );
            if (!fbBannerStatus) {
              broadcastClient?.addImageSource(
                addName2(ele?.userId, ind, currentParticipant.length),
                `DisplayName-${ele?.userId}`,
                {
                  index: 3,
                }
              );
            }

            if (isVideoMutedLive(ele?.userId)) {
              const profilePiclayoutOne = profilePic();
              if (profilePiclayoutOne) {
                broadcastClient?.addImageSource(
                  profilePiclayoutOne,
                  `image-${ele?.userId}`,
                  layoutPositionImage[currentParticipant.length][ind]
                );
                console.log("profilepic>>>Added");
              }
            }
          } else {
            const inputVideoStream = ele?.stream;
            if (!fbBannerStatus) {
              broadcastClient?.addImageSource(
                addName2(ele?.userId, ind, currentParticipant.length),
                `DisplayName-${ele?.userId}`,
                {
                  index: 3,
                }
              );
            }
            broadcastClient?.addVideoInputDevice(
              inputVideoStream,
              `video-${ele?.userId}`,
              layoutPosition[currentParticipant.length][ind]
            );
            if (isVideoMutedLive(ele?.userId)) {
              const profilePiclayoutOne = profilePic();
              if (profilePiclayoutOne) {
                broadcastClient?.addImageSource(
                  profilePiclayoutOne,
                  `image-${ele?.userId}`,
                  layoutPositionImage[currentParticipant.length][ind]
                );
                console.log("profilepic>>>Added");
              }
            }
          }
        });
        break;
      default:
      // code block
    }
  }
};

export const endBroadcast = async () => {
  const channelArn = store.getState()?.broadcastKey?.channel?.channelArn;
  if (broadcastClient) {
    const endResponse = await endApiBroadcast(channelArn);
    if (endResponse?.status !== 200) {
      broadcastClient.stopBroadcast();
    }
    broadcastClient = null;
  }
};
const removeDisplayName = (layout, participants) => {
  if (layout === "layout1") {
    const localUser = participants.find((ele) => ele.isLocal);
    broadcastClient?.removeImage(`DisplayName-${localUser?.id}`);
  } else {
    console.log(participants, "participants");
    participants.map((ele) => {
      broadcastClient?.removeImage(`DisplayName-${ele?.userId}`);
    });
  }
};

const addDisplayName = (layout, participants) => {
  console.log("inputvalu##", layout, participants);
  if (layout === "layout1") {
    const localUser = orderingParticipants(participants)[0];
    console.log("inputvalu####", localUser, participantName(localUser?.id))
    broadcastClient.addImageSource(
      participantName(localUser?.userId),
      `DisplayName-${localUser?.userId}`,
      {
        index: 5,
      }
    );
  } else {
    participants.map((ele, ind) => {
      broadcastClient.addImageSource(
        addName2(ele?.userId, ind, participants.length),
        `DisplayName-${ele?.userId}`,
        { index: 3 }
      );
    });
  }
};

const addBannerCaption = (awsStageArn) => {
  const fbBannerStyle = store.getState()?.broadcastBranding?.bannerStyle || "";
  const fbBannerBgColor =
    store.getState()?.broadcastBranding?.bannerBgColor || "";
  const fbBannerText = store.getState()?.broadcastBranding?.bannerText || "";
  broadcastClient.addImageSource(
    sessionBannerName(fbBannerText, fbBannerStyle, fbBannerBgColor),
    `Banner-${destructStageId(destructStage(awsStageArn))}`,
    {
      index: 9,
    }
  );
};

export const handleBannerUpdate = async (
  bannerStatus,
  participants,
  getLaytoutType
) => {
  console.log(bannerStatus,
    participants,
    getLaytoutType, "handleBannerUpdate")
  const awsStageReducer = store.getState()?.awsStageReducer;
  const awsStageArn = awsStageReducer?.stageArn || awsStageReducer?.stage?.arn;

  if (broadcastClient) {
    if (bannerStatus) {
      try {
        removeDisplayName(getLaytoutType, participants);
      } catch (exception) {
        console.log(exception, "exception: handleBannerUpdate => removeDisplayName");
      }
      addBannerCaption(awsStageArn);
    } else {
      try {
        broadcastClient?.removeImage(
          `Banner-${destructStageId(destructStage(awsStageArn))}`
        );
      } catch (exception) {
        console.log(exception, "exception: handleBannerUpdate => removeImage");
      }

      addDisplayName(getLaytoutType, participants);
    }
  }
};

export const updateBannerTextBC = (getBannerName, _stageArn) => {
  const bannerStatus = store.getState()?.broadcastBranding?.bannerStatus;
  const fbBannerStyle = store.getState()?.broadcastBranding?.bannerStyle || "";
  const fbBannerBgColor =
    store.getState()?.broadcastBranding?.bannerBgColor || "";
  console.log(bannerStatus, "bannerStatus");
  if (broadcastClient) {
    if (bannerStatus) {
      try {
        broadcastClient?.removeImage(
          `Banner-${destructStageId(destructStage(_stageArn))}`
        );
      } catch (exception) {
        console.log(exception, "exception: updateBannerTextBC => removeImage");

      }
      try {
        broadcastClient.addImageSource(
          sessionBannerName(getBannerName, fbBannerStyle, fbBannerBgColor),
          `Banner-${destructStageId(destructStage(_stageArn))}`,
          {
            index: 9,
          }
        );
      } catch (exception) {
        console.log(exception, "exception: updateBannerTextBC => addImageSource");
      }
    }
  }
};

export const updateBannerBG = (getBannerBG, _stageArn) => {
  const bannerBGImg = showBg(getBannerBG);
  if (broadcastClient) {
    broadcastClient?.removeImage(`BannerBg-${destructStageId(destructStage(_stageArn))}`);

    broadcastClient.addImageSource(bannerBGImg, `BannerBg-${destructStageId(destructStage(_stageArn))}`, UpdateBgContrain);
  }
};

export const updateBannerOverlayImg = (getBannerOverlayImg, _stageArn) => {
  const bannerBGImg = showOverlayBg(getBannerOverlayImg);
  const bannerOverlayConstrains = {
    index: 8,
    x: 0,
    y: 0,
  };
  if (broadcastClient) {
    broadcastClient?.removeImage(
      `BannerOverlayImg-${destructStageId(destructStage(_stageArn))}`
    );
    broadcastClient.addImageSource(
      bannerBGImg,
      `BannerOverlayImg-${destructStageId(destructStage(_stageArn))}`,
      bannerOverlayConstrains
    );
  }
};
export const updateBannerThemeStyle = (
  bannerText,
  bannerThemestyle,
  bannerBGC,
  _stageArn
) => {
  console.log(bannerText,
    bannerThemestyle,
    bannerBGC,
    _stageArn, "updateBannerThemeStyle")
  const bannerStatus = store.getState()?.broadcastBranding?.bannerStatus;
  if (broadcastClient) {
    console.log(
      bannerStatus,
      "sessionBannerName----",
      bannerText,
      bannerThemestyle,
      bannerBGC,
      _stageArn
    );
    if (bannerStatus) {
      try {
        broadcastClient?.removeImage(
          `Banner-${destructStageId(destructStage(_stageArn))}`
        );
      } catch (e) {
        console.log(e, "banner image not find")
      }
      broadcastClient.addImageSource(
        sessionBannerName(
          bannerText,
          bannerThemestyle === "" ? 0 : bannerThemestyle,
          bannerBGC
        ),
        `Banner-${destructStageId(destructStage(_stageArn))}`,
        {
          index: 9,
        }
      );
    }
  }
};

export const updateParticipantBannerStyle = (participant, layoutTypeComp) => {
  const broadcastBranding = store?.getState()?.broadcastBranding || [];
  const fbLayout = broadcastBranding?.layout || "";
  const layoutType = layoutTypeComp === "layout-share" ? fbLayout : layoutTypeComp
  console.log(participant, layoutType, "updateParticipantBannerStyle if participant");

  const localUser = participant.find((ele) => ele.isLocal);
  if (broadcastClient) {
    if (layoutType === "layout1") {
      try {
        broadcastClient?.removeImage(`DisplayName-${localUser?.userId}`);
      } catch (e) {
        console.log(e, "Display Image Not found");
      }
      broadcastClient.addImageSource(
        participantName(localUser?.userId),
        `DisplayName-${localUser?.userId}`,
        {
          index: 5,
        }
      );
    } else {
      console.log("updateParticipantBannerStyle else DisplayName");

      participant.map((ele) => {
        if (broadcastClient?.getVideoInputDevice(`video-${ele?.userId}`)) {
          try {
            broadcastClient?.removeImage(`DisplayName-${ele?.userId}`);
          } catch (err) {
            console.log("BC>>", err)
          }
        }
      });
      participant.map((ele, ind) => {
        if (broadcastClient?.getVideoInputDevice(`video-${ele?.userId}`)) {
          broadcastClient.addImageSource(
            addName2(ele?.userId, ind, participant.length),
            `DisplayName-${ele?.userId}`,
            { index: 3 }
          );
        }
      });
    }
  }
};

export const updateParticipantStream = (streamData) => {
  const participants = store.getState()?.stageParticipants;
  const awsStageReducer = store.getState()?.awsStageReducer;
  const awsStageArn = awsStageReducer?.stageArn || awsStageReducer?.stage?.arn;
  const audioStream1 = audioStream();
  if (broadcastClient) {
    if (broadcastClient?.getVideoInputDevice(`video-${streamData?.id}`))
      broadcastClient?.removeVideoInputDevice(`video-${streamData?.id}`);
    if (broadcastClient?.getAudioInputDevice(`audio-${streamData?.id}`))
      broadcastClient?.removeAudioInputDevice(`audio-${streamData?.id}`);
    setTimeout(() => {
      const participantsStreamData = store.getState()?.stageParticipantsData;
      const inputVideoStream = new MediaStream([
        getStream(participantsStreamData[streamData?.id], "video"),
      ]);
      const inputAudioStream = new MediaStream([
        getStream(participantsStreamData[streamData?.id], "audio"),
      ]);
      broadcastClient?.addVideoInputDevice(
        inputVideoStream,
        `video-${streamData?.id}`,
        { ...layoutPosition[participants.length][0] }
      );
      broadcastClient?.addAudioInputDevice(
        inputAudioStream,
        `audio-${streamData?.id}`
      );
      broadcastClient.addAudioInputDevice(
        audioStream1,
        `audio1-${destructStageId(destructStage(awsStageArn))}`
      );
    }, [500]);
  }
};

export const editPlayAudio = (status, type, attribute = 0) => {
  if (broadcastClient) {
    const broadcastBranding = store?.getState()?.broadcastBranding || [];
    const fbAudioPlayingFile = broadcastBranding?.audioPlayingFile || "";
    const fbAudioVolumeChanger = broadcastBranding?.audioVolumeChanger || 30;
    const fbAudioIsLoop = broadcastBranding?.audioIsLoop || false;
    const fbAudioIsPlaying = broadcastBranding?.audioIsPlaying || false;

    if (broadcastClient.getAudioInputDevice("AudioPlay")) {
      if (type === "MUTE") {
        if (status) {
          broadcastClient.removeAudioInputDevice("AudioPlay")
        } else {
          const audioCheck = audioStream(fbAudioPlayingFile, fbAudioVolumeChanger, fbAudioIsLoop, attribute);
          broadcastClient.addAudioInputDevice(
            audioCheck,
            "AudioPlay"
          );
        }
      }
      if (type === "LOOP") {
        broadcastClient.removeAudioInputDevice("AudioPlay")
        const audioCheck = audioStream(fbAudioPlayingFile, fbAudioVolumeChanger, fbAudioIsLoop, attribute);
        broadcastClient.addAudioInputDevice(
          audioCheck,
          "AudioPlay"
        );
      }
      if (type === "CHANGE") {
        broadcastClient.removeAudioInputDevice("AudioPlay");
        if (fbAudioIsPlaying) {
          const audioCheck = audioStream(fbAudioPlayingFile, fbAudioVolumeChanger, fbAudioIsLoop, attribute);
          broadcastClient.addAudioInputDevice(
            audioCheck,
            "AudioPlay"
          );
        }
      }
    }
    else {
      playAudio(broadcastBranding);
    }
  }
};

export const removeScreenShareOnAir = (removedParticipant) => {
  if (broadcastClient) {
    if (getScreenShareUserId(removedParticipant?.userId)) {
      try {
        broadcastClient?.removeImage(`screenShareBg-${removedParticipant?.userId}`);
      } catch (err) {
        console.log("awsIvs>>>", err);
      }
      broadcastClient.removeVideoInputDevice(
        `video-${removedParticipant?.userId}`
      );
      if (broadcastClient.getAudioInputDevice(`audio-${removedParticipant?.userId}`)) {
        broadcastClient.removeAudioInputDevice(
          `audio-${removedParticipant?.userId}`
        );
      }
      setTimeout(() => {
        store.dispatch(stopLoader(true));
      }, 1000);
      return;
    }
  } else {
    setTimeout(() => {
      store.dispatch(stopLoader(true));
    }, 1000);
  }
};
