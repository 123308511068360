export const fetchPreRecordListAction = (data = "") => {
  return {
    type: "FETCH_PRE_RECORD_LIST",
    data,
  };
};

export const updatePreRecordListAction = (data = "") => {
  return {
    type: "UPDATE_PRE_RECORD_LIST",
    data,
  };
};

export const updatePreRecordUploadListAction = (data = "") => {
  return {
    type: "UPDATE_PRE_RECORD_UPLOAD_LIST",
    data,
  };
};

export const doPreRecordDeleteAction = (data = "") => {
  return {
    type: "DO_PRE_RECORD_DELETE",
    data,
  };
};

export const doPreRecordCreateAction = (data = "") => {
  return {
    type: "DO_PRE_RECORD_CREATE",
    data,
  };
};

export const doPreRecordUpdateAction = (data = "") => {
  return {
    type: "DO_PRE_RECORD_UPDATE",
    data,
  };
};

export const fetchPreRecordDetailsAction = (data = "") => {
  return {
    type: "FETCH_PRE_RECORD_DETAILS",
    data,
  };
};

export const storePreRecordListAction = (data = "") => {
  return {
    type: "STORE_PRE_RECORD_LIST",
    data,
  };
};

export const doPreRecordStreamDeleteAction = (data = "") => {
  return {
    type: "DO_PRE_RECORD_STREAM_DELETE",
    data,
  };
};

export const doPreRecordStatusAction = (data = "") => {
  return {
    type: "STORE_PRE_RECORD_UPDATES",
    data,
  };
};

export const doPreRecordUploadingAction = (data = "") => {
  return {
    type: "STORE_PRE_RECORD_UPLOADING_DATA",
    data,
  };
};

export const storePreRecordSocialDetailsAction = (data = "") => {
  return {
    type: "STORE_PRE_RECORD_SOCIAL_DETAILS",
    data,
  };
};

export const doPreRecordStopAction = (data = "") => {
  return {
    type: "DO_PRE_RECORD_STOP",
    data,
  };
};

export const doPreRecordSimulcateAction = (data = "") => {
  return {
    type: "DO_PRE_RECORD_SIMULCAST",
    data,
  };
};

export const doPreRecordLiveSimulcateAction = (data = "") => {
  return {
    type: "DO_PRE_RECORD_LIVE_SIMULCAST",
    data,
  };
};
