import { failToast, serverNotRespond, succToast } from "../../helper/ApiToast";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { POST_SIMULCAST_MEDIA_LIST, SIMULCAST_MEDIA_LIST } from "../actionTypes/facebookTypes";
import { getSimulcastMediaListService, postSimulcastMediaService, removeSimulcastMediaService } from "../../services/simulcastService";
import store from "..";
import { mediaAccountSaveAction } from "../action/editStreamAction";

function* getSimulcastMediaListSaga(obj = {}) {
  yield put({ type: "DO_LOADING_PAGE", loading: true });
  try {
    console.log("getSimulcastMediaListSaga", obj);
    const response = yield call(getSimulcastMediaListService, obj?.data?.broadcast_id);
    const { data = {} } = response || {};
    console.log("getSimulcastMediaListSaga response", data);
    if (data.statusCode === 200) {
      yield put({ type: "GET_SIMULCAST_MEDIA_LIST", simulcastMediaList: data?.response });
      yield put({ type: "STORE_PRE_RECORD_SOCIAL_DETAILS", data: data?.response || [] });
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  } catch (error) {
    serverNotRespond();
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}
function* simulcastMediaService(apiService, payload) {
  // Get each call response and payload for further functionalyty
  const response = yield call(apiService, payload);
  return { response, payload };
}

function* postSimulcastMediaSaga(obj = {}) {
  yield put({ type: "MEDIA_ADDING_LOADER", addMediaLoader: true });
  try {
    const allPromises = [];
    // Media adding promises
    obj?.data?.addList?.forEach((item) => {
      allPromises.push(call(simulcastMediaService, postSimulcastMediaService, item));
    });
    // Media removing promisses
    obj?.data?.removableList?.forEach((item) => {
      if (!item?.connectedMedia?.id) return;
      allPromises.push(call(simulcastMediaService, removeSimulcastMediaService, item?.connectedMedia?.id));
    });
    // Call all APIs parallely and wait for every response
    const results = yield all(allPromises);

    // Check results for errors
    const errors = results.filter(result => result?.response?.data?.statusCode !== 200);
    if (errors.length) {
      errors.forEach(error => {
        const response = error?.response?.data;
        const payload = error?.payload;

        if (response?.statusCode !== 200 && payload?.item?.media_name && response?.message) {
          failToast(payload?.item?.media_name + ' : ' + response?.message, '', 5000);
        }
      });
    } else {
      // // obj?.data?.directGoLive === 2 && succToast('Saved Successfully'); // This toast remoed as per rakesh feedback on 25/07/24
      obj?.data?.directGoLive === 3 && succToast('Destination removed successfully');
    }

    // After all API calls, call list API to get updated list
    const response = yield call(getSimulcastMediaListService, obj?.data?.broadcastId || '');
    if (response?.data?.statusCode === 200) {
      yield put({ type: "GET_SIMULCAST_MEDIA_LIST", simulcastMediaList: response?.data?.response });
      // This one help to go live directly based on directGoLive value
      yield put({ type: "SET_READY_TO_GO_LIVE", readyToGoLive: obj?.data?.directGoLive || false });
      const callMediaLiveAPI = response?.data?.response?.filter((s) => Object.keys(s.connectedMedia)?.length);
      store.dispatch(mediaAccountSaveAction(callMediaLiveAPI?.length));
    }
  } catch (error) {
    serverNotRespond();
    yield put({ type: "MEDIA_ADDING_LOADER", addMediaLoader: false });
  }
  yield put({ type: "MEDIA_ADDING_LOADER", addMediaLoader: false });
}

export default function* paymentsSaga() {
  yield takeLatest(SIMULCAST_MEDIA_LIST, getSimulcastMediaListSaga);
  yield takeLatest(POST_SIMULCAST_MEDIA_LIST, postSimulcastMediaSaga);
}
