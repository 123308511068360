import { joinStage } from "../aws/ivsfunctions";
import { decodeRegistertoken } from "../helper/Encypt";
import {
  signUpdateTokenService,
  updateParticipantToken,
} from "../services/aswServices";
import { getSessionRequest } from "../aws/awsHelper";
import store from "../store";
import _get from "lodash/get";
import { addMeetData, awsGetTodayMeetData, isHostAction } from "../store/action/awsActions";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { getCustomerOrganisationList } from "../services/organisationServices";
import { currentOrganisation } from "../store/action/organisationAction";
import { getTeamMembers } from "../services/inviteMemberServices";
import { getCurrentOrgId, getCurrentUserId, isGuestLogin, isGuestUserId } from "../helper/utility";
import { passiveTeamMemberUpdate } from "../store/action/inviteMemberAction";
import { getDeleteStageService } from "../services/deleteServices";
import { getParticipantListAction } from "../store/action/participantAction";
import { mfGroupDetailsAction } from "../store/action/mfChatAction";
import mixpanel from "mixpanel-browser";
import { envPath } from "../helper/ApiUrl";
import timezones from "timezones.json";
import { serverNotRespond } from "../helper/ApiToast";
import { constantValue } from "../const/errorTypes";
import { SDK } from "mf-chat-uikit/dist";
import { getSessionDetails } from "../services/guestService";

export const isOffline = () => {
  return !navigator.onLine;
};

export const isDeveloper = (text = '') => {
  if (envPath === "dev") {
    if (typeof text === 'string') return `<p>${text}</p>`;
    if (typeof text === 'object') return `<pre>${JSON.stringify(text, undefined, 2)}</pre>`;
  }
  return '';
};

export const checkOffline = () => {
  if (!navigator.onLine) {
    serverNotRespond(constantValue.INTERNET_ERROR);
    return true;
  }
  return false;
};

/**
 * string is html or not find out
 * @param {string} str
 */
export const isHTML = (str = "") => {
  return str.includes("</");
};
export const handleDropFilters = (ele = "") => {
  const handleDrop = ele && ele?.target?.querySelector(".custom-dropdown");
  return handleDrop?.click();
};
export const handleDropFiltersIcon = (ele = "") => {
  const handleDropIcon = ele && ele?.target?.closest(".dropFilter");
  return handleDropIcon?.click();
};

export const isEllipsisActive = (element) => {
  return element.offsetWidth < element.scrollWidth;
};
export const handleEllipsis = (SelectorAll) => {
  Array.from(document.querySelectorAll(SelectorAll)).forEach((element) => {
    if (isEllipsisActive(element)) {
      element.title = element.innerText;
    }
  });
};
export const validateTitle = (title) => {
  return title.replaceAll(" ", "_");
};

export const removeUnderscoreFromPrefix = (title) => {
  return title.replace(/^_+/, "");
};

export const validateBothUnderscoreAndSpace = (title) => {
  const titleSplit = title.split(" ");
  let toSpace = [];
  titleSplit.forEach((ele) => {
    toSpace = [...toSpace, ele.replaceAll("_", " ")];
    return toSpace;
  });
  return toSpace.join("_");
};
export const validateUnderscore = (title = '') => {
  return title?.replaceAll("_", " ");
};
export const validateGetDataUnderscore = (getData) => {
  return getData.map((ele) => {
    return {
      ...ele,
      channelDetails: ele?.channelDetails
        ? {
          ...ele.channelDetails,
          stageName: validateUnderscore(ele?.channelDetails?.stageName),
        }
        : null,
      sessionName: validateUnderscore(ele?.sessionName),
    };
  });
};
export const isEmptyObject = (obj = {}) => {
  const isEmptyObj = Object.keys(obj).length > 0;
  return !isEmptyObj;
};

export const localParticipantId = (participantData = []) => {
  return participantData.filter((ele) => ele.isLocal === true)[0]?.id;
};

export const localParticipant = (participantData = []) => {
  return participantData.filter((ele) => ele.isLocal === true)[0];
};

export const updateToken = async (stageData, muteStatus) => {
  const { stageArn = "", userId = "", orgId = "" } = stageData;
  const updatedArray = [
    {
      orgId: orgId,
      participantUserId: userId,
      stageArn: stageArn,
    },
  ];
  const response = await signUpdateTokenService({
    updatedArray: updatedArray,
    addFromBroadcast: false,
  });
  const newParticipantId =
    response?.participantsTokensResponse[0]?.participantId;
  const newParticipantToken = response?.participantsTokensResponse[0]?.token;

  if (newParticipantId && newParticipantToken) {
    const constructedData = {
      ...stageData,
      participantId: newParticipantId,
      participantToken: newParticipantToken,
    };
    await joinStage(constructedData, muteStatus);
  }
};

export const signUpUpdateToken = async (
  stageData = [],
  userId = 0,
  jwt = ""
) => {
  const newObj = {
    ...stageData[0],
    participantUserId: userId,
  };
  const updatedArray = [newObj];

  await signUpdateTokenService({
    updatedArray: updatedArray,
    jwt: jwt,
    addFromBroadcast: false,
  });
};

export const constructInvites = async (inviteArray, stageArn) => {
  const {
    data: { data: teamMembersDetails = [] },
  } = await getTeamMembers(getCurrentOrgId());
  const inviteMemberListReducer = teamMembersDetails?.teamMembersDetails;
  const getMemberId = inviteArray.map(ele => {
    if (ele?.userId) {
      return ele;
    } else {
      const elementUserId = inviteMemberListReducer.find(element => element?.emailId === ele?.emailId)?.userId;
      return {
        ...ele, userId: elementUserId
      };
    }
  });
  const regArray = getMemberId
    .filter((ele) => ele?.userId)
    .map((el) => {
      return {
        participantUserId: el?.userId,
        orgId: getCurrentOrgId(),
        stageArn: stageArn,
      };
    });
  const unregArray = getMemberId
    .filter((ele) => !ele?.userId)
    .map((el) => {
      return el?.emailId;
    });
  return { regArray, unregArray };
};

export const updateCreateParticipantToken = async (
  userData = [],
  stageArn = ""
) => {

  await updateParticipantToken({
    updatedArray: userData,
    addFromBroadcast: true,
    invitedMailIds: [userData[0].emailId],
    stageArn: stageArn,
  });
};

export const setCurrentOrg = async (org = 0) => {
  if (org !== 0) {
    const response = await getCustomerOrganisationList(); //NOSONAR not used in secure contexts
    const currentOrg = response?.data?.data?.organisations?.filter((ele) => ele.organisationId === org)[0];
    if (currentOrg) {
      window.localStorage.setItem("currntOrgid", JSON.stringify(currentOrg));
      store.dispatch(currentOrganisation(currentOrg));
    }
  }
};

export const isEmailValidateToken = (search) => {
  const str = search.substring(1);
  try {
    window.atob(str);
    const decodedStringAtoB = atob(str); //decode the base64 token
    const obj = decodeRegistertoken(decodedStringAtoB);
    return obj?.emailValidateToken?.length > 0 || obj?.userEmailId?.length > 0;
  } catch (e) {
    return false;
  }
};

export const decodeQueryString = (urlString) => {
  const str = urlString.substring(1);
  try {
    window.atob(str);
    const decodedStringAtoB = atob(str); //decode the base64 token
    const obj = decodeRegistertoken(decodedStringAtoB);
    return obj || {};
  } catch (e) {
    return {};
  }
};

export const isvalidToken = (str) => {
  try {
    window.atob(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const addCreateMeet = () => {
  const awsStageData = store.getState()?.awsStageReducer;
  if (awsStageData?.method === "create") {
    const constructedRequestData = getSessionRequest(awsStageData);
    store.dispatch(addMeetData(constructedRequestData));
  }
};

export const constructInviteData = (obj, apiData) => {
  const { data = [], hostDetails = {} } = obj;
  const { data: userId } = apiData;
  return {
    fullName: null,
    userId: userId && parseInt(userId.slice(1, -1)),
    organisationId: hostDetails?.currentOrgDetails?.organisationId,
    isActive: userId ? 1 : 0,
    countryCode: null,
    countryCodeShort: null,
    userRoleId: data[0]?.inviteUserRoleId,
    createdAt: Date.now(),
    emailVerified: null,
    emailId: data[0]?.inviteUserEmail,
    phoneNumber: null,
    profileImage: null,
  };
};
export const navigateBroadcast = () => {
  return <Redirect to={"/broadcast"} />;
};

export const clearLocalStorage = () => {
  window.localStorage.clear();
};

export const destructStage = (stageArn) => {
  return stageArn?.includes(":") ? stageArn?.split(":")[5] : stageArn;
};

export const destructStageId = (stageArn) => {
  return stageArn?.split("/")[1];
};

export const getUserListNameOld = (userId) => {
  if (userId && !isGuestUserId(userId)) {
    const invitedList = store.getState()?.inviteMemberListReducer?.inviteMember;
    const userData = invitedList.find(
      (ele) => ele.userId === parseInt(userId)
    );
    const displayName = localStorage.getItem("dispalyName")
    const loginDetail = window.localStorage.getItem("userDetails")
    ? JSON.parse(window.localStorage.getItem("userDetails")) : {};
    const host = store.getState()?.awsStageReducer?.method !== "create"
    const hostlogin = host && (userData?.userId === loginDetail.data.userId)
    const displayusername = hostlogin ? displayName : userData?.fullName
      return displayusername
  } else if (isGuestUserId(userId)) {
    return userId.split("_")[0].replaceAll("-", " ");
  }
};

export const getUserListName = async (userId) => {
  if (typeof (userId) !== "string") {
    const searchParam = window?.location?.search.replace("?", "");
     const responseSession = await getSessionDetails(searchParam);
    if (isGuestLogin()) {
      const fbInBroadcastParticipantsName = store.getState()?.broadcastBranding?.inBroadcastParticipantsName;
      const fbparticipants = (fbInBroadcastParticipantsName !== undefined) ?
      fbInBroadcastParticipantsName?.find(ele => ele.split("_")[0] == userId).split("_")[1].replaceAll("-", " ")
      : responseSession.stageDetails.displayName;
      return fbparticipants;
    }
    const invitedList = store.getState()?.inviteMemberListReducer?.inviteMember;
    const userData = invitedList.find((ele) => ele.userId === parseInt(userId));
    if((responseSession?.stageDetails?.hostId) === (userData?.userId)){
      const updatedData = responseSession.stageDetails.displayName !== "" ? responseSession.stageDetails.displayName : userData?.fullName
      localStorage.setItem("dispalyName",updatedData)
      return updatedData;
    }else{
      const { data: memberResponse } = await getTeamMembers(getCurrentOrgId());
        if (memberResponse?.status === 200) {
          const newInvitedList = memberResponse?.data?.teamMembersDetails;
          store.dispatch(passiveTeamMemberUpdate(newInvitedList));
          const newUserData = newInvitedList?.find(
            (ele) => ele.userId === parseInt(userId)
          );
          return newUserData?.fullName || "User 2";
        }
    }
  } else {
    return userId?.split("_")[0].replaceAll("-", " ");
  }
};

export const getUserEmail = async (userId) => {
  const invitedList = store.getState()?.inviteMemberListReducer?.inviteMember;
  const userData = invitedList.find((ele) => ele.userId === parseInt(userId));
  if (userData?.fullName) {
    return userData;
  } else {
    const { data: memberResponse } = await getTeamMembers(getCurrentOrgId());
    if (memberResponse?.status === 200) {
      const newInvitedList = memberResponse?.data?.teamMembersDetails;
      store.dispatch(passiveTeamMemberUpdate(newInvitedList));
      const newUserData = newInvitedList?.find(
        (ele) => ele.userId === parseInt(userId)
      );
      return newUserData;
    }
  }
};

/**
 * @param  {array} array=[]
 * for delete purpose seletect item id find and remove undefined
 */
export const VideoTableSelectedUrlItem = (array = []) => {
  return (array || [])
    .map((ele, index) => {
      if (ele.isCheck) {
        return {
          url: _get(ele, "downloadUrl", ""),
          name: `${ele.channelName}_rec_${index + 1}.mp4`
        };
      }
      return undefined;
    })
    .filter((notUndefined) => notUndefined !== undefined);
};

/**
 * @param  {array} array=[]
 * for delete purpose seletect item id find and remove undefined
 */
export const cusTableSelectedDeleteItem = (array = []) => {
  return (array || [])
    .map((ele) => {
      if (ele.isCheck) {
        return _get(ele, "id", "");
      }
      return undefined;
    })
    .filter((notUndefined) => notUndefined !== undefined);
};
export const cusTableSelectedDeleteItemTeams = (array = []) => {
  return (array || [])
    .map((ele) => {
      if (ele.isCheck) {
        return _get(ele, "userId", "");
      }
      return undefined;
    })
    .filter((notUndefined) => notUndefined !== undefined);
};

export const getDeleteStageResponse = async (stage = '') => {
  const apiResponse = await getDeleteStageService(stage); //NOSONAR not used in secure contexts
  store.dispatch(mfGroupDetailsAction(apiResponse?.data?.data?.stageDetails?.sessionJid));
  return apiResponse?.data;
};

export const getParticipantApi = (obj = {}) => {
  const { data: { stageArn = "" } = {} } = obj;
  store.dispatch(getParticipantListAction(stageArn));
};

export const closeScreenShareAlert = () => {
  const alertPopup = document.querySelector('.screen-share-dialog');
  alertPopup?.close();
}

export const handleMixpanelTrack = (trackingName, trackingData) => {
  if (envPath === 'prod') {
    return mixpanel.track(trackingName, trackingData);
  }
  return false;
}

export const leftHelper = (participant) => {
  const fbHostId = store.getState()?.broadcastBranding?.host;
  if (fbHostId === participant?.userId) {
    store.dispatch(isHostAction(true));
  }
};

export const addHelper = (participant) => {
  const fbHostId = store.getState()?.broadcastBranding?.host;
  if (fbHostId === participant?.userId) {
    store.dispatch(isHostAction(false));
  }
};

export const getCurrentTimeZoneAbb = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timezones.find(ele => ele?.utc.includes(timeZone))?.abbr;
};

export const superAdminLandingScreen = '/analytics';
export const customerLandingScreen = '/broadcast';

export const addTodayMeetData = () => {
  const data = {
    userId: getCurrentUserId(),
    orgId: getCurrentOrgId(),
    searchData: "",
    position: "Today",
  };
  store.dispatch(awsGetTodayMeetData(data));
};

export const getQueryString = (href = '') => {
  const url = new URL(href);
  const queryParams = Object.fromEntries(url.searchParams.entries());
  let qs = '';
  if (Object.keys(queryParams).length) {
    if (queryParams.planid) qs = qs.concat(`planid=${queryParams.planid}&`);
    if (queryParams.mode) qs = qs.concat(`mode=${queryParams.mode}&`);
  }
  return qs ? '?' + qs : '';
};

export const trackHubspotEvent = (eventName, eventProperties) => {
  try {
    _hsq?.push([
      'trackCustomBehavioralEvent',
      {
        name: eventName,
        properties: eventProperties || {}
      },
    ]);
  } catch (error) {
    console.error('Error while initialize hubspot', error);
  }
};

export const convertMaxNumbers = (value) => {
  if (value >= 1000000) {
    const strMillion = (value / 1000000) + "M";
    return strMillion.match(/[a-z]+|[^a-z]+/gi);
  } else if (value >= 1000) {
    const strThousand = (value / 1000) + "K";
    return strThousand.match(/[a-z]+|[^a-z]+/gi);
  }
  return value || 0;
};

export const userType = () => {
  const currentOrgId = window.localStorage.getItem("currntOrgid")
    ? JSON.parse(window.localStorage.getItem("currntOrgid"))
    : {};
  if (currentOrgId === "") {
    return {};
  } else {
    return currentOrgId?.planId;
  }
}

export const isFreeuser = () => {
  return (userType() && userType() > 1) ? false : true;
};

export const msToTime = (duration) => {
  let seconds = parseInt((duration / 1000) % 60)
    , minutes = parseInt((duration / (1000 * 60)) % 60)
    , hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds;
};

export const isGuestUser = (data) => {
  const currentOrgId = window.localStorage.getItem("currntOrgid")
    ? JSON.parse(window.localStorage.getItem("currntOrgid"))?.organisationId
    : "";
  if (data === "") {
    return false;
  } else {
    return currentOrgId !== parseInt(data?.participantTokensResponse?.attributesDto?.orgId);
  }
}

const isAllInCallPArticipantPresent = (inStage, inCall) => {
  let allPresent = true;
  inStage.forEach(ele => {
    allPresent = allPresent && (inCall.includes(ele));
  });
  return allPresent;
}

export const isAddToStage = (allowedPart, inBroadcastPart = [], inCallParticipnat = []) => {
  if ((allowedPart > inBroadcastPart.length) ||
    (allowedPart === inBroadcastPart.length && !isAllInCallPArticipantPresent(inBroadcastPart.filter(ele => ele !== getCurrentUserId()), inCallParticipnat))) {
    return true;
  }
}

export const getIncallAndStageUserList = (allowedPart, inStage = [], inCall = []) => {
  if (allowedPart === inStage.length) {
    return inStage.filter(ele => ele === getCurrentUserId() || inCall.includes(ele));
  } else {
    return inStage;
  }
}

export const addGuestChat = async (groupJid, participantData) => {
  let userJidDetails;
  const groupDetails = await SDK.getGroupParticipants(groupJid);
  const participantList = groupDetails?.data?.participants || [];
  if (isGuestUserId(participantData)) {
    userJidDetails = await SDK.getJid(participantData.toLowerCase())?.userJid || "";
    console.log(userJidDetails, "addGuestChat---111")
  } else {
    const userData = await getUserEmail(participantData)
    console.log(userData, "addGuestChat")

    const firstName = userData?.emailId;
    const userId = firstName.replace(/[^A-Z0-9]/gi, "").toLowerCase();
    console.log(userId, ">>groupDetails<<--userId")
    userJidDetails = await SDK.getJid(userId)?.userJid || "";
  }

  console.log(userJidDetails, participantList, ">>groupDetails<<")
  const uJID = [];
  uJID.push(userJidDetails);
  const sessionName = store.getState()?.awsStageReducer?.sessionName;
  if (!participantList.some(ele => ele?.userId === participantData) && userJidDetails) {
    const res1 = await SDK.addParticipants(groupJid, sessionName, uJID);
    console.log(res1, ">>groupDetails<<222");
  }
  const res = await SDK.getGroupParticipants(groupJid);
  console.log(res, ">>groupDetails<<111");
}

export const getGuestCommentTokens = () => {
  const accessToken = window.localStorage.getItem("userData");
  const refreshToken = window.localStorage.getItem("userData");
  return { accessToken, refreshToken };
};

export const isMatchingUrl = () => {
  const currentUrl = window.location.href;
  const targetUrl = window.localStorage.getItem("guestCurrentStreamUrl") || "";
  if(targetUrl) {
    return currentUrl === targetUrl;
  }
  return true;
};

export const isMobileStream = (videoObject) => {
  const constraints = videoObject?.getSettings();
  return (constraints?.height >= constraints?.width);
};
