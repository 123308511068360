import { serverNotRespond } from "../../helper/ApiToast";
import { call, put, takeLatest } from "redux-saga/effects";
import { DO_GET_CUSTOMER_LIST, DO_GET_BROADCAST_COUNT_LIST } from "../actionTypes/customerType";
import { customerListRequest, broadcastCountListRequest } from "../../services/customerListServices";

function* doCustomerList(obj = {}) {
  try {
    const customerList = yield call(customerListRequest, obj);
    const { data = {} } = customerList || {};
    if (Object.keys(data).length === 0) {
      yield put({ type: "GET_CUSTOMER_LIST_REDUCER", customerListData: [] });
    } else if (data.status === 200) {
      yield put({ type: "GET_CUSTOMER_LIST_REDUCER", customerListData: data });
    } else {
      yield put({ type: "GET_CUSTOMER_LIST_REDUCER", customerListData: [] });
      serverNotRespond();
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  } catch (error) {
    serverNotRespond();
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

function* doBroadCastCountList(obj = {}) {
  try {
    const analyticsResponses = yield call(broadcastCountListRequest, obj);
    const { data = {} } = analyticsResponses || {};
    if (data.status === 200) {
      yield put({ type: "GET_BROADCAST_COUNT_LIST_REDUCER", analyticsResponses: data });
    }
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  } catch (error) {
    serverNotRespond();
    yield put({ type: "DO_LOADING_PAGE", loading: false });
  }
}

export default function* CustomerListSaga() {
  yield takeLatest(DO_GET_CUSTOMER_LIST, doCustomerList);
  yield takeLatest(DO_GET_BROADCAST_COUNT_LIST, doBroadCastCountList);
}
