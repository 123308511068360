import {
    FETCH_COMMENTS_REQUEST,
    FETCH_COMMENTS_SUCCESS,
    FETCH_COMMENTS_FAILURE,
    POST_COMMENT_REQUEST,
    SET_GUEST_COMMENTS_ACCESS,
    CLEAR_GUEST_COMMENTS_ACCESS,
    UPDATE_STARRED_COUNT,
    POST_COMMENT_STATUS,
    RESET_COMMENTS
  } from '../actionTypes/commentsTypes';

  export const fetchCommentsRequest = (commentsConfig) => ({
    type: FETCH_COMMENTS_REQUEST,
    payload: commentsConfig,
  });

  export const fetchCommentsSuccess = (comments) => ({
    type: FETCH_COMMENTS_SUCCESS,
    payload: comments,
  });

  export const fetchCommentsFailure = (error) => ({
    type: FETCH_COMMENTS_FAILURE,
    payload: error,
  });

  export const postCommentRequest = (comment) => (
    {
    type: POST_COMMENT_REQUEST,
    payload: comment,
  });

  export const setGuestCommentAccess = () => ({
    type: SET_GUEST_COMMENTS_ACCESS,
  });

  export const clearGuestCommentAccess = () => ({
    type: CLEAR_GUEST_COMMENTS_ACCESS,
  });

  export const updateStarredCount = (count) => ({
    type: UPDATE_STARRED_COUNT,
    payload: count,
  });

  export const updateCommentPostStatus = (status) => ({
    type: POST_COMMENT_STATUS,
    status,
  });

  export const resetComments = () => ({
    type: RESET_COMMENTS
  })
