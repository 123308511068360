import React from 'react';
import _get from "lodash/get";
import './TableSearchInput.scss';
import { IconClose } from '../../../../assets/images';
import { IconSearch } from '../../../../assets/otf';

const TableSearchInput = (props = {}) => {
    const {
        value = "",
        id = "",
        type = "",
        name = "",
        _class = "",
        _isFocusOnInput = false,
        _icon = <IconSearch />,
        _onKeyPress,
        parentClass = "",
        placeholder = "",
        _maxLength = 1000,
        _onBlur = () => { },//click function
        onInput = () => { },//click function
        handleInputboxClear = () => { },//click function
        handleSetSearchInput = () => { },//click function
    } = props;

    /**
    * @param  {object} {event} -
    * Teams and customer page all search input fields Handle Here
    **/
    const handleSearchInputField = (event = {}) => {
        const { name: inputName, value: inputValue } = _get(event, "target", {});
        const eventValue = {
            target: {
                name: inputName,
                value: inputValue,
            }
        };
        handleSetSearchInput(eventValue);
    };

    // space restricted from begining
    const handleRestrictSpace = (e) => {
        if (e.which === 32 && !e.target.value.length)
            e.preventDefault();
    };

    return (
        <React.Fragment>
            <div className={`${parentClass ? parentClass : ""} table_search`}>
                <label htmlFor={id ? id : name} className='table_search_icon'>
                    {_icon}
                </label>
                <input className={`table_search_input ${_isFocusOnInput && value !== "" ? " focused " : " "} ${_class}  ${value ? "active" : ""}`}
                    type={type} //input Type
                    name={name} //name
                    value={value} //value
                    id={id ? id : name} //when id is come appen otherwise name append
                    autoComplete={"off"}
                    maxLength={_maxLength || 1000} //maxLength is missing will append 1000 char
                    onBlur={_onBlur ? _onBlur : null} //onblur call
                    onInput={onInput ? onInput : null}
                    onKeyPress={_onKeyPress ? (e) => { _onKeyPress(e); handleRestrictSpace(); } : handleRestrictSpace}
                    onChange={(e) => handleSearchInputField(e)}
                    placeholder={placeholder}
                />
                {value !== "" &&
                    <button type='button' id="#jestResetSearch" className="table_search_reset"
                        onClick={handleInputboxClear}
                    >
                        <IconClose />
                    </button>
                }
            </div>
        </React.Fragment>
    );
};
export default React.memo(TableSearchInput);
