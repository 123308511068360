import React from 'react';
import Modal from ".";

function Modal5(props) {
    const { children } = props;
    return (
        <Modal id="modal5" >
            {children}
        </Modal>
    );
}

export default Modal5;
