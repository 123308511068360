import { encodeGetParams } from "../helper/Encypt";
import { Get, Post } from "../common/httpRestServices";
import { apiUrl, preRecordAPI, serverStudioApi } from "../helper/ApiUrl";

export const fetchPreRecordService = (data = {}) => {
  const params = encodeGetParams(data);
  return Get(`${apiUrl}${preRecordAPI.preRecordList}${params}`, {}, true);
};

export const doPreRecordCreate = (data = {}) => {
  const bodyParams = data;
  return Post(`${apiUrl}${preRecordAPI.preRecordCreate}`, bodyParams, true);
};

export const doPreRecordUpdate = (data = {}) => {
  const bodyParams = data;
  return Post(`${apiUrl}${preRecordAPI.preRecordUpdate}`, bodyParams, true);
};

export const fetchPreRecordDetails = (data = {}) => {
  const params = encodeGetParams(data);
  return Get(`${apiUrl}${preRecordAPI.preRecordDetails}${params}`, {}, true);
};

export const doPreRecordDelete = (data = {}) => {
  const bodyParams = data;
  return Post(`${apiUrl}${preRecordAPI.preRecordDelete}`, bodyParams, true);
};

export const doPreRecordStreamDelete = (data = {}) => {
  const bodyParams = data;
  return Post(`${apiUrl}${preRecordAPI.preRecordStreamDelete}`, bodyParams, true);
};

export const doPreRecordStreamStop = (data = {}) => {
  const bodyParams = data;
  return Post(`${apiUrl}${preRecordAPI.preRecordStreamStop}`, bodyParams, true);
};

export const stopStreamService = (data = {}) => {
  const bodyParams = data;
  return Post(`${apiUrl}${serverStudioApi.stopStream}`, bodyParams, true);
};

export const serverStreamSimulcastService = (data = {}) => {
  const bodyParams = data;
  return Post(`${apiUrl}${serverStudioApi.simulcastStream}`, bodyParams, true);
};

export const serverStreamLiveSimulcastService = (data = {}) => {
  const bodyParams = data;
  return Post(`${apiUrl}${preRecordAPI.preRecordSimulcate}`, bodyParams, true);
};
