import React from 'react';
import Modal from '.';

function Modal1(props) {
    const { children } = props;
    return (
        <Modal id="modal1" >
            {children}
        </Modal>
    );
}

export default Modal1;
