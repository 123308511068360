import React, { useEffect } from "react";
import "./Registration.scss";
import FeatureHighlights from "./FeatureHighlights";
import OtpSuccess from "./OtpSuccess";
import Logoheader from "./Logoheader";
import { getStripPaymentSession } from "../MainContent/Payments";
import { getCustomerDetails } from "../../store/action/customeAction";
import { doLogin } from "../../store/action/loginAction";
import { useDispatch, useSelector } from "react-redux";

const SuccessOtp = (props = {}) => {
  const url      = new URL(window.location.href);
  const queryParams = Object.fromEntries(url.searchParams.entries());
  const globalStore  = useSelector(_ => _);
  const { CusPage : { customerDtls = {} } } = globalStore;

  const dispatch = useDispatch();
  useEffect(() => {
    const emailId = localStorage.getItem('regPageEmail');
    const pwd = localStorage.getItem('regpwd');

    const loginAndFetchData = async () => {
      try {
        if (!Object.keys(customerDtls)?.length) {
          // Perform login
          dispatch(doLogin({
            email: emailId,
            password: pwd,
            ssoType: '',
          }));
        } else if (queryParams.planid) {
          // Fetch customer details
          getCustomerDetails();
          // Fetch Stripe payment session
          await getStripPaymentSession(
            +queryParams.planid,
            queryParams.mode === 'm' ? 'monthly' : 'yearly',
            'webRegister'
          );
        } else {
          window.location.href = '/';
        }
      } catch (error) {
        console.error("Error in login or fetching data:", error);
        // Handle error
      }
    };

    setTimeout(() => {
      loginAndFetchData();
    }, 3000);
  }, [dispatch, queryParams.planid, queryParams.mode, customerDtls]);

  useEffect(() => {
    setTimeout(() => {
      // Redirect to home page if this page stuck more than 20 seconds
      window.location.href = '/';
    }, 20000);
  });

  return (
    <React.Fragment>
      <div id="RegistrationWraper" className={`RegistrationWraper RegistrationPage Custom`}>
        <Logoheader />
        <div className="Container_lg">
          <div id="Registration_wraper" className={`Registration_wraper `}>
            <div id="RegistrationForm" className="RegistrationForm">
              <div style={{ paddingBottom: " 44px " }} className={`right-side`}>
                <div className='otp_verify_wraper'>
                  <OtpSuccess />
                </div>
              </div>
            </div>
            <FeatureHighlights />
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};
export default React.memo(SuccessOtp);
