export const initStateCommon = {
    ssoResponseDetails: {},
    selectedValue: new Date().toLocaleString('default', { month: 'long' }),
    editStreambutton: false,
    bgImages: [],
    quickOverview: {},
    loading: false,
    isMediaConnected: false,
    popUpType: '',
    destinationPopupPreviousState: false,
    recentlyAddedMediaId: '',
    newMediaConnectionType: '',
    setAsInvisibleUser: false,
};

export const commonDataReducer = (state = initStateCommon, action = {}) => {
    switch (action.type) {
        case 'INITIAL_STAGE_COMMON_REDUCER': {
            return {
                ...state,
                ...initStateCommon
            };
        }

        case 'SSO_LOGIN_RESPONSE_DETAILS': {
            return {
                ...state,
                ssoResponseDetails: action.ssoResponseDetails,
            };
        }
        case 'DASHBOARD_QUICK_OVERVIEW_DATA': {
            return {
                ...state,
                quickOverview: action.quickOverview,
            };
        }
        case 'DASHBOARD_RECENT_ACTIVITY_LIST': {
            return {
                ...state,
                recentCustomers: action.recentCustomers,
            };
        }
        case 'DASHBOARD_LOADER': {
            return {
                ...state,
                loading: action.loading,
            };
        }
        case 'EDIT_STREAM': {
            return {
                ...state,
                editStreambutton: action.data
            };
        }
        case 'EDIT_POPUPSTREAM': {
            return {
                ...state,
                editStreamPopup: action.data
            };
        }
        case 'INTEGRATED_POPUP': {
            return {
                ...state,
                integratedPopup: action.data
            };
        }
        case 'ACCESS_TOKEN': {
            return {
                ...state,
                getAccessToken: action.data
            };
        }
        case 'START_YT_LOADER': {
            return {
                ...state,
                startYTLoader: action.data
            };
        }
        case 'END_YT_LOADER': {
            return {
                ...state,
                endYTLoader: action.data
            };
        }
        case 'EDIT_STREAM_SAVE': {
            return {
                ...state,
                editStreamSave: action.data
            };
        }
        case 'MF_GROUP_DETAILS': {
            return {
                ...state,
                mfGroupDetails: action.data
            };
        }
        case 'DELETE_SOCIAL_ACCOUNT': {
            return {
                ...state,
                deleteSocialAccount: action.data
            };
        }
        case 'RECONNECT_SOCIAL_ACCOUNT': {
            return {
                ...state,
                reconnectAccount: action.data
            };
        }
        case 'TIMER_POPUP': {
            return {
                ...state,
                timerPopup: action.data
            };
        }
        case 'UPDATE_BACKGROUND_IMAGES': {
            return {
                ...state,
                bgImages: action.bgImages,
            };
        }
        case 'UPDATE_OVERLAY_IMAGES': {
            return {
                ...state,
                overlayImages: action.overlayImages,
            };
        }
        case 'MEDIA_ACCOUNT_SAVE': {
            return {
                ...state,
                mediaAccountSave: action.data,
            };
        }
        case 'YT_GOLIVE_STATE': {
            return {
                ...state,
                ytGoLiveState: action.data
            };
        }
        case 'MEDIA_CONNECTION_UPDATE': {
            return {
                ...state,
                mediaConnectionUpdate: action.data,
            };
        }
        case 'SET_SHOW_POPUP_TYPE': {
            return {
                ...state,
                popUpType: action.data,
                newMediaConnectionType: action.data,
            };
        }
        case 'SET_DESTINATION_POPUP_PREVIOUS_STATE': {
            return {
                ...state,
                destinationPopupPreviousState: action.data,
            };
        }
        case 'RECENTLY_ADDED_SOCIAL_MEDIA': {
            return {
                ...state,
                recentlyAddedMediaId: action.data.mediaId,
            };
        }
        case 'SET_CONNECTED_MEDIAS': {
            return {
                ...state,
                isMediaConnected: action.isMediaConnected
            };
        }
        case 'PLAN_UPGRADE_POPUP': {
            return {
                ...state,
                planUpgradePopupStatus: action.status
            };
        }
        case 'PLAN_UPGRADE_ALERT_POPUP': {
            return {
                ...state,
                planUpgradeAlertPopupStatus: action.status
            };
        }
        case 'STREAMING_ACTIVE_TAB': {
            return {
                ...state,
                streamingActiveTab: action.tab
            };
        }
        case "SET_AS_INVISIBLE_USER": {
            return {
                ...state,
                setAsInvisibleUser: true,
            };
        }
        default:
            return {
                ...state
            };
    }
};
