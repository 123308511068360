import { removeUnderscoreFromPrefix, validateBothUnderscoreAndSpace } from "../common/helper";
import { Delete, Get } from "../common/httpRestServices";
import { apiUrl, preRecordAPI, videoTableList } from "../helper/ApiUrl";

export const videoTableListRequest = (obj = {}) => {
  const { searchdata: { size = 10, page = 1, searchTerm = "", user_id = '' } = {} } = obj;
  return Get(
    `${apiUrl}${preRecordAPI.preRecordList}org_id=${obj.data.organisationId}&limit=${size}&page=${page}
    &searchTerm=${encodeURIComponent(removeUnderscoreFromPrefix(validateBothUnderscoreAndSpace(searchTerm)))}&video_type=2&user_id=${user_id}`,
    true
  );
};

export const videoRecordListRequest = (obj = {}) => {
  const { data: { orgId = 0, channleArn = "" } = {} } = obj;
  return Get(
    `${apiUrl}${videoTableList.getVideoRecordList}channelArn=${channleArn}&orgId=${orgId}`,
    true
  );
};
export const videoRecordedDeleteRequest = (obj = {}) => {
  const { data: { keyPrefix = "", channleArn = "" } = {} } = obj;
  return Delete(
    `${apiUrl}${videoTableList.deleteRecordedVideo}channelArn=${channleArn}&keyPrefix=${keyPrefix}`,
    true
  );
};
