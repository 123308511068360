export const EDIT_STREAM = "EDIT_STREAM";
export const EDIT_POPUPSTREAM = "EDIT_POPUPSTREAM";
export const INTEGRATED_POPUP = "INTEGRATED_POPUP";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const START_YT_LOADER = "START_YT_LOADER";
export const END_YT_LOADER = "END_YT_LOADER";
export const EDIT_STREAM_SAVE = "EDIT_STREAM_SAVE";
export const DELETE_SOCIAL_ACCOUNT = "DELETE_SOCIAL_ACCOUNT";
export const RECONNECT_SOCIAL_ACCOUNT = "RECONNECT_SOCIAL_ACCOUNT";
export const TIMER_POPUP = "TIMER_POPUP";
export const UPDATE_BACKGROUND_IMAGES = "UPDATE_BACKGROUND_IMAGES";
export const MEDIA_ACCOUNT_SAVE = "MEDIA_ACCOUNT_SAVE";
export const MEDIA_CONNECTION_UPDATE = "MEDIA_CONNECTION_UPDATE";
export const YT_GOLIVE_STATE = "YT_GOLIVE_STATE";
export const SET_SHOW_POPUP_TYPE = "SET_SHOW_POPUP_TYPE";
