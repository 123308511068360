import { firebaseRealtimeDb } from "./config";
import { onValue, ref, set, off, query, orderByChild, equalTo, get, update } from "firebase/database";
import { destructStage, destructStageId } from "../common/helper";
import store from "../store";
import { updateBranding, updateLogo } from "../store/action/awsActions";
import { currentStageArrn, getCurrentOrgId, getCurrentUserId } from "../helper/utility";
import { eventChannel } from 'redux-saga';

export const writeUserData = (orgId, stageArn, brandData) => {
  console.log(orgId, stageArn, brandData, "writeUserData")
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(destructStage(stageArn))}/brandData`
    ),
    brandData
  );
};

export const updateLayoutData = (orgId, stageArn, layout) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(destructStage(stageArn))}/brandData/layout`
    ),
    layout
  );
};

export const updateBannerText = (orgId, stageArn, bannerText) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/bannerText`
    ),
    bannerText
  );
};

export const updateBannerBackground = (orgId, stageArn, bannerBackground) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(destructStage(stageArn))}/brandData/bannerBackground`
    ),
    bannerBackground
  );
};

export const updateParticipantList = (orgId, stageArn, removeStatus) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/removeParticipant`
    ),
    removeStatus
  );
};
export const updateBannerTopOverlay = (orgId, stageArn, bannerTopOverlay) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/bannerTopOverlay`
    ),
    bannerTopOverlay
  );
};
export const updateBannerBgColor = (orgId, stageArn, bannerBgColor) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/bannerBgColor`
    ),
    bannerBgColor
  );
};

export const updateBannerTextColor = (orgId, stageArn, bannerTextColor) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/bannerTextColor`
    ),
    bannerTextColor
  );
};

export const updateBannerStyle = (orgId, stageArn, bannerStyle) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/bannerStyle`
    ),
    bannerStyle
  );
};

export const updateEnableBanner = (orgId, stageArn, bannerStatus) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/bannerStatus`
    ),
    bannerStatus
  );
};

export const updateLogoRight = (orgId, stageArn, isLogoRight) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/isLogoRight`
    ),
    isLogoRight
  );
};

export const updateGoLive = (orgId, stageArn, liveStatus) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/goliveStatus`
    ),
    liveStatus
  );
};
export const updateWarningTime = (orgId, stageArn, waringStatus) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/callWaringTime`
    ),
    waringStatus
  );
};
export const updateEndTime = (orgId, stageArn, callEndStatus) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/callEndTime`
    ),
    callEndStatus
  );
};
export const updateCallStartTime = (orgId, stageArn, callStartTime) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/callStartTime`
    ),
    callStartTime
  );
};

export const updateHlsLink = (orgId, stageArn, hlsLink) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(destructStage(stageArn))}/brandData/hlsLink`
    ),
    hlsLink || ""
  );
};

export const updateLogoUrlData = (orgId, stageArn, Url) => {
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(destructStage(stageArn))}/brandData/logoUrl`
    ),
    Url
  );
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/initialLogo`
    ),
    false
  );
};

export const handleUploadImageDimensions = (
  orgId,
  stageArn,
  getFinalWidth,
  getFinalHeight
) => {
  const logoDim = [getFinalWidth, getFinalHeight];
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/logoDimension`
    ),
    logoDim
  );
};

export const fbLiveListener = (orgId, stageArn) => {
  const starCountRef = ref(
    firebaseRealtimeDb,
    `/${orgId}/${destructStageId(destructStage(stageArn))}/brandData`
  );
  console.log('fbLiveListener', starCountRef);
  onValue(starCountRef, (snapshot) => {
    const logoUrl = snapshot.val()?.logoUrl || "";
    const brandData = snapshot.val();
    store.dispatch(updateLogo(logoUrl));
    store.dispatch(updateBranding(brandData));
  });
};

export const unsubscribeStage = (orgId, stageArn) => {
  const stopCountRef = ref(
    firebaseRealtimeDb,
    `/${orgId}/${destructStageId(destructStage(stageArn))}/brandData`
  );
  off(stopCountRef);
};

export const updateMembersDetails = (orgId, stageArn, membersData) => {
  if (stageArn) {
    set(
      ref(
        firebaseRealtimeDb,
        `/${orgId}/${destructStageId(
          destructStage(stageArn)
        )}/brandData/membersData`
      ),
      membersData
    );
  }
};

export const updateParticipantPostion = (
  orgId,
  stageArn,
  postion,
  type,
  userId
) => {
  if (postion > -1) {
    if (stageArn) {
      if (type === "ordering" && userId) {
        set(
          ref(
            firebaseRealtimeDb,
            `/${orgId}/${destructStageId(
              destructStage(stageArn)
            )}/brandData/postion${postion > 7 ? 7 : postion}`
          ),
          userId
        );
      } else {
        set(
          ref(
            firebaseRealtimeDb,
            `/${orgId}/${destructStageId(
              destructStage(stageArn)
            )}/brandData/postion${postion > 7 ? 7 : postion}`
          ),
          getCurrentUserId()
        );
      }
    }
  }
};

export const updateParticipantCount = (orgId, stageArn, participantCount) => {
  if (participantCount > -1) {
    if (stageArn) {
      set(
        ref(
          firebaseRealtimeDb,
          `/${orgId}/${destructStageId(
            destructStage(stageArn)
          )}/brandData/participantCount/`
        ),
        parseInt(participantCount) > 7 ? 7 : parseInt(participantCount)
      );
    }
  }
};

export const updateVideoMutedParticipants = (
  orgId,
  stageArn,
  participantArray
) => {
  if (stageArn) {
    set(
      ref(
        firebaseRealtimeDb,
        `/${orgId}/${destructStageId(
          destructStage(stageArn)
        )}/brandData/videoMutedParticipants/`
      ),
      participantArray
    );
  }
};

export const updateAudioMutedParticipants = (
  orgId,
  stageArn,
  participantArray
) => {
  if (stageArn) {
    set(
      ref(
        firebaseRealtimeDb,
        `/${orgId}/${destructStageId(
          destructStage(stageArn)
        )}/brandData/audioMutedParticipants/`
      ),
      participantArray
    );
  }
};

export const updateSessionMember = (orgId, participantCount, stagedata) => {
  console.log(orgId, participantCount, stagedata, "updateSessionMember")
  const stageArn = stagedata || currentStageArrn(store.getState()?.awsStageReducer);
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/sessionMemberCount/`
    ),
    participantCount
  );
};

export const updateScreenShare = (orgId, isScreenShare) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/isScreenShare/`
    ),
    isScreenShare
  );
};

export const updateAudioFile = (orgId, audioId) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/audioPlayingFile/`
    ),
    audioId
  );
};

export const updateAudioPlayingStatus = (orgId, isAudioPlaying) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/audioIsPlaying/`
    ),
    isAudioPlaying
  );
};
export const updateProfileImageStatus = (orgId, isProfileImageUpdate) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/isProfileImageUpdate/`
    ),
    isProfileImageUpdate
  );
};
export const updateProfileImageDeleteStatus = (orgId, isProfileImageDelete) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/isProfileImageDelete/`
    ),
    isProfileImageDelete
  );
};
export const updateAudioMuteStatus = (orgId, isAudioMute) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/audioIsMute/`
    ),
    isAudioMute
  );
};

export const updateAudioLoopStatus = (orgId, isAudioLoop) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/audioIsLoop/`
    ),
    isAudioLoop
  );
};

export const updateAudioVolume = (orgId, audioVolumeLevel) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/audioVolumeChanger/`
    ),
    parseInt(audioVolumeLevel)
  );
};

export const updateAudioTrack = (orgId, audioTracks) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/audioTrack/`
    ),
    audioTracks
  );
};

export const updateAudioDuration = (orgId, audioTrackDuration) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/audioTrackDuration/`
    ),
    audioTrackDuration
  );
};

export const updateHostJoinedStatus = (orgId, isHostJoined) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  set(
    ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/isHostJoined/`
    ),
    isHostJoined
  );
};

export const updateOnCallParticipants = (addedArray) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  const currentOrgId = getCurrentOrgId();

  set(
    ref(firebaseRealtimeDb,
      `/${currentOrgId}/${destructStageId(destructStage(stageArn))}/brandData/inBoardcastParticipants/`),
    addedArray
  );
};

export const addHostToStageInitially = (addedHostToStageInitially = false) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  const currentOrgId = getCurrentOrgId();

  set(
    ref(firebaseRealtimeDb,
      `/${currentOrgId}/${destructStageId(destructStage(stageArn))}/brandData/addedHostToStageInitially/`),
    addedHostToStageInitially
  );
};

export const updateOnCallParticipantsNames = (addedArray) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  const currentOrgId = getCurrentOrgId();
  set(
    ref(
      firebaseRealtimeDb,
      `/${currentOrgId}/${destructStageId(
        destructStage(stageArn)
      )}/brandData/inBroadcastParticipantsName/`
    ),
    addedArray
  );
};

export const isScreenShareOnAIrUpdate = (isScreenShareOnAIr) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  const currentOrgId = getCurrentOrgId();

  set(
    ref(
      firebaseRealtimeDb,
      `/${currentOrgId}/${destructStageId(destructStage(stageArn))}/brandData/isScreenShareOnAir`
    ),
    isScreenShareOnAIr
  );
};

export const addAudioMuted = (audioMutedList = []) => {
  console.log(audioMutedList, "FB--addAudioMuted")
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  const currentOrgId = getCurrentOrgId();
  set(
    ref(
      firebaseRealtimeDb,
      `/${currentOrgId}/${destructStageId(destructStage(stageArn))}/brandData/isAudioMuted`
    ),
    audioMutedList
  );
};

export const addVideoMuted = (videoMutedList = []) => {
  console.log(videoMutedList, "janusHandleVideoMute")
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  const currentOrgId = getCurrentOrgId();

  set(
    ref(
      firebaseRealtimeDb,
      `/${currentOrgId}/${destructStageId(destructStage(stageArn))}/brandData/isVideoMuted`
    ),
    videoMutedList
  );
};

export const updateMaxOnScreenPerson = (planUser = 0) => {
  const stageArn = currentStageArrn(store.getState()?.awsStageReducer);
  const currentOrgId = getCurrentOrgId();
  set(
    ref(
      firebaseRealtimeDb,
      `/${currentOrgId}/${destructStageId(destructStage(stageArn))}/brandData/maxOnScreenParticipants`
    ),
    planUser
  );
};

export const fetchCommentsFromFirebase = (orgId, stageArn, channelArn) => {
  const starCountRef = ref(
    firebaseRealtimeDb,
    `/${orgId}/${destructStageId(destructStage(stageArn))}/comments/${channelArn}`
  );

  return new Promise((resolve, reject) => {
    onValue(starCountRef, (snapshot) => {
      const commentsData = snapshot.val() || {};
      // Convert the commentsData object into an array of comments
      const commentsArray = Object.entries(commentsData).map(([key, value]) => ({
        id: key,
        ...value
      }));
      resolve(commentsArray);
    }, (error) => {
      reject(error);
    });
  });
};

export const createEventChannel = (orgId, stageArn, isShowStarred = false) => {
  console.log(orgId, stageArn, isShowStarred, "createEventChannel");
  return eventChannel(emit => {
    const commentsRef = ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(destructStage(stageArn))}/comments`
    );

    // Create the query to order by `publishedAt` and filter by `msgStarred` if necessary
    let commentsQuery;

    if (isShowStarred) {
      commentsQuery = query(
        commentsRef,
        orderByChild('msgStarred'),
        equalTo(1)
      );
    } else {
      commentsQuery = query(commentsRef, orderByChild('publishedAt'));
    }

    const unsubscribe = onValue(commentsQuery, (snapshot) => {
      const commentsData = snapshot.val();
      if (commentsData) {
        let commentsArray = Object.entries(commentsData).map(([key, value]) => {
          return {
            id: key,
            ...value
          };
        });

        commentsArray = commentsArray.filter((comment) => comment?.isDelete === 0 && comment?.msgBan === 0 && (comment?.mediaType === 'YT' || comment?.mediaType === 'sample'));

       // Sort the comments array by `starredTime` if `isShowStarred` is true, else by `publishedAt`
       if (isShowStarred) {
        commentsArray.sort((a, b) => a.starredTime - b.starredTime);
      } else {
        commentsArray.sort((a, b) => a.publishedAt.localeCompare(b.publishedAt));
      }

        console.log("commentsArray", commentsArray);
        emit({ data: commentsArray });
      } else {
        emit({ data: [] });
      }
    }, (error) => {
      emit({ error: new Error(error.message) });
    });

    // Return unsubscribe function to close the channel
    return () => unsubscribe();
  });
};

export const removeShownComment = async (orgId, stageArn) => {
  console.log('removeShownComment', orgId, stageArn);
  try {
    // Fetch the comments snapshot
    const commentsRef = ref(
      firebaseRealtimeDb,
      `/${orgId}/${destructStageId(destructStage(stageArn))}/comments`
    );
    const snapshot = await get(commentsRef);
    const comments = snapshot.val();

    if (comments) {
      const updates = {};

      Object.keys(comments).forEach((key) => {
        if (comments[key].msgShown === 1) {
          updates[`${key}/msgShown`] = 0;
        }
      });

      if (Object.keys(updates).length > 0) {
        await update(commentsRef, updates);
        console.log("Updated comments successfully");
      } else {
        console.log("No comments to update");
      }
    }
  } catch (error) {
    console.error("Error updating comments:", error);
  }
};
