import React from 'react';
import './Layout.scss';

const Layout = (props = {}) => {
    const { className = "", children = "", style = {}, layoutstyle = {}, layoutClass = "" } = props;
    return (
        <div style={{ ...layoutstyle }} className={` layout ${layoutClass} `}>
            <div style={{ ...style }} className={` ${className} common_layout `}>
                <div className={` common_layout_inner `}>
                    {children}
                </div>
            </div>
        </div >
    );
};

export default Layout;
