import store from "..";
import {
  EDIT_STREAM, INTEGRATED_POPUP, ACCESS_TOKEN, START_YT_LOADER, END_YT_LOADER,
  EDIT_POPUPSTREAM, EDIT_STREAM_SAVE, DELETE_SOCIAL_ACCOUNT,
  RECONNECT_SOCIAL_ACCOUNT, TIMER_POPUP, MEDIA_ACCOUNT_SAVE, YT_GOLIVE_STATE,
  MEDIA_CONNECTION_UPDATE,
  SET_SHOW_POPUP_TYPE
} from "../actionTypes/editStreamTypes";

export const editStreamAction = (data = "") => {
  return {
    type: EDIT_STREAM,
    data,
  };
};

export const editStreamPopupAction = (data = "") => {
  return {
    type: EDIT_POPUPSTREAM,
    data,
  };
};

export const integratedPopupAction = (data = "") => {
  return {
    type: INTEGRATED_POPUP,
    data,
  };
};

export const getAccessTokenAction = (data = "") => {
  return {
    type: ACCESS_TOKEN,
    data,
  };
};

export const startYTLoaderAction = (data = "") => {
  return {
    type: START_YT_LOADER,
    data,
  };
};

export const endYTLoaderAction = (data = "") => {
  return {
    type: END_YT_LOADER,
    data,
  };
};

export const editStreamSaveAction = (data = "") => {
  return {
    type: EDIT_STREAM_SAVE,
    data,
  };
};

export const deleteSocialAcc = (data = "") => {
  return {
    type: DELETE_SOCIAL_ACCOUNT,
    data,
  };
};

export const reconnectAccount = (data = "") => {
  return {
    type: RECONNECT_SOCIAL_ACCOUNT,
    data,
  };
};

export const mediaAccountSaveAction = (data = "") => {
  return {
    type: MEDIA_ACCOUNT_SAVE,
    data,
  };
};

export const ytGoLiveAction = (data = "") => {
  return {
    type: YT_GOLIVE_STATE,
    data,
  };
};

export const mediaConnectionUpdateAction = (data = "") => {
  return {
    type: MEDIA_CONNECTION_UPDATE,
    data,
  };
};

export const timerPopup = (data = "") => {
  return {
    type: TIMER_POPUP,
    data,
  };
};

export const newSimulcastMediaType = (data = "") => {
  // This is for poup type, based on the value popup will be shown. || set empty value to close all popups
  store.dispatch({ type: SET_SHOW_POPUP_TYPE, data });
};

export const setPopupType = (s) => {
  if (!s) {
    const stack = new Error().stack;
    const caller = stack.split('\n')[2].trim();
    // Dont remove this console, it shows where it was called empty value
    console.log(`!! Function called by: ${caller}`);
  }

  newSimulcastMediaType(s);
};
export const getPopupType = () => {
  // Use this value for all popup show/hide
  const { commonData: { popUpType = '' } } = store.getState();
  return popUpType;
};

export const _getPopupType = (_popUpType = "") => {
  // Use this value for all popup show/hide
  const { commonData: { popUpType = '' } } = store.getState();
  return _popUpType === popUpType;
};

export const destinationPopupPreviousStateAction = (data = "") => {
  // Edit Destination poup prevuios state
  store.dispatch({ type: "SET_DESTINATION_POPUP_PREVIOUS_STATE", data });
};

export const recentlyAddedSocialMedia = (mediaId = "") => {
  // This is for poup type, based on the value popup will be shown. || set empty value to close all popups
  store.dispatch({ type: "RECENTLY_ADDED_SOCIAL_MEDIA", data: { mediaId } });
};
