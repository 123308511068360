import * as loginTypes from '../actionTypes/loginTypes';
import { put, takeLatest, call, all } from 'redux-saga/effects';
import { fetchLogin, logoutService, otpEmailService, otpVerifyService, registerUserService, validateUserService } from "../../services/LoginServices";
import { succToast, serverNotRespond, failToast } from "../../helper/ApiToast";
import { Encrypt, EncryptLogInDetails, EncryptStoreInLocal, internetStatusEncrypt, userDetailsSet } from "../../helper/Encypt";
import store from '..';
import { doLogin, doOtpGenerate } from '../action/loginAction';
import mixpanel from 'mixpanel-browser';
import { getCustDtlsNew } from '../action/customeAction';
import { customerLandingScreen, handleMixpanelTrack, setCurrentOrg, signUpUpdateToken, superAdminLandingScreen, trackHubspotEvent, } from '../../common/helper';
import { TRACK_SIGNUP } from '../../helper/ApiUrl';
import { refereshUploads } from '../../services/uploadServices';

export function* doUserLogin(obj = {}) {
    try {
        const loginData = yield call(fetchLogin, obj);
        const { data = {} } = loginData || {};
        if (data.status === 200) {
            yield localStorage.setItem('updatedUserDetails', JSON.stringify(data?.data));
            yield localStorage.setItem('regPageEmail', data?.data?.email);
            yield localStorage.setItem('regpwd', data?.data?.pwd);
            userDetailsSet(data);//if developer store userDetails in sessionStorage for developer purpose
            if (!obj?.data?.ssoType && loginData && !loginData?.data?.data?.emailVerified && obj?.data?.from !== "loginAsCustomer") {
                yield put({ type: "REGISTER_OTP_PAGE", otpPage: true });
                const _data = {
                    email: data?.data?.email,
                    oldEmail: "",
                    successToast: false,
                };
                store.dispatch(doOtpGenerate(_data));
                return false;
            }

            handleMixpanelTrack("Signin_Success", {
                email: obj.data.email
            });
            mixpanel.identify(data.data.email);
            mixpanel.people.set({
                "$name": data.data.firstName,
                "$email": data.data.email
            });
            yield getCustDtlsNew({ customerId: data.data.userId });
            Encrypt(obj.data);//login Details Store encrpt username and pass
            EncryptStoreInLocal(obj.data);
            if (obj?.data?.from !== "registerForm") {
                succToast(data.message); // This will disable when user register
            }
            yield EncryptLogInDetails(data);//user Data pass
            internetStatusEncrypt(true);//online status true
            if (obj.data.regOrgId) {
                yield setCurrentOrg(obj.data.regOrgId);
            }
            if (obj.data.stateDetails) {
                yield signUpUpdateToken(obj.data.stateDetails, data.data.userId, data.data.jwt);
            }
            yield all([
                put({ type: "DO_LOADING_LOGIN_PAGE", loading: false }),//lodering on / off
                put({ type: "DO_LOGIN_SUCCES", logingData: data, loginStatus: true }),//loginData Store
            ]);
            if (obj?.data?.from === "loginAsCustomer") window.location.href = customerLandingScreen;
            if (obj?.data?.from === "loginAsSuperAdmin") window.location.href = superAdminLandingScreen;
        } else if (data.status >= 201 && data.status <= 400) {
            handleMixpanelTrack("Signin_Failure", {
                email: obj.data.email,
                errorMessage: data.message
            });
            if (!obj?.data?.ssoType) {
                failToast(data.message);
            }
            yield all([
                put({ type: "DO_LOADING_LOGIN_PAGE", loading: false }),//lodering on / off
                put({ type: "DO_LOGIN_FAIL", loginStatus: false }),//login status
            ]);
        } else {
            handleMixpanelTrack("Signin_Failure", {
                email: obj.data.email,
                errorMessage: data.message
            });
            yield all([
                put({ type: "DO_LOADING_LOGIN_PAGE", loading: false }),//lodering on / off
                put({ type: "DO_LOGIN_FAIL", loginStatus: false }),//login status
            ]);
        }
    }
    catch (error) {
        yield all([
            put({ type: "DO_LOADING_LOGIN_PAGE", loading: false })]);//lodering on / off
    }
}

const passToLogin = async (obj = {}, stateDetails = [], orgDetail = []) => {
    const { data: { password = "", emailId = "", email = "", pwd = "", organisationId = "", ssoType = "" } = {} } = obj;
    const newObj = {
        email: emailId || email,
        ssoType: ssoType,
        password: password || pwd,
        regOrgId: orgDetail[0]?.orgId || organisationId,
        stateDetails: stateDetails,
        from: "registerForm",
    };
    store.dispatch(doLogin(newObj));
};

export function* doRegisterUserSaga(obj = {}) {
    //Login Action Created
    try {
        const registerResponse = yield call(registerUserService, obj);
        const { data: { status = 0, message = "" } = {} } = registerResponse;
        const { data: { ssoType = "" } = {} } = obj;

        if (status === 200) {
            yield localStorage.setItem('regPageEmail', obj.data?.emailId);
            yield localStorage.setItem('regpwd', obj.data?.password);

            if (!ssoType) {
                yield put({ type: "REGISTER_OTP_PAGE", otpPage: true, registerObj: obj, registerData: registerResponse?.data?.data || {} });
            }

            handleMixpanelTrack("Signup_Success", {
                ...obj.data,
                email: obj.emailId
            });

            const urlParams = new URLSearchParams(window.location.search);

            trackHubspotEvent(TRACK_SIGNUP, {
                plan_type: urlParams.has('planid') ? 'paid' : 'free',
                plan_name: urlParams.has('planid') ? urlParams.get('planid') : 'free',
                signup_type: obj?.ssoType || 'self'
            });

            if (ssoType) {
                yield localStorage.setItem('regType', ssoType);
                yield doUserLogin({ data : {
                    email   : obj.data?.emailId,
                    password: obj.data?.password,
                    ssoType : ssoType,
                }});

                window.location.href = `/verified?${urlParams}`;
            } else {
                yield put({ type: "REGISTER_OTP_PAGE", otpPage: true, registerObj: obj, registerData: registerResponse?.data?.data || {} });
                yield put({ type: "REGISTER_OTP_PAGE_LOADER", otpPageLoader: true });
            }

            mixpanel.identify(obj.data.emailId);
            mixpanel.people.set({
                "$name": obj.data.firstName,
                "$email": obj.data.emailId
            });
        }
        else if (status === 400) {
            failToast(message);
            yield put({ type: "REGISTER_OTP_PAGE_LOADER", otpPageLoader: false });
            yield put({ type: "REGISTER_USER_LOADER", loading: false });
            yield put({ type: "REGISTER_OTP_PAGE", otpPage: false });
        }
        else {
            handleMixpanelTrack("Signin_Failure", {
                ...obj.data,
                otfName: obj.data.firstName,
                otfEmail: obj.emailId,
                errorMessage: message
            });
            failToast(message);
            yield put({ type: "REGISTER_OTP_PAGE_LOADER", otpPageLoader: false });
            yield put({ type: "REGISTER_USER_LOADER", loading: false });
            yield put({ type: "REGISTER_OTP_PAGE", otpPage: false, registerData: {} });
        }
    }
    catch (error) {
        serverNotRespond();
        yield put({ type: "REGISTER_OTP_PAGE_LOADER", otpPageLoader: false });
        yield put({ type: "REGISTER_USER_LOADER", loading: false });
        yield put({ type: "REGISTER_OTP_PAGE", otpPage: false, registerData: {} });
    }
}

export function* doOtpGenerateSaga(obj = {}) {
    const { successToast = true, email = "", oldEmail = "" } = obj?.data;
    const _obj = { data: { email: email, oldEmail: oldEmail } };
    try {
        yield put({ type: "REGISTER_OTP_PAGE_LOADER", otpPageLoader: true });
        const registerResponse = yield call(otpEmailService, _obj);
        const { data: { status = 0, message = "" } = {} } = registerResponse;
        if (status === 200) {
            if (successToast) {
                succToast(message);
            }
            yield put({ type: "REGISTER_OTP_PAGE_LOADER", otpPageLoader: false });
        } else {
            failToast(message);
            yield put({ type: "REGISTER_OTP_PAGE_LOADER", otpPageLoader: false });
        }
    }
    catch (error) {
        serverNotRespond();
        yield put({ type: "REGISTER_OTP_PAGE_LOADER", otpPageLoader: false });
    }
}

export function* doValidateUserSaga(obj = {}) {
    try {
        yield put({ type: "REGISTER_EMAIL_LOADER", emailVerified: false, emailVerifyLoader: true });
        const registerResponse = yield call(validateUserService, obj);
        const { data: { status = 0 } = {} } = registerResponse;
        if (status === 200) {
            yield put({ type: "REGISTER_EMAIL_LOADER", emailVerified: true, emailVerifyLoader: false });
        } else {
            yield put({ type: "REGISTER_EMAIL_LOADER", emailVerified: false, emailVerifyLoader: false });
        }
    }
    catch (error) {
        serverNotRespond();
        yield put({ type: "REGISTER_EMAIL_LOADER", emailVerified: false, emailVerifyLoader: false });
    }
}

export function* doOtpVerifySaga(obj = {}) {
    //Login Action Created
    try {
        yield put({ type: "REGISTER_OTP_PAGE_LOADER", otpPageLoader: true });
        const otpVerifyRes = yield call(otpVerifyService, obj);
        const { status = 0, message = "" } = otpVerifyRes?.data;
        if (status === 200) {
            yield put({ type: "REGISTER_USER_LOADER", loading: false });
            yield put({ type: "REGISTER_OTP_PAGE_LOADER", otpPageLoader: false });

            yield put({ type: "REGISTER_USER_LOADER", loading: false });
            yield put({ type: "REGISTER_OTP_PAGE_LOADER", otpPageLoader: false });

            window.location.href = `/verified${window.location.search}`;
        } else {
            failToast(message);
            yield put({ type: "REGISTER_USER_LOADER", loading: false });
            yield put({ type: "REGISTER_OTP_PAGE_LOADER", otpPageLoader: false });
        }
    }
    catch (error) {
        serverNotRespond();
        yield put({ type: "REGISTER_USER_LOADER", loading: false });
        yield put({ type: "REGISTER_OTP_PAGE_LOADER", otpPageLoader: false });
    }
}

function* doLogOut(obj = {}) {
    try {
        yield call(refereshUploads);
        const logout = yield call(logoutService, obj);

        const { data = {} } = logout || {};
        if (data.status === 200) {
            succToast(data.message);
        } else {
            failToast(data.message);
        }
        window.sessionStorage.clear();
        window.localStorage.clear();
        yield put({ type: "DO_LOADING_LOGIN_PAGE", loading: false });
    } catch (error) {
        yield all([put({ type: "DO_LOADING_LOGIN_PAGE", loading: false })]);
    }
}

export default function* rootLoginPostSaga() {
    yield takeLatest(loginTypes.DO_LOGIN, doUserLogin);
    yield takeLatest(loginTypes.REGISTER_NEW_USER, doRegisterUserSaga);
    yield takeLatest(loginTypes.DO_OTP_GENERATE, doOtpGenerateSaga);
    yield takeLatest(loginTypes.DO_OTP_VERIFY, doOtpVerifySaga);
    yield takeLatest(loginTypes.DO_EMAIL_CHANGE, doOtpGenerateSaga);
    yield takeLatest(loginTypes.DO_VALIDATE_USER, doValidateUserSaga);
    yield takeLatest(loginTypes.DO_LOGOUT, doLogOut);
}
