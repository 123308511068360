import store from "..";
import {
  FACEBOOK_PAGE_LIST,
  FACEBOOK_PAGE_CONNECTED,
  FACEBOOK_GROUP_LIST,
  FACEBOOK_PROFILE_LIST,
  FACEBOOK_GROUP_CONNECTED,
  FACEBOOK_PROFILE_CONNECTED,
  SIMULCAST_MEDIA_LIST,
  POST_SIMULCAST_MEDIA_LIST,
  FACEBOOK_PAGE_CLEAR_SELECTED
} from "../actionTypes/facebookTypes";

export const facebookPageListAction = (data = "") => {
  return {
    type: FACEBOOK_PAGE_LIST,
    data,
  };
};
export const facebookSelectedClearAction = (data = "") => {
  return {
    type: FACEBOOK_PAGE_CLEAR_SELECTED,
    data,
  };
};

export const facebookPageConnectedAction = (data = "") => {
  return {
    type: FACEBOOK_PAGE_CONNECTED,
    data,
  };
};

export const facebookGroupListAction = (data = "") => {
  return {
    type: FACEBOOK_GROUP_LIST,
    data,
  };
};

export const facebookGroupConnectedAction = (data = "") => {
  return {
    type: FACEBOOK_GROUP_CONNECTED,
    data,
  };
};

export const facebookProfileListAction = (data = "") => {
  return {
    type: FACEBOOK_PROFILE_LIST,
    data,
  };
};

export const facebookProfileConnectedAction = (data = "") => {
  return {
    type: FACEBOOK_PROFILE_CONNECTED,
    data,
  };
};

export const getSimulcastMediaListAction = (broadcastId = '') => {
  const awsStageReducer = store.getState()?.awsStageReducer;
  const awsStageArn = awsStageReducer?.stageArn || awsStageReducer?.stage?.arn;
  store.dispatch({ type: SIMULCAST_MEDIA_LIST, data: { broadcast_id: broadcastId || awsStageArn } });
};

export const postSimulcastMediaAction = (data = {}) => {
  store.dispatch({ type: POST_SIMULCAST_MEDIA_LIST, data });
};
