export const CREATE_STAGE = "CREATE_STAGE";
export const ADD_STAGE_PARTICIPANTS = "ADD_STAGE_PARTICIPANTS";
export const ADD_STAGE_STREAM_DATA = "ADD_STAGE_STREAM_DATA";
export const ADD_LOCAL_STREAMS = "ADD_LOCAL_STREAMS";
export const ADD_DEVICES_LISTS = "ADD_DEVICES_LISTS";
export const UPDATE_LOGO = "UPDATE_LOGO";
export const LOCAL_LEFT = "LOCAL_LEFT";
export const USER_LEFT_STAGE = "USER_LEFT_STAGE";
export const SELECTED_DEVICE_IDS = "SELECTED_DEVICE_IDS";
export const PARTICIPANT_MUTE_UPDATE = "PARTICIPANT_MUTE_UPDATE";
export const GET_MEET_DATA = "GET_MEET_DATA";
export const GET_PAST_DATA = "GET_PAST_DATA";
export const GET_UPCOMING_DATA = "GET_UPCOMING_DATA";
export const JOIN_STAGE = "JOIN_STAGE";
export const GET_BROADCAST_DATA = "GET_BROADCAST_DATA";
export const UPDATE_CALL_STATUS = "UPDATE_CALL_STATUS";
export const UPDATE_CAMERA_STREAM = "UPDATE_CAMERA_STREAM";
export const UPDATE_MIC_STREAM = "UPDATE_MIC_STREAM";
export const UPDATE_MUTE_STATUS = "UPDATE_MUTE_STATUS";
export const UPDATE_SPEAKER = "UPDATE_SPEAKER";
export const UPDATE_MEET_DATA = "UPDATE_MEET_DATA";
export const ADD_MEET_DATA = "ADD_MEET_DATA";
export const UPDATE_BRANDING = "UPDATE_BRANDING";
export const UPDATE_POSITIONING = "UPDATE_POSITIONING";
export const APP_STATUS = "APP_STATUS";
export const UPDATE_LOCAL_VIDEO_STREAM = "UPDATE_LOCAL_VIDEO_STREAM";
export const UPDATE_LOCAL_AUDIO_STREAM = "UPDATE_LOCAL_AUDIO_STREAM";
export const UPDATE_BLOCK_ACCESS = "UPDATE_BLOCK_ACCESS";
export const APP_ONLINE_STATUS = "APP_ONLINE_STATUS";
export const CAM_PERMISSION = "CAM_PERMISSION";
export const MIC_PERMISSION = "MIC_PERMISSION";
export const EMPTY_TEAMS_MEMBERS = "EMPTY_TEAMS_MEMBERS";
export const TEMP_AUDIO_STREAM = "TEMP_AUDIO_STREAM";
export const TEMP_VIDEO_STREAM = "TEMP_VIDEO_STREAM";
export const TEMP_AUDIO_STREAM_OUTPUT = "TEMP_AUDIO_STREAM_OUTPUT";
export const TEMP_INITIALS = "TEMP_INITIALS";
export const UPDATE_STAGE_PARTICIPANTS = "UPDATE_STAGE_PARTICIPANTS";
export const UPDATE_SCREEN_STREAM = "UPDATE_SCREEN_STREAM";
export const IS_HOST_ACTION = "IS_HOST_ACTION";
export const IS_LIVE = "IS_LIVE";
export const GET_TODAY_MEET = "GET_TODAY_MEET";
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const JANUS_PARTICIPANT_STREAM = "JANUS_PARTICIPANT_STREAM";
export const JANUS_PARTICIPANT_REMOVED = "JANUS_PARTICIPANT_REMOVED";
export const WEBINAR_USERS = "WEBINAR_USERS";
export const WEBINAR_CANCEL = "WEBINAR_CANCEL";
export const WEBINAR_BRANDING_UPDATE = "WEBINAR_BRANDING_UPDATE";
export const WEBINAR_BRANDING_DATA = "WEBINAR_BRANDING_DATA";
export const WEBINAR_LOGO_UPLOAD = "WEBINAR_LOGO_UPLOAD";
export const WEBINAR_LOGO_SELECT = "WEBINAR_LOGO_SELECT";
export const WEBINAR_LOGO_REMOVE = "WEBINAR_LOGO_REMOVE";
export const GET_PAST_MEET = "GET_PAST_MEET";
export const GET_SCHEDULED_MEET = "GET_SCHEDULED_MEET";
export const GUEST_JOIN_STAGE = "GUEST_JOIN_STAGE";
