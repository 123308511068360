import * as loginTypes from "../actionTypes/loginTypes";

export const doLogin = (data = {}) => {
  return {
    type: loginTypes.DO_LOGIN,
    data,
  };
};

export const doLoginSuccess = (data = {}) => {
  return {
    type: loginTypes.DO_LOGIN_SUCCESS,
    data,
  };
};

export const doLoginFail = (data = {}) => {
  return {
    type: loginTypes.DO_LOGIN_FAIL,
    data,
  };
};

export const doRegisterNewUserAction = (data = {}) => {
  return {
    type: loginTypes.REGISTER_NEW_USER,
    data,
  };
};

export const doOtpGenerate = (data = {}) => {
  return {
    type: loginTypes.DO_OTP_GENERATE,
    data,
  };
};

export const doOtpVerify = (data = {}) => {
  return {
    type: loginTypes.DO_OTP_VERIFY,
    data,
  };
};

export const doEmailChange = (data = {}) => {
  return {
    type: loginTypes.DO_EMAIL_CHANGE,
    data,
  };
};

export const doLogout = (data = {}, ownUser = {}) => {
  return {
    type: loginTypes.DO_LOGOUT,
    data,
    ownUser,
  };
};

export const doValidateEmailAction = (email = "") => {
  const data = {
    email: email
  };
  return {
    type: loginTypes.DO_VALIDATE_USER,
    data,
  };
};
