import {
    FETCH_COMMENTS_SUCCESS,
    FETCH_COMMENTS_FAILURE,
    SET_GUEST_COMMENTS_ACCESS,
    CLEAR_GUEST_COMMENTS_ACCESS,
    UPDATE_STARRED_COUNT,
    POST_COMMENT_STATUS,
    RESET_COMMENTS
} from '../actionTypes/commentsTypes';

const initialState = {
  comments: [],
  loading: false,
  error: null,
  starredCount: 0,
  commentStatus: ''
};

const guestCommentsInitialState = {
  hasAccess: (localStorage.getItem('guestAccessToken') && localStorage.getItem('guestRefreshToken')) ? true : false
};

export const commentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMENTS_SUCCESS:
      return { ...state, comments: action.payload, loading: false };

    case FETCH_COMMENTS_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case UPDATE_STARRED_COUNT:
      return { ...state, starredCount: action.payload };

    case POST_COMMENT_STATUS:
      return { ...state, commentStatus: action.status };

    case RESET_COMMENTS:
      return initialState;

    default:
      return state;
  }
};

export const guestCommentAccessReducer = (state = guestCommentsInitialState, action) => {
  switch (action.type) {
    case SET_GUEST_COMMENTS_ACCESS:
      const accessToken = window.localStorage.getItem('guestAccessToken');
      const refreshToken = window.localStorage.getItem('guestRefreshToken');
      const isGuestAccess = accessToken && refreshToken ? true : false;
      window.localStorage.setItem('guestAccess', isGuestAccess);
      return { ...state, hasAccess: isGuestAccess };

    case CLEAR_GUEST_COMMENTS_ACCESS:
      window.localStorage.setItem('guestAccess', false);
      window.localStorage.removeItem('guestAccessToken');
      window.localStorage.removeItem('guestRefreshToken');
      window.localStorage.removeItem('guestUserName');
      window.localStorage.removeItem('guestUserProfileImage');
      window.localStorage.removeItem('guestCurrentStreamUrl');
      return { ...state, hasAccess: false };

    default:
      return state;
  }
};
