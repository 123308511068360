import React, { useEffect } from 'react';
import { GifRegisterSuccess } from '../../assets/otf';
import "./OtpVerify.scss";
import { useHistory } from "react-router-dom";
import { trackHubspotEvent } from '../../common/helper';
import { TRACK_OTP } from '../../helper/ApiUrl';

function OtpSuccess(props = {}) {
    const regType = localStorage.getItem('regType') || "";
    const email = localStorage.getItem('regPageEmail');
    const history = useHistory();

    useEffect(() => {
        if (!email) {
            history.push("/");
        }
        else {
            setTimeout(function () {
                trackHubspotEvent(TRACK_OTP, {
                    status: 'success'
                });
            }, 2000);
        }
    }, []);

    return (
        <div className="otp_success">
            <div className='otp_success_img'>
                <img src={GifRegisterSuccess} alt="img" />
            </div>
            <h4 className="otp_success_heading">{regType ? " Registration " : " Verification "} Successful!</h4>
            <p className="otp_success_info">{regType ? "Thank you, you have signed up successfully and your account is active now. You will be redirected shortly." :
                "Thank you, your email has been verified and your account is active now. You will be redirected shortly."}</p>
        </div>
    );
}

export default OtpSuccess;
