import React from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { CUSTOM_DATE_FILTER_VALUE } from "../../constant";

const DayToYearDropDownListdatas = (props = {}) => {
  const cusDate = "Custom date";
  const {
    DropDown = false,
    SelectedValue = "",
    enableMonthPicker = false,
    _handleSelect = () => { },
  } = props;
  const { t } = useTranslation();

  return (
    <CSSTransition
      in={DropDown}
      timeout={500}
      classNames="fadeInUp"
      unmountOnExit
    >
      <div>
        {enableMonthPicker ?
          <ul>
            <li
              value="1"
              id={"JestDropValuePick"}
              className={` ${SelectedValue === "This month" ? " active " : " "}`}
              data-value="This month"
              onClick={(event) => _handleSelect(event)}
            >
              {t("This month")}
            </li>
            <li
              value="2"
              id={"JestDropValuePick"}
              className={` ${SelectedValue === "Last month" ? " active " : " "}`}
              data-value="Last month"
              onClick={(event) => _handleSelect(event)}
            >
              {t("Last month")}
            </li>
            <li
              value="3"
              data-value={cusDate}
              id={"JestDropValuePick"}
              onClick={(event) => _handleSelect(event)}
            >
              {t("TABLE.CUSTOM_DAY")}
            </li>
          </ul>
          :
          <ul>
            <li
              value="1"
              className={` ${SelectedValue === "Today" ? " active " : " "}`}
              data-value="Today"
              id={"JestDropValuePick"}
              onClick={() => _handleSelect(1)}
            >
              Today
          </li>
          <li
            value="2"
            id={"JestDropValuePick"}
            className={` ${SelectedValue === t("TABLE.SEVEN_DAY") ? " active " : " "}`}
            data-value={t("TABLE.SEVEN_DAY")}
            onClick={() => _handleSelect(7)}
          >
            {t("TABLE.SEVEN_DAY")}
          </li>

            <li
              value="3"
              id={"JestDropValuePick"}
              data-value={t("TABLE.FOURTEEN_DAY")}
              onClick={() => _handleSelect(14)}
              className={` ${SelectedValue === t("TABLE.FOURTEEN_DAY") ? " active " : " "}`}
            >
              {t("TABLE.FOURTEEN_DAY")}
            </li>
            <li
              value="4"
              id={"JestDropValuePick"}
              className={` ${SelectedValue === t("TABLE.TWENTYEIGHT_DAY") ? " active " : " "}`}
              data-value={t("TABLE.TWENTYEIGHT_DAY")}
              onClick={() => _handleSelect(28)}
            >
              {t("TABLE.TWENTYEIGHT_DAY")}
            </li>
            <li
              value="5"
              data-value={cusDate}
              id={"JestDropValuePick"}
              className={` ${SelectedValue === cusDate ? " active " : " "}`}
              onClick={() => _handleSelect(CUSTOM_DATE_FILTER_VALUE)}
            >
              {t("TABLE.CUSTOM_DAY")}
            </li>
            <li
            value="0"
            id={"JestDropValuePick"}
            className={` ${SelectedValue === t("TABLE.ALL") ? " active " : " "}`}
            data-value={t("TABLE.ALL")}
            onClick={() => _handleSelect(0)}
            style={{ display: "none" }}
          >
            {t("TABLE.ALL")}
          </li>
          </ul>
        }
      </div>
    </CSSTransition>
  );
};
export default React.memo(DayToYearDropDownListdatas);
