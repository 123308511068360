import React, { memo } from 'react';
import './TablePagination.scss';
import PagesInfo from '../../../../common/DataTable/pagination/PagesInfo';
import PageSize from '../../../../common/DataTable/pageSize';
import Pagination from '../../../../common/DataTable/pagination';
import { pagesizeData } from '../../../../common/MonckDataJson';

const TableLayoutFooter = (props = {}) => {
    const {
        initialPage = 1,
        bottomDetails = {},
        pageSizeChange = () => { }, //click
        changePageactive = () => { }, //click
        searchEnable,
        setSearchEnable = () => { },
        style = {}
    } = props || {};

    const viewFooter = bottomDetails.totalPages >= 2 ? true : false;
    return (
        <div style={{ ...style }} className='table_layout_footer'>
            <React.Fragment>
                <div className="table_pagination">
                    <div className="pagesInfo">
                        <PagesInfo bottomDetails={bottomDetails} />
                        {bottomDetails.totalRecords > 10 &&
                            <React.Fragment>
                                <div className="pageSize">
                                    <span className=''>Items per page</span>
                                    <React.Fragment>
                                        <PageSize
                                            OriginalData={pagesizeData}
                                            onChange={pageSizeChange}
                                            pageSize={bottomDetails.size}
                                        />
                                    </React.Fragment>
                                </div>
                            </React.Fragment>
                        }
                    </div>

                    {viewFooter && (
                        <Pagination
                            initialPage={initialPage}
                            onChangePage={changePageactive}
                            bottomDetails={bottomDetails}
                            searchEnable={searchEnable}
                            setSearchEnable={setSearchEnable}

                        />
                    )}
                </div>
            </React.Fragment>
        </div>
    );
};

export default memo(TableLayoutFooter);
