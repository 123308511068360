import React, { memo } from 'react';

function TableLayoutBody(props) {
    const { children = "", style = {} } = props;
    return (
        <div style={{ ...style }} className='table_layout_body'>

            {children}
        </div>
    );
}

export default memo(TableLayoutBody);
