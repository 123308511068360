import React from 'react';
import Modal from ".";

function Modal4(props) {
    const { children } = props;
    return (
        <Modal id="modal4" >
            {children}
        </Modal>
    );
}

export default Modal4;
