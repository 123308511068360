import { Get } from "../common/httpRestServices";
import { apiUrl, dashBoard } from "../helper/ApiUrl";
import { encodeGetParams } from "../helper/Encypt";

/* @param  {object} obj;
 * delete request button status
 */
export const getAnalyticsOverviewService = (obj = {}) => {
    const { data = {} } = obj || {};
    if (data.startDate && data.endDate) { // This will prevent single date search
        const queryString = data ? encodeGetParams(data) : null;
        return Get(`${apiUrl}${dashBoard.quickView}${queryString}`,{}, true);
    }
};

/* @param  {object} obj;
 * delete request button status
 */
export const getRecentActivityService = () => {
    return Get(`${apiUrl}${dashBoard.recentActivityCustomers}`,{}, true);
};
