import React, { useState } from 'react';
import { IconInfo4, IconLabelInfo } from '../../assets/images';
import './infoTooltip.scss';
import { IconQuestionMark, IconTriangle } from '../../assets/otf';

const InfoTooltip = (props = {}) => {
    const { style = {}, labelText = "", enableModal = false, className = "",
        tooltipTitle = "", tooltipDesc = '', toolTipClass = "",
        minHeight = "32px", maxWidth = "260px", iconType = "info",
        tooltipIcon = <IconLabelInfo />, mustFill = false } = props;

    const [tooltipX, setTooltipX] = useState("");
    const [tooltipy, setTooltipy] = useState("");
    const [getTooltipShow, setTooltipShow] = useState(false);

    const _onMouseEnter = (e) => {
        setTooltipShow(true);
        setTooltipy((e.clientY));
        setTooltipX((e.clientX));
    };

    const _onMouseLeave = () => {
        setTooltipy("");
        setTooltipX("");
        setTooltipShow(false);
    };

    return (<>
        <div style={style} className={`info_tooltip ${className}`}>
            {labelText ? <span>{labelText} <span style={{ color: "red" }}> {mustFill === true && "*"}</span></span> : null}
            <div onMouseEnter={_onMouseEnter} onMouseLeave={_onMouseLeave} className='info_tooltip_icon'>
                {iconType === "custom" ? tooltipIcon : null}
                {iconType === "info" ? <IconInfo4 style={{ transform: "rotate(-180deg)" }} /> : null}
                {iconType === "question" ? <IconQuestionMark /> : null}
                {(tooltipTitle || tooltipDesc) && (<div style={{
                    opacity: enableModal && getTooltipShow ? 1 : '',
                    position: enableModal ? "fixed" : "",
                    zIndex: enableModal ? 2 : "",
                    left: enableModal ? `${tooltipX}px` : "",
                    top: enableModal ? `${tooltipy}px` : "",
                    minHeight: minHeight,
                    maxWidth: maxWidth
                }}
                    className={` ${toolTipClass}info_tooltip_wraper`}>
                    {tooltipTitle ? <h4 className='info_tooltip_title'>{tooltipTitle}</h4> : null}
                    {tooltipDesc ? <p className='info_tooltip_desc'>{tooltipDesc}</p> : null}
                    <div className='info_tooltip_arrow'>
                        <IconTriangle />
                    </div>
                </div>)
                }
            </div>
        </div>
    </>);
};

export default InfoTooltip;
