export const initStateCommon = {
    facebookPageDetails: {},
    simulcastMediaList: [],
    simulcastMediaConnectedList: [],
    readyToGoLive: false,
    addMediaLoader: false,
};

const filterConnectedMedia = (mediaList = []) => {
    const copyData = [...mediaList];
    return copyData.filter((item) => item.connectedMedia && Object.keys(item.connectedMedia).length);
};

export const facebookDataReducer = (state = initStateCommon, action = {}) => {
    switch (action.type) {
        case 'FACEBOOK_PAGE_LIST': {
            return {
                ...state,
                facebookPageList: action.data
            };
        }
        case 'FACEBOOK_PAGE_CONNECTED': {
            return {
                ...state,
                facebookPageConnected: action.data
            };
        }
        case 'FACEBOOK_PAGE_CLEAR_SELECTED': {
            return {
                ...state,
                simulcastMediaConnectedList: action.data
            };
        }
        case 'FACEBOOK_GROUP_LIST': {
            return {
                ...state,
                facebookGroupList: action.data
            };
        }
        case 'FACEBOOK_GROUP_CONNECTED': {
            return {
                ...state,
                facebookGroupConnected: action.data
            };
        }
        case 'FACEBOOK_PROFILE_LIST': {
            return {
                ...state,
                facebookProfileList: action.data
            };
        }
        case 'FACEBOOK_PROFILE_CONNECTED': {
            return {
                ...state,
                facebookProfileConnected: action.data
            };
        }
        case 'GET_SIMULCAST_MEDIA_LIST': {
            return {
                ...state,
                simulcastMediaList: action.simulcastMediaList,
                simulcastMediaConnectedList: filterConnectedMedia(action.simulcastMediaList)
            };
        }
        case 'SET_READY_TO_GO_LIVE': {
            return {
                ...state,
                readyToGoLive: action.readyToGoLive
            };
        }
        case 'MEDIA_ADDING_LOADER': {
            return {
                ...state,
                addMediaLoader: action.addMediaLoader
            };
        }
        default:
            return {
                ...state
            };
    }
};
