import axios from "axios";
import { Delete, Post, Put } from "../common/httpRestServices";
import { failToast } from "../helper/ApiToast";
import { apiUrl, uploadMediaAPI } from "../helper/ApiUrl";
import { getCurrentOrgId, getCurrentUserId } from "../helper/utility";
import store from "../store";
import { fetchUploadMediaListTableAction } from "../store/action/mediaUploadListAction";
import { uploadProgressAction } from '../store/action/uploaderAction';
import { doPreRecordUploadingAction } from "../store/action/preRecordAction";

const chunkSizeConfig = [
  {
    lowerLimit: 0,
    upperLimit: 100,
    chunk: 1024 * 1024 * 10,
  },
  {
    lowerLimit: 101,
    upperLimit: 1024,
    chunk: 1024 * 1024 * 50,
  },
  {
    lowerLimit: 1024,
    upperLimit: 1024000,
    chunk: 1024 * 1024 * 100,
  },
];
let uploadInterval = false;
let uploadIntervalLoop = null;
let mediaUpload = [];
const mediaStatus = [];

const getPartCount = (fileSize) => {
  const fileSizeInMB = fileSize / (1024 * 1024);
  let chunkSize = null;
  for (const item of chunkSizeConfig) {
    if (chunkSize) break;
    if (item.lowerLimit <= fileSizeInMB && item.upperLimit >= fileSizeInMB) {
      chunkSize = item.chunk;
    }
  }

  if (!chunkSize || chunkSize > fileSize) chunkSize = fileSize;

  return { partCount: Math.ceil(fileSize / chunkSize), chunkSize };
};

const updateUploadStatus = async (uploadFailedData, status) => {
  try {
    await Put(
      `${apiUrl}${uploadMediaAPI?.updateUploadStatus}${uploadFailedData?.mediaId}`,
      { status },
      true
    );
  } catch (error) {
    console.error("Error updating upload status:", error);
  }
};

export const getVideoDuration = (inputVideo) => {
  return new Promise((resolve, reject) => {
    try {
      const video = document.createElement('video');
      video.src = URL.createObjectURL(inputVideo);
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        if (video.duration === Infinity) {
          video.currentTime = 1e101;
          video.ontimeupdate = () => {
            video.ontimeupdate = null;  // Remove the event listener
            video.currentTime = 0;  // Reset the current time
            resolve(video.duration * 1000);
            URL.revokeObjectURL(video.src);
          };
        } else {
          resolve(video.duration * 1000);
          URL.revokeObjectURL(video.src); // Clean up the object URL
        }
      };

      video.onerror = (error) => {
        console.log('Error on metadata', error);
        resolve(0);
      };
    } catch (error) {
      console.log('Error:', error);
      resolve(0);
    }
  });
};

const createMedia = async (mediaInfo) => {
  try {
    const result = await Post(`${apiUrl}${uploadMediaAPI?.saveUploadInprogress}`, mediaInfo, true);
    const response = result?.data?.response?.mediaInfo;
    return response;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

const getFileNameWithoutExtension = (fileName) => {
  return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
};

const updateStatus = async (media) => {
  const index = mediaStatus.findIndex(x => x?.uploadId === media?.uploadId);
  if (index > -1) {
    mediaStatus[index] = media;
  } else {
    mediaStatus.push(media);
  }
};

export const uploadControl = (value) => {
  let receivedStatus;
  const uploadMediaIndex = mediaUpload.findIndex(x => x.mediaId === value.mediaId);
  const videoList = store.getState()?.preRecordData?.PreRecordUploadList?.data || [];
  const selectedItem = videoList?.find((_I) => _I.video_id === value.mediaId) || {};

  if (value?.type === 'pause') {
    receivedStatus = 2;
  }
  if (value?.type === 'resume') {
    receivedStatus = 1;
  }
  if (value?.type === 'cancel') {
    receivedStatus = 6;
  }

  if (uploadMediaIndex > -1) {
    mediaUpload[uploadMediaIndex].uploadingStatus = receivedStatus;
    updateStatus(mediaUpload[uploadMediaIndex]);
  }

  if (value.type === 'cancel') {
    cancelMediaUpload(value?.mediaId, selectedItem.status); // Replace with appropriate cancel function and parameters
  }
};

const uploadComplete = async (media) => {
  const params = {
    uploadId: media?.uploadId,
    parts: media?.eTags,
    path: media?.mediaInfo?.key_prefix,
    size: media?.sourceFile?.size
  };
  const uploadMediaIndex = mediaUpload.findIndex(x => x?.uploadId === media?.uploadId);
  try {
    const uploadResponse = await Post(`${apiUrl}${uploadMediaAPI?.completeMediaUpload}${media?.mediaInfo?.id}`, params, true);
    const response = uploadResponse.data;

    if (response.statusCode === 200 && !response?.error) {
      // Call list API to update
      // reseting status in create popup only
      const obj = { ...mediaUpload[uploadMediaIndex] };
      obj.thumbnailImage = response.response.thumbnail_image;
      obj.status = 2;
      obj.progress = 0;
      obj.mode === "single" && store.dispatch(doPreRecordUploadingAction(obj));

      store.dispatch(fetchUploadMediaListTableAction({
        video_type: 3,
        org_id: getCurrentOrgId(),
        user_id: getCurrentUserId(),
      }));

      if (uploadMediaIndex > -1) {
        mediaUpload[uploadMediaIndex].uploadingStatus = 4;
        mediaUpload[uploadMediaIndex].uploadPercentage = 0;
        updateStatus(mediaUpload[uploadMediaIndex]);
      }
    }
  } catch (error) {
    if (uploadMediaIndex > -1) {
      await updateUploadStatus(media?.mediaId, 3);
    }
  }
};

const uploadProgress = (uploadId, part, error = false) => {
  const uploadMediaIndex = mediaUpload.findIndex(x => x?.uploadId === uploadId);
  if (uploadMediaIndex > -1) {
    delete mediaUpload[uploadMediaIndex]?.activeSubscription[part?.PartNumber];
    mediaUpload[uploadMediaIndex].uploadedSize += parseInt(part['chunkSize']);
    mediaUpload[uploadMediaIndex].uploadPercentage = (mediaUpload[uploadMediaIndex].uploadedSize / mediaUpload[uploadMediaIndex].fileSize * 100).toFixed(2);

    if (error) {
      mediaUpload[uploadMediaIndex].status = 4;
    } else {
      mediaUpload[uploadMediaIndex].status = 1;
      mediaUpload[uploadMediaIndex].uploadingStatus = (mediaUpload[uploadMediaIndex].uploadingStatus === 2 || mediaUpload[uploadMediaIndex].uploadingStatus === 6)
        ? mediaUpload[uploadMediaIndex].uploadingStatus : 1;
    }

    uploadProgressAction(mediaUpload[uploadMediaIndex]);
    mediaUpload.mode === "single" && store.dispatch(doPreRecordUploadingAction(mediaUpload[uploadMediaIndex]));

    if (!mediaUpload[uploadMediaIndex]?.uploadingData.length && !Object.keys(mediaUpload[uploadMediaIndex]?.activeSubscription).length) {
      // Only call uploadComplete if all chunks have been uploaded
      if (mediaUpload[uploadMediaIndex].uploadedSize >= mediaUpload[uploadMediaIndex].fileSize) {
        document.getElementById('media_upload').value = '';
        uploadComplete(mediaUpload[uploadMediaIndex]);
      }
    }
    updateStatus(mediaUpload[uploadMediaIndex]);
  }
};

const sendChunk = async (chunk, part, uploadId) => {

  const url = part?.url;
  const uploadMediaIndex = mediaUpload?.findIndex(x => x && x?.uploadId === uploadId);

  if (uploadMediaIndex > -1) {

    // Ensure mediaUpload[uploadMediaIndex] and part.PartNumber are defined before assignment
    if (mediaUpload[uploadMediaIndex] && part && part.PartNumber) {
      mediaUpload[uploadMediaIndex].activeSubscription[part.PartNumber] = axios.put(url, chunk)
        .then((result) => {
          const uploadIndex = mediaUpload.findIndex(x => x.uploadId === uploadId);
          if (uploadIndex > -1) {
            mediaUpload[uploadIndex].eTags.push({
              PartNumber: part.PartNumber,
              ETag: result.headers['etag'].replace(/"/g, "")
            });
            mediaUpload[uploadIndex].activeReq -= 1;
            mediaUpload[uploadIndex].errorcnt = 0;
            uploadProgress(mediaUpload[uploadIndex]?.uploadId, part);
          }
        })
        .catch((error) => {

          mediaUpload[uploadMediaIndex].activeReq -= 1;
          mediaUpload[uploadMediaIndex].uploadingStatus = 3;
          mediaUpload[uploadMediaIndex].errorcnt += 1;
          mediaUpload[uploadMediaIndex].uploadingData.push(part);

          if (axios.isCancel(error)) {
            console.log('Upload canceled');
          } else {
            console.error('Upload error:', error);
          }
        });
    }
  }
};

const uploadChunk = () => {
  if(mediaUpload?.length > 0) {
    mediaUpload?.forEach((element, index) => {
      if (element['isComplete'] === false && ![2, 4, 6].includes(element?.uploadingStatus) &&
          element['uploadingData']?.length > 0 && element['activeReq'] < element['noReq']) {
        const currentPart = element?.uploadingData?.pop();
        if (element?.sourceFile && currentPart) {
          const sentSize = (currentPart.PartNumber - 1) * element?.fileChunkSize;
          const chunk = element?.sourceFile.slice(sentSize, sentSize + element?.fileChunkSize);
          const actualSentSize = element?.sourceFile.size - sentSize;
          currentPart['chunkSize'] = actualSentSize > element?.fileChunkSize ? element?.fileChunkSize : actualSentSize;
          mediaUpload[index].activeReq += 1;
          sendChunk(chunk, currentPart, element?.uploadId);
        }
      }
    });
  } else {
    uploadInterval = false;
    clearInterval(uploadIntervalLoop);
  }
};

const triggerUploadInterval = () => {
  if (uploadInterval) {
    uploadIntervalLoop = setInterval(() => {
      uploadChunk();
    }, 1000);
  }
};

const getFileExtension = (fileName) => {
  const parts = fileName.split('.');
  if (parts?.length > 1) {
    const extension = parts.pop().toLowerCase();
    return extension;
  }
  return ''; // Return an empty string if no extension is found
};

const isValidVideoFormat = (fileName) => {
  const allowedFormats = ['mp4', 'mov'];
  const extension = getFileExtension(fileName);
  return allowedFormats.includes(extension);
};

const intializeUpload = async (file, setIsUploading, handleMenuAction = () => { }, mode = "multi") => {
  try {
    if (!isValidVideoFormat(file?.name)) {
      await setIsUploading(false);
      failToast('Video file format that supports here - mp4, mov ', "UnsupportedFileFormat");
      const timeoutId = setTimeout(() => {
        closeToast("UnsupportedFileFormat"); // Close the toast
        clearTimeout(timeoutId); // Clear the timeout
      }, 5000);
      return false;
    }
    const { partCount, chunkSize } = getPartCount(file.size);
    let duration = 0;
    const fileExtension = getFileExtension(file?.name);
    if (fileExtension !== 'flv' && fileExtension !== 'avi') {
      duration = await getVideoDuration(file);
    }

    const maxUploadLimit = 21474836480;
    if (file.size > maxUploadLimit) {
      failToast('Upload Duration Limit Exceeded ', "LIMITEXCEED");
      setIsUploading(false);
      return;
    }
    const fileNameWithoutExtension = getFileNameWithoutExtension(file.name);
    /**** create media data start */
    const mediaData = {
      name: fileNameWithoutExtension,
      fileName: file?.name,
      user_id: getCurrentUserId()?.toString() ? getCurrentUserId()?.toString() : JSON.parse(localStorage.getItem("userDetails")).data.userId,
      org_id: getCurrentOrgId(),
      status: 1,
      video_type: 3,
      duration,
      storage: file?.size,
      is_delete_id: file?.is_delete_id,
      is_delete_status: file?.is_delete_status
    };
    const mediaDetails = await createMedia(mediaData);
    mediaDetails['mediaId'] = mediaDetails?.id;
    mode === "single" && store.dispatch(doPreRecordUploadingAction({ ...mediaData, uploadedSize: "",
      video_id: mediaDetails?.id, fileName: mediaData?.name, uploadPercentage: 0,  mode }));
    uploadProgressAction(mediaDetails);
    /**** create media end */

    const payload = {
      fileName: file?.name,
      numberOfparts: partCount,
      size: file?.size,
      user_id: getCurrentUserId()?.toString() ? getCurrentUserId()?.toString() : JSON.parse(localStorage.getItem("userDetails")).data.userId,
      org_id: getCurrentOrgId(),
      duration,
      path: mediaDetails?.key_prefix
    };
    const result = await Post(`${apiUrl}${uploadMediaAPI?.createChunkUrl}`, payload, true);
    const { data } = result;
    if (data?.statusCode === 200 && data?.error === false) {
      const { response } = data;
      if (!response?.uploadId) {
        setIsUploading(false);
        return;
      }
      setIsUploading(true);
      handleMenuAction();
      uploadInterval = true;
      //upload data
      const uploadingData = response['partSignedUrlList'].map(item => {
        item['uploadId'] = response['uploadId'];
        return item;
      });
      const params = {
        file,
        fileName: fileNameWithoutExtension,
        numberOfParts: partCount,
        sourceFile: file,
        fileChunkSize: chunkSize,
        fileSize: file?.size,
        uploadId: response['uploadId'],
        uploadingData,
        activeSubscription: {},
        eTags: [],
        duration,
        mediaId: mediaDetails?.id,
        mediaInfo: mediaDetails,
        uploadedSize: 0,
        uploadPercentage: 0,
        uploadingStatus: 1, // 0 -> ideal 1-> uploading, 2-> paused, 3-> retrying , 4- > completed, 5-> error, 6-> cancelled
        isComplete: false,
        noReq: 2,
        activeReq: 0,
        errorcnt: 0,
        mode
      };
      mode === "single" && store.dispatch(doPreRecordUploadingAction(params));
      mediaUpload.push(params);
      triggerUploadInterval();
    }
  } catch (error) {
    failToast('Your video file is invalid!');
    console.error("Error fetching chunk URLs:", error);
    await setIsUploading(false);
    throw error;
  }
};

async function deleteUploaded(mediaId) {

  await Delete(`${apiUrl}${uploadMediaAPI?.cancelMediaUpload}${mediaId}`, {}, true);
  document.getElementById('media_upload').value = '';
  store.dispatch(fetchUploadMediaListTableAction({
    video_type: 3,
    org_id: getCurrentOrgId(),
    user_id: getCurrentUserId(),
  }));
};

async function cancelMediaUpload(mediaId, status = 0) {
  try {
    if (status && [1, 2].includes(status)) {
      const uploadMediaIndex = mediaUpload?.findIndex(x => x && x?.mediaId === mediaId);
      document.getElementById('media_upload').value = '';
      if (uploadMediaIndex > -1 && uploadMediaIndex !== '') {
        if (mediaUpload[uploadMediaIndex]) {

          await deleteUploaded(mediaId);
          mediaUpload[uploadMediaIndex].uploadingStatus = 6;
          updateStatus(mediaUpload[uploadMediaIndex]);
          mediaUpload.splice(uploadMediaIndex, 1);
        }
      } else {
        // Cancel all media uploads concurrently
        const existingUpload = mediaUpload || [];
        const deletePromises = existingUpload.map(item =>
          Delete(`${apiUrl}${uploadMediaAPI?.cancelMediaUpload}${item?.mediaId}`, {}, true)
        );

        await Promise.all(deletePromises);

        // Clear the mediaUpload array
        mediaUpload = [];
      }
    } else if (status) {
      await deleteUploaded(mediaId);
    }
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const cancelUploadingMedias = async (ids = '') => {
  try {
    await Put(`${apiUrl}${uploadMediaAPI?.updateUploadRefresh}`, { mediaIds: ids }, true);

    store.dispatch(fetchUploadMediaListTableAction({
      video_type: 3,
      org_id: getCurrentOrgId(),
      user_id: getCurrentUserId(),
    }));

  } catch (error) {
    console.error('error', error);
  }
};

export const refereshUploads = () => {
  return Put(`${apiUrl}${uploadMediaAPI.updateUploadRefresh}`, {
    isLogout: true,
    orgId: getCurrentOrgId(),
    userId: getCurrentUserId()
  }, true);
};

export { cancelMediaUpload, intializeUpload, updateUploadStatus };
