import React from 'react';
import { ImgLogoNewGreen } from '../../assets/images';

function Logoheader(props) {
    return (
        <div className="header_sticky">
            <div className="header_sticky_container">
                <div className="header_sticky_inner">
                    <a className="logo_link" href="/">
                        <img
                            src={ImgLogoNewGreen}
                            alt="mirrorfly logo"
                            width="157px"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Logoheader;
