import { DO_GET_PAYMENTS_HISTORY_LIST, GET_RAZORPAY_SESSION_ID, DO_GET_PAYMENT_OVERVIEW_DATA,
  GET_PAYPAL_SESSION_ID, UPDATE_USER_PLANID_CAMPAIGN } from '../actionTypes/paymentsTypes';
import store from '../index';

export const getPaymentsHistoryListAction = (data = {}) => {
  if (!data.organisationId) return false;
  store.dispatch({ type: DO_GET_PAYMENTS_HISTORY_LIST, data });
};

export const getPaymentOverviewAction = (data = {}) => {
  store.dispatch({type: DO_GET_PAYMENT_OVERVIEW_DATA, data });
};

export const getRazorPaySessionId = (data = {}) => {
  store.dispatch({ type: "DO_LOADING_PAGE", loading: true });
  store.dispatch({ type: GET_RAZORPAY_SESSION_ID, data });
};

export const getPayPalSessionId = (data = {}) => {
  store.dispatch({ type: "DO_LOADING_PAGE", loading: true });
  store.dispatch({ type: GET_PAYPAL_SESSION_ID, data });
};

export const updateUserPlanIDCampaign = (data = {}) => {
  store.dispatch({ type: "DO_LOADING_PAGE", loading: true });
  store.dispatch({ type: UPDATE_USER_PLANID_CAMPAIGN, data });
};
