export const initStateCommon = {
  paymentsHistoryList: [],
  paymentsOverviewData : {},
};

export const paymentsReducer = (state = initStateCommon, action = {}) => {
  switch (action.type) {
    case 'GET_PAYMENTS_HISTORY_LIST': {
      return {
        ...state,
        paymentsHistoryList: action.paymentsHistoryList
      };
    }
    case 'PAYMENTS_OVERVIEW_DATA': {
      return {
        ...state,
        paymentsOverviewData: action.paymentsOverviewData
      };
    }
    case 'GET_PAYMENTS_HISTORY_LIST_': {
      return {
        ...state,
        paymentsHistoryList: action.paymentsHistoryList_
      };
    }
    default:
      return {
        ...state
      };
  }
};
