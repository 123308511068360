import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { IconArrowLeft } from '../../../../assets/otf';
import { useTranslation } from 'react-i18next';

export function LayoutHeader(props) {
    const { pageDesc = "", btn1 = false, btn1Icon = "", btn1Text = "", btn1Action = () => { }, enablebackAction = false, backAction = () => { },
        btn2 = false, btn3 = false, btn2Icon = "", btn2Text = "", btn2Action = () => { }, btn3Text = "", btn3Action = () => { },
        btn4 = false, btn4Icon = "", btn4Text = "", headerName = "", teamsheader = "", videosheader = "", btn3className = "", customerName = "",
        exportFeature = "", btn6 = false, btn6Text = "", btn6Action = () => { } } = props;

    const location = useLocation() || {}; //get user path
    const { pathname = "" } = location;
    const [pageName, setPageName] = useState("");
    const { t } = useTranslation();

    // landing page welcome note
    const handlePageName = (path = "") => {
        if (path.includes('/profile/')) {
            return setPageName(t("Profile"));
        }
        if (path.includes('/no-of-broadcasts/')) {
            return setPageName(t("No. of Streams"));
        }
        switch (path) {
            case "/users":
                return setPageName(t("Members"));
            case "/teams":
                return setPageName(t("Teams"));
            case "/broadcast":
                return setPageName(t("Events"));
            case "/recordings":
                return setPageName(t("Video Library"));
            case "/video-upload":
                return setPageName(t("Upload Video"));
            case "/integration":
                return setPageName(t("Social Destinations"));
            case "/customers":
                return setPageName(t("Customers"));
            case "/payments":
                return setPageName(t("Subscriptions"));
            case "/payments-info":
                return setPageName(t("Payments"));
            case "/payments-detail":
                return setPageName(t("Payments"));
            case "/raise-ticket":
                return setPageName(t("Help & Support"));
            case "/no-of-broadcasts":
                return setPageName(t("No. of Streams"));
            case "/analytics":
                return setPageName(t("Analytics"));
            case "/profile":
                return setPageName(t("Profile"));
            default:
                return setPageName(t(""));
        }
    };

    useEffect(() => {
        handlePageName(pathname);
    }, [pathname]);

    /*** Video Upload Start */
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const uploadMediaFile = (event) => {
        const file = event.target.files[0];
        if (file) {
            btn6Action(file);
        }
    };
    /*** Video Upload End */
    return (
        <div className={`common_layout_header ${teamsheader ? "teamsheader" : ""} ${videosheader ? "videosheader" : ""}`}>
            <div className='common_layout_header_card'>
                <div className='common_layout_header_card_left'>
                    <div className='card_header'>
                        {!!enablebackAction && <button onClick={backAction} title='back' className='card_header_back'><IconArrowLeft /></button>}
                        <h3
                            className='flex card_header_text'> <div className='pagetitle' title={headerName ? headerName : pageName}>
                                {headerName || pageName} {customerName ? "/" : null} </div>
                            {customerName ? <div className='sublink' title={customerName}>{customerName}</div> : <></>} </h3>
                    </div>
                    <small className='card_header_desc'>{pageDesc}</small>
                </div>
                <div className='common_layout_header_card_right'>
                    {btn2 && <button onClick={btn2Action} className='btn_card btn_card_4' type='button'>
                        <div className='btn_card_desc'>
                            <div className='btn_card_icon'>
                                {btn2Icon}
                            </div>
                            <div className='btn_card_text'>
                                <strong className='btn_card_name'>{btn2Text}</strong>
                            </div>
                        </div>
                    </button>
                    }
                    {btn4 && <button onClick={btn2Action} className='btn_card btn_card_4 btn_card_4--2' type='button'>
                        <div className='btn_card_desc'>
                            <div className='btn_card_icon'>
                                {btn4Icon}
                            </div>
                            <div className='btn_card_text'>
                                <strong className='btn_card_name'>{btn4Text}</strong>
                            </div>
                        </div>
                    </button>
                    }
                    {btn1 && <button onClick={btn1Action} className='btn_card btn_card_4 btn_card_4--1' type='button'>
                        <div className='btn_card_desc'>
                            <div className='btn_card_icon'>
                                {btn1Icon}
                            </div>
                            <div className='btn_card_text'>
                                <strong className='btn_card_name'>{btn1Text}</strong>
                            </div>
                        </div>
                    </button>}
                    {btn3 && <button onClick={btn3Action} className={`btn_card btn_card_3 ${btn3className}`} type='button'>
                        <strong className='btn_card_name'>{btn3Text}</strong>
                    </button>
                    }
                    {btn6 && <button onClick={handleButtonClick} className={`btn_card btn_card_3 upload ${btn3className}`} type='button'>
                        <strong className='btn_card_name'>{btn6Text}</strong>
                    </button>
                    }
                    <input
                        type="file"
                        id="media_upload"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        accept=".mp4,.mov"
                        onChange={uploadMediaFile}
                        onClick={(e) => {
                            e.target.value = null;
                        }}
                    />
                    {exportFeature}
                </div >
            </div >
        </div >
    );
};

export default LayoutHeader;
